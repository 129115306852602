import { EditorView } from 'prosemirror-view'
import { memo, useState } from 'react'
import { EditorProps } from '.'
import { Designed } from '../../../../types'
import { Mathdown } from '../../compiler'
import { useMathdownEditorState } from '../context'
import { useMathdownPlugins } from '../plugins'
import { schema } from '../schema'
import { mathdownToNode, nodeToMathdown } from '../utils'
import { MobileMenu } from './menu'
import { ProseMirrorEditor } from './prose_mirror'

const EditorImpl = ({
  onChange: handleChange,
  onSave: handleSave,
  defaultValue = '',
  macros,
  references,
  windowMode,
  mobileMenuPosition,
  room,
  // isValid, // TODO 適した処理を実装
  // isInvalid, // TODO 適した処理を実装
  className,
  style,
}: Designed<EditorProps>) => {
  const [editor, setEditor] = useState<EditorView | null>(null)
  const { setSelection, setFocus } = useMathdownEditorState()
  const [editorContainerElement, setEditorContainerElement] =
    useState<HTMLDivElement>()

  const { plugins } = useMathdownPlugins({
    editorContainerElement,
    macros,
    handleFocus: setFocus,
    handleSelection: setSelection,
    handleSave,
  })

  return (
    <div style={style} className={className}>
      <div
        className={windowMode === 'input' ? 'd-block h-100 w-100' : 'd-none'}
      >
        {editor && (
          <MobileMenu
            editor={editor}
            className="bg-light rounded-0 py-2"
            menuPosition={mobileMenuPosition}
          />
        )}
        <ProseMirrorEditor
          editorContainerRefSetter={setEditorContainerElement}
          defaultValue={defaultValue}
          onChange={handleChange}
          onSave={handleSave}
          onCreateEditorView={setEditor}
          toNode={mathdownToNode}
          fromNode={nodeToMathdown}
          plugins={plugins}
          schema={schema}
          room={room}
        />
      </div>
      <Mathdown
        mathdown={defaultValue}
        className={
          windowMode !== 'input'
            ? `h-100 w-100 overflow-auto flex-grow-1 text-break pt-3 mb-6`
            : 'd-none'
        }
        design={'colorful'}
        macros={macros}
        references={references}
        dynamic
      />
    </div>
  )
}

export const MobileMathdownEditor = memo(EditorImpl)
