import Link from 'next/link'
import { FaLock } from 'react-icons/fa'
import { ESArticle } from '../../elastic_search'
import { Article } from '../../firebase'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'

type Props = {
  article: Article | ESArticle
}

export const ArticleTitleLink = ({
  article,
  ...containerProps
}: Designed<Props>) => (
  <Link href={`/articles/${article.id}`} passHref>
    <a {...containerProps}>
      {article.visibility !== 'public' && (
        <IconWrapper className="fs-7 mx-1">
          <FaLock />
        </IconWrapper>
      )}
      {article.title}
    </a>
  </Link>
)
