import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { PromiseVoid } from '../types'

type Props = {
  title: string
  show: boolean
  children: React.ReactNode
  yesLabel: string
  noLabel: string
  onSelectYes: () => PromiseVoid
  onSelectNo: () => PromiseVoid
  onHide?: () => PromiseVoid
}

export const ConfirmationPopup = ({
  title,
  show,
  children,
  yesLabel,
  noLabel,
  onSelectNo: handleNo,
  onSelectYes: handleYes,
  onHide: handleHide = handleNo,
}: Props) => {
  return (
    <Modal show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleNo} variant="secondary" size="sm">
          {noLabel}
        </Button>
        <Button onClick={handleYes} size="sm">
          {yesLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
