import {
  insertAxmBox,
  insertConjBox,
  insertCorBox,
  insertDefBox,
  insertExBox,
  insertExcBox,
  insertFmlBox,
  insertLemBox,
  insertNormalBox,
  insertPrfBox,
  insertPropBox,
  insertRemBox,
  insertThmBox,
} from '../../../../../commands'
import { OperationItem } from '../../OperationItem'

export const formatDropdownData = [
  {
    component: OperationItem,
    children: <span>囲み枠</span>,
    command: insertNormalBox,
  },
  {
    component: OperationItem,
    children: <span>予想</span>,
    command: insertConjBox,
  },
  {
    component: OperationItem,
    children: <span>公理</span>,
    command: insertAxmBox,
  },
  {
    component: OperationItem,
    children: <span>定義</span>,
    command: insertDefBox,
  },
  {
    component: OperationItem,
    children: <span>命題</span>,
    command: insertPropBox,
  },
  {
    component: OperationItem,
    children: <span>公式</span>,
    command: insertFmlBox,
  },
  {
    component: OperationItem,
    children: <span>補題</span>,
    command: insertLemBox,
  },
  {
    component: OperationItem,
    children: <span>定理</span>,
    command: insertThmBox,
  },
  {
    component: OperationItem,
    children: <span>系</span>,
    command: insertCorBox,
  },
  {
    component: OperationItem,
    children: <span>証明</span>,
    command: insertPrfBox,
  },
  {
    component: OperationItem,
    children: <span>例</span>,
    command: insertExBox,
  },
  {
    component: OperationItem,
    children: <span>問題</span>,
    command: insertExcBox,
  },
  {
    component: OperationItem,
    children: <span>注意</span>,
    command: insertRemBox,
  },
]
