import {
  QueryDocumentSnapshot,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { useCallback, useMemo, useReducer, useRef, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Comment, Commentable, CommentsSectionSetting } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import {
  currentUserCommentsRef,
  newCommentsRef,
  sortedCommentsRefWithLimit,
} from '../../models/message'

const COMMENT_LIMIT = 5

export const useComments = (
  commentable: Commentable,
  setting?: CommentsSectionSetting
) => {
  const { user, isLoading: isUserLoading } = useAuthState()
  const now = useRef(new Date())

  const isHiddenComment = (comment: Comment) => {
    const hiddenUids = setting?.hidden_uids ?? []
    return (
      comment.is_hidden ??
      hiddenUids.find((uid) => uid === comment.created_by) !== undefined
    )
  }

  const isCurrentUserComment = (comment: Comment) => {
    if (!user) return false
    return comment.created_by === user.uid
  }

  const [newComments] = useCollectionData(
    newCommentsRef(commentable, now.current)
  )
  const newHiddenComments = useMemo(
    () =>
      newComments?.filter(
        (comment) => isHiddenComment(comment) && !isCurrentUserComment(comment)
      ) ?? [],
    [newComments]
  )

  const newShowComments = useMemo(
    () =>
      newComments?.filter(
        (comment) => !isHiddenComment(comment) && !isCurrentUserComment(comment)
      ) ?? [],
    [newComments]
  )

  const [currentUserComments] = useCollectionData(
    !isUserLoading && user
      ? currentUserCommentsRef(commentable, user.uid)
      : null
  )

  const [oldShowComments, setOldShowComments] = useState<Comment[]>([])
  const [oldHiddenComments, setOldHiddenComments] = useState<Comment[]>([])

  const lastComment = useRef<QueryDocumentSnapshot<Comment> | null>(null)
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useReducer(() => true, false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    if (isUserLoading) return

    setFetching(true)
    const loadedCommentsSnapshot = await getDocs(
      query(
        sortedCommentsRefWithLimit(
          commentable.ref,
          lastComment.current,
          COMMENT_LIMIT
        ),
        where('created_at', '<=', now.current)
      )
    )

    const loadedHiddenComments: Comment[] = []
    const loadedShowComments: Comment[] = []

    for (const loadedCommentDoc of loadedCommentsSnapshot.docs) {
      const loadedComment = loadedCommentDoc.data()
      if (isCurrentUserComment(loadedComment)) continue
      if (isHiddenComment(loadedComment))
        loadedHiddenComments.push(loadedComment)
      else loadedShowComments.push(loadedComment)
    }

    setOldShowComments((current) => {
      return [...current, ...loadedShowComments]
    })

    setOldHiddenComments((current) => {
      return [...current, ...loadedHiddenComments]
    })

    if (!loadedCommentsSnapshot.empty) {
      lastComment.current = loadedCommentsSnapshot.docs.slice(-1)[0]
    }
    if (loadedCommentsSnapshot.docs.length < COMMENT_LIMIT) {
      setIsLast()
    }
    setFetching(false)
  }, [isUserLoading])

  const hiddenComments = useMemo(() => {
    return [...newHiddenComments, ...oldHiddenComments]
  }, [oldHiddenComments, newHiddenComments])

  const showComments = useMemo(() => {
    return [...newShowComments, ...oldShowComments]
  }, [oldShowComments, newShowComments])

  return {
    loadMore,
    hiddenComments,
    showComments,
    currentUserComments,
    isLast,
    fetching,
  }
}
