import { Message } from '../firebase'
import { getSigmoidPainless, sigmoid } from './sigmoid'

export const MessageEvaluationIndicator = {
  weights: {
    good_count: 1.0,
    bad_count: -1.0,
    reports_score: -3.0,
    // 追加する場合はcalcAuthorEvaluation関数も更新すること
  },
  sigmoid: {
    a: 1 / 8,
  },
}

export const getMessageEvaluationPainless = () => {
  const weightKeys = Object.keys(
    MessageEvaluationIndicator['weights']
  ) as (keyof typeof MessageEvaluationIndicator['weights'])[]
  const x = weightKeys
    .map(
      (key) =>
        `${MessageEvaluationIndicator['weights'][key]} * doc['${key}'].value`
    )
    .join(' + ')
  return getSigmoidPainless(x, MessageEvaluationIndicator['sigmoid'])
}

export const calcMessageEvaluation = (message: Message) => {
  const weights = MessageEvaluationIndicator.weights
  const { good_count, bad_count, reports_score } = message
  return sigmoid(
    good_count * weights['good_count'] +
      bad_count * weights['bad_count'] +
      reports_score * weights['reports_score'],
    MessageEvaluationIndicator.sigmoid.a
  )
}
