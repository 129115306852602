import { calPill } from './cal'
import { decorationsPill } from './decorations'
import { frakPill } from './frak'
import { greekLettersPill } from './greek_letters'
import { othersPill } from './others'
import { scrPill } from './scr'
import { spacesPill } from './spaces'

export const charactersTab = {
  tab: 'characters',
  label: '文字',
  pillData: [
    calPill,
    decorationsPill,
    frakPill,
    greekLettersPill,
    othersPill,
    scrPill,
    spacesPill,
  ],
}
