import { Node } from 'prosemirror-model'
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { PromiseVoid } from '../../../../types'
import { isBlank } from '../../../../utils'

export type SearchState = {
  active: boolean
  query: string | null
  cursor: number
  currentIndex: number | null
  decorations: DecorationSet
}

export const searchKey = new PluginKey<SearchState>('searchReplace')

const findMatches = (doc: Node, query: string | null, cursor: number) => {
  if (query === null || query === '')
    return { decorations: DecorationSet.empty, currentIndex: null }
  const decorations: Decoration[] = []
  const regex = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g')
  doc.descendants((node, pos) => {
    if (node.isText) {
      let match
      while ((match = regex.exec(node.text ?? '')) !== null) {
        const start = pos + match.index
        const end = start + match[0].length
        decorations.push(
          Decoration.inline(start, end, {
            class: 'search-highlight',
          })
        )
      }
    }
  })

  // cursorの直後を選択状態にする
  const decorationSet = DecorationSet.create(doc, decorations)
  const decorationsAfterCursor = decorationSet.find(cursor)
  if (decorationsAfterCursor.length === 0)
    return { decorations: decorationSet, currentIndex: null }

  return {
    decorations: decorationSet.remove([decorationsAfterCursor[0]]).add(doc, [
      Decoration.inline(
        decorationsAfterCursor[0].from,
        decorationsAfterCursor[0].to,
        {
          class: 'search-highlight selected',
        }
      ),
    ]),
    currentIndex: decorationSet.find().length - decorationsAfterCursor.length,
  }
}

const DEFAULT_SEARCH_STATE = {
  query: null,
  cursor: 0,
  currentIndex: 0,
  active: false,
  decorations: DecorationSet.empty,
}

export const searchPlugin = (
  handleSearch?: (state: SearchState) => PromiseVoid
) =>
  new Plugin<SearchState>({
    key: searchKey,

    state: {
      init(): SearchState {
        return DEFAULT_SEARCH_STATE
      },
      apply(tr: Transaction, prevState: SearchState): SearchState {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const meta = tr.getMeta(searchKey)
        if (!isBlank(meta)) {
          const { query, cursor, active } = meta as SearchState
          if (!active) {
            if (handleSearch) void handleSearch(DEFAULT_SEARCH_STATE)
            return DEFAULT_SEARCH_STATE
          }
          const { decorations, currentIndex } = findMatches(
            tr.doc,
            query,
            cursor
          )
          const state = {
            query,
            decorations,
            currentIndex,
            cursor,
            active,
          }
          if (handleSearch) void handleSearch(state)
          return state
        }
        if (tr.docChanged) {
          if (!prevState.active) {
            if (handleSearch) void handleSearch(DEFAULT_SEARCH_STATE)
            return DEFAULT_SEARCH_STATE
          }
          const { decorations, currentIndex } = findMatches(
            tr.doc,
            prevState.query,
            prevState.cursor
          )
          const state = {
            ...prevState,
            decorations,
            currentIndex,
          }
          if (handleSearch) void handleSearch(state)
          return state
        }
        return prevState
      },
    },

    props: {
      decorations(state: EditorState) {
        return this.getState(state)?.decorations
      },
    },
  })
