import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { SubmitHandler, useController, useForm } from 'react-hook-form'
import { TypeBadgeSelector } from '.'
import { usePopupMessage } from '../../assets/messenger'
import { SpinnerButton } from '../../assets/spinners'
import {
  Comment,
  Commentable,
  DocRef,
  Reply,
  Timestamp,
  updateDoc,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useCurrentUserRoles } from '../../hooks'
import {
  MessageParams,
  createComment,
  createCommentDraft,
  createReply,
  createReplyDraft,
} from '../../models/message'
import { Designed, PromiseVoid } from '../../types'
import { logEvent } from '../../utils'

type Props = (
  | {
      type: 'comment'
      parentRef: DocRef<Commentable>
      message?: Comment
      onSave?: (commentRef: DocRef<Comment>) => PromiseVoid
    }
  | {
      type: 'reply'
      parentRef: DocRef<Comment>
      message?: Reply
      onSave?: (replyRef: DocRef<Reply>) => PromiseVoid
    }
) & {
  onCancel?: () => PromiseVoid
}

const DEFAULT_MESSAGE = { type: 'impression', body: '' } as const

export const MessageForm = ({
  type,
  message,
  parentRef,
  onCancel: handleCancel,
  onSave: handleSave,
  ...wrapperProps
}: Designed<Props>) => {
  const { user } = useAuthState()
  const router = useRouter()
  const { setPopupMessage } = usePopupMessage()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { hasRole } = useCurrentUserRoles()
  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    register,
    control,
    reset,
    watch,
  } = useForm<MessageParams>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: message
      ? { type: message.type, body: message.body }
      : DEFAULT_MESSAGE,
  })

  const {
    field: { onChange: handleTypeChange, value: messageType },
  } = useController({
    name: 'type',
    control,
    rules: { required: true },
  })

  const currentValue = watch()

  const onSubmit: SubmitHandler<MessageParams> = async (params) => {
    if (!user) throw new Error('This request needs to authentication.')
    setIsSubmitting(true)
    if (type === 'comment') {
      try {
        const commentRef = message
          ? await updateDoc(
              message.ref,
              hasRole('mathlog_admin') === false
                ? { ...params, updated_by_user_at: Timestamp.now() }
                : params
            )
          : await createComment(user.uid, parentRef, params)
        if (handleSave) await handleSave(commentRef)
        reset(DEFAULT_MESSAGE)
        setPopupMessage(`コメントを${message ? '更新' : '作成'}しました。`)
        logEvent(message ? 'update_comment' : 'create_comment', {
          commentable_path: parentRef.path,
          uid: user.uid,
          id: commentRef.id,
        })
      } catch (error) {
        logEvent(message ? 'failed_update_comment' : 'failed_create_comment', {
          commentable_path: parentRef.path,
          uid: user.uid,
          id: message?.id,
        })
        setPopupMessage(
          `コメントの${message ? '更新' : '作成'}に失敗しました。`,
          'danger'
        )
      }
    } else {
      try {
        const replyRef = message
          ? await updateDoc(
              message.ref,
              hasRole('mathlog_admin') === false
                ? { ...params, updated_by_user_at: Timestamp.now() }
                : params
            )
          : await createReply(user.uid, parentRef, params)
        if (handleSave) await handleSave(replyRef)
        reset(DEFAULT_MESSAGE)
        setPopupMessage(`返信を${message ? '更新' : '作成'}しました。`)
        logEvent(message ? 'update_reply' : 'create_reply', {
          commentable_path: parentRef.parent.parent?.path,
          uid: user.uid,
          comment_id: parentRef.id,
          id: replyRef.id,
        })
      } catch (error) {
        logEvent(message ? 'failed_update_reply' : 'failed_create_reply', {
          commentable_path: parentRef.parent.parent?.path,
          uid: user.uid,
          comment_id: parentRef.id,
          id: message?.id,
        })
        setPopupMessage(
          `返信の${message ? '更新' : '作成'}に失敗しました。`,
          'danger'
        )
      }
    }
    setIsSubmitting(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...wrapperProps}>
      <Button
        className="text-success"
        variant="link"
        onClick={async () => {
          if (!user) throw new Error('This request needs to authentication.')
          if (type === 'comment') {
            const draftRef = await createCommentDraft(
              user.uid,
              parentRef,
              currentValue,
              message?.ref
            )
            await router.push(`/comment_drafts/${draftRef.id}/edit`)
          } else {
            const draftRef = await createReplyDraft(
              user.uid,
              parentRef,
              currentValue,
              message?.ref
            )
            await router.push(`/reply_drafts/${draftRef.id}/edit`)
          }
        }}
      >
        エディタで編集
      </Button>
      <TypeBadgeSelector
        className="mb-2"
        type={messageType}
        onChange={handleTypeChange}
      />
      <Form.Control
        {...register('body', {
          required: true,
        })}
        isInvalid={!!errors.body}
        placeholder={`${
          type === 'comment' ? 'コメント' : '返信'
        }を入力してください`}
        as="textarea"
        rows={6}
        className="mb-2"
      />
      <div className="d-flex gap-2">
        <SpinnerButton
          loading={isSubmitting}
          loadingText={message !== undefined ? '更新中...' : '追加中...'}
          variant="primary"
          size="sm"
          disabled={!isDirty || !isValid}
        >
          {message !== undefined ? '更新' : '追加'}
        </SpinnerButton>
        <Button
          variant="secondary"
          size="sm"
          onClick={async () => {
            reset(DEFAULT_MESSAGE)
            if (handleCancel) await handleCancel()
          }}
        >
          キャンセル
        </Button>
      </div>
    </Form>
  )
}
