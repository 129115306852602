import * as renderer from '../../renderer/tex'
import { TexImage, Token } from '../../types'
import { parseInlineToTex } from './inline'
import { parseList } from './list'
import { parseTableToTex } from './table'

export const parseBlockToTex = (
  tokens: Token[],
  labels: Map<string, string> = new Map(),
  images: Map<string, TexImage> = new Map(),
  indent = 0,
  top = true
): string => {
  let out = ''
  for (let tokenIndex = 0; tokenIndex < tokens.length; tokenIndex++) {
    const token = tokens[tokenIndex]
    switch (token.type) {
      case 'space': {
        continue
      }
      case 'hr': {
        out += renderer.hr()
        continue
      }
      case 'heading': {
        out += renderer.heading(
          token.depth,
          parseInlineToTex(token.tokens, labels, images),
          token.label
        )
        continue
      }
      case 'code': {
        out += renderer.code(token.text)
        continue
      }
      case 'table': {
        out += parseTableToTex(token, labels, images)
        continue
      }
      case 'blockquote': {
        const body = parseBlockToTex(token.tokens, labels, images, indent, top)
        out += renderer.blockquote(body)
        continue
      }
      case 'list': {
        out += parseList(token, indent, labels, images)
        continue
      }
      case 'html': {
        out += renderer.blockHtml(
          parseInlineToTex(token.tokens, labels, images)
        )
        continue
      }
      case 'paragraph': {
        out += renderer.paragraph(
          parseInlineToTex(token.tokens, labels, images)
        )
        continue
      }
      case 'box': {
        out += renderer.box(
          token.boxType,
          parseInlineToTex(token.titleTokens, labels, images),
          parseBlockToTex(token.tokens, labels, images, indent, top),
          token.title,
          token.label
        )
        continue
      }
      case 'text': {
        let body = token.tokens
          ? parseInlineToTex(token.tokens, labels, images)
          : token.text
        while (tokenIndex + 1 < tokens.length) {
          const nextToken = tokens[tokenIndex + 1]
          if (nextToken.type === 'text') {
            body +=
              '\n' +
              (nextToken.tokens
                ? parseInlineToTex(nextToken.tokens, labels, images)
                : token.text)
            tokenIndex++
          } else break
        }
        out += top ? renderer.paragraph(body) : body
        continue
      }

      default: {
        indent = 0
        const errMsg = 'Token with "' + token.type + '" type was not found.'
        console.error(errMsg)
        return ''
      }
    }
  }

  return out
}
