import { EditorView } from 'prosemirror-view'
import { memo, useRef, useState } from 'react'
import {
  DropdownButton as BootstrapDropdownButton,
  ButtonGroup,
} from 'react-bootstrap'
import { MathJax } from '../../../../mathjax'
import { FormulaButtonData } from '../../formula_palette/data/type'

type MenuDataType = FormulaButtonData & {
  component: any
}

type Props = {
  menuData: MenuDataType[]
  editor: EditorView
  menuStyle: React.CSSProperties
  children: React.ReactNode
  menuClassName: string
}

const FormulaDropdownImpl = ({
  children,
  menuData,
  editor,
  menuStyle,
  menuClassName,
}: Props) => {
  const [show, setShow] = useState(false)
  const formulaeCache = useRef<Record<string, string>>({})

  return (
    <>
      <BootstrapDropdownButton
        title={children}
        variant={show ? 'dark' : 'outline-dark'}
        size="sm"
        onToggle={() => setShow(!show)}
        as={ButtonGroup}
        show={show}
      >
        <div className={menuClassName} style={menuStyle}>
          {menuData.map(
            ({ component: Component, formula, ...props }, index) => (
              <Component
                key={index}
                cache={formulaeCache.current[formula]}
                editor={editor}
                onClick={() => {
                  setShow(false)
                }}
                formula={formula}
                {...props}
              />
            )
          )}
        </div>
      </BootstrapDropdownButton>

      {/* 数式のキャッシュ BootstrapDropdownButton の後に置かないと ButtonGroup の 左端の border が表示されなくなるので注意 */}
      <div className="d-none">
        {menuData.map(({ formula }) => (
          <MathJax
            key={formula}
            onTypeset={(result) => {
              formulaeCache.current[formula] = result
            }}
          >{`$\\displaystyle{${formula}}$`}</MathJax>
        ))}
      </div>
    </>
  )
}

export const FormulaDropdown = memo(FormulaDropdownImpl)
