import { getMathlogReleaseDate } from 'web_core/shared/utils'

// apps/functions/src/local/sitemap_generator/ranking.tsに同様の関数あり
export const getStrYearAndMonth = (date: Date) => {
  const strMonth = `0${date.getMonth() + 1}`.slice(-2)
  return `${date.getFullYear()}/${strMonth}`
}

export const isValidRankingPeriod = (year: string, month?: string) => {
  const now = new Date()
  const numberYear = Number(year)
  const mathlogReleaseDate = getMathlogReleaseDate()
  if (
    month === undefined &&
    numberYear >= mathlogReleaseDate.getFullYear() &&
    numberYear <= now.getFullYear()
  )
    return true
  const numberMonth = Number(month) - 1
  const date = new Date(numberYear, numberMonth)
  return (
    date.getSeconds() <= now.getSeconds() &&
    date.getSeconds() >= mathlogReleaseDate.getSeconds()
  )
}

// apps/functions/src/local/sitemap_generator/ranking.tsに同様の関数あり
export const makeRankingDateList = () => {
  const date = getMathlogReleaseDate()
  const now = new Date()
  const list: Date[] = []
  while (date.getTime() <= now.getTime()) {
    list.push(new Date(date.getFullYear(), date.getMonth()))
    date.setMonth(date.getMonth() + 1)
  }
  return list
}
