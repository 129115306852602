import { arrayRemove, arrayUnion } from 'firebase/firestore'
import { useRef, useState } from 'react'
import { Alert, Button, Form, FormGroup, Modal } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import {
  CommentsSectionSetting,
  Message,
  profileRef,
  updateDoc,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useCurrentUserRoles } from '../../hooks'
import { usePopupMessage } from '../messenger'

type Props = {
  message: Message
  setting?: CommentsSectionSetting
  commentSectionOwnerUid: string
}

export const HideButton = ({
  message,
  setting,
  commentSectionOwnerUid,
}: Props) => {
  const { user } = useAuthState()
  const { hasRole } = useCurrentUserRoles()
  const [show, setShow] = useState(false)
  const isHidden = useRef(
    message.is_hidden ??
      setting?.hidden_uids.includes(message.created_by) ??
      false
  )

  const [action, setAction] = useState<
    'hide_message' | 'hide_user' | 'show_user' | 'show_message' | null
  >(null)
  const [processed, setProcessed] = useState(false)
  const { setPopupMessage } = usePopupMessage()

  if (
    (!user || user.uid !== commentSectionOwnerUid) &&
    !hasRole('mathlog_admin')
  )
    return <></>

  if (processed)
    return (
      <Button
        variant="link"
        size="sm"
        disabled
        className="text-muted d-print-none"
      >
        {isHidden.current ? (
          <IconWrapper suffix="再表示済み">
            <FaEye />
          </IconWrapper>
        ) : (
          <IconWrapper suffix="非表示済み">
            <FaEyeSlash />
          </IconWrapper>
        )}
      </Button>
    )

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>コメントの非表示設定</Modal.Header>
        <Modal.Body>
          <Alert variant="warning py-2">
            <ul className="ps-2 mb-0">
              <li>
                コメントを非表示にした場合は他のユーザと区別して表示されます。
              </li>
              <li>
                非表示にした場合でも他のユーザは表示ボタンを押すことで閲覧することができます。
              </li>
              <li>
                ユーザ単位の非表示は他のあなたのコンテンツのコメント欄にも適用されます。
              </li>
              <li>
                非表示にしたコメントに関する通知は、これ以降届かなくなります。
              </li>
            </ul>
          </Alert>
          <FormGroup className="d-flex flex-column gap-2">
            {isHidden.current ? (
              <>
                <Form.Check
                  type="radio"
                  id={'show_message'}
                  onChange={(e) => {
                    if (e.currentTarget.checked) setAction('show_message')
                  }}
                  checked={action === 'show_message'}
                  label="このコメントのみを再表示"
                />

                {message.is_hidden === undefined && (
                  <Form.Check
                    type="radio"
                    id={'show_user'}
                    onChange={(e) => {
                      if (e.currentTarget.checked) setAction('show_user')
                    }}
                    checked={action === 'show_user'}
                    label={
                      <>
                        このユーザのコメントを全て再表示
                        <br />
                        <Form.Text className="small">
                          個別で非表示にしたコメントは対象外です。
                        </Form.Text>
                      </>
                    }
                  />
                )}
              </>
            ) : (
              <>
                <Form.Check
                  type="radio"
                  id={'hide_message'}
                  onChange={(e) => {
                    if (e.currentTarget.checked) setAction('hide_message')
                  }}
                  checked={action === 'hide_message'}
                  label="このコメントのみを非表示"
                />
                <Form.Check
                  type="radio"
                  id={'hide_user'}
                  onChange={(e) => {
                    if (e.currentTarget.checked) setAction('hide_user')
                  }}
                  checked={action === 'hide_user'}
                  label={
                    <>
                      このユーザのコメントを全て非表示
                      <br />
                      <Form.Text className="small">
                        個別で表示設定しているコメントは対象外です。
                      </Form.Text>
                    </>
                  }
                />
              </>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setShow(false)} variant="secondary">
            キャンセル
          </Button>
          <Button
            size="sm"
            onClick={async () => {
              switch (action) {
                case 'hide_message':
                  await updateDoc<Message>(message.ref, { is_hidden: true })
                  break
                case 'show_message':
                  await updateDoc<Message>(message.ref, { is_hidden: false })
                  break
                case 'show_user':
                  await updateDoc(profileRef(commentSectionOwnerUid), {
                    'comments_section_setting.hidden_uids': arrayRemove(
                      message.created_by
                    ),
                  })
                  break
                case 'hide_user':
                  await updateDoc(profileRef(commentSectionOwnerUid), {
                    'comments_section_setting.hidden_uids': arrayUnion(
                      message.created_by
                    ),
                  })
                  break

                default:
                  throw new Error('Unreachable')
              }
              if (action === 'show_user' || action === 'hide_user') {
                setPopupMessage(
                  '設定を即座に反映させるためには再ロードが必要です。',
                  'danger'
                )
              }
              setProcessed(true)
              setShow(false)
            }}
            disabled={
              isHidden.current
                ? action !== 'show_message' && action !== 'show_user'
                : action !== 'hide_message' && action !== 'hide_user'
            }
          >
            決定
          </Button>
        </Modal.Footer>
      </Modal>
      {!isHidden.current ? (
        <Button
          variant="link"
          size="sm"
          className="text-muted d-print-none"
          onClick={() => setShow(true)}
        >
          <IconWrapper suffix="非表示">
            <FaEyeSlash />
          </IconWrapper>
        </Button>
      ) : (
        <Button
          variant="link"
          size="sm"
          className="text-muted d-print-none"
          onClick={() => setShow(true)}
        >
          <IconWrapper suffix="再表示">
            <FaEye />
          </IconWrapper>
        </Button>
      )}
    </>
  )
}
