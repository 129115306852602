type Props = {
  price: number
  size?: 1 | 2 | 3 | 4 | 5 | 6
}

export const Price = ({ price, size = 5 }: Props) => {
  return (
    <>
      <span className={`fs-${size + 1}`}>¥</span>
      <span className={`mx-1 fs-${size}`}>
        {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
      <span className={`fs-${size + 1}`}>/月</span>
    </>
  )
}
