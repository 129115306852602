import { Stack } from 'react-bootstrap'
import { FaLock, FaMedal, FaThumbsUp, FaUserAlt } from 'react-icons/fa'
import { LinkWrapper } from '../../assets/LinkWrapper'
import {
  BookImage,
  BookOperationLinks,
  LaTeXExportButton,
} from '../../assets/book'
import { CategoryBadge } from '../../assets/category'
import { ReferenceBadge, ReferencesList } from '../../assets/reference'
import { InlineTagList } from '../../assets/tag'
import { useAccount } from '../../context/account'
import {
  Badge,
  Book,
  Category,
  Macro,
  Page,
  Profile,
  Reference,
  Tag,
  bookRefWithoutConverter,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { shouldShowAdsInContent } from '../../models/user'
import { ADSENSE_SLOTS } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { PVCounter } from '../PVCounter'
import { BadgeGift, BadgeList } from '../badge'
import { BeginnerAlert } from '../content/BeginnerAlert'
import { CollaboratorList } from '../content/CollaboratorList'
import { GoodCounter, GoodEvaluatorList } from '../evaluation'
import { BadCounter } from '../evaluation/BadCounter'
import { AdsCard } from '../google_adsense/AdsCard'
import { MathJax } from '../mathdown/mathjax'
import { MessagesCounter } from '../message/MessagesCounter'
import { ReportButton } from '../report/Button'
import { ContentTimeStamp } from '../timestamp/Timestamp'
import { UserInfo, UserItem } from '../user'

type Props = {
  book: Book
  pages: Page[]
  profile: Profile
  macros: Macro[]
  category: Category
  tags: Tag[]
  references: Reference[]
  badges: Badge[]
}

export const BookDetail = ({
  book,
  pages,
  profile,
  macros,
  category,
  tags,
  references,
  badges,
}: Props) => {
  const { user } = useAuthState()
  const { account } = useAccount()
  return (
    <>
      <div className="d-block d-sm-flex justify-content-between gap-5">
        <div className="align-self-center mb-4 flex-grow-1">
          <UserItem
            profile={profile}
            imageSize={33}
            className="mb-3"
            style={{ maxWidth: 320 }}
          />

          <Stack direction="horizontal" gap={1} className="mb-1">
            <CategoryBadge category={category} contentRef={book.ref} />
            <ReferenceBadge referable={book} />
          </Stack>
          <h1 className="h3 text-break mb-1">
            {book.visibility !== 'public' && (
              <IconWrapper className="fs-6 small mx-1">
                <FaLock />
              </IconWrapper>
            )}
            {book.title}
          </h1>
          <InlineTagList tags={tags} className="mb-3" />
          <div className="d-flex flex-wrap gap-3">
            <GoodCounter value={book.good_count} className="text-muted small" />
            <BadCounter value={book.bad_count} className="text-muted small" />
            <PVCounter value={book.pv} className="text-muted small" />
            <MessagesCounter
              value={book.comments_count}
              className="text-muted small"
            />
            <LaTeXExportButton
              book={book}
              author={profile}
              macros={macros}
              pages={pages}
              references={references}
              className="d-print-none"
            />
            <BookOperationLinks book={book} />
          </div>
        </div>
        <BookImage book={book} width={150} className="align-self-center" />
      </div>
      {book.is_beginner && <BeginnerAlert className="py-2 px-3 small mt-2" />}
      <h2 className="fs-5 fw-bold mt-6">概要</h2>
      <MathJax>{book.description}</MathJax>
      <h2 className="fs-5 fw-bold mt-7">目次</h2>
      <ol>
        {pages.map((page) => (
          <li className="mb-2" key={page.id}>
            <LinkWrapper href={`/books/${book.id}/${page.id}`} passHref>
              <span className="cursor-pointer text-primary">{page.title}</span>
            </LinkWrapper>
          </li>
        ))}
      </ol>
      {book.has_references && (
        <>
          <h2 className="fs-5 fw-bold mt-7">参考文献</h2>
          <ReferencesList
            references={references}
            className="gap-1 d-flex flex-column"
            amazonBookImage
            link
          />
        </>
      )}
      <div className="mt-4">
        <ContentTimeStamp content={book} />
      </div>
      {user?.uid !== book.created_by && (
        <ReportButton
          reportableRefWithoutConverter={bookRefWithoutConverter(book.id)}
          label="この本を運営に通報する"
          targetUid={book.created_by}
          className="d-print-none small text-muted mt-3"
        />
      )}
      {/* 広告 */}
      {shouldShowAdsInContent(profile?.plan, account?.plan) && (
        <AdsCard className="mt-7" slot={ADSENSE_SLOTS.ARTICLE_BOTTOM_SLOT} />
      )}
      {book.collaborator_uids.length !== 0 && (
        <div className="mt-7 d-print-none">
          <h2 className="h5">
            <IconWrapper suffix="共同編集者" className="text-muted">
              <FaUserAlt />
            </IconWrapper>
          </h2>
          <CollaboratorList
            collaboratorUids={book.collaborator_uids}
            className="mb-2"
          />
        </div>
      )}
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="この本を高評価した人" className="text-muted">
            <FaThumbsUp />
          </IconWrapper>
        </h2>
        <GoodEvaluatorList evaluable={book} />
      </div>
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="この本に送られたバッジ" className="text-muted">
            <FaMedal />
          </IconWrapper>
        </h2>
        <BadgeList size={'70'} badges={badges} className="mb-5" />
        {user !== undefined &&
          (user === null || book.created_by !== user.uid) && (
            <BadgeGift parentRef={book.ref} />
          )}
      </div>
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="投稿者" className="text-muted">
            <FaUserAlt />
          </IconWrapper>
        </h2>
        <UserInfo profile={profile} />
      </div>
    </>
  )
}

export default Page
