import {
  insertBlockquote,
  insertLink,
  searchSelectedText,
} from '../../../../commands'
import { ImageUploader } from '../../../image_uploader'
import { TableEditor } from '../../../table_editor'
import { Dropdown } from '../Dropdown'
import { FormulaDropdown } from '../FormulaDropdown'
import { OperationButton } from '../OperationButton'
import {
  decorationDropdownData,
  formatDropdownData,
  formulaDropdownData,
  headingDropdownData,
  listDropdownData,
} from './dropdown_data'

export const menuData = [
  {
    component: FormulaDropdown,
    children: <span className="fw-bold">数式</span>,
    menuClassName: 'px-2 gap-2 d-flex flex-wrap',
    menuStyle: { width: 700 }, // 横幅が md(768px) の時に綺麗に表示されるサイズ
    menuData: formulaDropdownData,
  },
  {
    component: Dropdown,
    children: <span className="fw-bold">形式</span>,
    menuData: formatDropdownData,
  },
  {
    component: Dropdown,
    children: <span className="fw-bold">見出し</span>,
    menuData: headingDropdownData,
  },
  {
    component: Dropdown,
    children: <span className="fw-bold">箇条書き</span>,
    menuData: listDropdownData,
  },
  {
    component: OperationButton,
    children: <span className="fw-bold">引用</span>,
    command: insertBlockquote,
  },
  {
    component: Dropdown,
    children: <span className="fw-bold">装飾</span>,
    menuData: decorationDropdownData,
  },
  {
    component: OperationButton,
    children: <span className="fw-bold">リンク</span>,
    command: insertLink,
  },
  {
    component: TableEditor,
    buttonLabel: '表',
    buttonSize: 'sm',
    buttonVariant: 'outline-dark',
    buttonClassName: 'fw-bold',
  },
  {
    component: ImageUploader,
  },
  {
    component: OperationButton,
    children: <span className="fw-bold">検索</span>,
    command: searchSelectedText,
  },
]
