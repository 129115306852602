import { useState } from 'react'
import { Button, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { IconType } from 'react-icons'
import { FaEye, FaPlus, FaQuestionCircle } from 'react-icons/fa'
import { MacroForm, MacroList } from '.'
import {
  Macro,
  MacrosParentRefAssignable,
  ValidDocument,
  addDoc,
  packagesRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useMedia } from '../../hooks'
import { Designed } from '../../types'
import { HELP_NOTION_LINKS, logEvent } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { WindowMode } from '../mathdown/editor/types'
import { usePopupMessage } from '../messenger'
import { LoadingSpinner } from '../spinners'
import { PackageList } from './PackageList'

type Props<T extends ValidDocument> = {
  parentRef: MacrosParentRefAssignable<T>
  macros: Macro[]
  windowMode?: WindowMode
  isDefaultSetting?: boolean
}

export const MacrosEditor = <T extends ValidDocument>({
  parentRef,
  macros,
  windowMode,
  isDefaultSetting = false,
  ...containerProps
}: Designed<Props<T>>) => {
  const { user } = useAuthState()
  const [packages] = useCollectionData(
    user && isDefaultSetting ? packagesRef(user.uid) : null
  )

  const [editingMacroId, setEditingMacroId] = useState<string>()
  const editingMacro = macros.find((m) => m.id === editingMacroId)
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const isMd = useMedia('md')

  const { setPopupMessage } = usePopupMessage()

  const createPackage = async () => {
    if (!user) return
    await addDoc(packagesRef(user.uid), { is_active: true, name: 'My package' })
    setPopupMessage('パッケージを追加しました。')
  }

  type Mode = 'input' | 'output'
  const [mode, setMode] = useState<Mode>('input')

  type Pill = {
    modeType: Mode
    name: string
    icon: IconType
  }

  const pills: Pill[] = [
    {
      modeType: 'input',
      name: '追加',
      icon: FaPlus,
    },
    {
      modeType: 'output',
      name: '確認',
      icon: FaEye,
    },
  ]

  return (
    <div {...containerProps}>
      <div className="d-flex flex-column h-100 w-100 overflow-hidden">
        <div className="flex-shrink-0 mb-2 d-md-none">
          <Nav variant="pills" className="fs-7">
            {pills.map(({ modeType, name, icon: Icon }) => (
              <Nav.Item key={name}>
                <Nav.Link
                  active={mode === modeType}
                  onClick={() => setMode(modeType)}
                  className="px-2 py-1"
                >
                  <IconWrapper suffix={name}>
                    <Icon />
                  </IconWrapper>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="d-flex flex-grow-1 overflow-hidden">
          {(isMd || mode === 'input') && (
            <MacroForm
              className="flex-grow-1 h-100 overflow-auto pe-md-3"
              style={{ minWidth: '50%' }}
              macro={editingMacro}
              parentRef={parentRef}
              onSave={(macroRef) => {
                if (!activeKeys.includes(macroRef.id))
                  setActiveKeys([...activeKeys, macroRef.id])
                setEditingMacroId(undefined)
              }}
              onCancel={() => {
                setEditingMacroId(undefined)
              }}
              packages={packages}
            />
          )}
          {(isMd || mode === 'output') && (
            <div
              className="flex-grow-1 h-100 d-flex flex-column ps-md-3"
              style={{ minWidth: '50%' }}
            >
              <div className="flex-shrink-0 d-flex justify-content-between align-items-center mb-2">
                <h2 className="fs-5 mb-0">追加済みのマクロ</h2>
                {packages && (
                  <div className="d-flex justify-content-center align-items-center">
                    <a
                      className="d-inline-flex me-2 text-muted"
                      href={HELP_NOTION_LINKS.PACKAGES_SETTING}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        logEvent('tap_package_help', {
                          uid: user?.uid,
                        })
                      }
                    >
                      <FaQuestionCircle />
                    </a>
                    {packages.length > 0 ? (
                      <Button size="sm" onClick={createPackage}>
                        パッケージを追加
                      </Button>
                    ) : (
                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip>
                            パッケージで複数のマクロをまとめて管理できます。
                          </Tooltip>
                        }
                      >
                        <Button size="sm" onClick={createPackage}>
                          パッケージを追加
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                )}
              </div>
              {isDefaultSetting && packages && packages.length > 0 ? (
                <PackageList
                  packages={packages}
                  macros={macros}
                  className="flex-grow-1 overflow-auto"
                  editingId={editingMacroId}
                  activeKeys={activeKeys}
                  setActiveKeys={setActiveKeys}
                  onClickEditButton={(macro) => setEditingMacroId(macro.id)}
                />
              ) : isDefaultSetting && !packages ? (
                <LoadingSpinner />
              ) : (
                <>
                  <MacroList
                    macros={macros}
                    className="flex-grow-1 overflow-auto"
                    editingId={editingMacroId}
                    activeKeys={activeKeys}
                    setActiveKeys={setActiveKeys}
                    onClickEditButton={(macro) => setEditingMacroId(macro.id)}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
