import { arrowPill } from './arrow'
import { dotsAndLinesPill } from './dots_and_lines'
import { figurePill } from './figure'
import { othersPill } from './others'
import { parenthesesPill } from './parentheses'

export const symbolsTab = {
  tab: 'symbols',
  label: '記号',
  pillData: [
    arrowPill,
    dotsAndLinesPill,
    figurePill,
    othersPill,
    parenthesesPill,
  ],
}
