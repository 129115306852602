import { FormulaButtonData } from '../type'

export const popularFormulae: FormulaButtonData[] = [
  { formula: '\\cdot', tooltip: '掛ける（簡略化）' },
  {
    formula: '\\frac{b}{a}',
    tooltip: '分数',
    fontSize: 18,
    mobileFontSize: 13,
  },
  {
    formula: 'a^{n}',
    displayFormula: 'a^n',
    tooltip: '累乗',
  },
  { formula: '\\sqrt{a}', tooltip: '平方根' },
  { formula: '\\cos', tooltip: 'コサイン' },
  { formula: '\\sin', tooltip: 'サイン' },
  { formula: '\\tan', tooltip: 'タンジェント' },
  {
    formula: '\\log_{a}',
    displayFormula: '\\log',
    tooltip: '対数',
  },
  {
    formula: '\\int_{a}^{b}',
    displayFormula: '\\int',
    tooltip: 'インテグラル',
    fontSize: 15,
    mobileFontSize: 11,
  },
  {
    formula: '\\lim_{n \\to \\infty}',
    displayFormula: '\\lim',
    tooltip: '極限',
  },
  {
    formula: '\\sum_{i=0}^{n}',
    displayFormula: '\\sum',
    tooltip: '総和',
    mobileFontSize: 12,
  },
  {
    formula: '\\prod_{i=0}^{n}',
    displayFormula: '\\prod',
    tooltip: '総乗',
    mobileFontSize: 12,
  },
  { formula: '\\gt', tooltip: '大なり' },
  { formula: '\\lt', tooltip: '小なり' },
  { formula: '\\geq', tooltip: '大なりイコール' },
  { formula: '\\leq', tooltip: '小なりイコール' },
  { formula: '\\neq', tooltip: '等しくない' },
  { formula: '\\Longrightarrow', tooltip: '含意' },
  {
    formula: '\\Longleftarrow',
    tooltip: '含意（逆向き）',
  },
  {
    formula: '\\Longleftrightarrow',
    tooltip: '同値',
  },
  { formula: '\\forall', tooltip: '全称記号' },
  { formula: '\\exists', tooltip: '存在記号' },
  { formula: '\\in', tooltip: '帰属関係' },
  { formula: '\\subset', tooltip: '部分集合' },
  { formula: '\\cap', tooltip: '共通部分' },
  { formula: '\\cup', tooltip: '和集合' },
  {
    formula: '\\overrightarrow{a}',
    tooltip: '頭に右矢印',
  },
  { formula: '\\boldsymbol{a}', tooltip: '太字' },
  { formula: '\\alpha', tooltip: 'アルファ' },
  { formula: '\\beta', tooltip: 'ベータ' },
  { formula: '\\theta', tooltip: 'シータ' },
  { formula: '\\pi', tooltip: 'パイ' },
  {
    formula:
      '\\begin{eqnarray}\n\t\\left(\n\t\t\\begin{array}{cc}\n\t\t\ta & b \\\\\n\t\t\tc & d\n\t\t\\end{array}\n\t\\right)\n\\end{eqnarray}',
    displayFormula:
      '\\begin{eqnarray}\n\\left(\n\\begin{array}{cc}\na & b\\\\\nc & d\n\\end{array}\n\\right)\n\\end{eqnarray}',
    tooltip: '行列',
    fontSize: 11,
    mobileFontSize: 10,
  },
  {
    formula: '\\mathbb{N}',
    tooltip: '自然数全体の集合',
  },
  {
    formula: '\\mathbb{Z}',
    tooltip: '整数全体の集合',
  },
  {
    formula: '\\mathbb{Q}',
    tooltip: '有理数全体の集合',
  },
  {
    formula: '\\mathbb{R}',
    tooltip: '実数全体の集合',
  },
  {
    formula: '\\mathbb{C}',
    tooltip: '複素数全体の集合',
  },
  { formula: '\\cdots', tooltip: '複数の点' },
  {
    formula:
      '\\begin{eqnarray}\n\t\\left\\{\n\t\t\\begin{array}{l}\n\t\t\t\\cdots \\\\\n\t\t\t\\cdots\n\t\t\\end{array}\n\t\\right.\n\\end{eqnarray}',
    displayFormula:
      '\\begin{eqnarray}\n\\left\\{\n\\begin{array}{l}\n\\cdots\\\\\n\\cdots\n\\end{array}\n\\right.\n\\end{eqnarray}',
    tooltip: '複数行',
    fontSize: 15,
    mobileFontSize: 12,
  },
]

export const popularTab = {
  tab: 'popular',
  label: '定番',
  pillData: [
    {
      pill: 'popular_symbols',
      label: '定番',
      buttonData: popularFormulae,
    },
  ],
}
