import { CommentItem } from '.'
import { Comment, Commentable, CommentsSectionSetting } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  commentable: Commentable
  comments: Comment[]
  commentSectionOwnerUid: string
  setting?: CommentsSectionSetting
  hiddenLabel?: boolean
}

export const CommentsList = ({
  comments,
  commentable,
  commentSectionOwnerUid,
  setting,
  hiddenLabel = false,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      {comments.map((comment, index) => (
        <CommentItem
          hiddenLabel={hiddenLabel}
          commentSectionOwnerUid={commentSectionOwnerUid}
          setting={setting}
          key={comment.id}
          comment={comment}
          commentable={commentable}
          className={index !== 0 ? 'mt-7' : ''}
        />
      ))}
      {comments.length === 0 && <div>コメントはありません。</div>}
    </div>
  )
}
