import { popularFormulae } from '../../../../formula_palette/data/popular'
import { PCFormulaPalette } from '../../../../formula_palette/pc'
import { PCFormulaPaletteButton } from '../../../../formula_palette/pc/Button'

export const formulaDropdownData = [
  ...popularFormulae.map((popularFormula) => {
    return {
      component: PCFormulaPaletteButton,
      ...popularFormula,
    }
  }),
  {
    component: PCFormulaPalette,
    buttonLabel: 'もっと見る',
    buttonVariant: 'link',
    buttonClassName: 'd-block w-100 text-start mt-1',
  },
]
