import { ESArticle } from '../../elastic_search'
import { Designed } from '../../types'
import { ArticleItem } from './Item'

type Props = {
  articles: ESArticle[]
  showProfile?: boolean
  showProfileImage?: boolean
  showProfileDisplayName?: boolean
  notExistMessage?: string
  border?: boolean
}

export const ArticleList = ({
  articles,
  showProfile,
  showProfileImage,
  showProfileDisplayName,
  notExistMessage,
  border = false,
  ...wrapperProps
}: Designed<Props>) => (
  <div {...wrapperProps}>
    {articles.map((article, index) => (
      <>
        {border && index === 0 && (
          <hr className="my-2" key={`border-top-${article.id}`} />
        )}
        <ArticleItem
          className="overflow-hidden"
          key={article.id}
          article={article}
          showProfile={showProfile}
          showProfileImage={showProfileImage}
          showProfileDisplayName={showProfileDisplayName}
        />
        {border && <hr className="my-2" key={`border-bottom-${article.id}`} />}
      </>
    ))}
    {articles.length === 0 && (
      <span className="text-muted">
        {notExistMessage ?? '記事はありません。'}
      </span>
    )}
  </div>
)
