import { createHash } from 'crypto'
import { _altAttribute, _srcAttribute } from '../../rules/common/_attribute'
import { TexImage } from '../../types'
import { resolveMathdownImagePath } from '../../utils'

const tagTypeReg = /^<(\/)?([a-zA-Z][\w:-]*)/

const urlReg =
  /(https:\/\/mathlog\.info)?(\/uploads\/mathdown\/\d+?\.(jpg|jpeg|gif|png))/g

export const tag = (tag: string, images: Map<string, TexImage>) => {
  // html要素内にあるMathdownにアップロードされたファイルのパスを全部取得して置換する

  const matches = tag.matchAll(urlReg)
  let src = tag
  for (const match of matches) {
    const filePath = match[2]
    src = src.replace(filePath, resolveMathdownImagePath(filePath))
  }

  const tagTypeCap = tagTypeReg.exec(tag)
  if (tagTypeCap === null) return ''

  const status: 'close' | 'open' =
    tagTypeCap[1] === undefined ? 'open' : 'close'
  const type = tagTypeCap[2] // ul, ol, p, a など
  let htmlTagToTex
  switch (type) {
    case 'ul':
      htmlTagToTex =
        status === 'open' ? '\\begin{itemize}\n' : '\\end{itemize}\n'
      break
    case 'img': {
      const srcCap = _srcAttribute.exec(tag)
      const src = srcCap?.[1] ?? ''
      const altCap = _altAttribute.exec(tag)
      const alt = altCap?.[1] ?? ''
      if (src !== '') {
        const hash = createHash('sha256').update(src).digest('hex').slice(0, 15)
        const texImage: TexImage = {
          fileName: `${hash}.png`,
          url: src,
        }
        images.set(`${hash}`, texImage)
        htmlTagToTex = `\n\\begin{figure}[t]
    \\centering
    \\includegraphics[width=10cm]{${hash}.png}
    \\caption{${alt}}
\\end{figure}\n`
      } else {
        htmlTagToTex = ''
      }
      break
    }
    case 'table':
      htmlTagToTex =
        status === 'open'
          ? `\\begin{table}[htbp]
    \\centering
    \\begin{tabular}\n`
          : `\n   \\end{tabular}
\\end{table}\n`
      break
    case 'tr':
      htmlTagToTex = status === 'open' ? '' : '\\\\'
      break
    case 'th':
      htmlTagToTex = status === 'open' ? '' : '&'
      break
    case 'td':
      htmlTagToTex = status === 'open' ? '' : '&'
      break
    case 'ol':
      htmlTagToTex =
        status === 'open' ? '\\begin{enumerate}\n' : '\\end{enumerate}\n'
      break
    case 'li':
      htmlTagToTex = status === 'open' ? '    \\item ' : '\n'
      break
    case 'br':
      htmlTagToTex = '\\par '
      break
    case 'h1':
      htmlTagToTex = status === 'open' ? '\\section{' : '}\n'
      break
    case 'h2':
      htmlTagToTex = status === 'open' ? '\\section{' : '}\n'
      break
    case 'h3':
      htmlTagToTex = status === 'open' ? '\\subsection{' : '}\n'
      break
    case 'h4':
      htmlTagToTex = status === 'open' ? '\\subsubsection{' : '}\n'
      break
    case 'h5':
      htmlTagToTex = status === 'open' ? '\\textbf{' : '}\n'
      break
    case 'code':
      htmlTagToTex = status === 'open' ? '\\verb|' : '|'
      break
    case 'del':
      htmlTagToTex = status === 'open' ? '\\sout{' : '}'
      break
    case 'em':
      htmlTagToTex = status === 'open' ? '\\emph{' : '}'
      break
    case 'hr':
      htmlTagToTex = '\\hrulefill\n\n'
      break
    case 'blockquote':
      htmlTagToTex = status === 'open' ? '\\begin{quote}\n' : '\\end{quote}\n'
      break
    default:
      htmlTagToTex = ''
  }
  return htmlTagToTex.replace(/%/g, '\\%')
}
