import Link from 'next/link'
import { EditorView } from 'prosemirror-view'
import { memo } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'
import { useAuthState } from '../../../../../../firebase/hooks'
import {
  useElementSize,
  useIPhoneSupportedFixedTop,
  useScrollStatus,
} from '../../../../../../hooks'
import { Designed } from '../../../../../../types'
import { HELP_NOTION_LINKS, logEvent } from '../../../../../../utils'
import { menuData } from './data'

type Props = {
  editor: EditorView
  menuPosition?: { top?: number; left?: number; right?: number }
}

const MobileMenuImpl = ({
  editor,
  menuPosition,
  ...otherProps
}: Designed<Props>) => {
  const { user } = useAuthState()

  const { height, elementRef: mobileMenuRef } = useElementSize()

  useIPhoneSupportedFixedTop(mobileMenuRef, menuPosition?.top ?? 0)

  const { status: scrollStatus } = useScrollStatus(100)

  return (
    <>
      <div
        ref={mobileMenuRef}
        className={`bg-white overflow-auto flex-nowrap scrollbar-none ${
          scrollStatus === 'scroll' ? 'invisible' : ''
        }`}
        style={{
          right: menuPosition?.right ?? 0,
          left: menuPosition?.left ?? 0,
          whiteSpace: 'nowrap',
        }}
      >
        <ButtonGroup {...otherProps}>
          {menuData.map(({ component: Component, ...props }, index) => (
            // TODO: any型を改善する
            <Component key={index} editor={editor} {...(props as any)} />
          ))}
          <Link href={HELP_NOTION_LINKS.EDITOR_USAGE}>
            <a
              className="btn btn-outline-dark btn-sm fw-bold align-items-center"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                logEvent('tap_editor_help', {
                  uid: user?.uid,
                })
              }
            >
              <FaQuestionCircle />
            </a>
          </Link>
        </ButtonGroup>
      </div>
      <div style={{ height }} className="w-100" />
    </>
  )
}

export const MobileMenu = memo(MobileMenuImpl)
