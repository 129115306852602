import { MutableRefObject } from 'react'
import { Stack } from 'react-bootstrap'
import { FaMedal, FaThumbsUp, FaUserAlt } from 'react-icons/fa'
import { BookOperationLinks, LaTeXExportButton } from '.'
import { CategoryBadge } from '../../assets/category'
import { Mathdown } from '../../assets/mathdown'
import { InlineTagList } from '../../assets/tag'
import { UserInfo, UserItem } from '../../assets/user'
import { useAccount } from '../../context/account'
import { useEvaluation } from '../../context/evaluation'
import { usePrintSetting } from '../../context/print_setting'
import {
  Badge,
  Book,
  Category,
  Macro,
  Page,
  Profile,
  Reference,
  Tag,
  bookRefWithoutConverter,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { shouldShowAdsInContent } from '../../models/user'
import { ADSENSE_SLOTS } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { PVCounter } from '../PVCounter'
import { BadgeGift, BadgeList } from '../badge'
import { CommentSection } from '../comment'
import { BeginnerAlert } from '../content/BeginnerAlert'
import { BadButton, GoodButton, GoodEvaluatorList } from '../evaluation'
import { AdsCard } from '../google_adsense/AdsCard'
import { MessagesCounter } from '../message'
import { ReferenceBadge, ReferencesList } from '../reference'
import { ReportButton } from '../report/Button'
import { ContentTimeStamp } from '../timestamp/Timestamp'

type Props = {
  book: Book
  currentPage: Page
  pages: Page[]
  profile: Profile
  category: Category
  tags: Tag[]
  references: Reference[]
  badges: Badge[]
  showDisplayname?: boolean
  macros: Macro[]
  contentRef: MutableRefObject<HTMLDivElement | null>
}

export const BookPageDetail = ({
  book,
  pages,
  currentPage,
  profile,
  category,
  tags,
  references,
  macros,
  contentRef,
  badges,
}: Props) => {
  const { isPrinting, printSetting } = usePrintSetting()
  const { user } = useAuthState()
  const { account } = useAccount()

  const { goodCount, badCount, handleChangeGoodCount, handleChangeBadCount } =
    useEvaluation()

  return (
    <div className="mw-100 mb-5">
      <UserItem
        profile={profile}
        imageSize={33}
        className="mb-3"
        isFollowButton={false}
      />
      <Stack direction="horizontal" gap={1} className="mb-1">
        <CategoryBadge category={category} contentRef={book.ref} />
        <ReferenceBadge
          referable={book}
          color={
            isPrinting && printSetting.theme === 'monotone'
              ? 'secondary'
              : undefined
          }
        />
      </Stack>
      <h1 className="h3 text-break mb-1">{currentPage.title}</h1>
      <InlineTagList tags={tags} className="mb-3" />
      <div className="d-flex gap-3">
        <GoodButton
          evaluable={book}
          currentGoodCount={goodCount}
          onChangeGoodCount={handleChangeGoodCount}
          showCounter
          size="sm"
          className="text-muted small"
        />
        <BadButton
          evaluable={book}
          currentBadCount={badCount}
          onChangeBadCount={handleChangeBadCount}
          showCounter
          size="sm"
          className="text-muted small"
        />
        <PVCounter value={book.pv} className="text-muted small" />
        <MessagesCounter
          value={currentPage.comments_count}
          className="text-muted small"
        />
        <LaTeXExportButton
          book={book}
          author={profile}
          macros={macros}
          pages={pages}
          references={references}
          className="d-print-none"
        />
        <BookOperationLinks book={book} className="d-print-none" />
      </div>

      <div
        ref={contentRef}
        className={`mt-6 mathdown-${
          isPrinting ? printSetting.theme : 'colorful'
        }`}
      >
        {book.is_beginner && <BeginnerAlert className="py-2 px-3 small mt-2" />}
        <Mathdown
          mathdown={currentPage.body}
          macros={macros}
          references={references}
          className="text-break"
        />
        {book.has_references && (
          <>
            <h2>参考文献</h2>
            <ReferencesList
              references={references}
              className="gap-1 d-flex flex-column"
              amazonBookImage
              link
            />
          </>
        )}
      </div>
      <div className="mt-7">
        <ContentTimeStamp content={currentPage} />
      </div>
      {user?.uid !== book.created_by && (
        <ReportButton
          reportableRefWithoutConverter={bookRefWithoutConverter(book.id)}
          label="この本を運営に通報する"
          targetUid={book.created_by}
          className="d-print-none small text-muted mt-3"
        />
      )}
      {/* 広告 */}
      {shouldShowAdsInContent(profile?.plan, account?.plan) && (
        <AdsCard
          className="mt-7"
          height="90px"
          slot={ADSENSE_SLOTS.PAGE_BOTTOM_SLOT}
        />
      )}
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="この本を高評価した人" className="text-muted">
            <FaThumbsUp />
          </IconWrapper>
        </h2>
        <GoodEvaluatorList evaluable={book} />
      </div>
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="この本に送られたバッジ" className="text-muted">
            <FaMedal />
          </IconWrapper>
        </h2>
        <BadgeList size={'70'} badges={badges} className="mb-5" />
        {user !== undefined &&
          (user === null || book.created_by !== user.uid) && (
            <BadgeGift parentRef={book.ref} />
          )}
      </div>
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="投稿者" className="text-muted">
            <FaUserAlt />
          </IconWrapper>
        </h2>
        <UserInfo profile={profile} />
      </div>
      <div className="mt-7">
        <CommentSection
          commentSectionOwnerUid={book.created_by}
          setting={profile.comments_section_setting}
          commentable={currentPage}
          className={
            isPrinting && printSetting.showComments ? '' : 'd-print-none'
          }
        />
      </div>
    </div>
  )
}
