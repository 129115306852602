import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaAngleDown, FaAngleUp, FaReply } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroller'
import { IconWrapper } from '../../assets/IconWrapper'
import { MessageItem } from '../../assets/message'
import { RepliesList, ReplyForm } from '../../assets/reply'
import { LoadingSpinner, SpinnerButton } from '../../assets/spinners'
import { Comment, Commentable, CommentsSectionSetting } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useReplies } from '../../hooks/models/useReplies'
import { Designed, PromiseVoid } from '../../types'

type ShowRepliesButtonProps = {
  comment: Comment
  loading: boolean
  onClick?: () => PromiseVoid
}

const ShowRepliesButton = ({
  loading,
  comment,
  onClick: handleClick,
}: ShowRepliesButtonProps) => (
  <SpinnerButton
    loading={loading}
    loadingText="返信を取得中..."
    variant="link"
    size="sm"
    onClick={handleClick}
  >
    <IconWrapper suffix={`${comment.replies_count}件の返信を表示`}>
      <FaAngleDown />
    </IconWrapper>
  </SpinnerButton>
)

type HideRepliesButtonProps = {
  onClick?: () => PromiseVoid
}

const HideRepliesButton = ({
  onClick: handleClick,
}: HideRepliesButtonProps) => (
  <Button variant="link" size="sm" onClick={handleClick}>
    <IconWrapper suffix="返信を非表示">
      <FaAngleUp />
    </IconWrapper>
  </Button>
)

type Props = {
  comment: Comment
  commentable: Commentable
  commentSectionOwnerUid: string
  setting?: CommentsSectionSetting
  hiddenLabel?: boolean
}

export const CommentItem = ({
  comment,
  commentable,
  commentSectionOwnerUid,
  setting,
  hiddenLabel = false,
  ...wrapperProps
}: Designed<Props>) => {
  const { user: currentUser } = useAuthState()
  const [showReplies, setShowReplies] = useState(false)
  const [showNewReplyForm, setShowNewReplyForm] = useState(false)
  const { replies, loadMore, isLast, fetching } = useReplies(
    comment,
    showReplies
  )

  return (
    <div {...wrapperProps}>
      <MessageItem
        hiddenLabel={hiddenLabel}
        setting={setting}
        commentSectionOwnerUid={commentSectionOwnerUid}
        type="comment"
        parentRef={commentable.ref}
        message={comment}
        onReplyButtonClick={() => {
          setShowNewReplyForm(true)
        }}
        replyButton
      />
      {comment.replies_count > 0 && (
        <div className="mt-1 d-print-none">
          {!showReplies || replies === undefined ? (
            <ShowRepliesButton
              comment={comment}
              loading={showReplies && replies === undefined}
              onClick={() => setShowReplies(true)}
            />
          ) : (
            <HideRepliesButton onClick={() => setShowReplies(false)} />
          )}
        </div>
      )}
      {(showNewReplyForm || showReplies) && (
        <div className="ps-6 ms-1 border-start border-2 py-3">
          {showReplies && (
            <>
              <InfiniteScroll
                loadMore={async () => {
                  if (!fetching) await loadMore()
                }}
                hasMore={!isLast}
                loader={<LoadingSpinner />}
                useWindow={true}
              >
                <RepliesList
                  comment={comment}
                  replies={replies}
                  setting={setting}
                  commentSectionOwnerUid={commentSectionOwnerUid}
                />
              </InfiniteScroll>
              {currentUser && (
                <Button
                  variant="link"
                  size="sm"
                  className={`text-muted mt-6 d-print-none`}
                  onClick={() => setShowNewReplyForm(true)}
                >
                  <IconWrapper suffix="このスレッドに返信">
                    <FaReply />
                  </IconWrapper>
                </Button>
              )}
            </>
          )}
          {showNewReplyForm && (
            <ReplyForm
              comment={comment}
              onCancel={() => setShowNewReplyForm(false)}
            />
          )}
        </div>
      )}
    </div>
  )
}
