import { usePrintSetting } from '../../context/print_setting'
import { WithUserTimestamp } from '../../firebase'
import { ShapedTimestamp } from './ShapedTimestamp'

type Props = {
  content: WithUserTimestamp
}

export const ContentTimeStamp = ({ content }: Props) => {
  const { isPrinting } = usePrintSetting()
  return (
    <>
      <div className="text-muted">
        <div>
          投稿日：
          <ShapedTimestamp
            timestamp={content.created_by_user_at}
            absolute={isPrinting}
          />
        </div>
      </div>
      {content.updated_by_user_at.valueOf() !==
        content.created_by_user_at.valueOf() && (
        <div className="text-muted">
          <div>
            更新日：
            <ShapedTimestamp
              timestamp={content.updated_by_user_at}
              absolute={isPrinting}
            />
          </div>
        </div>
      )}
    </>
  )
}
