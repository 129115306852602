import Image from 'next/image'
import { Badge } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  size: string
  badge: Badge
}

export const BadgeItem = ({
  size,
  badge,
  ...otherProps
}: Designed<Props>): JSX.Element => {
  switch (badge.type) {
    case 'elegant':
      return (
        <Image
          height={size}
          width={size}
          src="/images/elegant.png"
          alt="美しい！"
          key={badge.id}
          {...otherProps}
        />
      )
    case 'read_more':
      return (
        <Image
          height={size}
          width={size}
          src="/images/read_more.png"
          alt="もっと読みたい"
          key={badge.id}
          {...otherProps}
        />
      )
    case 'thank_you':
      return (
        <Image
          height={size}
          width={size}
          src="/images/thank_you.png"
          alt="Thank you!"
          key={badge.id}
          {...otherProps}
        />
      )
    case 'annual_no1_good_count':
      return (
        <Image
          height={size}
          width={size}
          src="/images/annual_no1_good_count.png"
          alt="年間高評価数1位"
          key={badge.id}
          {...otherProps}
        />
      )
    case 'annual_no2_good_count':
      return (
        <Image
          height={size}
          width={size}
          src="/images/annual_no2_good_count.png"
          alt="年間高評価数2位"
          key={badge.id}
          {...otherProps}
        />
      )
    case 'annual_no3_good_count':
      return (
        <Image
          height={size}
          width={size}
          src="/images/annual_no3_good_count.png"
          alt="年間高評価数3位"
          key={badge.id}
          {...otherProps}
        />
      )
    case 'monthly_no1_good_count':
      return (
        <Image
          height={size}
          width={size}
          src="/images/monthly_no1_good_count.png"
          alt="月高評価数1位"
          key={badge.id}
          {...otherProps}
        />
      )
  }
}
