import { FormulaButtonData } from '../type'

const others: FormulaButtonData[] = [
  {
    formula: '\\propto',
    tooltip: '比例',
  },
  {
    formula: '\\ast',
    tooltip: 'アスタリスク',
  },
  {
    formula: '\\star',
    tooltip: 'スター',
  },
  {
    formula: '\\ltimes',
    tooltip: '左線と掛ける',
  },
  {
    formula: '\\rtimes',
    tooltip: '右線と掛ける',
  },
  {
    formula: '\\Join',
    tooltip: '自然結合',
  },
  {
    formula: '\\$',
    tooltip: 'ドル記号',
  },
  {
    formula: '\\And',
    tooltip: '＆記号',
  },
  {
    formula: '\\yen',
    tooltip: '円マーク',
  },
  {
    formula: '\\checkmark',
    tooltip: 'チェックマーク',
  },
  {
    formula: '\\diamondsuit',
    tooltip: 'ダイヤモンド',
  },
  {
    formula: '\\heartsuit',
    tooltip: 'ハート',
  },
  {
    formula: '\\clubsuit',
    tooltip: 'クラブ',
  },
  {
    formula: '\\spadesuit',
    tooltip: 'スペード',
  },
  {
    formula: '\\flat',
    tooltip: 'フラット',
  },
  {
    formula: '\\natural',
    tooltip: 'ナチュラル',
  },
  {
    formula: '\\sharp',
    tooltip: 'シャープ',
  },
  {
    formula: '\\dagger',
    tooltip: 'ダガー',
  },
  {
    formula: '\\ddagger',
    tooltip: 'ダガー2',
  },
  {
    formula: '\\llcorner',
    tooltip: '左下の角',
  },
  {
    formula: '\\lrcorner',
    tooltip: '右下の角',
  },
  {
    formula: '\\ulcorner',
    tooltip: '左上の角',
  },
  {
    formula: '\\urcorner',
    tooltip: '右上の角',
  },
]

export const othersPill = {
  pill: 'others',
  label: 'その他',
  buttonData: others,
}
