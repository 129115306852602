export const shortenedLink = (title: string, labels: Map<string, string>) => {
  const type = labels.get(title) !== null ? labels.get(title) : ''
  switch (type) {
    case 'def':
      return `定義\\ref{${title}}`
    case 'thm':
      return `定理\\ref{${title}}`
    case 'axm':
      return `公理\\ref{${title}}`
    case 'cor':
      return `系\\ref{${title}}`
    case 'lem':
      return `補題\\ref{${title}}`
    case 'conj':
      return `予想\\ref{${title}}`
    case 'prop':
      return `命題\\ref{${title}}`
    case 'fml':
      return `公式\\ref{${title}}`
    case 'prf':
      return `証明\\ref{${title}}`
    case 'ex':
      return `例\\ref{${title}}`
    case 'exc':
      return `問題\\ref{${title}}`
    case 'rem':
      return `注意\\ref{${title}}`
    default:
      return `\\ref{${title}}`
  }
}
