import Link from 'next/link'
import { Badge } from 'react-bootstrap'
import { FaBell } from 'react-icons/fa'
import { useUnreadNotifications } from '../../hooks/models/useUnreadNotifications'
import { App, Designed } from '../../types'

type Props = {
  app: App
  showPopup: boolean
}

export const NotificationIcon = ({
  app,
  showPopup,
  ...designProps
}: Designed<Props>) => {
  const { countLabel } = useUnreadNotifications(app, showPopup)

  return (
    <div className={`position-relative ${countLabel === '0' ? '' : 'me-2'}`}>
      <Link href="/notifications" passHref>
        <a>
          <FaBell {...designProps} />
          {countLabel !== '0' && (
            <Badge
              pill
              bg="danger"
              className="position-absolute fs-7 small m-0"
              style={{ bottom: -5, right: -10, padding: '3px' }}
            >
              {countLabel}
            </Badge>
          )}
        </a>
      </Link>
    </div>
  )
}
