import { FaComment } from 'react-icons/fa'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'

type Props = {
  value: number
}

export const MessagesCounter = ({
  value,
  ...wrapperProps
}: Designed<Props>) => (
  <IconWrapper suffix={value} {...wrapperProps}>
    <FaComment />
  </IconWrapper>
)
