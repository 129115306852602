import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Navbar } from 'react-bootstrap'
import { FaBookmark } from 'react-icons/fa'
import { SearchField } from 'web_core/assets/SearchField'
import { NotificationIcon } from 'web_core/assets/notification'
import { useAuthState } from 'web_core/firebase/hooks/useAuthState'
import { useElementSize, useMedia } from 'web_core/hooks'
import { HEADER_HEIGHT, useLayoutProperty } from '~/context/layout_property'
import { MobileHeaderUserMenu } from './MobileHeaderUserMenu'
import { PCHeaderUserMenu } from './PCHeaderUserMenu'

type Props = {
  fixed: boolean // ヘッダーを上部に固定する
  maxWidth: number | 'none' // ページ全体の横幅の最大値 ページ全体で合わせる
  hideSearchField?: boolean // 検索フィールドを非表示にする
  hideNewContentsButton?: boolean // 新規作成ボタンを非表示にする
}

export const Header = ({
  fixed,
  maxWidth,
  hideNewContentsButton = false,
  hideSearchField = false,
}: Props) => {
  const { t } = useTranslation()
  const { user, showSignInPopup } = useAuthState()
  const [expandSearchField, setExpandSearchField] = useState(false)
  const isMd = useMedia('md')
  const router = useRouter()
  const { setHeaderHeight } = useLayoutProperty()
  const { elementRef: headerRef, height: headerHeight } = useElementSize({
    onChangeHeight: setHeaderHeight,
    defaultHeight: HEADER_HEIGHT,
  })

  return (
    <>
      <Navbar
        expand="md"
        variant="dark"
        className={`flex-shrink-0 shadow-sm bg-primary-dark d-print-none w-100 ${
          fixed ? 'position-fixed' : 'zindex-fixed'
        }`} // z-index は常に fixed と同じ状態にする
        ref={headerRef}
        style={{ height: 55 }} // ヘッダーの高さは中の要素の高さの影響を受けないように固定値にした
      >
        <div
          className="w-100 d-flex justify-content-between align-items-center gap-4 px-2 px-md-4 mx-auto"
          style={{ maxWidth }}
        >
          {(!expandSearchField || isMd) && (
            <div
              className="d-flex gap-2 align-items-center"
              style={{ height: 120 * 0.2441 }} // ロゴと同じ高さ
            >
              <Link href="/" passHref>
                <Navbar.Brand className="d-flex align-items-center py-0 h-100">
                  <Image
                    src="/logo_white.svg"
                    alt="Mathlog"
                    width={120}
                    height={120 * 0.2441} // ロゴのアスペクト比
                  />
                </Navbar.Brand>
              </Link>
            </div>
          )}
          {!hideSearchField && (
            <SearchField
              onSearch={async (keyword) => {
                const pathname = router.pathname
                await router.push(
                  `${
                    ['/books', '/users'].includes(pathname)
                      ? pathname
                      : '/articles'
                  }?keyword=${keyword}`
                )
              }}
              variant="dark"
              className="flex-grow-1"
              onExpand={() => setExpandSearchField(true)}
              onShrink={() => setExpandSearchField(false)}
            />
          )}
          {(!expandSearchField || isMd) && (
            <div className="d-flex align-items-center gap-4">
              {!hideNewContentsButton && (
                <Button
                  variant="primary"
                  className="d-none d-lg-block"
                  onClick={async () => {
                    if (user) await router.push('/article_drafts')
                    else if (user === null) showSignInPopup('/article_drafts')
                  }}
                >
                  {t('mathlog.components.layout.header.create_new')}
                </Button>
              )}
              {user && (
                <>
                  <Link href="/bookmarks/articles" passHref>
                    <a>
                      <FaBookmark className="text-white fs-4" />
                    </a>
                  </Link>
                  <NotificationIcon
                    className="text-white fs-4"
                    app="mathlog"
                    showPopup
                  />
                </>
              )}
              <PCHeaderUserMenu />
              <MobileHeaderUserMenu />
            </div>
          )}
        </div>
      </Navbar>
      {fixed && (
        <div className="flex-shrink-0" style={{ height: headerHeight }} />
      )}
    </>
  )
}
