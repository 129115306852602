import JSZip from 'jszip'
import { useState } from 'react'
import { FaFileDownload, FaLock } from 'react-icons/fa'
import { useAccount } from '../../context/account'
import { Article, Macro, Profile, Reference } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { Designed } from '../../types'
import { HELP_NOTION_LINKS, logEvent, saveBlob } from '../../utils'
import { ConfirmationPopup } from '../ConfirmationPopup'
import { IconWrapper } from '../IconWrapper'
import { makeArticleTexFilesZip } from '../mathdown/compiler/tex_export/article'
import { SpinnerButton } from '../spinners'
import { PremiumPlanPaymentPopup } from '../subscription/PremiumPlanPaymentPopup'

type Props = {
  article: Article
  author: Profile
  macros: Macro[]
  references: Reference[]
  variant?: string
}

export const LaTeXExportButton = ({
  article,
  author,
  macros,
  references,
  ...otherProps
}: Designed<Props>) => {
  const [downloading, setDownloading] = useState(false)
  const [premiumPlanPopupShow, setPremiumPlanPopupShow] = useState(false)
  const [showDLConfirmationPopup, setShowDLConfirmationPopup] = useState(false)
  const { account } = useAccount()
  const { isSignedIn, showSignInPopup } = useAuthState()
  const texExportable = account?.features?.tex_export ?? false
  const latexExportLink = HELP_NOTION_LINKS.LATEX_EXPORT
  const handleYes = async () => {
    setShowDLConfirmationPopup(false)
    if (!account) throw new Error('This action needs to authenticate.')
    if (!texExportable) {
      // LaTeXエクスポートの機能アクセス時のイベント収集
      logEvent('tap_latex_export')

      setPremiumPlanPopupShow(true)
      return
    }
    setDownloading(true)
    const zip = new JSZip()
    await makeArticleTexFilesZip(zip, article, author, macros, references)
    const blob = await zip.generateAsync({ type: 'blob' })
    saveBlob(blob, article.title)
    setDownloading(false)
  }

  const handleNo = () => {
    setShowDLConfirmationPopup(false)
    if (!account) throw new Error('This action needs to authenticate.')
  }

  const handleDownloadClick = () => {
    if (isSignedIn === true) {
      setShowDLConfirmationPopup(true)
    } else {
      showSignInPopup()
    }
  }

  return (
    <>
      <ConfirmationPopup
        show={showDLConfirmationPopup}
        title="LaTeXエクスポートに関する注意"
        yesLabel="ダウンロード"
        noLabel="キャンセル"
        onSelectNo={handleNo}
        onSelectYes={handleYes}
        onHide={() => setShowDLConfirmationPopup(false)}
      >
        MathlogのpLaTeX形式のLaTeXエクスポートに対応しております。Mathlogの記法と完全な互換性はありませんので、修正が必要になることがあります。詳細は、Mathlogの
        <a href={latexExportLink} target="_blank" rel="noopener noreferrer">
          LaTeXエクスポート
        </a>
        の使い方をご覧ください。
      </ConfirmationPopup>
      <SpinnerButton
        variant="link"
        loading={downloading}
        className="text-primary"
        onClick={handleDownloadClick}
        {...otherProps}
      >
        {texExportable ? (
          <IconWrapper suffix="LaTeXエクスポート">
            <FaFileDownload />
          </IconWrapper>
        ) : (
          <IconWrapper suffix="LaTeXエクスポート">
            <FaLock />
          </IconWrapper>
        )}
      </SpinnerButton>
      <PremiumPlanPaymentPopup
        show={premiumPlanPopupShow}
        onHide={() => setPremiumPlanPopupShow(false)}
      />
    </>
  )
}
