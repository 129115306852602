import { FormulaButtonData } from '../type'

const geometry: FormulaButtonData[] = [
  {
    formula: '90^{\\circ}',
    tooltip: '角度（度数）',
  },
  { formula: '\\angle', tooltip: '角記号' },
  {
    formula: '/\\!/',
    tooltip: '平行（日本スタイル）',
  },
  {
    formula: '\\parallel',
    tooltip: '平行（海外スタイル）',
  },
  { formula: '\\perp', tooltip: '垂直' },
  { formula: '\\triangle', tooltip: '三角形' },
  { formula: '\\Box', tooltip: '四角形' },
  {
    formula: '\\stackrel{\\huge\\frown}{AB}',
    tooltip: '弧',
  },
  { formula: '\\equiv', tooltip: '合同（日本スタイル）' },
  { formula: '\\cong', tooltip: '合同（海外スタイル）' },
  { formula: '\\backsim', tooltip: '相似（日本スタイル）' },
  { formula: '\\sim', tooltip: '相似（海外スタイル）' },
]

export const geometryPill = {
  pill: 'geometry',
  label: '幾何学',
  buttonData: geometry,
}
