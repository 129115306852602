import Image from 'next/image'
import { useRef, useState } from 'react'
import { Button, Card, FormSelect, InputGroup, Modal } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'
import { BadgesParentRefAssignable, ValidDocument } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useStripeBadgeProduct } from '../../hooks'
import { Designed } from '../../types'
import { HELP_NOTION_LINKS, logEvent } from '../../utils'
import { SpinnerButton } from '../spinners'

type Props<T extends ValidDocument> = {
  parentRef: BadgesParentRefAssignable<T>
}
export const BadgeGift = <T extends ValidDocument>({
  parentRef,
  ...otherProps
}: Designed<Props<T>>) => {
  const [modalShow, setModalShow] = useState(false)
  const amountRef = useRef<HTMLSelectElement>(null)
  const { isSignedIn, user, showSignInPopup } = useAuthState()
  const {
    currentBadge,
    currentBadgePrices,
    selectBadge,
    purchase,
    isPurchasing,
  } = useStripeBadgeProduct('jpy', 'thank_you_badge')
  return (
    <>
      <Modal
        show={modalShow}
        backdrop={'static'}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">バッチの種類と金額を選択</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-6 px-0">
            <div
              className="col d-block text-center cursor-pointer"
              onClick={() => selectBadge('thank_you_badge')}
            >
              <Image
                height={100}
                width={100}
                src="/images/thank_you.png"
                alt="Thank you!"
                style={{
                  opacity: currentBadge === 'thank_you_badge' ? 1.0 : 0.5,
                }}
              />
            </div>
            <div
              className="col d-block text-center cursor-pointer"
              onClick={() => selectBadge('read_more_badge')}
            >
              <Image
                height={100}
                width={100}
                src="/images/read_more.png"
                alt="もっと読みたい"
                style={{
                  opacity: currentBadge === 'read_more_badge' ? 1.0 : 0.5,
                }}
              />
            </div>
            <div
              className="col d-block text-center cursor-pointer"
              onClick={() => selectBadge('elegant_badge')}
            >
              <Image
                height={100}
                width={100}
                src="/images/elegant.png"
                alt="美しい！"
                style={{
                  opacity: currentBadge === 'elegant_badge' ? 1.0 : 0.5,
                }}
              />
            </div>
          </div>
          <InputGroup>
            <InputGroup.Text>金額</InputGroup.Text>
            <FormSelect ref={amountRef}>
              {currentBadgePrices?.map((price) => {
                return (
                  <option
                    key={price.id}
                    value={price.id}
                  >{`${price.unit_amount}円`}</option>
                )
              })}
            </FormSelect>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <SpinnerButton
            loading={isPurchasing}
            loadingText={'手続き中...'}
            onClick={async () => {
              if (!amountRef.current) throw new Error('Unexpected error.')
              const price = amountRef.current.value
              await purchase(price, parentRef)
            }}
          >
            購入の手続きへ
          </SpinnerButton>
        </Modal.Footer>
      </Modal>
      <Card {...otherProps}>
        <Card.Body>
          <Card.Title className="h6 fw-bold d-flex">
            バッチを贈って投稿者を応援しよう
            <a
              className="d-flex align-items-center ms-2 text-muted"
              href={HELP_NOTION_LINKS.SUPPORT_FOR_CREATORS}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => logEvent('tap_badge_help', { uid: user?.uid })}
            >
              <FaQuestionCircle />
            </a>
          </Card.Title>
          <p className="mb-2 small">
            バッチを贈ると投稿者に現金やAmazonのギフトカードが還元されます。
          </p>
          <Button
            size="sm"
            disabled={isSignedIn === undefined}
            onClick={() => {
              if (isSignedIn === false) showSignInPopup()
              else setModalShow(true)
            }}
          >
            バッチを贈る
          </Button>
        </Card.Body>
      </Card>
    </>
  )
}
