import { ESBook } from '../../elastic_search'
import { Designed } from '../../types'
import { BookItem } from './Item'

type Props = {
  books: ESBook[]
  width?: string
  hideBadges?: boolean
  hideTitle?: boolean
  hideProfile?: boolean
  notExistMessage?: string
}

export const BookList = ({
  books,
  width = '150px',
  hideBadges = false,
  hideTitle = false,
  hideProfile = false,
  notExistMessage,
  style,
  className,
}: Designed<Props>) =>
  books.length === 0 ? (
    <span className="text-muted">{notExistMessage ?? '本はありません。'}</span>
  ) : (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, minmax(${width}, 1fr))`,
        gridRowGap: '1.5rem',
        gridColumnGap: '1.5rem',
        ...style,
      }}
      className={className}
    >
      {books.map((book) => (
        <BookItem
          key={book.id}
          book={book}
          hideBadges={hideBadges}
          hideTitle={hideTitle}
          hideProfile={hideProfile}
        />
      ))}
      {/* 綺麗に整列させるためにいくつかdivを後ろに足す */}
      <div className="visibility-hidden" />
      <div className="visibility-hidden" />
      <div className="visibility-hidden" />
      <div className="visibility-hidden" />
      <div className="visibility-hidden" />
    </div>
  )
