import {
  FaBold,
  FaHeading,
  FaImage,
  FaItalic,
  FaLink,
  FaListOl,
  FaNairaSign,
  FaQuoteLeft,
  FaTable,
} from 'react-icons/fa6'
import { LuFrame } from 'react-icons/lu'
import { TbMathFunction } from 'react-icons/tb'
import {
  insertBlockquote,
  insertBold,
  insertDel,
  insertItalic,
  insertLink,
} from '../../../../commands'
import { MobileFormulaPalette } from '../../../formula_palette'
import { ImageUploader } from '../../../image_uploader'
import { TableEditor } from '../../../table_editor'
import { Offcanvas } from '../Offcanvas'
import { OperationButton } from '../OperationButton'
import {
  formatOffcanvasData,
  headingOffcanvasItemData,
  listOffcanvasItemData,
} from './offcanvas_data'

export const menuData = [
  {
    component: MobileFormulaPalette,
    children: (
      <span className="fs-6 px-1">
        <TbMathFunction />
      </span>
    ),
  },
  {
    component: Offcanvas,
    children: (
      <span className="fs-6 px-1">
        <LuFrame />
      </span>
    ),
    menuData: formatOffcanvasData,
  },
  {
    component: Offcanvas,
    children: (
      <span className="fs-6 px-1">
        <FaHeading />
      </span>
    ),
    menuData: headingOffcanvasItemData,
  },
  {
    component: Offcanvas,
    children: (
      <span className="fs-6 px-1">
        <FaListOl />
      </span>
    ),
    menuData: listOffcanvasItemData,
  },
  {
    component: OperationButton,
    children: (
      <span className="fs-6 px-1">
        <FaQuoteLeft />
      </span>
    ),
    command: insertBlockquote,
  },
  {
    component: OperationButton,
    children: (
      <span className="fs-6 px-1">
        <FaBold />
      </span>
    ),
    command: insertBold,
  },
  {
    component: OperationButton,
    children: (
      <span className="fs-6 px-1">
        <FaItalic />
      </span>
    ),
    command: insertItalic,
  },
  {
    component: OperationButton,
    children: (
      <span className="fs-6 px-1">
        <FaNairaSign />
      </span>
    ),
    command: insertDel,
  },
  {
    component: OperationButton,
    children: (
      <span className="fs-6 px-1">
        <FaLink />
      </span>
    ),
    command: insertLink,
  },
  {
    component: TableEditor,
    buttonLabel: (
      <span className="fs-6 px-1">
        <FaTable />
      </span>
    ),
    buttonSize: 'sm',
    buttonVariant: 'outline-dark',
    buttonClassName: 'fw-bold',
  },
  {
    component: ImageUploader,
    buttonLabel: (
      <span className="fs-6 px-1">
        <FaImage />
      </span>
    ),
  },
]
