import { EditorView } from 'prosemirror-view'
import { memo, useMemo, useRef, useState } from 'react'
import { Button, CloseButton, Form, Nav, Offcanvas } from 'react-bootstrap'
import { Macro } from '../../../../../../firebase'
import { MathJax, MathJaxEditor } from '../../../../mathjax'
import { getFormulaState, getInsertFormulaCommand } from '../../../commands'
import { MobileFormulaPaletteButton } from './Button'
import { mobileFormulaPaletteData } from './data'

type Props = {
  editor: EditorView
  macros?: Macro[]
  children: React.ReactNode
}

const MobileFormulaPaletteImpl = ({ editor, macros, children }: Props) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [currentTab, setCurrentTab] = useState('popular')
  const [currentPill, setCurrentPill] = useState('all')
  const [formula, setFormula] = useState('')

  const formulaeCache = useRef<Record<string, string>>({})

  const formulaType = useMemo(() => {
    if (!show) return null
    console.log('test')
    return getFormulaState(editor.state).type
  }, [show, editor.state])

  return (
    <>
      <Button
        variant={show ? 'dark' : 'outline-dark'}
        size="sm"
        onClick={handleShow}
      >
        {children}
      </Button>

      {/* 数式のキャッシュ */}
      <div className="d-none">
        {mobileFormulaPaletteData.map(({ pillData, tab }) =>
          pillData.map(({ buttonData, pill }) =>
            buttonData.map((formulaData, formulaIndex) => {
              const key = `${tab}-${pill}-${formulaIndex}`
              return (
                <MathJax
                  key={key}
                  onTypeset={(result) => {
                    formulaeCache.current[key] = result
                  }}
                  style={{ fontSize: formulaData.mobileFontSize }}
                >{`$\\displaystyle{${formulaData.formula}}$`}</MathJax>
              )
            })
          )
        )}
      </div>

      {/* Offcanvasの表示/非表示を d-none で制御して表示速度を向上させている（数式の読み込みが一度で済むため） */}
      <Offcanvas
        show={show}
        backdrop={false}
        className={`w-100 h-100 p-4 ${show ? '' : 'd-none'}`}
        dialogClassName="w-100 h-100 m-0 mw-100"
        contentClassName="h-100"
        placement="bottom"
      >
        <div className="d-flex flex-column gap-4 h-100">
          <Offcanvas.Header className="p-1">
            <Offcanvas.Title className="h6">
              数式パレット
              {formulaType !== null && (
                <>
                  {formulaType === 'inline'
                    ? ' (インライン)'
                    : ' (ディスプレイ)'}
                </>
              )}
            </Offcanvas.Title>
            <CloseButton onClick={handleClose} />
          </Offcanvas.Header>
          <Offcanvas.Body className="overflow-hidden flex-grow-1 d-flex flex-column gap-2 p-1">
            <MathJaxEditor
              variant="pills"
              className="flex-shrink-0"
              macros={macros ?? []}
              height={150}
              affix={
                formulaType !== null
                  ? formulaType === 'inline'
                    ? '$'
                    : '$$'
                  : ''
              }
              value={formula}
              onChange={(formula) => setFormula(formula)}
            />
            <div className="flex-shrink-0">
              <Nav fill variant="tabs">
                {mobileFormulaPaletteData.map(({ label, tab }) => (
                  <Nav.Item key={tab}>
                    <Nav.Link
                      className="px-1"
                      active={tab === currentTab}
                      onClick={() => {
                        setCurrentTab(tab)
                        setCurrentPill('all')
                      }}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              {mobileFormulaPaletteData.map(({ tab, pillData }) => {
                /* pillDataが一つ以下のときはプルダウンを表示しない */
                if (pillData.length < 2) return <></>
                return (
                  <Form.Select
                    key={tab}
                    size="sm"
                    className={`mt-2 ${tab === currentTab ? '' : 'd-none'}`}
                    style={{ maxWidth: 200 }}
                    onChange={(e) => {
                      setCurrentPill(e.currentTarget.value)
                    }}
                  >
                    <option value="all">すべて</option>
                    {pillData.map(({ label, pill }) => (
                      <option key={pill} value={pill}>
                        {label}
                      </option>
                    ))}
                  </Form.Select>
                )
              })}
            </div>
            <div className="flex-grow-1 overflow-y-auto">
              {mobileFormulaPaletteData.map(({ tab, pillData }) =>
                pillData.map(({ pill, label, buttonData }) => {
                  return (
                    <div
                      key={pill}
                      className={`overflow-hidden ${
                        tab === currentTab &&
                        (currentPill === 'all' || pill === currentPill)
                          ? ''
                          : 'd-none'
                      }`}
                    >
                      {
                        /* pillDataが一つ以下の時はタイトルを表示しない
                         * 特定のpillDataのみ表示されている時はタイトルを表示しない
                         */
                        pillData.length >= 2 && pill !== currentPill && (
                          <div className="fs-5 fw-bold mt-4 mb-2">{label}</div>
                        )
                      }
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: `repeat(auto-fit, minmax(50px, 1fr))`,
                          gridRowGap: '0.35rem',
                          gridColumnGap: '0.35rem',
                        }}
                        className="overflow-hidden"
                      >
                        {buttonData.map((formulaData, formulaIndex) => {
                          const key = `${tab}-${pill}-${formulaIndex}`
                          return (
                            <MobileFormulaPaletteButton
                              key={key}
                              cache={formulaeCache.current[key]}
                              onClick={() => {
                                setFormula(
                                  formula === ''
                                    ? formulaData.formula
                                    : `${formula} ${formulaData.formula}`
                                )
                              }}
                              size={50}
                              {...formulaData}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </Offcanvas.Body>
          <div className="flex-shrink-0 d-flex gap-2">
            <Button variant="secondary" onClick={() => setShow(false)}>
              キャンセル
            </Button>
            <Button
              onClick={() => {
                const command = getInsertFormulaCommand(formula)
                // eslint-disable-next-line @typescript-eslint/unbound-method
                command(editor.state, editor.dispatch)
                setFormula('')
                setShow(false)
              }}
            >
              決定
            </Button>
          </div>
        </div>
      </Offcanvas>
    </>
  )
}
export const MobileFormulaPalette = memo(MobileFormulaPaletteImpl)
