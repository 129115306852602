import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import {
  articleConverter,
  BadgeNotification,
  bookConverter,
  Content,
  DocRef,
  profileRef,
} from '../../../firebase'
import { ArticleTitleLink } from '../..//article'
import { BadgeItem } from '../..//badge'
import { BookTitleLink } from '../..//book'

type props = {
  notification: BadgeNotification
}

export const CreateBadgeNotificationDetail = ({ notification }: props) => {
  const [badge] = useDocumentDataOnce(notification.target_ref)
  const [sender] = useDocumentDataOnce(
    badge && badge.sent_by !== 'official' ? profileRef(badge.sent_by) : null
  )
  const [content] = useDocumentDataOnce<Content>(
    badge?.ref.parent.parent?.parent.id === 'articles'
      ? (badge.ref.parent.parent.withConverter(
          articleConverter
        ) as DocRef<Content>)
      : badge?.ref.parent.parent?.parent.id === 'books'
      ? (badge.ref.parent.parent.withConverter(
          bookConverter
        ) as DocRef<Content>)
      : null
  )

  const senderName =
    badge?.sent_by === 'official'
      ? '運営'
      : sender
      ? sender.display_name
      : 'unknown'

  return badge ? (
    <>
      <p>
        {content ? (
          <>
            {senderName}から
            {content.parent_id === 'books' ? (
              <BookTitleLink book={content} />
            ) : (
              <ArticleTitleLink article={content} />
            )}
            に送られたバッチ
          </>
        ) : (
          <>{senderName}からバッチが送られました。</>
        )}
      </p>
      <BadgeItem badge={badge} size="50px" />
    </>
  ) : (
    <></>
  )
}
