import { FormulaButtonData } from '../type'

const parentheses: FormulaButtonData[] = [
  {
    formula: '\\left( x \\right)',
    tooltip: '丸括弧',
  },
  {
    formula: '\\lbrack x \\rbrack',
    tooltip: '角括弧',
  },
  {
    formula: '\\lceil x \\rfloor',
    tooltip: 'かぎ括弧',
  },
  {
    formula: '\\lfloor x \\rceil',
    tooltip: 'かぎ括弧',
  },
  {
    formula: '\\lbrace x \\rbrace',
    tooltip: '波括弧',
  },
  {
    formula: '\\langle x \\rangle',
    tooltip: '山括弧',
  },
  {
    formula: '\\left| x \\right|',
    tooltip: '絶対値',
  },
  {
    formula: '\\left\\| x \\right\\|',
    tooltip: 'ノルム',
    mobileFontSize: 15,
  },
  {
    formula: '\\overbrace{a}',
    tooltip: '上括弧',
  },
  {
    formula: '\\underbrace{a}',
    tooltip: '下括弧',
  },
]

export const parenthesesPill = {
  pill: 'parentheses',
  label: '括弧',
  buttonData: parentheses,
}
