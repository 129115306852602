import { Badge } from 'react-bootstrap'
import { Message, MessageDraft } from '../../firebase'

type Props = {
  message: Message | MessageDraft
}

export const MessageTypeBadge = ({ message }: Props) => {
  switch (message.type) {
    case 'indication':
      return <Badge bg="danger">指摘</Badge>
    case 'question':
      return (
        <Badge bg="warning" className="text-black">
          質問
        </Badge>
      )
    case 'impression':
      return <Badge bg="primary">感想</Badge>
  }
}
