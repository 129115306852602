import { FormulaButtonData } from '../type'

const analysis: FormulaButtonData[] = [
  {
    formula: '\\lim_{n \\to \\infty}',
    displayFormula: '\\lim',
    tooltip: '極限',
  },
  {
    formula: '\\limsup_{n\\to\\infty}',
    displayFormula: '\\limsup',
    tooltip: '上極限',
    fontSize: 15,
    mobileFontSize: 10,
  },
  {
    formula: '\\varlimsup_{n\\to\\infty}',
    displayFormula: '\\varlimsup',
    tooltip: '上極限（簡略）',
  },
  {
    formula: '\\liminf_{n\\to\\infty}',
    displayFormula: '\\liminf',
    tooltip: '下極限',
    fontSize: 15,
    mobileFontSize: 12,
  },
  {
    formula: '\\varliminf_{n\\to\\infty}',
    displayFormula: '\\varliminf',
    tooltip: '下極限（簡略）',
  },
  {
    formula: '\\frac{dy}{dx}',
    tooltip: '微分（ライプニッツ）',
    mobileFontSize: 12,
  },
  {
    formula: '\\frac{d^n y}{dx^n}',
    tooltip: 'n階微分（ライプニッツ）',
    mobileFontSize: 12,
  },
  {
    formula: 'f^{\\prime}',
    tooltip: '微分（ラグランジュ）',
  },
  {
    formula: 'f^{\\prime\\prime}',
    tooltip: '2階微分（ラグランジュ）',
  },
  {
    formula: 'f^{(n)}',
    tooltip: 'n階微分（ラグランジュ）',
  },
  { formula: 'Df', tooltip: '微分（オイラー）1' },
  {
    formula: 'D_{x} f',
    tooltip: '微分（オイラー）2',
  },
  {
    formula: 'D^{n} f',
    tooltip: 'n階微分（オイラー）',
  },
  {
    formula: '\\dot{y}',
    tooltip: '微分（ニュートン）',
  },
  {
    formula: '\\ddddot{y}',
    tooltip: '4階微分（ニュートン）',
  },
  {
    formula: '\\frac{ \\partial f }{ \\partial x }',
    tooltip: '偏微分1',
  },
  {
    formula: 'f_{x}',
    tooltip: '偏微分2',
  },
  {
    formula: '\\mathbb{grad}\\ ',
    displayFormula: '\\mathbb{grad}',
    tooltip: '勾配',
    mobileFontSize: 14,
  },
  {
    formula: '\\mathbb{rot}\\ ',
    displayFormula: '\\mathbb{rot}',
    tooltip: '回転',
  },
  {
    formula: '\\mathbb{div}\\ ',
    displayFormula: '\\mathbb{div}',
    tooltip: '発散',
  },
  {
    formula: '\\nabla',
    tooltip: 'ナブラ',
  },
  {
    formula: '\\Delta',
    tooltip: 'ラプラシアン',
  },
  {
    formula: '\\int_{a}^{b}',
    displayFormula: '\\int',
    tooltip: 'インテグラル',
    mobileFontSize: 12,
  },
  {
    formula: '\\iint_{D}',
    displayFormula: '\\iint',
    tooltip: '2重積分',
    mobileFontSize: 12,
  },
  {
    formula: '\\oint_{C}',
    displayFormula: '\\oint',
    tooltip: '周回積分',
    mobileFontSize: 11,
  },
]

export const analysisPill = {
  pill: 'analysis',
  label: '解析学',
  buttonData: analysis,
}
