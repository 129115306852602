export type SigmoidOption = {
  a: number
}

export const getSigmoidPainless = (x: string, { a }: SigmoidOption) => {
  return `1 / (1 + Math.exp(- ${a} * ( ${x} )))`
}

export const sigmoid = (x: number, a: number) => {
  return 1 / (1 + Math.exp(-a * x))
}
