import autocomplete, {
  ActionKind,
  AutocompleteAction,
  closeAutocomplete,
  openAutocomplete,
} from 'prosemirror-autocomplete'
import { Fragment, Slice } from 'prosemirror-model'
import { TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Macro } from '../../../../../firebase'
import {
  getBeginOfParagraph,
  getEndOfParagraph,
  getSizeByText,
  mathdownToNode,
} from '../../utils'
import { inputEnter } from '../autocomplete'
import { boxTypes, commands, environments, langs } from './data'

export type Suggestion = {
  label: string
  value: string
  cursor: number
}

export type SuggestionAction = 'command' | 'box' | 'lang' | 'env'

export type Suggestions = {
  name: SuggestionAction | null
  items: Suggestion[]
  selectedIndex: number
  isOpen: boolean
  view: EditorView | null
  range: {
    from: number
    to: number
  } | null
  acceptSuggestion: ((index?: number) => void) | null
  position: {
    editorHeight: number
    cursorHeight: number
    cursorBottom: number
    cursorTop: number
    cursorLeft: number
  }
}

const DEFAULT_SUGGESTIONS: Suggestions = {
  name: null,
  items: [],
  selectedIndex: 0,
  isOpen: false,
  view: null,
  range: null,
  acceptSuggestion: null,
  position: {
    editorHeight: 0,
    cursorHeight: 0,
    cursorTop: 0,
    cursorLeft: 0,
    cursorBottom: 0,
  },
}

export const useSuggestionsPlugins = (
  editorContainerElement: HTMLDivElement | undefined,
  macros: Macro[] = []
) => {
  const editorContainerElementRef = useRef<HTMLDivElement | undefined>(
    editorContainerElement
  )
  useEffect(() => {
    editorContainerElementRef.current = editorContainerElement
  }, [editorContainerElement])

  // マクロを変更不可能な関数でも変更に応じた値を使えるようにmacrosをrefで使えるようにする
  const macrosRef = useRef<Macro[]>(macros)
  useEffect(() => {
    macrosRef.current = macros
  }, [macros])

  const getItems = (action: AutocompleteAction): Suggestion[] => {
    // '\\begin{'はコマンドをacceptした際に即座に発火するため、ユーザ入力によるものではなく、nameが存在しないことがある
    const actionName = action.trigger === '\\begin{' ? 'env' : action.type?.name
    if (actionName === null) return []
    switch (actionName as SuggestionAction) {
      case 'box': {
        return boxTypes.map((type) => {
          return {
            value: `&&&${type}\n\n&&&`,
            label: type,
            cursor: getSizeByText(`&&&${type}\n`),
          }
        })
      }
      case 'lang': {
        return langs.map((lang) => {
          return {
            value: `\`\`\`${lang}\n\n\`\`\``,
            label: lang,
            cursor: getSizeByText(`\`\`\`${lang}\n`),
          }
        })
      }
      case 'command': {
        return [
          {
            value: '\\begin{',
            label: 'begin',
            cursor: getSizeByText(`\\begin{`),
          },
          ...macrosRef.current.map(({ command_name: command }) => {
            return {
              value: `\\${command}`,
              label: command,
              cursor: getSizeByText(`\\${command}`),
            }
          }),
          ...commands.map((command) => {
            return {
              value: `\\${command}`,
              label: command,
              cursor: getSizeByText(`\\${command}`),
            }
          }),
        ]
      }
      case 'env': {
        return environments.map((env) => {
          return {
            value: `\\begin{${env}}\n\n\\end{${env}}`,
            label: env,
            cursor: getSizeByText(`\\begin{${env}}\n`),
          }
        })
      }
      default:
        return []
    }
  }

  // autocompleteのオプションに指定するreducerの関数は変更不可能で、stateを逐次更新しながら使うことが困難なので、refとsuggestionsを使ってsuggestionsを参照できるようにする必要がある
  const [suggestionsState, setSuggestionsState] =
    useState<Suggestions>(DEFAULT_SUGGESTIONS)
  const suggestionsRef = useRef<Suggestions>(DEFAULT_SUGGESTIONS)

  // StateとRefをどちらも更新できる関数
  const setSuggestions = (
    setter: Suggestions | ((currentSuggestions: Suggestions) => Suggestions)
  ) => {
    const newSuggestions: Suggestions =
      typeof setter === 'function' ? setter(suggestionsRef.current) : setter
    setSuggestionsState(newSuggestions)
    suggestionsRef.current = newSuggestions
  }

  const setSuggestionsPosition = () => {
    setSuggestions((current) => {
      if (editorContainerElementRef.current === undefined)
        return DEFAULT_SUGGESTIONS
      const autocompleteElement =
        editorContainerElementRef.current.getElementsByClassName(
          'autocomplete'
        )[0] as HTMLElement
      return {
        ...current,
        position: {
          editorHeight: editorContainerElementRef.current.offsetHeight,
          cursorHeight: autocompleteElement.offsetHeight,
          cursorTop: autocompleteElement.offsetTop,
          cursorBottom:
            autocompleteElement.offsetTop + autocompleteElement.offsetHeight,
          cursorLeft: autocompleteElement.offsetLeft,
        },
      }
    })
  }

  useEffect(() => {
    document.addEventListener('resize', setSuggestionsPosition)
    return () => {
      document.removeEventListener('resize', setSuggestionsPosition)
    }
  }, [])

  const reducer = useCallback((action: AutocompleteAction): boolean => {
    setSuggestions((current) => {
      return {
        ...current,
        name: (action.type?.name as SuggestionAction) ?? null,
        view: action.view,
        range: action.range,
        acceptSuggestion: (index) => {
          const currentSuggestions = suggestionsRef.current
          const item =
            currentSuggestions.items[index ?? currentSuggestions.selectedIndex]

          // beginが選ばれた場合は即座に環境のトリガーを発火する
          if (item.label === 'begin') {
            const tr = action.view.state.tr.deleteRange(
              action.range.from,
              action.range.to
            )
            action.view.dispatch(tr)
            openAutocomplete(action.view, '\\begin{', '')
            return
          }

          const beginOfParagraph = getBeginOfParagraph(
            action.view.state.doc,
            action.range.from
          )
          const endOfParagraph = getEndOfParagraph(
            action.view.state.doc,
            action.range.to
          )

          if (beginOfParagraph === null || endOfParagraph === null)
            throw new Error('Unexpected.')

          const lineStart = action.view.state.tr.doc.textBetween(
            beginOfParagraph,
            action.range.from
          )
          const lineEnd = action.view.state.tr.doc.textBetween(
            action.range.to,
            endOfParagraph
          )
          const text = `${lineStart}${item.value}${lineEnd}`
          const node = mathdownToNode(text)
          const fragment = Fragment.from(node)
          const tr = action.view.state.tr.replace(
            beginOfParagraph - 1,
            endOfParagraph,
            new Slice(fragment, 0, 0)
          )
          tr.setSelection(
            TextSelection.create(tr.doc, action.range.from + item.cursor)
          )
          action.view.dispatch(tr)
          setSuggestions(DEFAULT_SUGGESTIONS)
        },
      }
    })

    switch (action.kind) {
      case ActionKind.open:
        setSuggestionsPosition()
        setSuggestions((current) => {
          return {
            ...current,
            items: getItems(action),
            isOpen: true,
          }
        })
        return true
      case ActionKind.close:
        setSuggestions((current) => {
          return {
            ...current,
            items: [],
            acceptSuggestion: null,
            selectedIndex: 0,
            isOpen: false,
            range: null,
          }
        })
        return true
      case ActionKind.up:
        setSuggestions((current) => {
          let newSelectedIndex = current.selectedIndex
          newSelectedIndex -= 1
          newSelectedIndex += current.items.length
          newSelectedIndex %= current.items.length
          return {
            ...current,
            selectedIndex: newSelectedIndex,
          }
        })
        return true
      case ActionKind.filter: {
        // filterする文字列に英語以外が指定されたら、Autocompleteを閉じる
        if (action.filter !== undefined && !/^[a-zA-Z]*$/.test(action.filter)) {
          closeAutocomplete(action.view)
          return false
        }

        // コマンドの後にbeginが入力された時は環境の方にトリガーを変更するため、一旦Autocompleteを閉じる
        if (action.type?.name === 'command' && action.filter === 'begin') {
          closeAutocomplete(action.view)
          return false
        }

        setSuggestions((current) => {
          const filter = action.filter
          const items = getItems(action).filter(
            (item) => filter === undefined || item.label.startsWith(filter)
          )
          return {
            ...current,
            items,
            selectedIndex: 0,
          }
        })
        return true
      }
      case ActionKind.down:
        setSuggestions((current) => {
          let newSelectedIndex = current.selectedIndex
          newSelectedIndex += 1
          newSelectedIndex %= current.items.length
          return {
            ...current,
            selectedIndex: newSelectedIndex,
          }
        })
        return true
      case ActionKind.enter: {
        // suggestionsStateを参照するとreducerは変更できないので、Stateが変わらずうまく動作しない
        const currentSuggestions = suggestionsRef.current

        // 選択肢がない場合や選択時に受け入れる関数がない場合は標準のエンターの挙動を実行
        if (
          currentSuggestions.items[currentSuggestions.selectedIndex] ===
            undefined ||
          currentSuggestions.acceptSuggestion === null
        ) {
          closeAutocomplete(action.view)
          inputEnter(action.view)
          return false
        }

        // 現在選択中の選択肢を受け入れる
        currentSuggestions.acceptSuggestion()

        return true
      }
      default:
        return false
    }
  }, [])

  const options = {
    reducer,
    triggers: [
      { name: 'box', trigger: /^(&&&)$/ },
      { name: 'env', trigger: /(\\begin\{)$/ },
      { name: 'command', trigger: /(\\)$/ },
      { name: 'lang', trigger: /^(```)$/ },
    ],
  }

  return { plugins: autocomplete(options), suggestions: suggestionsState }
}
