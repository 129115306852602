import { memo } from 'react'
import { Button } from 'react-bootstrap'
import { FaArrowLeft, FaArrowRight, FaRegTrashAlt } from 'react-icons/fa'

type Props = {
  table: string[][]
  setTable: (table: string[][]) => void
  col: number
  width: number
  disableDelete: boolean
}

const ColManagerImpl = ({
  col,
  table,
  setTable,
  width,
  disableDelete,
}: Props) => {
  // col1 < col2 を満たすようにすること
  const changeCol = (col1: number, col2: number) =>
    setTable(
      table.map((rowVal) => [
        ...rowVal.slice(0, col1),
        rowVal[col2],
        ...rowVal.slice(col1 + 1, col2),
        rowVal[col1],
        ...rowVal.slice(col2 + 1),
      ])
    )

  const deleteCol = (col: number) =>
    setTable(
      table.map((rowVal) => [...rowVal.slice(0, col), ...rowVal.slice(col + 1)])
    )

  return (
    <div
      style={{ width }}
      className="flex-shrink-0 d-flex gap-2 justify-content-between"
    >
      <Button
        variant="secondary"
        disabled={col === 0}
        onClick={() => changeCol(col - 1, col)}
      >
        <FaArrowLeft />
      </Button>
      <Button
        variant="danger"
        disabled={disableDelete}
        onClick={() => deleteCol(col)}
      >
        <FaRegTrashAlt />
      </Button>
      <Button
        variant="secondary"
        disabled={col === table[0].length - 1}
        onClick={() => changeCol(col, col + 1)}
      >
        <FaArrowRight />
      </Button>
    </div>
  )
}

export const ColManager = memo(ColManagerImpl)
