import { ReplyItem } from '.'
import { Comment, CommentsSectionSetting, Reply } from '../../firebase'

type Props = {
  comment: Comment
  replies: Reply[]
  commentSectionOwnerUid: string
  setting?: CommentsSectionSetting
}

export const RepliesList = ({
  replies,
  comment,
  commentSectionOwnerUid,
  ...wrapperProps
}: Props) => {
  return (
    <div {...wrapperProps}>
      {replies.map((reply, index) => (
        <ReplyItem
          key={reply.id}
          comment={comment}
          reply={reply}
          className={index !== 0 ? 'mt-6' : ''}
          commentSectionOwnerUid={commentSectionOwnerUid}
        />
      ))}
      {replies.length === 0 && <div>返信はありません。</div>}
    </div>
  )
}
