import { Profile } from '../firebase'
import { getSigmoidPainless, sigmoid } from './sigmoid'

export const AuthorEvaluationIndicator = {
  weights: {
    contents_good_count_sum: 1.0,
    contents_bad_count_sum: -1.0,
    contents_reports_score_sum: -1.5,
    // 追加する場合はcalcAuthorEvaluation関数も更新すること
  },
  sigmoid: {
    a: 1 / 10,
  },
}

export const getAuthorEvaluationPainless = () => {
  const weightKeys = Object.keys(
    AuthorEvaluationIndicator['weights']
  ) as (keyof typeof AuthorEvaluationIndicator['weights'])[]
  const x = weightKeys
    .map(
      (key) =>
        `${AuthorEvaluationIndicator['weights'][key]} * doc['user.${key}'].value`
    )
    .join(' + ')
  return getSigmoidPainless(x, AuthorEvaluationIndicator['sigmoid'])
}

export const calcAuthorEvaluation = (author: Profile) => {
  const weights = AuthorEvaluationIndicator.weights
  const { good_count, bad_count, reports_score } = author.contents_aggregation
  return sigmoid(
    good_count * weights['contents_good_count_sum'] +
      bad_count * weights['contents_bad_count_sum'] +
      reports_score * weights['contents_reports_score_sum'],
    AuthorEvaluationIndicator.sigmoid.a
  )
}
