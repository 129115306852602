import { FormulaButtonData } from '../type'

const figure: FormulaButtonData[] = [
  {
    formula: '\\circ',
    tooltip: '白丸',
  },
  {
    formula: '\\bullet',
    tooltip: '黒丸',
  },
  {
    formula: '\\bigcirc',
    tooltip: '大きい丸',
  },
  {
    formula: '\\oplus',
    tooltip: '丸にプラス',
  },
  {
    formula: '\\ominus',
    tooltip: '丸にマイナス',
  },
  {
    formula: '\\otimes',
    tooltip: '丸に掛ける',
  },
  {
    formula: '\\odot',
    tooltip: '丸に点',
  },
  {
    formula: '\\triangle',
    tooltip: '三角形',
  },
  {
    formula: '\\triangledown',
    tooltip: '下向き三角形',
  },
  {
    formula: '\\bigtriangleup',
    tooltip: '大きな上向き三角形',
  },
  {
    formula: '\\bigtriangledown',
    tooltip: '大きな下向き三角形',
  },
  {
    formula: '\\triangleleft',
    tooltip: '左向き三角形',
  },
  {
    formula: '\\lhd',
    tooltip: '左向き三角形2',
  },
  {
    formula: '\\triangleright',
    tooltip: '右向き三角形',
  },
  {
    formula: '\\rhd',
    tooltip: '右向き三角形2',
  },
  {
    formula: '\\unlhd',
    tooltip: '左向き三角形と下線',
  },
  {
    formula: '\\unrhd',
    tooltip: '右向き三角形と下線',
  },
  {
    formula: '\\blacktriangle',
    tooltip: '黒い三角形',
  },
  {
    formula: '\\square',
    tooltip: '正方形',
  },
  {
    formula: '\\Box',
    tooltip: '四角形',
  },
  {
    formula: '\\boxplus',
    tooltip: '四角形と十字',
  },
  {
    formula: '\\boxminus',
    tooltip: '四角形と横線',
  },
  {
    formula: '\\boxtimes',
    tooltip: '四角形と×',
  },
  {
    formula: '\\boxdot',
    tooltip: '四角形と点',
  },
  {
    formula: '\\blacksquare',
    tooltip: '黒い正方形',
  },
  {
    formula: '\\diamond',
    tooltip: 'ダイヤモンド',
  },
  {
    formula: '\\Diamond',
    tooltip: 'ダイヤモンド2',
  },
  {
    formula: '\\lozenge',
    tooltip: 'ひし形',
  },
  {
    formula: '\\blacklozenge',
    tooltip: '黒いひし形',
  },
]

export const figurePill = {
  pill: 'figure',
  label: '図形',
  buttonData: figure,
}
