import { useEffect } from 'react'
import { Designed } from '../../types'
import { ADSENSE_CLIENT } from '../../utils'

type Props = {
  slot?: string
  height?: string
}

export const AdsCard = ({ slot, height, ...wrapperProps }: Designed<Props>) => {
  useEffect(() => {
    try {
      if (window.adsbygoogle === undefined) window.adsbygoogle = []
      window.adsbygoogle.push({})
    } catch (err) {
      console.log('AdSense ads are already loaded.')
    }
  }, [])

  return (
    <div {...wrapperProps}>
      {height !== undefined ? (
        <ins
          className="adsbygoogle"
          style={{ display: 'block', height }}
          data-ad-client={ADSENSE_CLIENT}
          data-ad-slot={slot}
          data-full-width-responsive="true"
        />
      ) : (
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client={ADSENSE_CLIENT}
          data-ad-slot={slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        />
      )}
    </div>
  )
}
