import { Button } from 'react-bootstrap'
import { FaPencilAlt } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'

type Props = {
  onClickEditButton: () => void
}

export const EditMacroButton = ({
  onClickEditButton: handleClickEditButton,
}: Props) => {
  return (
    <Button
      type="button"
      size="sm"
      variant="success"
      onClick={() => {
        const msg =
          '左側のフォームに入力中の内容は失われます。本当に続けますか？'
        if (window.confirm(msg)) handleClickEditButton()
      }}
    >
      <IconWrapper suffix="編集">
        <FaPencilAlt />
      </IconWrapper>
    </Button>
  )
}
