import JSZip from 'jszip'
import { Book, Macro, Page, Profile, Reference, fb } from '../../../../firebase'
import { Lexer } from '../lexer'
import { parseBlockToTex } from '../parser/tex'
import { TexImage } from '../types'
import { makeCompleteTexFile } from '../utils'

export const makeBookTexFilesZip = async (
  zip: JSZip,
  book: Book,
  author: Profile,
  pages: Page[],
  macros: Macro[],
  references: Reference[]
) => {
  const labels: Map<string, string> = new Map()
  const images: Map<string, TexImage> = new Map()
  // 概要と目次の作成
  let src = '\\chapter*{概要}\n'
  src += book.description + '\n'
  src += '\\tableofcontents\n'
  for (let i = 0; i < pages.length; i++) {
    const tokens = Lexer.lex(pages[i].body, null, labels, images)
    src += '\\chapter{' + pages[i].title + '}\n'
    const body = parseBlockToTex(tokens, labels, images)
    src += body
  }
  const imagesKeysArray: string[] = Array.from(images.keys())
  await Promise.all(
    imagesKeysArray.map(async (key) => {
      const texImage = images.get(key)
      if (texImage !== undefined) {
        try {
          const { data } = await fb.call('fetchImageByURL')({
            url: texImage.url,
          })
          const imageBuffer = Buffer.from(data.image, 'base64')
          // // 画像をPNG形式に変換
          zip.file(texImage.fileName, imageBuffer, { binary: true })
        } catch (error) {
          console.error(error)
        }
      }
    })
  )
  const tex = await makeCompleteTexFile(
    'jsbook',
    book.title,
    author.display_name,
    book.created_at,
    src,
    macros,
    references
  )
  zip.file('main.tex', tex)
}
