import { useEffect } from 'react'
import { Notification, updateDoc } from '../../../firebase'
import { useAuthState } from '../../../firebase/hooks'
import { getMessage } from '../../../models/notification'
import { Designed } from '../../../types'
import { CreateBadgeNotificationDetail } from './CreateBadgeDetail'
import { CreateCommentNotificationDetail } from './CreateCommentDetail'
import { CreateReplyNotificationDetail } from './CreateReplyDetail'

type NotificationDetailProps = {
  notification: Notification
}

const NotificationDetail = ({
  notification,
}: NotificationDetailProps): JSX.Element => {
  switch (notification.message) {
    case 'create_comment':
      return <CreateCommentNotificationDetail notification={notification} />

    case 'create_reply':
      return <CreateReplyNotificationDetail notification={notification} />

    case 'create_badge':
      return <CreateBadgeNotificationDetail notification={notification} />

    case 'official_notice':
      return <></>
  }
}

type Props = {
  notification: Notification
  readAction?: boolean
  onlySummary?: boolean
}

export const NotificationItem = ({
  notification,
  readAction = false,
  onlySummary = false,
}: Designed<Props>) => {
  const { user } = useAuthState()
  useEffect(() => {
    if (!user) return
    if (!readAction) return
    if (notification.status === 'read') return
    if (onlySummary === true) return
    void updateDoc<Notification>(notification.ref, { status: 'read' }) // 表示されたら既読にする
  }, [readAction, user])

  return (
    <div>
      <p>{getMessage(notification)}</p>
      {onlySummary === false && (
        <NotificationDetail notification={notification} />
      )}
    </div>
  )
}
