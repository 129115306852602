import { EditorView } from 'prosemirror-view'
import { memo, useState } from 'react'
import {
  DropdownButton as BootstrapDropdownButton,
  ButtonGroup,
} from 'react-bootstrap'

type Props = {
  menuData: any[]
  editor: EditorView
  menuStyle: React.CSSProperties
  children: React.ReactNode
  menuClassName: string
}

const DropdownImpl = ({
  children,
  menuData,
  editor,
  menuStyle,
  menuClassName,
}: Props) => {
  const [show, setShow] = useState(false)
  return (
    <BootstrapDropdownButton
      title={children}
      variant={show ? 'dark' : 'outline-dark'}
      size="sm"
      onToggle={() => setShow(!show)}
      as={ButtonGroup}
      show={show}
    >
      <div className={menuClassName} style={menuStyle}>
        {menuData.map(({ component: Component, ...props }, index) => (
          <Component
            key={index}
            editor={editor}
            onClick={() => {
              setShow(false)
            }}
            {...props}
          />
        ))}
      </div>
    </BootstrapDropdownButton>
  )
}

export const Dropdown = memo(DropdownImpl)
