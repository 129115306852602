import { FormulaButtonData } from '../type'
import { makeFormulaArrayWithAlphabet } from './utils'

const cal: FormulaButtonData[] = makeFormulaArrayWithAlphabet('mathcal')

export const calPill = {
  pill: 'cal',
  label: 'カリグラフィー',
  buttonData: cal,
}
