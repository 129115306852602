import Image from 'next/image'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'

type Props = {
  text: string
}

export const BeginnerIcon = ({ text, ...wrapperProps }: Designed<Props>) => {
  return (
    <IconWrapper suffix={text} {...wrapperProps}>
      <Image
        src="/icons/elementary.svg"
        alt="初心者マーク"
        width={150 / 13}
        height={192 / 13}
      />
    </IconWrapper>
  )
}
