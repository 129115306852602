import { FormulaButtonData } from '../type'

const arrow: FormulaButtonData[] = [
  {
    formula: '\\leftarrow',
    tooltip: '左矢印',
  },
  {
    formula: '\\longleftarrow',
    tooltip: '左矢印（長い）',
  },
  {
    formula: '\\rightarrow',
    tooltip: '右矢印',
  },
  {
    formula: '\\longrightarrow',
    tooltip: '右矢印（長い）',
  },
  {
    formula: '\\uparrow',
    tooltip: '上矢印',
  },
  {
    formula: '\\downarrow',
    tooltip: '下矢印',
  },
  {
    formula: '\\leftrightarrow',
    tooltip: '左右矢印',
  },
  {
    formula: '\\longleftrightarrow',
    tooltip: '左右矢印（長い）',
  },
  {
    formula: '\\updownarrow',
    tooltip: '上下矢印',
  },
  {
    formula: '\\Leftarrow',
    tooltip: '2重左矢印',
  },
  {
    formula: '\\Longleftarrow',
    tooltip: '2重左矢印（長い）',
  },
  {
    formula: '\\Rightarrow',
    tooltip: '2重右矢印',
  },
  {
    formula: '\\Longrightarrow',
    tooltip: '2重右矢印（長い）',
  },
  {
    formula: '\\Uparrow',
    tooltip: '2重上矢印',
  },
  {
    formula: '\\Downarrow',
    tooltip: '2重下矢印',
  },
  {
    formula: '\\Leftrightarrow',
    tooltip: '2重左右矢印',
  },
  {
    formula: '\\Longleftrightarrow',
    tooltip: '2重左右矢印（長い）',
  },
  {
    formula: '\\Updownarrow',
    tooltip: '2重上下矢印',
  },
  {
    formula: '\\nearrow',
    tooltip: '右上矢印',
  },
  {
    formula: '\\searrow',
    tooltip: '右下矢印',
  },
  {
    formula: '\\nwarrow',
    tooltip: '左上矢印',
  },
  {
    formula: '\\swarrow',
    tooltip: '左下矢印',
  },
  {
    formula: '\\mapsto',
    tooltip: '棒付矢印',
  },
  {
    formula: '\\longmapsto',
    tooltip: '棒付矢印（長い）',
  },
  {
    formula: '\\circlearrowright',
    tooltip: '時計回り矢印',
  },
  {
    formula: '\\circlearrowleft',
    tooltip: '反時計回り矢印',
  },
]

export const arrowPill = {
  pill: 'arrow',
  label: '矢印',
  buttonData: arrow,
}
