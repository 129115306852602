import { useMemo, useRef, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { FaPencilAlt } from 'react-icons/fa'
import { Batch, Macro, Package, updateDoc } from '../../firebase'
import { Designed } from '../../types'
import { MacroList } from './List'

type ItemProps = {
  package?: Package
  macros: Macro[]
  activeKeys: string[]
  editingId?: string
  setActiveKeys: (keys: string[]) => void
  onClickEditButton: (macro: Macro) => void
}

const PackageItem = ({
  package: pkg,
  macros,
  activeKeys,
  setActiveKeys,
  editingId,
  onClickEditButton: handleClickEditButton,
  ...otherProps
}: Designed<ItemProps>) => {
  const packageMacros = useMemo(() => {
    return macros.filter((macro) => macro.package_id === pkg?.id)
  }, [macros, pkg])

  const [isEditing, setIsEditing] = useState(false)
  const nameFieldRef = useRef<HTMLInputElement>(null)

  if (!pkg && packageMacros.length === 0) return <></>
  return (
    <div {...otherProps}>
      <div className="d-flex gap-2 mb-2 align-items-center">
        {!isEditing ? (
          <>
            <h2 className="h6 fw-bold mb-0">
              {pkg ? pkg.name || '名称未設定' : 'パッケージ未指定'}
            </h2>
            {pkg && (
              <FaPencilAlt
                className="small text-success"
                onClick={() => setIsEditing(true)}
              />
            )}
          </>
        ) : (
          <InputGroup style={{ maxWidth: 300 }}>
            <FormControl
              defaultValue={pkg?.name}
              size="sm"
              ref={nameFieldRef}
            />
            <Button
              size="sm"
              onClick={async () => {
                const value = nameFieldRef.current?.value
                if (value !== undefined && value !== '' && pkg)
                  await updateDoc(pkg.ref, { name: value })
                setIsEditing(false)
              }}
            >
              決定
            </Button>
          </InputGroup>
        )}
      </div>
      {pkg ? (
        <div className="d-flex mb-2 gap-2">
          {pkg.is_active ? (
            <Button
              size="sm"
              variant="link"
              className="text-danger"
              onClick={async () => {
                await updateDoc(pkg.ref, { is_active: false })
              }}
            >
              無効化
            </Button>
          ) : (
            <Button
              size="sm"
              variant="link"
              className="text-success"
              onClick={async () => {
                await updateDoc(pkg.ref, { is_active: true })
              }}
            >
              有効化
            </Button>
          )}
          <Button
            size="sm"
            variant="link"
            className="text-danger"
            onClick={async () => {
              const confirmed = window.confirm(
                'パッケージを削除すると関連するマクロが全て削除されます。本当に削除しますか。'
              )
              if (!confirmed) return
              const batch = new Batch()
              await batch.delete(pkg.ref)
              for (const macro of packageMacros) {
                await batch.delete(macro.ref)
              }
              await batch.commit()
            }}
          >
            削除
          </Button>
        </div>
      ) : (
        <div className="text-muted mb-2">
          パッケージが未指定のマクロは常に有効状態となります。
        </div>
      )}

      <MacroList
        editingId={editingId}
        macros={packageMacros}
        onClickEditButton={handleClickEditButton}
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
      />
    </div>
  )
}

type Props = {
  packages: Package[]
  macros: Macro[]
  activeKeys: string[]
  editingId?: string
  setActiveKeys: (keys: string[]) => void
  onClickEditButton: (macro: Macro) => void
}

export const PackageList = ({
  packages,
  macros,
  setActiveKeys,
  activeKeys,
  onClickEditButton: handleClickEditButton,
  editingId,
  ...otherProps
}: Designed<Props>) => {
  return (
    <div {...otherProps}>
      <div className="d-flex flex-column gap-7">
        <PackageItem
          editingId={editingId}
          macros={macros}
          onClickEditButton={handleClickEditButton}
          activeKeys={activeKeys}
          setActiveKeys={setActiveKeys}
        />
        {packages.map((pkg) => (
          <PackageItem
            key={pkg.id}
            editingId={editingId}
            macros={macros}
            package={pkg}
            onClickEditButton={handleClickEditButton}
            activeKeys={activeKeys}
            setActiveKeys={setActiveKeys}
          />
        ))}
      </div>
    </div>
  )
}
