import { EditorView } from 'prosemirror-view'
import { useEffect, useState } from 'react'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { FaLongArrowAltDown, FaLongArrowAltUp, FaTimes } from 'react-icons/fa'
import { VscReplace, VscReplaceAll, VscSearch } from 'react-icons/vsc'
import { Designed } from '../../../../../types'
import {
  deactivateSearch,
  getReplaceSearchedTextCommand,
  getSearchCommand,
  proceedNextSearchedText,
  proceedPrevSearchedText,
} from '../../commands'
import { useMathdownEditorState } from '../../context'

type Props = {
  editor: EditorView
}

export const PCSearchReplace = ({ editor, ...otherProps }: Designed<Props>) => {
  const { searchState } = useMathdownEditorState()
  const [search, setSearch] = useState(searchState?.query ?? '')
  const [replacer, setReplacer] = useState('')

  useEffect(() => {
    setSearch(searchState?.query ?? '')
  }, [searchState])

  const matchesCount = searchState?.decorations.find().length ?? 0

  if (searchState === undefined || !searchState.active) return <></>
  return (
    <div {...otherProps}>
      <div className="d-flex p-1 gap-2 align-items-baseline">
        <div className="flex-grow-1 d-flex flex-column gap-1">
          <InputGroup>
            <FormControl
              size="sm"
              className="border-secondary box-shadow-none"
              value={search}
              placeholder="検索する文字列"
              onChange={(e) => {
                setSearch(e.currentTarget.value)
                const searchCommand = getSearchCommand(
                  e.currentTarget.value,
                  searchState?.cursor
                )
                // eslint-disable-next-line @typescript-eslint/unbound-method
                searchCommand(editor.state, editor.dispatch, editor)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  // eslint-disable-next-line @typescript-eslint/unbound-method
                  proceedNextSearchedText(editor.state, editor.dispatch, editor)
                }
              }}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="py-0 fs-6"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/unbound-method
                proceedNextSearchedText(editor.state, editor.dispatch, editor)
              }}
            >
              <VscSearch />
            </Button>
          </InputGroup>
          <InputGroup>
            <FormControl
              size="sm"
              className="border-secondary box-shadow-none"
              value={replacer}
              placeholder="置換する文字列"
              onChange={(e) => setReplacer(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const replaceCommand = getReplaceSearchedTextCommand(replacer)
                  // eslint-disable-next-line @typescript-eslint/unbound-method
                  replaceCommand(editor.state, editor.dispatch, editor)
                }
              }}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="py-0 fs-6"
              onClick={() => {
                const replaceCommand = getReplaceSearchedTextCommand(replacer)
                // eslint-disable-next-line @typescript-eslint/unbound-method
                replaceCommand(editor.state, editor.dispatch, editor)
              }}
            >
              <VscReplace />
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              className="py-0 fs-6"
              onClick={() => {
                const replaceCommand = getReplaceSearchedTextCommand(
                  replacer,
                  true
                )
                // eslint-disable-next-line @typescript-eslint/unbound-method
                replaceCommand(editor.state, editor.dispatch, editor)
              }}
            >
              <VscReplaceAll />
            </Button>
          </InputGroup>
        </div>
        <div
          style={{ width: 200 }}
          className="py-1 d-flex justify-content-between align-items-center pe-3"
        >
          {matchesCount > 0 ? (
            <div className="text-muted">
              {searchState.currentIndex !== null
                ? searchState.currentIndex + 1
                : 0}
              /{matchesCount}
            </div>
          ) : (
            <div className="text-danger small">検索結果はありません</div>
          )}
          <div className="d-flex align-items-center gap-3">
            {matchesCount > 0 && (
              <>
                <Button
                  variant="link"
                  className="text-muted"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    proceedNextSearchedText(editor.state, editor.dispatch)
                  }}
                >
                  <FaLongArrowAltDown />
                </Button>
                <Button
                  variant="link"
                  className="text-muted"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/unbound-method
                    proceedPrevSearchedText(editor.state, editor.dispatch)
                  }}
                >
                  <FaLongArrowAltUp />
                </Button>
              </>
            )}
            <Button
              variant="link"
              className="text-muted"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/unbound-method
                deactivateSearch(editor.state, editor.dispatch)
              }}
            >
              <FaTimes />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
