import { basicTab } from '../data/basic'
import { charactersTab } from '../data/characters'
import { fieldsTab } from '../data/fields'
import { popularTab } from '../data/popular'
import { symbolsTab } from '../data/symbols'

export const mobileFormulaPaletteData = [
  popularTab,
  basicTab,
  charactersTab,
  fieldsTab,
  symbolsTab,
]
