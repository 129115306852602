import JSZip from 'jszip'
import router from 'next/router'
import { useState } from 'react'
import { FaFileDownload, FaLock } from 'react-icons/fa'
import { useAccount } from '../../context/account'
import { Book, Macro, Page, Profile, Reference } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { Designed } from '../../types'
import { HELP_NOTION_LINKS, saveBlob } from '../../utils'
import { ConfirmationPopup } from '../ConfirmationPopup'
import { IconWrapper } from '../IconWrapper'
import { makeBookTexFilesZip } from '../mathdown/compiler/tex_export/book'
import { SpinnerButton } from '../spinners'
import { PremiumPlanPaymentPopup } from '../subscription/PremiumPlanPaymentPopup'

type Props = {
  book: Book
  author: Profile
  pages: Page[]
  macros: Macro[]
  references: Reference[]
  variant?: string
}

export const LaTeXExportButton = ({
  book,
  author,
  pages,
  macros,
  references,
  ...otherProps
}: Designed<Props>) => {
  const [downloading, setDownloading] = useState(false)
  const [premiumPlanPopupShow, setPremiumPlanPopupShow] = useState(false)
  const [showDLConfirmationPopup, setShowDLConfirmationPopup] = useState(false)
  const { account } = useAccount()
  const { isSignedIn, showSignInPopup } = useAuthState()
  const texExportable = account?.features?.tex_export ?? false
  const latexExportLink = HELP_NOTION_LINKS.LATEX_EXPORT
  const handleYes = async () => {
    setShowDLConfirmationPopup(false)
    if (!account) throw new Error('This action needs to authenticate.')
    if (!texExportable) {
      setPremiumPlanPopupShow(true)
      return
    }
    setDownloading(true)
    const zip = new JSZip()
    await makeBookTexFilesZip(zip, book, author, pages, macros, references)
    const blob = await zip.generateAsync({ type: 'blob' })
    saveBlob(blob, book.title)
    setDownloading(false)
  }

  const handleNo = async () => {
    setShowDLConfirmationPopup(false)
    if (!account) throw new Error('This action needs to authenticate.')
    await router.push(`/books/${book.id}`)
  }

  const handleDownloadClick = () => {
    if (isSignedIn !== undefined && isSignedIn) {
      setShowDLConfirmationPopup(true)
    } else {
      showSignInPopup()
    }
  }

  return (
    <>
      <ConfirmationPopup
        show={showDLConfirmationPopup}
        title="LaTeXエクスポートに関する注意"
        yesLabel="ダウンロード"
        noLabel="キャンセル"
        onSelectNo={handleNo}
        onSelectYes={handleYes}
        onHide={() => setShowDLConfirmationPopup(false)}
      >
        MathlogのpLaTeX形式のLaTeXエクスポートに対応しております。Mathlogの記法と完全な互換性はありませんので、修正が必要になることがあります。詳細は、Mathlogの
        <a href={latexExportLink} target="_blank" rel="noopener noreferrer">
          LaTeXエクスポート
        </a>
        の使い方をご覧ください。
      </ConfirmationPopup>
      <SpinnerButton
        variant="link"
        loading={downloading}
        className="text-primary"
        onClick={handleDownloadClick}
        {...otherProps}
      >
        {texExportable ? (
          <IconWrapper suffix="LaTeXエクスポート">
            <FaFileDownload />
          </IconWrapper>
        ) : (
          <IconWrapper suffix="LaTeXエクスポート">
            <FaLock />
          </IconWrapper>
        )}
      </SpinnerButton>
      <PremiumPlanPaymentPopup
        show={premiumPlanPopupShow}
        onHide={() => setPremiumPlanPopupShow(false)}
      />
    </>
  )
}
