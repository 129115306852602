import { FaCrown } from 'react-icons/fa'
import { ESArticle } from '../../elastic_search'
import { Designed } from '../../types'
import { ArticleItem } from './Item'

const CROWN_COLOR = ['#dbb52d', '#c9caca', '#c08d5e']

type RankingProps = {
  number: number
}

export const Rank = ({ number }: RankingProps) => {
  return (
    <>
      <div
        className={`text-center d-lg-none fw-bold ${
          number <= 3 ? 'lh-0' : 'lh-1'
        }`}
        style={{ fontSize: '30px', minWidth: '50px' }}
      >
        {number <= 3 && <FaCrown style={{ color: CROWN_COLOR[number - 1] }} />}
        {number > 3 && <span className="text-orange">{number}</span>}
      </div>
      <div
        className={`text-center d-none d-lg-block fw-bold ${
          number <= 3 ? 'lh-0' : 'lh-1'
        }`}
        style={{ fontSize: '45px', minWidth: '50px' }}
      >
        {number <= 3 && <FaCrown style={{ color: CROWN_COLOR[number - 1] }} />}
        {number > 3 && <span className="text-orange">{number}</span>}
      </div>
    </>
  )
}

type Props = {
  articles: ESArticle[]
  showProfile?: boolean
  showProfileImage?: boolean
  showProfileDisplayName?: boolean
  notExistMessage?: string
  border?: boolean
}

export const RankingArticleList = ({
  articles,
  showProfile,
  showProfileImage,
  showProfileDisplayName,
  notExistMessage,
  border = false,
  ...props
}: Designed<Props>) => (
  <div {...props}>
    {articles.map((article, index) => (
      <div className="d-flex gap-3 w-100 overflow-hidden" key={article.id}>
        <div className="flex-shrink-0 align-self-start">
          <Rank number={index + 1} />
        </div>
        <ArticleItem
          className="flex-grow-1 overflow-hidden"
          article={article}
          showProfile={showProfile}
          showProfileImage={showProfileImage}
          showProfileDisplayName={showProfileDisplayName}
        />
        {border && <hr className="my-2" key={`border-bottom-${article.id}`} />}
      </div>
    ))}
    {articles.length === 0 && (
      <span className="text-muted">
        {notExistMessage ?? '記事はありません。'}
      </span>
    )}
  </div>
)
