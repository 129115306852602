export const boxTypes = [
  'def',
  'thm',
  'prop',
  'lem',
  'cor',
  'prf',
  'conj',
  'axm',
  'fml',
  'ex',
  'exc',
  'rem',
]
