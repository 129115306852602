import { FormulaButtonData } from '../type'

const decorations: FormulaButtonData[] = [
  {
    formula: '\\boldsymbol{ a }',
    displayFormula: '\\boldsymbol{ a }',
    tooltip: '太字',
  },
  {
    formula: '\\hat{ a }',
    displayFormula: '\\hat{ a }',
    tooltip: 'ハット',
  },
  {
    formula: '\\grave{ a }',
    displayFormula: '\\grave{ a }',
    tooltip: 'グレイブ',
  },
  {
    formula: '\\acute{ a }',
    displayFormula: '\\acute{ a }',
    tooltip: 'アキュート',
  },
  {
    formula: '\\dot{ a }',
    displayFormula: '\\dot{ a }',
    tooltip: 'ドット',
  },
  {
    formula: '\\ddot{ a }',
    displayFormula: '\\ddot{ a }',
    tooltip: 'ダブルドット',
  },
  {
    formula: '\\bar{ a }',
    displayFormula: '\\bar{ a }',
    tooltip: 'バー',
  },
  {
    formula: '\\overline{ A }',
    displayFormula: '\\overline{ A }',
    tooltip: '上線',
  },
  {
    formula: '\\underline{ A }',
    displayFormula: '\\underline{ A }',
    tooltip: '下線',
  },
  {
    formula: '\\cancel{a}',
    displayFormula: '\\cancel{a}',
    tooltip: 'キャンセル',
  },
  {
    formula: '\\bcancel{a}',
    displayFormula: '\\bcancel{a}',
    tooltip: 'バックキャンセル',
  },
  {
    formula: '\\xcancel{a}',
    displayFormula: '\\xcancel{a}',
    tooltip: 'x印キャンセル',
  },
  {
    formula: '\\cancelto{a}{b}',
    displayFormula: '\\cancelto{a}{b}',
    tooltip: 'キャンセルと矢印',
  },
  {
    formula: '\\vec{ a }',
    displayFormula: '\\vec{ a }',
    tooltip: 'ベクトル',
  },
  {
    formula: '\\overrightarrow{ a }',
    displayFormula: '\\overrightarrow{ a }',
    tooltip: '頭に右矢印',
  },
  {
    formula: '\\overleftarrow{ a }',
    displayFormula: '\\overleftarrow{ a }',
    tooltip: '頭に左矢印',
  },
  {
    formula: '\\check{ a }',
    displayFormula: '\\check{ a }',
    tooltip: 'チェック',
  },
  {
    formula: '\\tilde{ a }',
    displayFormula: '\\tilde{ a }',
    tooltip: 'チルダ',
  },
  {
    formula: '\\breve{ a }',
    displayFormula: '\\breve{ a }',
    tooltip: 'ブリーブ',
  },
  {
    formula: '\\widehat{a}',
    displayFormula: '\\widehat{a}',
    tooltip: '広いハット',
  },
  {
    formula: '\\widetilde{a}',
    displayFormula: '\\widetilde{a}',
    tooltip: '広いチルダ',
  },
  {
    formula: '\\boxed{a}',
    displayFormula: '\\boxed{a}',
    tooltip: '囲み文字',
  },
  {
    formula: 'a^{b}',
    displayFormula: 'a^{b}',
    tooltip: '上付き文字',
  },
  {
    formula: '{}^{b} a',
    displayFormula: '{}^{b} a',
    tooltip: '上付き文字（左）',
  },
  {
    formula: 'a_{b}',
    displayFormula: 'a_{b}',
    tooltip: '下付き文字',
  },
  {
    formula: '{}_{b} a',
    displayFormula: '{}_{b} a',
    tooltip: '下付き文字（左）',
  },
  {
    formula: '\\tiny{A}',
    displayFormula: '\\tiny{A}',
    tooltip: '極小サイズ',
  },
  {
    formula: '\\scriptsize{A}',
    displayFormula: '\\scriptsize{A}',
    tooltip: '小さいサイズ',
  },
  {
    formula: '\\small{A}',
    displayFormula: '\\small{A}',
    tooltip: '小さいサイズ2',
  },
  {
    formula: '\\normalsize{A}',
    displayFormula: '\\normalsize{A}',
    tooltip: 'ノーマルサイズ',
  },
  {
    formula: '\\large{A}',
    displayFormula: '\\large{A}',
    tooltip: '大きいサイズ',
  },
  {
    formula: '\\Large{A}',
    displayFormula: '\\Large{A}',
    tooltip: '大きいサイズ2',
  },
  {
    formula: '\\LARGE{A}',
    displayFormula: '\\LARGE{A}',
    tooltip: '大きいサイズ3',
  },
  {
    formula: '\\huge{A}',
    displayFormula: '\\huge{A}',
    tooltip: '極大サイズ',
  },
  {
    formula: '\\Huge{A}',
    displayFormula: '\\Huge{A}',
    tooltip: '極大サイズ2',
  },
]

export const decorationsPill = {
  pill: 'decorations',
  label: '装飾',
  buttonData: decorations,
}
