import Link from 'next/link'
import { Nav } from 'react-bootstrap'
import { useElementSize } from 'web_core/hooks'
import {
  MOBILE_BOTTOM_MENU_HEIGHT,
  useLayoutProperty,
} from '~/context/layout_property'
import { HeaderNavLink, NAV_LINKS } from './Default'

type Props = {
  activeNavLink?: HeaderNavLink // headerMenu の特定要素をアクティブに
}

export const MobileBottomMenu = ({ activeNavLink }: Props) => {
  const { setMobileBottomMenuHeight } = useLayoutProperty()

  const { elementRef: mobileBottomMenuRef, height: mobileBottomMenuHeight } =
    useElementSize({
      onChangeHeight: setMobileBottomMenuHeight,
      defaultHeight: MOBILE_BOTTOM_MENU_HEIGHT,
    })
  return (
    <>
      <div
        ref={mobileBottomMenuRef}
        className="flex-shrink-0 position-fixed bottom-0 bg-white w-100 flex-shrink-0 d-md-none shadow border-top"
      >
        <Nav variant="pills" className="bg-primary-light">
          {NAV_LINKS.map(({ key, path, icon: Icon }) => (
            <Nav.Item key={key} className="col">
              <Link href={path} passHref>
                <Nav.Link
                  active={activeNavLink === key}
                  className="rounded-0 fs-2 text-center py-3 d-flex justify-content-center align-items-center"
                >
                  <Icon />
                </Nav.Link>
              </Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div
        className="flex-shrink-0 d-md-none d-print-none pt-1"
        style={{ height: mobileBottomMenuHeight }}
      />
    </>
  )
}
