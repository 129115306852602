import { MutableRefObject } from 'react'
import { Stack } from 'react-bootstrap'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { FaLock, FaMedal, FaThumbsUp, FaUserAlt } from 'react-icons/fa'
import { ArticleOperationLinks, ArticleTypeBadge, LaTeXExportButton } from '.'
import { YouTubePlayer } from '../../assets/YouTubePlayer'
import { CategoryBadge } from '../../assets/category'
import { Mathdown } from '../../assets/mathdown'
import { ReferenceBadge, ReferencesList } from '../../assets/reference'
import { InlineTagList } from '../../assets/tag'
import { UserInfo, UserItem } from '../../assets/user'
import { useAccount } from '../../context/account'
import { useEvaluation } from '../../context/evaluation'
import { usePrintSetting } from '../../context/print_setting'
import {
  Article,
  Badge,
  Category,
  Macro,
  Profile,
  Reference,
  Tag,
  articleRefWithoutConverter,
  profileRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { shouldShowAdsInContent } from '../../models/user'
import { ADSENSE_SLOTS } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { PVCounter } from '../PVCounter'
import { BadgeGift, BadgeList } from '../badge'
import { CommentSection } from '../comment'
import { BeginnerAlert } from '../content/BeginnerAlert'
import { CollaboratorList } from '../content/CollaboratorList'
import { BadButton, GoodButton, GoodEvaluatorList } from '../evaluation'
import { AdsCard } from '../google_adsense/AdsCard'
import { MessagesCounter } from '../message/MessagesCounter'
import { ReportButton } from '../report/Button'
import { ContentTimeStamp } from '../timestamp/Timestamp'

type Props = {
  article: Article
  profile: Profile
  category: Category
  tags: Tag[]
  references: Reference[]
  showDisplayname?: boolean
  macros: Macro[]
  contentRef: MutableRefObject<HTMLDivElement | null>
  badges: Badge[]
}

export const ArticleDetail = ({
  article,
  profile,
  category,
  tags,
  references,
  macros,
  contentRef,
  badges,
}: Props) => {
  const { goodCount, badCount, handleChangeGoodCount, handleChangeBadCount } =
    useEvaluation()

  const { isPrinting, printSetting } = usePrintSetting()
  const { user } = useAuthState()
  const { account } = useAccount()
  const [authorProfile, authorProfileLoading] = useDocumentDataOnce(
    profileRef(article.created_by)
  )
  return (
    <div className="mw-100 mb-5">
      <UserItem
        profile={profile}
        imageSize={33}
        className="mb-3"
        isFollowButton={false}
      />
      <Stack direction="horizontal" gap={1} className="mb-1">
        <CategoryBadge category={category} contentRef={article.ref} />
        <ArticleTypeBadge article={article} />
        <ReferenceBadge referable={article} />
      </Stack>
      <h1 className="h3 text-break mb-1">
        {article.visibility !== 'public' && (
          <IconWrapper className="fs-6 small mx-1">
            <FaLock />
          </IconWrapper>
        )}
        {article.title}
      </h1>
      <InlineTagList tags={tags} className="mb-3" />
      <div className="d-flex flex-wrap gap-3">
        <GoodButton
          evaluable={article}
          currentGoodCount={goodCount}
          onChangeGoodCount={handleChangeGoodCount}
          showCounter
          className="text-muted small"
          size="sm"
        />
        <BadButton
          evaluable={article}
          currentBadCount={badCount}
          onChangeBadCount={handleChangeBadCount}
          showCounter
          className="text-muted small"
          size="sm"
        />
        <PVCounter value={article.pv} className="text-muted small" />
        <MessagesCounter
          value={article.comments_count}
          className="text-muted small"
        />
        <LaTeXExportButton
          article={article}
          author={profile}
          macros={macros}
          references={references}
          className="d-print-none"
        />
        <ArticleOperationLinks article={article} className="d-print-none" />
      </div>
      {article.youtube_code !== undefined && !isPrinting && (
        <YouTubePlayer youtube_code={article.youtube_code} />
      )}
      <div
        ref={contentRef}
        className={`mt-6 mathdown-${
          isPrinting ? printSetting.theme : 'colorful'
        }`}
      >
        {article.is_beginner && (
          <BeginnerAlert className="py-2 px-3 small mt-2" />
        )}
        <Mathdown
          mathdown={article.body}
          references={references}
          macros={macros}
          className="text-break"
        />
        {article.has_references && (
          <>
            <h2>参考文献</h2>
            <ReferencesList
              references={references}
              className="mb-3 gap-1 d-flex flex-column"
              amazonBookImage
              link
            />
          </>
        )}
        <div className="mt-4">
          <ContentTimeStamp content={article} />
        </div>
        {user?.uid !== article.created_by && (
          <ReportButton
            reportableRefWithoutConverter={articleRefWithoutConverter(
              article.id
            )}
            label="この記事を運営に通報する"
            targetUid={article.created_by}
            className="d-print-none small text-muted mt-3"
          />
        )}
        {/* 広告 */}
        {shouldShowAdsInContent(profile?.plan, account?.plan) && (
          <AdsCard
            slot={ADSENSE_SLOTS.ARTICLE_BOTTOM_SLOT}
            height="90px"
            className="mt-7"
          />
        )}
      </div>
      {article.collaborator_uids.length !== 0 && (
        <div className="mt-7 d-print-none">
          <h2 className="h5">
            <IconWrapper suffix="共同編集者" className="text-muted">
              <FaUserAlt />
            </IconWrapper>
          </h2>
          <CollaboratorList
            collaboratorUids={article.collaborator_uids}
            className="mb-2"
          />
        </div>
      )}
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="この記事を高評価した人" className="text-muted">
            <FaThumbsUp />
          </IconWrapper>
        </h2>
        <GoodEvaluatorList evaluable={article} />
      </div>
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="この記事に送られたバッジ" className="text-muted">
            <FaMedal />
          </IconWrapper>
        </h2>
        <BadgeList size={'70'} badges={badges} className="mb-5" />
        {user !== undefined &&
          (user === null || article.created_by !== user.uid) && (
            <BadgeGift parentRef={article.ref} />
          )}
      </div>
      <div className="mt-7 d-print-none">
        <h2 className="h5">
          <IconWrapper suffix="投稿者" className="text-muted">
            <FaUserAlt />
          </IconWrapper>
        </h2>
        <UserInfo profile={profile} />
      </div>
      <div className="mt-7">
        <CommentSection
          commentSectionOwnerUid={article.created_by}
          setting={profile.comments_section_setting}
          commentable={article}
          className={
            isPrinting && printSetting.showComments ? '' : 'd-print-none'
          }
        />
      </div>
    </div>
  )
}
