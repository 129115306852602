import {
  insertOL,
  insertOLWithParenthesis,
  insertOLWithSquareBrackets,
  insertRomanOL,
  insertRomanOLWithParenthesis,
  insertRomanOLWithSquareBracket,
  insertUL,
} from '../../../../../commands'
import { OperationItem } from '../../OperationItem'

export const listDropdownData = [
  {
    component: OperationItem,
    children: <span>数字なし</span>,
    command: insertUL,
  },
  {
    component: OperationItem,
    children: <span>通常数字あり</span>,
    command: insertOL,
  },
  {
    component: OperationItem,
    children: <span>丸括弧・通常数字あり</span>,
    command: insertOLWithParenthesis,
  },
  {
    component: OperationItem,
    children: <span>角括弧・通常数字あり</span>,
    command: insertOLWithSquareBrackets,
  },
  {
    component: OperationItem,
    children: <span>ローマン数字あり</span>,
    command: insertRomanOL,
  },
  {
    component: OperationItem,
    children: <span>丸括弧・ローマン数字あり</span>,
    command: insertRomanOLWithParenthesis,
  },
  {
    component: OperationItem,
    children: <span>角括弧・ローマン数字あり</span>,
    command: insertRomanOLWithSquareBracket,
  },
]
