import { MessageItem } from '../../assets/message'
import { Comment, CommentsSectionSetting, Reply } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  comment: Comment
  reply: Reply
  commentSectionOwnerUid: string
  setting?: CommentsSectionSetting
}

export const ReplyItem = ({
  reply,
  comment,
  commentSectionOwnerUid,
  setting,
  ...otherProps
}: Designed<Props>) => (
  <MessageItem
    type="reply"
    parentRef={comment.ref}
    message={reply}
    setting={setting}
    commentSectionOwnerUid={commentSectionOwnerUid}
    {...otherProps}
  />
)
