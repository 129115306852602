import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroller'
import { useCollaborators } from '../../hooks/models/useCollaborators'
import { Designed } from '../../types'
import { LoadingSpinner } from '../spinners'
import { UserList } from '../user'
import { UserImageList } from '../user/ImageList'

type Props = {
  collaboratorUids: string[]
}

export const CollaboratorList = ({
  collaboratorUids,
  ...otherProps
}: Designed<Props>) => {
  const { loadMore, collaborators, isLast, fetching } =
    useCollaborators(collaboratorUids)

  const [show, setShow] = useState(false)

  useEffect(() => {
    void loadMore()
  }, [])

  if (collaboratorUids.length === 0) return <></>

  return (
    <>
      <Modal onHide={() => setShow(false)} show={show} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">共同編集者</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InfiniteScroll
            loadMore={async () => {
              if (!fetching) await loadMore()
            }}
            hasMore={!isLast}
            useWindow={false}
            loader={<LoadingSpinner />}
          >
            <UserList profiles={collaborators} imageSize="40" />
          </InfiniteScroll>
        </Modal.Body>
      </Modal>
      <div {...otherProps}>
        {collaborators.length !== 0 && (
          <>
            <UserImageList
              className="d-flex gap-3 mb-2 flex-wrap"
              size="35"
              profiles={collaborators.slice(0, 20)}
            />
            <Button
              variant="link"
              onClick={() => {
                setShow(true)
              }}
            >
              もっと見る
            </Button>
          </>
        )}
      </div>
    </>
  )
}
