import { memo } from 'react'
import { Button } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'

type Props = {
  table: string[][]
  setTable: (table: string[][]) => void
  width: number
}

const AddColButtonImpl = ({ table, setTable, width }: Props) => {
  const addCol = () => setTable(table.map((colVal) => [...colVal, '']))
  return (
    <Button style={{ width }} variant="success" onClick={addCol}>
      <FaPlus />
    </Button>
  )
}
export const AddColButton = memo(AddColButtonImpl)
