import {
  QueryDocumentSnapshot,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { useCallback, useMemo, useReducer, useRef, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Comment, Reply } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import {
  currentUserRepliesRef,
  newRepliesRef,
  sortedRepliesRefWithLimit,
} from '../../models/message'

const REPLY_LIMIT = 5

export const useReplies = (comment: Comment, showReplies: boolean) => {
  const now = useRef(new Date())
  const { user, isLoading: isUserLoading } = useAuthState()

  const lastReply = useRef<QueryDocumentSnapshot<Reply> | null>(null)
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useReducer(() => true, false)
  const [oldReplies, setOldReplies] = useState<Reply[]>([])

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    if (isUserLoading) return
    if (!showReplies) return

    setFetching(true)
    const loadedRepliesSnapshot = await getDocs(
      query(
        sortedRepliesRefWithLimit(comment.ref, lastReply.current, REPLY_LIMIT),
        where('created_at', '<=', now.current)
      )
    )
    const loadedReplyDocs = loadedRepliesSnapshot.docs
    const loadedReplies = loadedReplyDocs.map((replyDoc) => replyDoc.data())

    setOldReplies((current) => {
      return [...current, ...loadedReplies]
    })
    if (!loadedRepliesSnapshot.empty) {
      lastReply.current = loadedReplyDocs.slice(-1)[0]
    }
    if (loadedReplyDocs.length < REPLY_LIMIT) {
      setIsLast()
    }
    setFetching(false)
  }, [isUserLoading, showReplies])

  const [newReplies] = useCollectionData(
    isLast && showReplies ? newRepliesRef(comment, now.current) : null
  )
  const [currentUserReplies] = useCollectionData(
    !isUserLoading && user ? currentUserRepliesRef(comment, user.uid) : null
  )

  const replies = useMemo(() => {
    const dynamicOldReplies = oldReplies.map((reply) => {
      // 現在のユーザの場合は変更を同期しているreplyを使う
      if (user && user.uid === reply.created_by && currentUserReplies)
        return currentUserReplies.find(({ id }) => id === reply.id) ?? reply
      else return reply
    })
    return newReplies
      ? [...dynamicOldReplies, ...newReplies]
      : dynamicOldReplies
  }, [oldReplies, newReplies, currentUserReplies, lastReply.current])

  return { loadMore, replies, isLast, fetching }
}
