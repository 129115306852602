import { signOut } from 'firebase/auth'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { Button, ListGroup, Offcanvas } from 'react-bootstrap'
import { BiVolumeMute } from 'react-icons/bi'
import {
  FaCog,
  FaCoins,
  FaInfoCircle,
  FaListUl,
  FaQuestionCircle,
  FaRegEnvelope,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserAlt,
  FaUserCircle,
  FaUserCog,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa'
import { MdPrivacyTip } from 'react-icons/md'
import { VscLaw } from 'react-icons/vsc'
import { IconWrapper } from 'web_core/assets/IconWrapper'
import { LinkWrapper } from 'web_core/assets/LinkWrapper'
import { usePopupMessage } from 'web_core/assets/messenger'
import { UserImage } from 'web_core/assets/user'
import { useAuthState } from 'web_core/firebase/hooks'
import { fb } from 'web_core/firebase/instance'
import { useMedia } from 'web_core/hooks'
import {
  HELP_NOTION_LINKS,
  TEAM_ACCOUNT_APPLICATION_LINK,
  logEvent,
} from 'web_core/utils'
import { PlanInfo } from './PlanInfo'

export const MobileHeaderUserMenu = () => {
  const { t } = useTranslation()
  const { user, profile } = useAuthState()
  const { setPopupMessage } = usePopupMessage()
  const [show, setShow] = useState(false)
  const isMd = useMedia('md')

  useEffect(() => {
    if (isMd && show) setShow(false)
  }, [isMd])

  return (
    <div className="d-md-none">
      <Button
        className="d-flex justify-content-center align-items-center"
        variant="link"
        onClick={() => {
          setShow(!show)
        }}
      >
        {profile ? (
          <UserImage size={35} profile={profile} className="d-inline-block" />
        ) : (
          <FaUserCircle
            className="text-white"
            style={{ fontSize: 35 }} // UserImageのsizeと一致させた。height, width に1emが設定されるため。
          />
        )}
      </Button>
      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        placement="bottom"
        style={{ height: '60vh' }}
      >
        <Offcanvas.Body className="p-0 my-2">
          {user && (
            <>
              <PlanInfo className="px-4 mt-3" />
              <hr className="my-2" />
            </>
          )}
          <ListGroup variant="flush">
            {user ? (
              <>
                <LinkWrapper href={`/users/${user.uid}`} passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.profile'
                      )}
                    >
                      <FaUserAlt />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <LinkWrapper href="/article_drafts" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.drafts'
                      )}
                    >
                      <FaListUl />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <LinkWrapper href="/default_macros" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.macros'
                      )}
                    >
                      <FaCog />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <LinkWrapper href="/sales" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.sales'
                      )}
                    >
                      <FaCoins />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <hr className="my-2" />
                <LinkWrapper href="/mute_and_block" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.mute_and_block'
                      )}
                    >
                      <BiVolumeMute />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <LinkWrapper href="/auth/setting" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.setting'
                      )}
                    >
                      <FaUserCog />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <LinkWrapper href={TEAM_ACCOUNT_APPLICATION_LINK} passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.apply_team'
                      )}
                    >
                      <FaUsers />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <ListGroup.Item
                  className="border-0"
                  action
                  onClick={async () => {
                    await signOut(fb.auth)
                    setPopupMessage(
                      t('packages.firebase.popup_messages.sign_out'),
                      'danger'
                    )
                  }}
                >
                  <IconWrapper
                    suffix={t(
                      'mathlog.components.layout.header.dropdown.sign_out'
                    )}
                  >
                    <FaSignOutAlt />
                  </IconWrapper>
                </ListGroup.Item>
                <hr className="my-2" />
              </>
            ) : (
              <>
                <LinkWrapper href="/auth/sign_up" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.sign_up'
                      )}
                    >
                      <FaUserPlus />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <LinkWrapper href="/auth/sign_in" passHref>
                  <ListGroup.Item className="border-0" action>
                    <IconWrapper
                      suffix={t(
                        'mathlog.components.layout.header.dropdown.sign_in'
                      )}
                    >
                      <FaSignInAlt />
                    </IconWrapper>
                  </ListGroup.Item>
                </LinkWrapper>
                <hr className="my-2" />
              </>
            )}
            <a
              href={HELP_NOTION_LINKS['ROOT']}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                logEvent('tap_help', {
                  uid: user?.uid,
                })
              }
            >
              <ListGroup.Item className="border-0" action>
                <IconWrapper
                  suffix={t('mathlog.components.layout.header.dropdown.help')}
                >
                  <FaQuestionCircle />
                </IconWrapper>
              </ListGroup.Item>
            </a>
            <LinkWrapper href="/community_guideline" passHref>
              <ListGroup.Item className="border-0" action>
                <IconWrapper
                  suffix={t(
                    'mathlog.components.layout.header.dropdown.community_guideline'
                  )}
                >
                  <FaUsers />
                </IconWrapper>
              </ListGroup.Item>
            </LinkWrapper>
            <LinkWrapper href="/terms" passHref>
              <ListGroup.Item className="border-0" action>
                <IconWrapper
                  suffix={t('mathlog.components.layout.header.dropdown.terms')}
                >
                  <FaInfoCircle />
                </IconWrapper>
              </ListGroup.Item>
            </LinkWrapper>
            <LinkWrapper href="/privacy" passHref>
              <ListGroup.Item className="border-0" action>
                <IconWrapper
                  suffix={t(
                    'mathlog.components.layout.header.dropdown.privacy'
                  )}
                >
                  <MdPrivacyTip />
                </IconWrapper>
              </ListGroup.Item>
            </LinkWrapper>
            <LinkWrapper href="/tradelaw" passHref>
              <ListGroup.Item className="border-0" action>
                <IconWrapper
                  suffix={t(
                    'mathlog.components.layout.header.dropdown.tradelaw'
                  )}
                >
                  <VscLaw />
                </IconWrapper>
              </ListGroup.Item>
            </LinkWrapper>
            <LinkWrapper href="/inquiry" passHref>
              <ListGroup.Item className="border-0" action>
                <IconWrapper
                  suffix={t(
                    'mathlog.components.layout.header.dropdown.contact'
                  )}
                >
                  <FaRegEnvelope />
                </IconWrapper>
              </ListGroup.Item>
            </LinkWrapper>
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}
