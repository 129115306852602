import Link from 'next/link'
import { Page } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  base: string
  page: Page
}

export const PageTitleLink = ({
  base,
  page,
  ...containerProps
}: Designed<Props>) => {
  return (
    <Link href={`${base}/${page.id}`} passHref>
      <a {...containerProps}>{page.title}</a>
    </Link>
  )
}
