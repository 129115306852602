import { Button, ButtonGroup } from 'react-bootstrap'
import { FaBook, FaCog, FaPen, FaRegEye } from 'react-icons/fa'
import { EditorTypeName } from '.'
import { PromiseVoid } from '../../../types'
import { WindowMode } from '../../mathdown/editor/types'

type Props = {
  windowMode: WindowMode
  onChangeWindowMode: (mode: WindowMode) => PromiseVoid
  editorType: EditorTypeName
  onChangeEditorType: (type: EditorTypeName) => PromiseVoid
}

export const EditorTypeChangeButtonGroup = ({
  windowMode,
  onChangeWindowMode: handleChangeWindowMode,
  editorType,
  onChangeEditorType: handleChangeEditorType,
}: Props) => {
  return (
    <ButtonGroup>
      <Button
        variant="outline-light"
        className="fs-5 py-1 d-flex justify-content-center align-items-center"
        size="sm"
        onClick={() => {
          void handleChangeWindowMode('input')
          void handleChangeEditorType('mathdown')
        }}
        active={editorType === 'mathdown' && windowMode === 'input'}
      >
        <FaPen />
      </Button>
      <Button
        variant="outline-light"
        className="fs-5 py-1 d-flex justify-content-center align-items-center"
        size="sm"
        onClick={() => {
          void handleChangeWindowMode('output')
          void handleChangeEditorType('mathdown')
        }}
        active={editorType === 'mathdown' && windowMode === 'output'}
      >
        <FaRegEye />
      </Button>
      <Button
        variant="outline-light"
        className="fs-5 py-1 d-flex justify-content-center align-items-center"
        size="sm"
        onClick={() => {
          void handleChangeEditorType('references')
        }}
        active={editorType === 'references'}
      >
        <FaBook />
      </Button>
      <Button
        variant="outline-light"
        className="fs-5 py-1 d-flex justify-content-center align-items-center"
        size="sm"
        onClick={() => {
          void handleChangeEditorType('macros')
        }}
        active={editorType === 'macros'}
      >
        <FaCog />
      </Button>
    </ButtonGroup>
  )
}
