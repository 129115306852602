import { Node } from 'prosemirror-model'
import { EditorState } from 'prosemirror-state'
import { schema } from '../schema'

export const mathdownToNode = (text: string): Node => {
  const lines = text.replace(/\r\n|\r/g, '\n').split('\n')
  const nodes = []

  for (const line of lines) {
    const paragraphNode =
      line !== ''
        ? schema.nodes.paragraph.create(undefined, schema.text(line))
        : schema.nodes.paragraph.create(undefined)
    nodes.push(paragraphNode)
  }
  const docNode = schema.nodes.doc.create(undefined, nodes)
  return docNode
}

export const nodeToMathdown = (node: Node) => {
  if (node.isText) {
    return node.text ?? ''
  }
  if (node.type.name === 'paragraph') {
    let result = ''
    node.content.forEach((child) => {
      result += nodeToMathdown(child)
    })
    return result + '\n'
  }
  if (node.type.name === 'hard_break') {
    return '\n'
  }
  let result = ''
  node.content.forEach((child) => {
    result += nodeToMathdown(child)
  })
  return result.slice(0, -1)
}

export const nodesToMathdown = (nodes: Node[]) => {
  return nodes.map(nodeToMathdown).join('')
}

export const getBeginOfParagraph = (doc: Node, pos: number) => {
  const resolvedPos = doc.resolve(pos)
  let depth = resolvedPos.depth

  while (depth > 0 && resolvedPos.node(depth).type.name !== 'paragraph') {
    depth--
  }
  if (depth === 0) return null
  return resolvedPos.start(depth)
}

export const getEndOfParagraph = (doc: Node, pos: number) => {
  const resolvedPos = doc.resolve(pos)
  let depth = resolvedPos.depth
  while (depth > 0 && resolvedPos.node(depth).type.name !== 'paragraph') {
    depth--
  }
  if (depth === 0) return null
  return resolvedPos.end(depth)
}

export const getBlockSelectedText = (state: EditorState) => {
  const { from, to } = state.selection

  const endOfParagraph = getEndOfParagraph(state.doc, to)
  const beginOfParagraph = getBeginOfParagraph(state.doc, from)

  if (endOfParagraph === null || beginOfParagraph === null)
    throw new Error('Unexpected.')

  const nodes: Node[] = []
  state.doc.nodesBetween(beginOfParagraph, endOfParagraph, (node) => {
    if (node.type.name === 'paragraph') nodes.push(node)
  })
  return nodesToMathdown(nodes)
}

/**
 * Editor上のドキュメントのサイズは単純なlengthではないので、改行などは数え上げる必要がある。今後、太字などのスタイルを足すときは、ノードに依存した数え方にする必要がある。
 * @param text
 */
export const getSizeByText = (text: string) => {
  return text.length + text.split('').filter((c) => c === '\n').length
}
