import { insertH1, insertH2, insertH3, insertH4 } from '../../../../../commands'
import { OperationItem } from '../../OperationItem'

export const headingDropdownData = [
  {
    component: OperationItem,
    children: <h1>特大見出し</h1>,
    command: insertH1,
  },
  {
    component: OperationItem,
    children: <h2>大見出し</h2>,
    command: insertH2,
  },
  {
    component: OperationItem,
    children: <h3>中見出し</h3>,
    command: insertH3,
  },
  {
    component: OperationItem,
    children: <h4>子見出し</h4>,
    command: insertH4,
  },
]
