import { FormulaButtonData } from '../type'

const greekLetters: FormulaButtonData[] = [
  {
    formula: '\\alpha',
    displayFormula: '\\alpha',
    tooltip: '',
  },
  {
    formula: '\\beta',
    displayFormula: '\\beta',
    tooltip: '',
  },
  {
    formula: '\\gamma',
    displayFormula: '\\gamma',
    tooltip: '',
  },
  {
    formula: '\\delta',
    displayFormula: '\\delta',
    tooltip: '',
  },
  {
    formula: '\\epsilon',
    displayFormula: '\\epsilon',
    tooltip: '',
  },
  {
    formula: '\\varepsilon',
    displayFormula: '\\varepsilon',
    tooltip: '',
  },
  {
    formula: '\\zeta',
    displayFormula: '\\zeta',
    tooltip: '',
  },
  {
    formula: '\\eta',
    displayFormula: '\\eta',
    tooltip: '',
  },
  {
    formula: '\\theta',
    displayFormula: '\\theta',
    tooltip: '',
  },
  {
    formula: '\\vartheta',
    displayFormula: '\\vartheta',
    tooltip: '',
  },
  {
    formula: '\\iota',
    displayFormula: '\\iota',
    tooltip: '',
  },
  {
    formula: '\\kappa',
    displayFormula: '\\kappa',
    tooltip: '',
  },
  {
    formula: '\\lambda',
    displayFormula: '\\lambda',
    tooltip: '',
  },
  {
    formula: '\\mu',
    displayFormula: '\\mu',
    tooltip: '',
  },
  {
    formula: '\\nu',
    displayFormula: '\\nu',
    tooltip: '',
  },
  {
    formula: '\\xi',
    displayFormula: '\\xi',
    tooltip: '',
  },
  {
    formula: '\\pi',
    displayFormula: '\\pi',
    tooltip: '',
  },
  {
    formula: '\\varpi',
    displayFormula: '\\varpi',
    tooltip: '',
  },
  {
    formula: '\\rho',
    displayFormula: '\\rho',
    tooltip: '',
  },
  {
    formula: '\\varrho',
    displayFormula: '\\varrho',
    tooltip: '',
  },
  {
    formula: '\\sigma',
    displayFormula: '\\sigma',
    tooltip: '',
  },
  {
    formula: '\\varsigma',
    displayFormula: '\\varsigma',
    tooltip: '',
  },
  {
    formula: '\\tau',
    displayFormula: '\\tau',
    tooltip: '',
  },
  {
    formula: '\\upsilon',
    displayFormula: '\\upsilon',
    tooltip: '',
  },
  {
    formula: '\\phi',
    displayFormula: '\\phi',
    tooltip: '',
  },
  {
    formula: '\\varphi',
    displayFormula: '\\varphi',
    tooltip: '',
  },
  {
    formula: '\\chi',
    displayFormula: '\\chi',
    tooltip: '',
  },
  {
    formula: '\\psi',
    displayFormula: '\\psi',
    tooltip: '',
  },
  {
    formula: '\\omega',
    displayFormula: '\\omega',
    tooltip: '',
  },
  {
    formula: '\\Gamma',
    displayFormula: '\\Gamma',
    tooltip: '',
  },
  {
    formula: '\\varGamma',
    displayFormula: '\\varGamma',
    tooltip: '',
  },
  {
    formula: '\\Delta',
    displayFormula: '\\Delta',
    tooltip: '',
  },
  {
    formula: '\\varDelta',
    displayFormula: '\\varDelta',
    tooltip: '',
  },
  {
    formula: '\\Theta',
    displayFormula: '\\Theta',
    tooltip: '',
  },
  {
    formula: '\\varTheta',
    displayFormula: '\\varTheta',
    tooltip: '',
  },
  {
    formula: '\\Lambda',
    displayFormula: '\\Lambda',
    tooltip: '',
  },
  {
    formula: '\\varLambda',
    displayFormula: '\\varLambda',
    tooltip: '',
  },
  {
    formula: '\\Xi',
    displayFormula: '\\Xi',
    tooltip: '',
  },
  {
    formula: '\\varXi',
    displayFormula: '\\varXi',
    tooltip: '',
  },
  {
    formula: '\\Pi',
    displayFormula: '\\Pi',
    tooltip: '',
  },
  {
    formula: '\\varPi',
    displayFormula: '\\varPi',
    tooltip: '',
  },
  {
    formula: '\\Sigma',
    displayFormula: '\\Sigma',
    tooltip: '',
  },
  {
    formula: '\\varSigma',
    displayFormula: '\\varSigma',
    tooltip: '',
  },
  {
    formula: '\\Upsilon',
    displayFormula: '\\Upsilon',
    tooltip: '',
  },
  {
    formula: '\\varUpsilon',
    displayFormula: '\\varUpsilon',
    tooltip: '',
  },
  {
    formula: '\\Phi',
    displayFormula: '\\Phi',
    tooltip: '',
  },
  {
    formula: '\\varPhi',
    displayFormula: '\\varPhi',
    tooltip: '',
  },
  {
    formula: '\\Psi',
    displayFormula: '\\Psi',
    tooltip: '',
  },
  {
    formula: '\\varPsi',
    displayFormula: '\\varPsi',
    tooltip: '',
  },
  {
    formula: '\\Omega',
    displayFormula: '\\Omega',
    tooltip: '',
  },
  {
    formula: '\\varOmega',
    displayFormula: '\\varOmega',
    tooltip: '',
  },
]

export const greekLettersPill = {
  pill: 'greek_letters',
  label: 'ギリシャ文字',
  buttonData: greekLetters,
}
