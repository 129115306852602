import { useCallback, useEffect, useRef } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { usePopupMessage } from '../../assets/messenger'
import { Notification } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { getMessage, unreadNotificationsRef } from '../../models/notification'
import { App } from '../../types'
import { sleep } from '../../utils'

export const useUnreadNotifications = (app: App, show: boolean) => {
  const { user } = useAuthState()
  const mostRecentNotification = useRef<Notification | null>()
  const [unreadNotifications, loading, error] = useCollectionData(
    user ? unreadNotificationsRef(app, user.uid, 10) : null // 10件以上は読み取らない
  )

  const { setPopupMessage } = usePopupMessage()

  const showNotifications = useCallback(
    async (notifications: Notification[]) => {
      if (!show) return
      for (const notification of notifications) {
        setPopupMessage(getMessage(notification))
        await sleep(2000) // 同時に通知をするときはインターバルを設ける
      }
    },
    [show]
  )

  useEffect(() => {
    if (!unreadNotifications) return
    if (mostRecentNotification.current === null) {
      void showNotifications(unreadNotifications)
    } else if (mostRecentNotification.current !== undefined) {
      const index = unreadNotifications.findIndex((n) => n.id)
      void showNotifications(unreadNotifications.slice(0, index))
    }
    mostRecentNotification.current =
      unreadNotifications.length > 0 ? unreadNotifications[0] : null
  }, [unreadNotifications])

  const countLabel = unreadNotifications
    ? unreadNotifications.length > 9
      ? '9+'
      : `${unreadNotifications.length}`
    : undefined

  return { countLabel, unreadNotifications, loading, error }
}
