import { FormulaButtonData } from '../type'

const arithmeticSymbols: FormulaButtonData[] = [
  { formula: '+', tooltip: '足す' },
  { formula: '-', tooltip: '引く' },
  { formula: '\\pm', tooltip: 'プラスマイナス' },
  { formula: '\\mp', tooltip: 'マイナスプラス' },
  { formula: '\\times', tooltip: '掛ける' },
  { formula: '\\cdot', tooltip: '掛ける（簡略化）' },
  { formula: '\\div', tooltip: '割る' },
  {
    formula: '\\frac{b}{a}',
    tooltip: '分数1',
    fontSize: 18,
    mobileFontSize: 12,
  },
  { formula: 'a/b', tooltip: '分数2' },
  { formula: 'a^{n}', tooltip: '累乗' },
  {
    formula: '\\left| x \\right|',
    tooltip: '絶対値',
  },
  {
    formula: '\\left\\| x \\right\\|',
    tooltip: 'ノルム',
    mobileFontSize: 14,
  },
  {
    displayFormula: '\\sum',
    formula: '\\sum_{i=0}^{n}',
    tooltip: '総和',
    mobileFontSize: 12,
  },
  {
    displayFormula: '\\prod',
    formula: '\\prod_{i=0}^{n}',
    tooltip: '総乗',
    mobileFontSize: 12,
  },
  { formula: '\\sqrt{a}', tooltip: '平方根' },
  { formula: '\\sqrt[n]{a}', tooltip: 'n乗根' },
  { formula: '\\equiv', tooltip: '同値' },
  {
    displayFormula: '\\mod{n}',
    formula: '\\mod{n}',
    tooltip: '剰余演算',
    fontSize: 12,
    mobileFontSize: 9,
  },
  {
    displayFormula: '\\pmod{n}',
    formula: '\\pmod{n}',
    tooltip: '剰余演算（括弧付）',
    fontSize: 10,
    mobileFontSize: 7,
  },
  { formula: 'n !', tooltip: '階乗' },
  { formula: '{}_n \\mathrm{P}_k', tooltip: '順列' },
  {
    formula: '{}_n \\mathrm{ C }_k',
    tooltip: '組合せ',
  },
  {
    formula: '{ n \\choose k }',
    tooltip: '二項係数',
    mobileFontSize: 12,
  },
  {
    formula: '{}_n \\mathrm{ H }_k',
    tooltip: '重複組合せ',
  },
]

export const arithmeticSymbolsPill = {
  pill: 'arithmetic_symbols',
  label: '算術記号',
  buttonData: arithmeticSymbols,
}
