import {
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore'
import { Notification, notificationsRef, Timestamp } from '../firebase'
import { App } from '../types'

export const unreadNotificationsRef = (app: App, uid: string, size: number) =>
  query(
    notificationsRef(uid),
    where('app', '==', app),
    where('status', '==', 'unread'),
    limit(size)
  )

export const sortedFutureNotificationsRef = (
  app: App,
  uid: string,
  now: Date
) =>
  query(
    notificationsRef(uid),
    where('app', '==', app),
    where('created_at', '>=', Timestamp.fromDate(now)),
    orderBy('created_at', 'desc')
  )

export const sortedPastNotificationsRef = (
  app: App,
  uid: string,
  now: Date,
  after: QueryDocumentSnapshot<Notification> | null,
  per: number
) =>
  after
    ? query(
        notificationsRef(uid),
        where('app', '==', app),
        where('created_at', '<', Timestamp.fromDate(now)),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(
        notificationsRef(uid),
        where('app', '==', app),
        where('created_at', '<', Timestamp.fromDate(now)),
        orderBy('created_at', 'desc'),
        limit(per)
      )

export const getMessage = (notification: Notification): string => {
  switch (notification.message) {
    case 'create_comment':
      switch (notification.target_ref.parent.parent?.parent.id) {
        case 'articles':
          return 'あなたの記事にコメントがあります。'
        case 'pages':
          return 'あなたの本のページにコメントがあります。'
        default:
          throw new Error('Unexpected target_ref.')
      }
    case 'create_reply':
      return 'あなたが参加中のスレッドに返信がありました。'
    // case 'create_good_evaluation':
    //   switch (notification.target_ref.parent.parent?.parent.id) {
    //     case 'articles':
    //       return 'あなたの記事が高評価されました。'
    //     case 'books':
    //       return 'あなたの本が高評価されました。'
    //     default:
    //       throw new Error('Unexpected target_ref.')
    //   }
    case 'create_badge':
      switch (notification.target_ref.parent.parent?.parent.id) {
        case 'articles':
          return 'あなたの記事にバッチが届きました。'
        case 'books':
          return 'あなたの本にバッチが届きました。'
        default:
          throw new Error('Unexpected target_ref.')
      }
    case 'official_notice':
      return notification.localized_message.ja
  }
}
