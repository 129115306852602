import { createHash } from 'crypto'
import { TexImage } from '../../types'
import { resolveMathdownImagePath } from '../../utils'

export const image = (
  href: string,
  title: string,
  alt: string,
  images: Map<string, TexImage> = new Map()
) => {
  const src = href.startsWith('/') ? resolveMathdownImagePath(href) : href
  const hash = createHash('sha256').update(src).digest('hex').slice(0, 15)
  const texImage: TexImage = {
    fileName: `${hash}.png`,
    url: src,
  }
  images.set(`${hash}`, texImage)
  return `\n\\begin{figure}[H]
    \\centering
    \\includegraphics[width=10cm]{${hash}.png}
    \\caption{${alt}}
    \\label{${title.replace(/%/g, '\\%')}}
\\end{figure}`
}
