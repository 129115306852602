import Link from 'next/link'
import { FaLock } from 'react-icons/fa'
import { ESBook } from '../../elastic_search'
import { Book } from '../../firebase'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'

type Props = {
  book: Book | ESBook
}

export const BookTitleLink = ({ book, ...containerProps }: Designed<Props>) => (
  <Link href={`/books/${book.id}`} passHref>
    <a {...containerProps}>
      {book.visibility !== 'public' && (
        <IconWrapper className="fs-7 mx-1">
          <FaLock />
        </IconWrapper>
      )}
      {book.title}
    </a>
  </Link>
)
