import { FirebaseError } from 'firebase/app'
import { useEffect, useMemo } from 'react'
import { useController, useForm } from 'react-hook-form'
import { Page, PagesParent, Timestamp, updateDoc } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useMacros, useProfile, useReferences } from '../../hooks'
import { addCollaborator } from '../../models/draft'
import { IS_PROD } from '../../utils'

type Fields = {
  title: string
  body: string
}

export const usePageForm = (parent: PagesParent, page: Page) => {
  const { user } = useAuthState()
  const { profile: authorProfile } = useProfile(parent.created_by, null)
  const { macros } = useMacros<PagesParent>(parent.ref, true)
  const { references } = useReferences<PagesParent>(parent.ref, true)

  useEffect(() => {
    // book_draftの場合のみ実行する
    if (parent.parent_id !== 'book_drafts') return
    if (!user) return
    if (user.uid === parent.created_by) return
    if (parent.collaborator_uids.includes(user.uid)) return
    void addCollaborator(parent, user.uid)
  }, [user])

  const {
    formState: { errors, isValid },
    control,
    setValue,
    getValues,
  } = useForm<Fields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: page.title,
      body: page.body,
    },
  })

  const {
    field: { onChange: handleTitleChange, value: title },
  } = useController({
    name: 'title',
    control,
    rules: { required: true },
  })

  const saveTitleChange = async () => {
    const { title } = getValues()
    await updateDoc(page.ref, {
      title,
      updated_by_user_at: Timestamp.now(),
    })
  }

  const {
    field: { onChange: handleBodyChange, value: body },
  } = useController({
    name: 'body',
    control,
    rules: { required: true },
  })

  const saveBodyChange = async () => {
    const { body } = getValues()
    try {
      await updateDoc(page.ref, {
        body,
        updated_by_user_at: Timestamp.now(),
      })
    } catch (error) {
      // 編集からページから削除ボタンを押して離れた場合に
      // 既に下書き削除されておりエラーになるので、その場合は無視する
      if (error instanceof FirebaseError && error.code === 'not-found')
        console.warn(error.code)
      else throw error
    }
  }

  const handleSave = async () => {
    const { title, body } = getValues()
    await updateDoc(page.ref, {
      title,
      body,
      updated_by_user_at: Timestamp.now(),
    })
  }

  const room = useMemo(() => {
    if (!user || !authorProfile) return undefined
    // 本の下書き以外ではルームを設定しない
    if (parent.parent_id !== 'book_drafts') return undefined
    // 著書が無料プランのユーザーの場合は、共同編集のルームを設定しない
    // TODO: 本来は plan ではなく features をみるべきだが、features は現状アクセス困難なため妥協
    if (authorProfile.plan === 'Free') return undefined
    // 共同編集の設定がされていない場合は、共同編集のルームを設定しない
    if (
      !parent.editability_details.authorized_by_url &&
      parent.editability_details.authorized_uids.length === 0
    )
      return undefined

    // 現在のユーザにアクセス権がない場合は、共同編集のルームを設定しない
    if (
      !parent.editability_details.authorized_by_url &&
      !parent.editability_details.authorized_uids.includes(user.uid) &&
      user.uid !== parent.created_by
    )
      return undefined
    return `${IS_PROD ? 'prod' : 'dev'}/${parent.parent_id}/${
      parent.id
    }/pages/${page.id}`
  }, [parent, page, user, authorProfile])

  useEffect(() => {
    setValue('title', page.title)
  }, [page.title])

  useEffect(() => {
    setValue('body', page.body)
  }, [page.body])

  return {
    room,
    title,
    handleTitleChange,
    saveTitleChange,
    body,
    saveBodyChange,
    handleBodyChange,
    macros,
    references,
    errors,
    isValid,
    handleSave,
  }
}
