import { EditorView } from 'prosemirror-view'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getBlockSelectedReplacer } from '../../commands'
import { getBlockSelectedText } from '../../utils'
import { AddColButton } from './AddColButton'
import { AddRowButton } from './AddRowButton'
import { ColManager } from './ColManager'
import { Field } from './Field'
import { RowManager } from './RowManager'

const getSelectedTable = (editor: EditorView) => {
  const select = getBlockSelectedText(editor.state)
  return mathdown2table(select)
}

const createMathdownTableRow = (row: string[]) => {
  let tableRow = '| '
  row.map((r) => (tableRow += `${r} | `))
  return tableRow
}

export const table2mathdown = (table: string[][]) => {
  let mathdownTable = ''
  if (table[0] !== undefined) {
    const tableRowLength = table[0].length
    const shiftResult = table.shift()
    if (!shiftResult)
      throw new Error('Unexpected error has occurred in table2mathdown')
    mathdownTable = `\n${createMathdownTableRow(shiftResult)}\n`
    mathdownTable += '|'
    for (let i = 0; i < tableRowLength; i++) mathdownTable += ' --- |'
    mathdownTable += '\n'
    for (let i = 0; i < table.length; i++)
      mathdownTable += createMathdownTableRow(table[i]) + '\n'
  }
  return mathdownTable
}

export const mathdown2table = (mathdownCode: string) => {
  const table: string[][] = []
  const splittedVal = mathdownCode.split(/\n/)
  let rowIndex = 0
  for (let line = 0; line < splittedVal.length; line++) {
    if (splittedVal[line] === '') continue
    if (rowIndex !== 1) {
      const row: string[] = []
      const splittedRow = splittedVal[line].trim().split('|')
      for (let colIndex = 0; colIndex < splittedRow.length; colIndex++) {
        if (splittedRow[colIndex] === '') continue
        row.push(splittedRow[colIndex])
      }
      if (splittedRow.length === 0) row.push('')
      table.push(row)
    }
    rowIndex++
  }
  if (table.length === 0)
    return [
      ['', ''],
      ['', ''],
    ]
  if (table.length === 1) {
    const additionalRow = []
    for (let colIndex = 0; colIndex < table[0].length; colIndex++)
      additionalRow.push('')
    table.push(additionalRow)
  }
  return table
}

type Props = {
  editor: EditorView
  buttonLabel: React.ReactNode
  buttonVariant?: string
  buttonSize?: 'sm' | 'lg'
  buttonClassName?: string
}

export const TableEditor = ({
  editor,
  buttonLabel,
  buttonVariant,
  buttonSize,
  buttonClassName,
}: Props) => {
  const [show, setShow] = useState(false)
  // editorで選択されているMathdownのTableがあったらそれを初期値に設定するように変更
  const [table, setTable] = useState<string[][]>([
    ['', ''],
    ['', ''],
  ])
  const width = 150
  return (
    <>
      <Button
        onClick={() => {
          setTable(getSelectedTable(editor))
          setShow(true)
        }}
        variant={buttonVariant}
        size={buttonSize}
        className={buttonClassName}
      >
        {buttonLabel}
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="w-100 h-100 p-4"
        dialogClassName="w-100 h-100 m-0 mw-100"
        contentClassName="h-100"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">表エディタ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hidden">
          <div className="d-flex flex-column gap-2 overflow-auto">
            <div className="d-flex gap-2 w-100">
              <div
                className="visibility-hidden flex-shrink-0"
                style={{ width }}
              />
              {table[0].map((val, col) => (
                <ColManager
                  key={`table-editor-col-manager-${col}`}
                  disableDelete={table[0].length === 1}
                  {...{ col, table, setTable, width }}
                />
              ))}
              <AddColButton {...{ table, setTable, width }} />
            </div>
            {table.map((rowVal, row) => (
              <div
                key={`table-editor-${row}`}
                className={`d-flex gap-2 ${
                  row === 0 ? 'border-bottom pb-2' : ''
                }`}
              >
                <RowManager
                  disableDelete={table.length <= 2}
                  {...{ row, table, setTable, width }}
                />
                {rowVal.map((val, col) => (
                  <Field
                    key={`table-editor-${row}-${col}`}
                    {...{ row, col, table, setTable, width }}
                  />
                ))}
              </div>
            ))}
            <AddRowButton {...{ table, setTable, width }} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              const text = table2mathdown(table)
              const replace = getBlockSelectedReplacer(text)

              //commandの内部でthisを書き換えられることはないのでESLintのエラーを無視する
              // eslint-disable-next-line @typescript-eslint/unbound-method
              replace(editor.state, editor.dispatch, editor)

              setShow(false)
            }}
            variant="primary"
          >
            挿入
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
