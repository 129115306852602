import { Badge, Form } from 'react-bootstrap'
import { MessageType } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  type: MessageType
  onChange: (...event: any[]) => void
}

export const TypeBadgeSelector = ({
  type,
  onChange: handleChange,
  ...wrapperProps
}: Designed<Props>) => (
  <div {...wrapperProps}>
    <Form.Check
      inline
      value="impression"
      id="impression"
      type="radio"
      label={<Badge bg="primary">感想</Badge>}
      checked={type === 'impression'}
      onChange={handleChange}
    />
    <Form.Check
      inline
      value="question"
      id="question"
      type="radio"
      label={
        <Badge bg="warning" className="text-black">
          質問
        </Badge>
      }
      checked={type === 'question'}
      onChange={handleChange}
    />
    <Form.Check
      inline
      value="indication"
      type="radio"
      id="indication"
      label={<Badge bg="danger">指摘</Badge>}
      checked={type === 'indication'}
      onChange={handleChange}
    />
  </div>
)
