import { Profile } from '../firebase'
import { getSigmoidPainless, sigmoid } from './sigmoid'

export const AuthorPopularityIndicator = {
  weights: {
    muted_users_count: -5.0,
    followers_count: 1.0,
    // 追加する場合はcalcAuthorEvaluation関数も更新すること
  },
  sigmoid: {
    a: 1 / 5,
  },
}

export const getAuthorPopularityPainless = () => {
  const weightKeys = Object.keys(
    AuthorPopularityIndicator['weights']
  ) as (keyof typeof AuthorPopularityIndicator['weights'])[]
  const x = weightKeys
    .map(
      (key) =>
        `${AuthorPopularityIndicator['weights'][key]} * doc['user.${key}'].value`
    )
    .join(' + ')
  return getSigmoidPainless(x, AuthorPopularityIndicator['sigmoid'])
}

export const calcAuthorPopularity = (author: Profile) => {
  const weights = AuthorPopularityIndicator.weights
  const { followers_count, muted_users_count } = author
  return sigmoid(
    followers_count * weights['followers_count'] +
      muted_users_count * weights['muted_users_count'],
    AuthorPopularityIndicator.sigmoid.a
  )
}
