import { Stack } from 'react-bootstrap'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { BookImage, BookTitleLink } from '.'
import { LinkWrapper } from '../../assets/LinkWrapper'
import { CategoryBadge } from '../../assets/category'
import { ESBook } from '../../elastic_search'
import { bookRef, profileRef } from '../../firebase'
import { useCurrentUserRoles } from '../../hooks'
import {
  calcAuthorEvaluation,
  calcAuthorPopularity,
  calcCommunicationScore,
  calcContentEvaluation,
} from '../../shared/indicators'
import { PVCounter } from '../PVCounter'
import { BeginnerIcon } from '../content/BeginnerIcon'
import { GoodCounter } from '../evaluation'
import { BadCounter } from '../evaluation/BadCounter'
import { MessagesCounter } from '../message'
import { UserNameLink } from '../user'
import { UserImageWithLink } from '../user/ImageWithLink'

type Props = {
  book: ESBook
  width?: string
  hideBadges?: boolean
  hideProfile?: boolean
  hideTitle?: boolean
}

export const BookItem = ({
  book,
  width = '150px',
  hideBadges = false,
  hideTitle = false,
  hideProfile = false,
}: Props) => {
  // 管理者の場合だけarticleを読み込んでスコアを表示
  const { hasRole } = useCurrentUserRoles()
  const [articleForAdmin] = useDocumentDataOnce(
    hasRole('mathlog_admin') ? bookRef(book.id) : null
  )
  const [profileForAdmin] = useDocumentDataOnce(
    hasRole('mathlog_admin') ? profileRef(book.user.id) : null
  )

  return (
    <div style={{ maxWidth: width }}>
      {!hideBadges && (
        <Stack direction="horizontal" gap={1} className={`mb-2 `}>
          <CategoryBadge
            category={book.category}
            contentRef={bookRef(book.id)}
          />
        </Stack>
      )}
      <LinkWrapper href={`/books/${book.id}`} passHref>
        <BookImage book={book} width={width} className="mb-1" />
      </LinkWrapper>
      {!hideTitle && (
        <BookTitleLink
          book={book}
          className={`text-break text-truncate-3 text-black small fw-bold`}
        />
      )}
      <div>
        <div className="d-flex gap-2 text-muted">
          <GoodCounter value={book.good_count} className="fs-7" />
          <BadCounter value={book.bad_count} className="fs-7" />
          <PVCounter value={book.pv} className="fs-7" />
          <MessagesCounter value={book.comments_count} className="fs-7" />
        </div>
        {book.is_beginner && (
          <BeginnerIcon text={'初心者'} className="fs-7 text-muted" />
        )}
      </div>
      {!hideProfile && (
        <div className="d-flex align-items-center gap-2 mt-1">
          <UserImageWithLink
            profile={book.user}
            size={30}
            className="flex-shrink-0"
          />
          <div className="flex-grow-1 overflow-hidden">
            <UserNameLink
              profile={book.user}
              className="d-block text-truncate text-muted small"
            />
          </div>
        </div>
      )}
      {hasRole('mathlog_admin') && articleForAdmin && profileForAdmin && (
        <div className="mt-1 text-muted smalls">
          CE: {calcContentEvaluation(articleForAdmin).toFixed(5)}
          <br />
          UE: {calcAuthorEvaluation(profileForAdmin).toFixed(5)}
          <br />
          UP: {calcAuthorPopularity(profileForAdmin).toFixed(5)}
          <br />
          UC: {calcCommunicationScore(profileForAdmin).toFixed(5)}
        </div>
      )}
    </div>
  )
}
