import { baseKeymap } from 'prosemirror-commands'
import { redo, undo } from 'prosemirror-history'
import { Command } from 'prosemirror-state'
import { PromiseVoid } from '../../../../types'
import {
  insertBold,
  insertDel,
  insertHr,
  insertItalic,
  insertLink,
  insertTab,
  removeLineStartTab,
  searchSelectedText,
} from '../commands'

type Props = {
  handleSave?: () => PromiseVoid
  handlePreview?: () => PromiseVoid
}

const prevent: Command = () => {
  return true
}

export const getMathdownKeymap = ({ handleSave, handlePreview }: Props) => {
  return {
    ...baseKeymap,
    'Mod-z': undo,
    'Mod-y': redo,
    'Mod-Shift-z': redo,

    'Mod-b': insertBold,
    'Mod-i': insertItalic,
    'Mod-g': insertDel,
    'Mod-h': insertHr,
    'Mod-l': insertLink,

    Tab: insertTab,
    'Shift-Tab': removeLineStartTab,

    'Mod-s': () => {
      if (handleSave === undefined) return false
      void handleSave()
      return true
    },
    'Mod-e': () => {
      if (handlePreview === undefined) return false
      void handlePreview()
      return true
    },

    'Mod-f': searchSelectedText,

    'Mod-r': prevent,
    F5: prevent,
  }
}
