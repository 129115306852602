import { basicTab } from '../data/basic'
import { charactersTab } from '../data/characters'
import { fieldsTab } from '../data/fields'
import { symbolsTab } from '../data/symbols'

export const pcFormulaPaletteData = [
  // popularTab, // PCではPoplarはDropDownで出すのでFormulaPaletteには含めない
  basicTab,
  charactersTab,
  fieldsTab,
  symbolsTab,
]
