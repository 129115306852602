import { Node } from 'prosemirror-model'
import { Plugin, PluginKey, Selection } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { PromiseVoid } from '../../../../types'
import { getBeginOfParagraph, getEndOfParagraph } from '../utils'

const selectionKey = new PluginKey<DecorationSet>('selectionPlugin')

const BASIC_BRACKETS_MAP: Record<string, string> = {
  '{': '}',
  '[': ']',
  '(': ')',
  '}': '{',
  ']': '[',
  ')': '(',
}

const BRACKETS = [
  '\\right(',
  '\\right)',
  '\\left)',
  '\\left(',
  '\\right\\{',
  '\\right\\}',
  '\\left\\}',
  '\\left\\{',
  '\\right[',
  '\\right]',
  '\\left]',
  '\\left[',
  '\\right.',
  '\\left.',
  '\\right|',
  '\\left|',
  '\\begin',
  '\\end',
  '{',
  '}',
  '[',
  ']',
  '(',
  ')',
]

type BracketInfo = {
  begin: number
  end: number
  text: string
  type: string
  status: 'open' | 'close'
}

// 指定されたposの要素がかっこの一部である場合にかっこに関する位置情報や種類を返却
const getBracketOf = (
  doc: Node,
  pos: number,
  mode: 'begin' | 'end' | 'middle' = 'middle'
): BracketInfo | null => {
  if (
    doc.resolve(pos).node().type.name !== 'paragraph' ||
    doc.resolve(pos + 1).node().type.name !== 'paragraph'
  )
    return null
  const char = doc.textBetween(pos, pos + 1)
  if (char === '') return null
  const brackets = BRACKETS.filter(
    (b) =>
      (mode === 'begin' && b[0] === char) ||
      (mode === 'end' && b[b.length - 1] === char) ||
      (mode === 'middle' && b.includes(char))
  )
  for (const bracket of brackets) {
    const indices = bracket
      .split('')
      .map((c, index) => (char === c ? index : -1))
      .filter((i) => i >= 0)
    for (const index of indices) {
      if (
        pos - index <= 0 ||
        pos + bracket.length - index >= doc.nodeSize - 2 ||
        doc.resolve(pos - index).node().type.name !== 'paragraph' ||
        doc.resolve(pos + bracket.length - index).node().type.name !==
          'paragraph'
      )
        continue

      const text = doc.textBetween(pos - index, pos + bracket.length - index)

      // 現在のindexでかっこが一致しない場合
      if (text !== bracket) continue

      // 奇数個のバックスラッシュが前についている場合
      let backslashCount = 0
      while (
        doc.resolve(pos - index - backslashCount - 1).node().type.name ===
          'paragraph' &&
        doc.textBetween(
          pos - index - backslashCount - 1,
          pos - index - backslashCount
        ) === '\\'
      )
        backslashCount++
      if (backslashCount % 2 === 1) continue

      // 以下は適切にかっこが見つかった場合
      const type = bracket.includes('right')
        ? 'right'
        : bracket.includes('left')
        ? 'left'
        : bracket.includes('begin')
        ? 'begin'
        : bracket.includes('end')
        ? 'end'
        : bracket
      return {
        begin: pos - index,
        end: pos - index + bracket.length,
        text: bracket,
        type,
        status: ['{', '(', '{', '[', 'left', 'begin'].includes(type)
          ? 'open'
          : 'close',
      }
    }
  }
  return null
}

const findMatchingBracket = (doc: Node, firstBracket: BracketInfo) => {
  const stack = [firstBracket]
  let pos =
    firstBracket.status === 'open' ? firstBracket.end : firstBracket.begin - 1

  while (stack.length > 0 && pos > 0 && pos < doc.nodeSize - 1) {
    // textが取得できる状況ではない場合はcontinue
    if (
      doc.resolve(pos).node().type.name !== 'paragraph' ||
      doc.resolve(pos + 1).node().type.name !== 'paragraph'
    ) {
      pos = firstBracket.status === 'open' ? pos + 1 : pos - 1
      continue
    }

    // posの位置がかっこの両端である可能性がない場合はcontinue
    const char = doc.textBetween(pos, pos + 1)
    if (
      !BRACKETS.some(
        (b) =>
          (firstBracket.status === 'open' && b[0] === char) ||
          (firstBracket.status === 'close' && b[b.length - 1] === char)
      )
    ) {
      pos = firstBracket.status === 'open' ? pos + 1 : pos - 1
      continue
    }

    const currentBracket = getBracketOf(
      doc,
      pos,
      firstBracket.status === 'open' ? 'begin' : 'end'
    )

    // posの位置がかっこの両端ではない場合はcontinue
    if (currentBracket === null) {
      pos = firstBracket.status === 'open' ? pos + 1 : pos - 1
      continue
    }

    // posの位置にかっこがある場合はstackを更新
    const lastBracket = stack.slice(-1)[0]
    switch (lastBracket.status) {
      case 'close':
        if (currentBracket.status === 'close') stack.push(currentBracket)
        else if (
          (lastBracket.type === 'right' && currentBracket.type === 'left') ||
          (lastBracket.type === 'end' && currentBracket.type === 'begin') ||
          BASIC_BRACKETS_MAP[lastBracket.type] === currentBracket.type
        ) {
          stack.pop()
        } else return null
        break

      case 'open':
        if (currentBracket.status === 'open') stack.push(currentBracket)
        else if (
          (lastBracket.type === 'left' && currentBracket.type === 'right') ||
          (lastBracket.type === 'begin' && currentBracket.type === 'end') ||
          BASIC_BRACKETS_MAP[lastBracket.type] === currentBracket.type
        ) {
          stack.pop()
        } else return null
        break
    }

    // 取得したかっこを読み飛ばすようにpositionを更新
    pos =
      firstBracket.status === 'open'
        ? Math.max(currentBracket.end, pos + 1)
        : Math.min(currentBracket.begin - 1, pos - 1)

    if (stack.length === 0) return currentBracket
  }

  return null
}

export const selectionPlugin = (
  handleSelectionChange?: (selection: Selection) => PromiseVoid
) =>
  new Plugin({
    key: selectionKey,

    state: {
      init() {
        return DecorationSet.empty
      },
      apply(tr, set) {
        try {
          // カーソルまたは選択が変更された場合に新しい装飾を計算する
          if (tr.selectionSet || tr.docChanged) {
            const { from, to } = tr.selection
            if (from !== to) return DecorationSet.empty

            const decorations: Decoration[] = []

            // 選択行にクラスをつける
            const beginOfParagraph = getBeginOfParagraph(tr.doc, from)
            const endOfParagraph = getEndOfParagraph(tr.doc, from)
            if (endOfParagraph !== null && beginOfParagraph !== null) {
              decorations.push(
                Decoration.node(beginOfParagraph - 1, endOfParagraph + 1, {
                  class: 'selected-line',
                })
              )
            }

            // かっこが選択されている場合に対応する括弧に印をつける用
            const selectedBracket =
              getBracketOf(tr.doc, from) ?? getBracketOf(tr.doc, from - 1)
            const matchingBracket = selectedBracket
              ? findMatchingBracket(tr.doc, selectedBracket)
              : null
            if (selectedBracket !== null && matchingBracket !== null) {
              decorations.push(
                Decoration.inline(selectedBracket.begin, selectedBracket.end, {
                  class: 'selected-bracket',
                })
              )
              decorations.push(
                Decoration.inline(matchingBracket.begin, matchingBracket.end, {
                  class: 'matching-bracket',
                })
              )
            }
            return DecorationSet.create(tr.doc, decorations)
          }
          // それ以外の場合は、既存の装飾を維持する
          return set
        } catch (error) {
          // FIXME:予期されないタイミングで選択位置をresolveできなくなる場合があるので、ログだけ出してエラーは無視するように設定しておく
          console.error(error)
          return DecorationSet.empty
        }
      },
    },

    props: {
      decorations(state) {
        return selectionKey.getState(state)
      },
    },

    view() {
      return {
        async update(view, prevState) {
          if (view.state.selection.eq(prevState.selection)) return
          if (handleSelectionChange)
            await handleSelectionChange(view.state.selection)
        },
      }
    },
  })
