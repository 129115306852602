import { FormulaButtonData } from '../type'

const set: FormulaButtonData[] = [
  {
    formula: '\\in',
    tooltip: '帰属関係',
  },
  {
    formula: '\\ni',
    tooltip: '帰属関係2',
  },
  {
    formula: '\\notin',
    tooltip: '帰属していない',
  },
  {
    formula: '\\subset',
    tooltip: '部分集合',
  },
  {
    formula: '\\subseteq',
    tooltip: '部分集合2',
  },
  {
    formula: '\\subseteqq',
    tooltip: '部分集合3',
  },
  {
    formula: '\\supset',
    tooltip: '上位集合',
  },
  {
    formula: '\\supseteq',
    tooltip: '上位集合2',
  },
  {
    formula: '\\supseteqq',
    tooltip: '上位集合3',
  },
  {
    formula: '\\not \\subset',
    tooltip: '部分集合でない',
  },
  {
    formula: '\\subsetneqq',
    tooltip: '真の部分集合',
  },
  {
    formula: '\\cap',
    tooltip: '共通部分',
  },
  {
    displayFormula: '\\bigcap',
    formula: '\\bigcap_{i = 0}^n',
    tooltip: '共通部分（大型）',
    mobileFontSize: 13,
  },
  {
    formula: '\\cup',
    tooltip: '和集合',
  },
  {
    displayFormula: '\\bigcup',
    formula: '\\bigcup_{i = 0}^n',
    tooltip: '和集合（大型）',
    mobileFontSize: 13,
  },
  {
    formula: '\\varnothing',
    tooltip: '空集合',
  },
  {
    formula: '\\emptyset',
    tooltip: '空集合',
  },
  {
    formula: 'A^c',
    tooltip: '補集合',
  },
  {
    formula: '\\overline{A}',
    tooltip: '補集合2',
  },
  {
    formula: '\\setminus',
    tooltip: '差集合',
  },
  {
    formula: '\\triangle',
    tooltip: '対称差',
  },
  {
    formula: '\\mathbb{N}',
    tooltip: '自然数全体の集合',
  },
  {
    formula: '\\mathbb{Z}',
    tooltip: '整数全体の集合',
  },
  {
    formula: '\\mathbb{Q}',
    tooltip: '有理数全体の集合',
  },
  {
    formula: '\\mathbb{R}',
    tooltip: '実数全体の集合',
  },
  {
    formula: '\\mathbb{C}',
    tooltip: '複素数全体の集合',
  },
  {
    formula: '\\mathbb{H}',
    tooltip: '四元数全体の集合',
  },
]

export const setPill = {
  label: '集合',
  pill: 'set',
  buttonData: set,
}
