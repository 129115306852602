import { FormulaButtonData } from '../type'

const elementaryFunctions: FormulaButtonData[] = [
  { formula: '\\sin', tooltip: 'サイン' },
  { formula: '\\cos', tooltip: 'コサイン' },
  { formula: '\\tan', tooltip: 'タンジェント' },
  { formula: '\\sec', tooltip: 'セカント' },
  { formula: '\\csc', tooltip: 'コセカント' },
  { formula: '\\cot', tooltip: 'コタンジェント' },
  { formula: '\\arcsin', tooltip: 'アークサイン', mobileFontSize: 13 },
  { formula: '\\arccos', tooltip: 'アークコサイン', mobileFontSize: 13 },
  {
    formula: '\\arctan',
    tooltip: 'アークタンジェント',
    fontSize: 16,
    mobileFontSize: 12,
  },
  { formula: '\\sinh', tooltip: 'ハイパボリックサイン' },
  { formula: '\\cosh', tooltip: 'ハイパボリックコサイン' },
  { formula: '\\tanh', tooltip: 'ハイパボリックタンジェント' },
  { formula: '\\coth', tooltip: 'ハイパボリックコタンジェント' },
  { formula: 'e^{x}', tooltip: '指数関数1' },
  {
    formula: '\\exp \\left( x \\right)',
    displayFormula: '\\exp',
    tooltip: '指数関数2',
    mobileFontSize: 11,
  },
  { formula: '\\sqrt{a}', tooltip: '平方根' },
  { formula: '\\sqrt[n]{a}', tooltip: 'n乗根' },
  { formula: '\\log_{a}', tooltip: '対数' },
  { formula: '\\ln', tooltip: '自然対数' },
]

export const elementaryFunctionsPill = {
  pill: 'elementary_functions',
  label: '初等関数',
  buttonData: elementaryFunctions,
}
