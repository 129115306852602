import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { PromiseVoid } from '../types'

type PrintSetting = {
  theme: 'monotone' | 'colorful'
  showComments: boolean
}

const defaultPrintSetting: PrintSetting = {
  theme: 'monotone',
  showComments: false,
}

export const PrintSettingContext = createContext<{
  isPrinting: boolean
  print: () => PromiseVoid
  printSetting: PrintSetting
  setPrintSetting: (printSetting: PrintSetting) => PromiseVoid
}>({
  isPrinting: false,
  print: () => {
    throw new Error('Unexpected call.')
  },
  printSetting: defaultPrintSetting,
  setPrintSetting: () => {
    throw new Error('Unexpected call.')
  },
})

type ProviderProps = {
  children: React.ReactNode
}

export const PrintSettingProvider = ({ children }: ProviderProps) => {
  const [isPrinting, setIsPrinting] = useState(false)
  const [printSetting, setPrintSetting] =
    useState<PrintSetting>(defaultPrintSetting)
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (!isPrinting) return
    if (ref.current) {
      ref.current.classList.add(`${printSetting.theme}-printing`)
      window.print()
      ref.current.classList.remove(`${printSetting.theme}-printing`)
    }
    setIsPrinting(false)
  }, [isPrinting])
  return (
    <PrintSettingContext.Provider
      value={{
        isPrinting,
        print: () => setIsPrinting(true),
        printSetting,
        setPrintSetting,
      }}
    >
      <div ref={ref}>{children}</div>
    </PrintSettingContext.Provider>
  )
}

export const usePrintSetting = () => useContext(PrintSettingContext)
