import router from 'next/router'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaPencilAlt, FaTrash } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { usePopupMessage } from '../../assets/messenger'
import { useAccount } from '../../context/account'
import { Book } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useCurrentUserRoles } from '../../hooks'
import {
  canEditBook,
  checkBookDraftExists,
  createBookDraft,
  deleteBook,
  getExistingBookDraftID,
} from '../../models/book'
import { Designed } from '../../types'
import { ConfirmationPopup } from '../ConfirmationPopup'
import { PremiumPlanPaymentPopup } from '../subscription/PremiumPlanPaymentPopup'

type DeleteLinkProps = {
  book: Book
}

const DeleteLink = ({ book }: DeleteLinkProps) => {
  const { setPopupMessage } = usePopupMessage()
  return (
    <Button
      variant="link"
      className="text-danger"
      onClick={async () => {
        const confirmed = window.confirm('本当に削除しますか。')
        if (confirmed) {
          await deleteBook(book)
          setPopupMessage('本を削除しました。', 'danger')
          await router.push(`/users/${book.created_by}`)
        }
      }}
    >
      <IconWrapper suffix="削除">
        <FaTrash />
      </IconWrapper>
    </Button>
  )
}

type EditLinkProps = {
  book: Book
  onEditButtonClick?: (book: Book) => Promise<boolean> | boolean
}

const EditLink = ({ book }: EditLinkProps) => {
  const { user } = useAuthState()
  const { account } = useAccount()
  const [showPopup, setShowPopup] = useState(false)
  const [premiumPlanPopupShow, setPremiumPlanPopupShow] = useState(false)

  const handleYes = async () => {
    setShowPopup(false)
    if (!user) throw new Error('This action needs to authenticate.')
    const bookDraftId = await getExistingBookDraftID(user.uid, book.id)
    await router.push(`/book_drafts/${bookDraftId}/edit`)
  }

  const handleNo = async () => {
    setShowPopup(false)
    if (!user) throw new Error('This action needs to authenticate.')
    const bookDraftRef = await createBookDraft(user.uid, book.ref)
    await router.push(`/book_drafts/${bookDraftRef.id}/edit`)
  }
  return (
    <>
      <Button
        variant="link"
        className="text-success"
        onClick={async () => {
          if (!user || !account)
            throw new Error('This action needs to authenticate.')

          // この本が編集不可の場合、プレミアムプランのポップアップを出す
          if (!canEditBook(book, account)) {
            setPremiumPlanPopupShow(true)
            return
          }

          // 下書きの存在確認
          const existsBookDraft = await checkBookDraftExists(user.uid, book.id)
          if (existsBookDraft) {
            // 存在する場合、下書きをどうするかの確認ポップアップを出す
            setShowPopup(true)
            return
          } else {
            // 下書きが存在しない場合、下書きを作成し編集ページへリダイレクト
            const bookDraftRef = await createBookDraft(user.uid, book.ref)
            await router.push(`/book_drafts/${bookDraftRef.id}/edit`)
            return
          }
        }}
      >
        <IconWrapper suffix="編集">
          <FaPencilAlt />
        </IconWrapper>
      </Button>
      <ConfirmationPopup
        show={showPopup}
        title="確認"
        yesLabel="続きから編集"
        noLabel="新規作成して編集"
        onSelectNo={handleNo}
        onSelectYes={handleYes}
        onHide={() => setShowPopup(false)}
      >
        既に作成中の下書きがあります。続きから編集しますか？
      </ConfirmationPopup>
      <PremiumPlanPaymentPopup
        show={premiumPlanPopupShow}
        onHide={() => setPremiumPlanPopupShow(false)}
      />
    </>
  )
}

type Props = {
  book: Book
}

export const BookOperationLinks = ({
  book,
  className,
  style,
}: Designed<Props>) => {
  const { user } = useAuthState()
  const { hasRole } = useCurrentUserRoles()
  if (
    !user ||
    (user.uid !== book.created_by && hasRole('mathlog_admin') !== true)
  )
    return <></>
  return (
    <div className={className} style={style}>
      <div className="d-flex gap-2">
        <EditLink book={book} />
        <DeleteLink book={book} />
      </div>
    </div>
  )
}
