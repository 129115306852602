import { arithmeticSymbolsPill } from './arithmetic_symbols'
import { comparisonSymbolsPill } from './comparison_symbols'
import { elementaryFunctionsPill } from './elementary_functions'
import { logicPill } from './logic'
import { multipleLinesPill } from './multiple_lines'
import { setPill } from './set'

export const basicTab = {
  tab: 'basic',
  label: '基本',
  pillData: [
    arithmeticSymbolsPill,
    comparisonSymbolsPill,
    elementaryFunctionsPill,
    logicPill,
    multipleLinesPill,
    setPill,
  ],
}
