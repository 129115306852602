import { Command } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { memo } from 'react'
import { ListGroup } from 'react-bootstrap'

export type OffcanvasItemData = {
  command: Command
  menuIcon: React.ReactNode
  menuTitle: string
  menuDescription?: string
}

type Props = OffcanvasItemData & {
  editor: EditorView
  onClick: () => void
}

const OffcanvasItemImpl = ({
  editor,
  command,
  onClick: handleClick,
  menuIcon: MenuIcon,
  menuTitle,
  menuDescription,
}: Props) => (
  <ListGroup.Item
    className="d-flex align-items-center gap-3 clickable py-3 cursor-pointer"
    onClick={() => {
      handleClick()

      // commandの内部でthisを書き換えられることはないのでESLintのエラーを無視する
      // eslint-disable-next-line @typescript-eslint/unbound-method
      command(editor.state, editor.dispatch, editor)
    }}
  >
    <div className="flex-shrink-0 border rounded p-2 fs-1 lh-0">{MenuIcon}</div>
    <div className="flex-grow-1 overflow-hidden">
      <div className="fw-bold">{menuTitle}</div>
      <div className="text-muted small text-truncate">{menuDescription}</div>
    </div>
  </ListGroup.Item>
)

export const OffcanvasItem = memo(OffcanvasItemImpl)
