import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaUserAlt } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroller'
import { IconWrapper } from '../../assets/IconWrapper'
import { UserList } from '../../assets/user'
import { Tag } from '../../firebase'
import { useTagContributors } from '../../hooks/models/useTagContributors'
import { useTagFollowers } from '../../hooks/models/useTagFollowers'
import { Designed, PromiseVoid } from '../../types'
import { LoadingSpinner } from '../spinners'

type RelationShipType = 'contributors' | 'followers'

type TitleProps = {
  title: string
}

const Title = ({ title }: TitleProps) => (
  <IconWrapper suffix={title} className="text-muted">
    <FaUserAlt />
  </IconWrapper>
)

type RelationshipsListProps = {
  tag: Tag
  onClose: () => void
}

const FollowersList = ({
  tag,
  onClose: handleClose,
}: RelationshipsListProps) => {
  const { followers, loadMore, isLast, fetching } = useTagFollowers(tag.id)
  return (
    <InfiniteScroll
      loadMore={async () => {
        if (!fetching) await loadMore()
      }}
      hasMore={!isLast}
      loader={<LoadingSpinner />}
      useWindow={false}
    >
      <UserList profiles={followers} onClick={handleClose} />
    </InfiniteScroll>
  )
}

const ContributorsList = ({
  tag,
  onClose: handleClose,
}: RelationshipsListProps) => {
  const { contributors, loadMore, isLast, fetching } = useTagContributors(
    tag.id
  )
  return (
    <InfiniteScroll
      loadMore={async () => {
        if (!fetching) await loadMore()
      }}
      hasMore={!isLast}
      useWindow={false}
      loader={<LoadingSpinner />}
    >
      <UserList profiles={contributors} onClick={handleClose} />
    </InfiniteScroll>
  )
}

type ModalProps = {
  show: boolean
  type: RelationShipType
  onHide: () => PromiseVoid
  tag: Tag
}

const RelationshipsModal = ({
  show,
  type,
  onHide: handleClose,
  tag,
}: ModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} scrollable>
      <Modal.Header closeButton className="py-2">
        <Modal.Title className="fs-6">
          {type === 'contributors' && <Title title={'使用者'} />}
          {type === 'followers' && <Title title={'フォロワー'} />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show && (
          <>
            {type === 'contributors' && (
              <ContributorsList tag={tag} onClose={handleClose} />
            )}
            {type === 'followers' && (
              <FollowersList tag={tag} onClose={handleClose} />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

type Props = {
  tag: Tag
  type: RelationShipType
}

export const TagRelationshipsCounter = ({
  tag,
  type,
  ...wrapperProps
}: Designed<Props>) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        variant="link"
        onClick={() => setShowModal(true)}
        {...wrapperProps}
      >
        {type === 'contributors' && (
          <span>
            <span>{tag.contributors_count}</span> Contributors
          </span>
        )}
        {type === 'followers' && (
          <span>
            <span>{tag.followers_count}</span> Followers
          </span>
        )}
      </Button>
      <RelationshipsModal
        tag={tag}
        type={type}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}
