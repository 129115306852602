export const box = (
  type: string | undefined,
  titleDom: string,
  text: string,
  title?: string,
  label?: string
) => {
  let result = ''
  if (type === undefined || type === '') {
    result = `\\begin{screen}\n`
    result += label !== undefined ? `\\label{${label}}\n` : ''
    result += `    ${text.trim()}\n`
    result += `\\end{screen}\n`
    return result
  }
  if (type === 'def') type = 'dfn'
  result += `\\begin{${type}}`
  result += `${title !== undefined ? `[${titleDom}]\n` : '\n'}`
  result += label !== undefined ? `\\label{${label}}\n` : ''
  result += `    ${text}`
  result += `\\end{${type}}\n\n`
  return result
}
