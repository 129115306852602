import {
  useDocumentData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore'
import {
  CommentNotification,
  Commentable,
  DocRef,
  articleConverter,
  bookConverter,
} from '../../../firebase'
import { useAuthState } from '../../../firebase/hooks'
import { ArticleTitleLink } from '../../article'
import { CommentItem } from '../../comment'
import { PageTitleLink } from '../../page'

type props = {
  notification: CommentNotification
}

export const CreateCommentNotificationDetail = ({ notification }: props) => {
  const [comment] = useDocumentData(notification.target_ref)
  const [commentable] = useDocumentDataOnce<Commentable>(
    comment?.ref.parent.parent?.parent.id === 'articles'
      ? (comment.ref.parent.parent.withConverter(
          articleConverter
        ) as DocRef<Commentable>)
      : comment?.ref.parent.parent?.parent.id === 'books'
      ? (comment.ref.parent.parent.withConverter(
          bookConverter
        ) as DocRef<Commentable>)
      : null
  )
  const { profile } = useAuthState()
  return comment && commentable ? (
    <>
      <div className="mb-2">
        {commentable.parent_id === 'articles' ? (
          <ArticleTitleLink article={commentable} />
        ) : (
          <PageTitleLink
            base={
              commentable.ref.parent.parent
                ? `/${commentable.ref.parent.parent.parent.id}/${commentable.ref.parent.parent.id}`
                : ''
            }
            page={commentable}
          />
        )}
      </div>
      {profile && (
        <CommentItem
          hiddenLabel
          setting={profile.comments_section_setting}
          commentSectionOwnerUid={profile.id}
          comment={comment}
          commentable={commentable}
        />
      )}
    </>
  ) : (
    <></>
  )
}
