import { EditorView } from 'prosemirror-view'
import { memo, useEffect, useRef, useState } from 'react'
import { EditorProps } from '.'
import { Designed } from '../../../../types'
import { useMathdownEditorState } from '../context'
import { useSyncScrollElement } from '../hooks'
import { useMathdownPlugins } from '../plugins'
import { schema } from '../schema'
import { mathdownToNode, nodeToMathdown } from '../utils'
import { PCMenu } from './menu'
import { Previewer } from './previewer'
import { ProseMirrorEditor } from './prose_mirror'
import { PCSearchReplace } from './search_replace/pc'
import { PCSuggestionList } from './suggestions'
import { WindowModeButtonGroup } from './window_mode_changer'

const EditorImpl = ({
  onChange: handleChange,
  onSave: handleSave,
  design = 'monotone',
  defaultValue = '',
  macros,
  references,
  windowMode,
  onChangeWindowMode: handleWindowModeChange,
  room,
  // isValid, // TODO 適した処理を実装
  // isInvalid, // TODO 適した処理を実装
  className,
  style,
}: Designed<EditorProps>) => {
  const value = useRef<string>(defaultValue)
  const autoPreview = useRef<boolean>(true)
  const editorHeaderRef = useRef<HTMLDivElement>(null)
  const [editorHeight, setEditorHeight] = useState('100%')

  const [previewValue, setPreviewValue] = useState(defaultValue)

  const preview = () => setPreviewValue(value.current)

  const [editor, setEditor] = useState<EditorView | null>(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [editorContainerElement, setEditorContainerElement] =
    useState<HTMLDivElement>()
  const { ref: previewerRef } = useSyncScrollElement(editorContainerElement)

  const inputClassName =
    windowMode === 'output'
      ? 'd-none'
      : windowMode === 'both'
      ? 'w-50'
      : 'w-100'
  const outputClassName =
    windowMode === 'input' ? 'd-none' : windowMode === 'both' ? 'w-50' : 'w-100'

  const { setSelection, setFocus, setSearchState } = useMathdownEditorState()

  const { plugins, suggestions } = useMathdownPlugins({
    editorContainerElement,
    macros,
    handleFocus: setFocus,
    handleSelection: setSelection,
    handlePreview: preview,
    handleSave,
    handleSearch: setSearchState,
  })

  // Editorのヘッダーの大きさを動的に引いて、エディタの高さを設定する
  useEffect(() => {
    const editorHeaderElement = editorHeaderRef.current
    if (editorHeaderElement === null) return
    const updateEditorHeight = () => {
      const headerHeight = editorHeaderElement.offsetHeight
      setEditorHeight(`calc(100% - ${headerHeight}px)`)
    }
    updateEditorHeight()
    const observer = new MutationObserver(updateEditorHeight)

    // オブザーバーの設定オプション
    const config = { childList: true }

    // オブザーバーを開始
    observer.observe(editorHeaderElement, config)

    return () => {
      // 必要に応じてオブザーバーを停止
      observer.disconnect()
    }
  }, [])

  return (
    <div style={style} className={className}>
      <div
        className={`h-100 w-100 d-flex flex-column ${
          isFullscreen
            ? 'position-absolute top-0 bottom-0 left-0 right-0 bg-white'
            : ''
        }`}
        style={isFullscreen ? { zIndex: 700 } : {}}
      >
        <div className="d-flex justify-content-between gap-6 mb-2">
          <div className="flex-shrink-0 d-none d-md-flex align-items-center">
            {editor && (
              <PCMenu
                editor={editor}
                className={`${windowMode === 'output' ? 'd-none' : 'd-block'}`}
              />
            )}
          </div>
          <div className="flex-shrink-0 d-flex align-items-center gap-2">
            <WindowModeButtonGroup
              mode={windowMode}
              onChange={handleWindowModeChange}
              isFullScreen={isFullscreen}
              onChangeScreen={(screen) => setIsFullscreen(screen === 'full')}
            />
          </div>
        </div>
        <div className="text-break w-100 flex-grow-1 overflow-hidden">
          <div className="h-100 w-100 d-flex gap-6">
            <div className={`${inputClassName} border p-1`}>
              <div ref={editorHeaderRef}>
                {editor && <PCSearchReplace editor={editor} />}
              </div>
              {/* position-relativeは同期スクロールやSuggestionを出すときの高さ計算を楽にするためについてる */}
              {/* flex-grow-1を使った構成にするとスクロール同期が動作しないので注意 */}
              <div
                className="position-relative w-100"
                style={{ height: editorHeight }}
              >
                <PCSuggestionList suggestions={suggestions} />
                <ProseMirrorEditor
                  className="overflow-auto"
                  editorContainerRefSetter={setEditorContainerElement}
                  defaultValue={defaultValue}
                  onChange={(newValue) => {
                    value.current = newValue
                    handleChange(newValue)
                    if (autoPreview.current) preview()
                  }}
                  onSave={handleSave}
                  onCreateEditorView={setEditor}
                  toNode={mathdownToNode}
                  fromNode={nodeToMathdown}
                  plugins={plugins}
                  schema={schema}
                  room={room}
                />
              </div>
            </div>
            <div className={`${outputClassName} h-100`}>
              <Previewer
                _ref={previewerRef}
                value={previewValue}
                onPreviewButtonClick={preview}
                onAutoPreviewChange={(newAutoPreview) => {
                  autoPreview.current = newAutoPreview
                }}
                windowMode={windowMode}
                design={design}
                macros={macros}
                references={references}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PCMathdownEditor = memo(EditorImpl)
