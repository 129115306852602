import { memo } from 'react'
import { Button } from 'react-bootstrap'
import { FaPlus } from 'react-icons/fa'
import { range } from '../../../../../utils'

type Props = {
  table: string[][]
  setTable: (table: string[][]) => void
  width: number
}

const AddRowButtonImpl = ({ table, setTable, width }: Props) => {
  const addRow = () => {
    setTable([...table, range(table[0].length).map(() => '')])
  }

  return (
    <Button style={{ width }} variant="success" onClick={addRow}>
      <FaPlus />
    </Button>
  )
}
export const AddRowButton = memo(AddRowButtonImpl)
