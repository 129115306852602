import { ESArticle } from '../../elastic_search'
import { Article } from '../../firebase'

type Props = {
  article: Article | ESArticle
}

export const ArticleTypeBadge = ({ article }: Props) => {
  switch (article.type) {
    case 'explanation':
      return (
        <span className="badge" style={{ backgroundColor: '#29ABA4' }}>
          解説
        </span>
      )

    case 'problem':
      return (
        <span className="badge" style={{ backgroundColor: '#106060' }}>
          問題
        </span>
      )

    case 'discussion':
      return (
        <span className="badge" style={{ backgroundColor: '#003030' }}>
          議論
        </span>
      )
  }
}
