import { useEffect, useRef } from 'react'
import { useMathdownEditorState } from '../context'

type LineRange = {
  from: {
    line: number
    ratio: number
  }
  to: {
    line: number
    ratio: number
  }
}

const getVisibleLineRange = (editorElement: HTMLDivElement) => {
  const visibleLineRange: LineRange = {
    from: {
      line: 0,
      ratio: 1.0,
    },
    to: {
      line: 0,
      ratio: 1.0,
    },
  }
  // Editorの表示領域の高さ (offsetHeightだとズレが生じた)
  const editorHeight = editorElement.getBoundingClientRect().height
  // Editorをどの程度スクロールしているか
  // editorHeight + editorScrollTop = editor内の非表示領域も含む全体の高さ
  const editorScrollTop = editorElement.scrollTop
  // editor内の各パラグラフ(行)に対してEditorに存在するかを判定して
  // visibleLineRangeを更新していく
  for (const child of editorElement.children) {
    const p = child as HTMLParagraphElement // EditorのSchemeの仕様より必ずpタグ
    // Editorの表示領域の上部を原点とした時のpタグの上部の座標（下方向が正）
    const paragraphTopFromEditorTop = p.offsetTop - editorScrollTop
    // Editorの表示領域の下部を原点とした時のpタグの上部の座標（下方向が正）
    const paragraphTopFromEditorBottom =
      paragraphTopFromEditorTop - editorHeight
    // 段落の全体の高さに対するEditorの表示領域の上部より上の段落の領域の割合
    const paragraphHeightRatioOfEditorTop =
      -paragraphTopFromEditorTop / p.offsetHeight
    // 段落の全体の高さに対するEditorの表示領域の下部より上の段落の領域の割合
    const paragraphHeightRatioOfEditorBottom =
      -paragraphTopFromEditorBottom / p.offsetHeight
    // Editorの表示領域の下部を原点とした時のpタグの上部の座標が正になるまでは見えていない
    if (paragraphTopFromEditorTop < 0) {
      visibleLineRange.from.line++
      visibleLineRange.from.ratio = paragraphHeightRatioOfEditorTop
      if (visibleLineRange.from.ratio < 0) visibleLineRange.from.ratio = 0.0
      if (visibleLineRange.from.ratio > 1) visibleLineRange.from.ratio = 1.0
    }
    // Editorの表示領域の下部を原点とした時のpタグの上部の座標が負の間は見えている
    if (paragraphTopFromEditorBottom < 0) {
      visibleLineRange.to.line++
      visibleLineRange.to.ratio = paragraphHeightRatioOfEditorBottom
      if (visibleLineRange.to.ratio < 0) visibleLineRange.to.ratio = 0.0
      if (visibleLineRange.to.ratio > 1) visibleLineRange.to.ratio = 1.0
    }
  }
  return visibleLineRange
}

const getLineAttribute = (e: Element) => {
  const lineAttribute = e.getAttribute('data-line')
  return lineAttribute !== null ? Number(lineAttribute) : 0
}

const getTokenTypeAttribute = (e: Element) => {
  return e.getAttribute('data-token-type')
}

const scrollIntoView = (
  syncElement: HTMLDivElement,
  { from, to }: LineRange,
  maxLine: number
) => {
  let line = 1
  const syncElementHeight = syncElement.offsetHeight
  const editorVisible = {
    top: 0,
    bottom: 0,
  }
  if (to.line === maxLine) {
    syncElement.scrollTop = syncElement.scrollHeight - syncElementHeight
    return
  }
  for (
    let childIndex = 0;
    childIndex < syncElement.children.length;
    childIndex++
  ) {
    const child = syncElement.children.item(childIndex) as HTMLElement | null
    if (child === null) continue
    const nextChild =
      childIndex + 1 < syncElement.children.length
        ? (syncElement.children.item(childIndex + 1) as HTMLElement | null)
        : null
    const previousLine = line
    line += getLineAttribute(child)
    let height = child.offsetHeight
    if (nextChild !== null && getTokenTypeAttribute(nextChild) === 'space') {
      line += getLineAttribute(nextChild)
      height += nextChild.offsetHeight
      childIndex++
    }
    if (line < from.line) continue
    if (to.line < previousLine) break

    if (previousLine < from.line && line >= from.line) {
      editorVisible.top =
        ((from.line - previousLine + from.ratio) / (line - previousLine + 1)) *
          height +
        child.offsetTop
    }
    if (to.line <= line) {
      editorVisible.bottom =
        ((to.line - previousLine + to.ratio) / (line - previousLine + 1)) *
          height +
        child.offsetTop
    }
  }
  const floatFromLine = from.line - 1 + from.ratio
  const floatToLine = to.line - 1 + to.ratio
  const floatRemainingLine = maxLine - floatToLine
  const invisibleFloatLine = floatFromLine + floatRemainingLine
  if (invisibleFloatLine > 0) {
    syncElement.scrollTop =
      editorVisible.top * (floatRemainingLine / invisibleFloatLine) +
      (editorVisible.bottom - syncElementHeight) *
        (floatFromLine / invisibleFloatLine)
  } else {
    syncElement.scrollTop = syncElement.scrollHeight - syncElementHeight
  }
}

export const useSyncScrollElement = (
  editorContainerElement: HTMLDivElement | undefined
) => {
  const { searchState } = useMathdownEditorState()

  const syncElementRef = useRef<HTMLDivElement | null>(null)

  const sync = () => {
    if (editorContainerElement === undefined) return
    const editorElement = editorContainerElement.getElementsByClassName(
      'ProseMirror'
    )[0] as HTMLDivElement
    const syncElement = syncElementRef.current
    if (syncElement === null) return
    const visibleRange = getVisibleLineRange(editorElement)
    scrollIntoView(syncElement, visibleRange, editorElement.children.length)
  }

  const handleSyncElementWheel = (e: WheelEvent) => {
    if (editorContainerElement === undefined) return
    const editorElement = editorContainerElement.getElementsByClassName(
      'ProseMirror'
    )[0] as HTMLDivElement
    if (editorElement === undefined) return
    e.preventDefault()
    const syncElement = syncElementRef.current
    if (syncElement === null) return

    const dy = e.deltaY // ユーザがスクロールした量

    // エディタの要素がスクロール不可能な時は同期する要素をスクロール
    // エディタがスクロールできる場合はエディタをスクロールして間接的に同期する要素をスクロール
    const editorScrollable =
      editorElement.offsetHeight < editorElement.scrollHeight
    if (!editorScrollable) {
      syncElement.scrollTop += dy
    } else {
      editorElement.scrollTop += dy
    }
  }

  useEffect(() => {
    if (editorContainerElement === undefined) return
    const editorElement = editorContainerElement.getElementsByClassName(
      'ProseMirror'
    )[0] as HTMLDivElement
    if (editorElement === undefined) return
    const syncElement = syncElementRef.current
    if (syncElement === null) return
    sync()
    // scrollは要素のスクロールバーが動いた時に発火
    // wheelは要素の上でユーザがスクロールをした際に発火
    // 同期する要素がエディタを通じて間接的にスクロールされる場合があり、
    // 両方scrollイベントにしてしまうと無限ループするので注意
    editorElement.addEventListener('scroll', sync)
    editorElement.addEventListener('resize', sync)
    editorElement.addEventListener('keydown', sync)
    syncElement.addEventListener('resize', sync)
    syncElement.addEventListener('wheel', handleSyncElementWheel)

    return () => {
      editorElement.removeEventListener('scroll', sync)
      editorElement.removeEventListener('keydown', sync)
      editorElement.removeEventListener('resize', sync)
      syncElement.removeEventListener('resize', sync)
      syncElement.removeEventListener('wheel', handleSyncElementWheel)
    }
  }, [editorContainerElement])

  // 検索している時に検索のヒット箇所のスクロールを行う
  useEffect(() => {
    if (!searchState) return
    if (editorContainerElement === undefined) return
    const editorElement = editorContainerElement.getElementsByClassName(
      'ProseMirror'
    )[0] as HTMLDivElement
    const selectedSearchTextElement = editorElement.getElementsByClassName(
      'search-highlight selected'
    )[0] as HTMLDivElement | undefined
    if (!selectedSearchTextElement) return
    editorElement.scrollTop = selectedSearchTextElement.offsetTop
    // 検索結果が変わる時はsearchStateを監視すれば問題ない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  return { ref: syncElementRef, syncScroll: sync }
}
