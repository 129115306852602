import { EditorView } from 'prosemirror-view'
import { memo, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { useAuthState } from '../../../../../firebase/hooks/useAuthState'
import { uploadMathdownImage } from '../../../../../models/uploader'
import { getReplaceCommand, insertUploadingImage } from '../../commands'

type Props = {
  editor: EditorView
  buttonLabel?: React.ReactNode
  onClick?: () => void
}

const ImageUploaderImpl = ({
  editor,
  buttonLabel = <>画像</>,
  onClick: handleClick,
}: Props) => {
  const { user } = useAuthState()
  const ref = useRef<HTMLInputElement | null>(null)
  return (
    <>
      <Button
        onClick={() => {
          if (!ref.current) throw new Error()
          ref.current.click()
          if (handleClick) handleClick()
        }}
        size="sm"
        variant="outline-dark"
        className="fw-bold"
      >
        {buttonLabel}
        <input
          ref={ref}
          type="file"
          className="d-none"
          onChange={async (e) => {
            if (!user) {
              alert('本操作はアカウント作成者にのみ許可されています。')
              return
            }
            if (!e.currentTarget.files) {
              alert('ファイルを選択してください。')
              return
            }
            const file = e.currentTarget.files[0]
            const extension = file.name
              .split('.')
              .slice(-1)[0]
              .toLocaleLowerCase()
            e.currentTarget.value = ''
            if (!['jpeg', 'jpg', 'gif', 'png'].includes(extension)) {
              alert('対応していない拡張子です。')
              return
            }
            // commandの内部でthisを書き換えられることはないのでESLintのエラーを無視する
            // eslint-disable-next-line @typescript-eslint/unbound-method
            insertUploadingImage(editor.state, editor.dispatch, editor)

            const fileUrl = await uploadMathdownImage(file, extension, user)
            const replace = getReplaceCommand('uploading...', fileUrl)

            //commandの内部でthisを書き換えられることはないのでESLintのエラーを無視する
            // eslint-disable-next-line @typescript-eslint/unbound-method
            replace(editor.state, editor.dispatch, editor)
          }}
        />
      </Button>
    </>
  )
}

export const ImageUploader = memo(ImageUploaderImpl)
