import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { FaPencilAlt, FaReply, FaTrash } from 'react-icons/fa'
import { MessageForm, MessageTypeBadge } from '.'
import { IconWrapper } from '../../assets/IconWrapper'
import { BadButton, GoodButton } from '../../assets/evaluation'
import { Mathdown } from '../../assets/mathdown'
import { usePopupMessage } from '../../assets/messenger'
import { ReferencesList } from '../../assets/reference'
import { UserNameLink } from '../../assets/user'
import {
  Comment,
  Commentable,
  CommentsSectionSetting,
  DocRef,
  Message,
  Reply,
  commentRefWithoutConverter,
  profileRef,
  replyRefWithoutConverter,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useCurrentUserRoles, useMacros, useReferences } from '../../hooks'
import { deleteComment, deleteReply } from '../../models/message'
import {
  calcAuthorEvaluation,
  calcAuthorPopularity,
  calcCommunicationScore,
} from '../../shared/indicators'
import { calcMessageEvaluation } from '../../shared/indicators/message_evaluation'
import { PromiseVoid } from '../../types'
import { ReportButton } from '../report/Button'
import { ShapedTimestamp } from '../timestamp/ShapedTimestamp'
import { UserImageWithLink } from '../user/ImageWithLink'
import { HideButton } from './HideButton'
import { MessagesCounter } from './MessagesCounter'

type Props =
  | {
      type: 'comment'
      parentRef: DocRef<Commentable>
      message: Comment
      commentSectionOwnerUid: string
      replyButton?: boolean
      setting?: CommentsSectionSetting
      onReplyButtonClick?: () => PromiseVoid
      hiddenLabel?: boolean
    }
  | {
      type: 'reply'
      parentRef: DocRef<Comment>
      message: Reply
      commentSectionOwnerUid: string
      setting?: CommentsSectionSetting
      replyButton?: undefined
      onReplyButtonClick?: undefined
      hiddenLabel?: undefined
    }

export const MessageItem = ({
  type,
  message,
  parentRef,
  replyButton,
  commentSectionOwnerUid,
  setting,
  onReplyButtonClick: handleReplyButtonClick,
  hiddenLabel = true,
  ...wrapperProps
}: Props) => {
  const { user: currentUser, showSignInPopup } = useAuthState()
  const { setPopupMessage } = usePopupMessage()
  const [formVisibility, setFormVisibility] = useState(false)
  const { hasRole } = useCurrentUserRoles()

  const isHidden =
    message.is_hidden ??
    setting?.hidden_uids.includes(message.created_by) ??
    false

  const [profile] = useDocumentDataOnce(profileRef(message.created_by))
  const { macros } = useMacros<Message>(message.ref, true)
  const { references } = useReferences<Message>(message.ref, true)

  const [goodCount, setGoodCount] = useState<number>(message.good_count)
  const [badCount, setBadCount] = useState<number>(message.bad_count)

  const [forceShow, setForceShow] = useState(false)

  return (
    <div {...wrapperProps}>
      {isHidden &&
      message.parent_id === 'replies' &&
      (!currentUser || message.created_by !== currentUser.uid) &&
      !forceShow ? (
        <>
          <div>この返信は非表示に設定されています。</div>
          <Button variant="link" onClick={() => setForceShow(true)}>
            表示する
          </Button>
        </>
      ) : (
        <>
          {hiddenLabel === true && isHidden && (
            <div className="text-muted mb-1">
              このコメントは非表示に設定されています。
            </div>
          )}
          {formVisibility &&
            (type === 'comment' ? (
              <MessageForm
                type={type}
                parentRef={parentRef}
                message={message}
                onCancel={() => setFormVisibility(false)}
                onSave={() => setFormVisibility(false)}
              />
            ) : (
              <MessageForm
                type={type}
                parentRef={parentRef}
                message={message}
                onCancel={() => setFormVisibility(false)}
                onSave={() => setFormVisibility(false)}
              />
            ))}
          {!formVisibility && (
            <>
              <div className="d-flex align-items-center gap-3">
                <UserImageWithLink
                  profile={profile}
                  size="50px"
                  className="flex-shrink-0"
                />
                <div className="flex-grow-1 overflow-hidden">
                  <div className="d-flex align-items-center gap-1 fs-7">
                    <MessageTypeBadge message={message} />
                    <ShapedTimestamp timestamp={message.created_at} />
                  </div>
                  <div className="text-truncate">
                    {profile && <UserNameLink profile={profile} />}
                  </div>
                </div>
              </div>
              <div className="mathdown-colorful my-2">
                <Mathdown
                  mathdown={message.body}
                  macros={macros}
                  references={references}
                  className="my-2 text-break"
                />
                {references && references.length > 0 && (
                  <>
                    <h2>参考文献</h2>
                    <ReferencesList
                      references={references}
                      amazonBookImage
                      link
                    />
                  </>
                )}
              </div>
              <div className="d-flex gap-2 align-items-center">
                {replyButton === true && (
                  <Button
                    variant="link"
                    size="sm"
                    className="text-muted d-print-none"
                    onClick={
                      currentUser !== null
                        ? handleReplyButtonClick
                        : () => showSignInPopup()
                    }
                  >
                    <IconWrapper suffix="返信">
                      <FaReply />
                    </IconWrapper>
                  </Button>
                )}
                <GoodButton
                  evaluable={message}
                  currentGoodCount={goodCount}
                  onChangeGoodCount={(added, isDecrementBad) => {
                    setGoodCount((current) => current + added)
                    if (isDecrementBad) setBadCount((current) => current - 1)
                  }}
                  size="sm"
                  showCounter
                />
                <BadButton
                  evaluable={message}
                  currentBadCount={badCount}
                  onChangeBadCount={(added, isDecrementGood) => {
                    setBadCount((current) => current + added)
                    if (isDecrementGood) setGoodCount((current) => current - 1)
                  }}
                  size="sm"
                  showCounter
                />
                {type === 'comment' && (
                  <MessagesCounter
                    value={message.replies_count}
                    className="text-muted small"
                  />
                )}
                <HideButton
                  commentSectionOwnerUid={commentSectionOwnerUid}
                  setting={setting}
                  message={message}
                />
                {currentUser?.uid !== message.created_by && (
                  <ReportButton
                    reportableRefWithoutConverter={
                      message.parent_id !== 'comments'
                        ? replyRefWithoutConverter(
                            parentRef as DocRef<Comment>,
                            message.id
                          )
                        : commentRefWithoutConverter(
                            parentRef as DocRef<Commentable>,
                            message.id
                          )
                    }
                    size="sm"
                    targetUid={message.created_by}
                    label="通報"
                    className="d-print-none small text-muted"
                  />
                )}
                {((currentUser && currentUser.uid === message.created_by) ||
                  hasRole('mathlog_admin') === true) && (
                  <>
                    <Button
                      variant="link"
                      size="sm"
                      className="text-success d-print-none"
                      onClick={() => setFormVisibility(true)}
                    >
                      <IconWrapper suffix="編集">
                        <FaPencilAlt />
                      </IconWrapper>
                    </Button>
                    <Button
                      variant="link"
                      size="sm"
                      className="text-danger d-print-none"
                      onClick={async () => {
                        const confirmed = window.confirm('本当に削除しますか。')
                        if (confirmed) {
                          type === 'comment'
                            ? await deleteComment(message, parentRef)
                            : await deleteReply(message, parentRef)
                          setPopupMessage(
                            `${
                              type === 'comment' ? 'コメント' : '返信'
                            }を削除しました。`,
                            'danger'
                          )
                        }
                      }}
                    >
                      <IconWrapper suffix="削除">
                        <FaTrash />
                      </IconWrapper>
                    </Button>
                  </>
                )}
              </div>
              {hasRole('mathlog_admin') && profile && (
                <div className="mt-1 text-muted smalls">
                  CE: {calcMessageEvaluation(message).toFixed(5)}, UE:{' '}
                  {calcAuthorEvaluation(profile).toFixed(5)}, UP:{' '}
                  {calcAuthorPopularity(profile).toFixed(5)}, UC:{' '}
                  {calcCommunicationScore(profile).toFixed(5)}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
