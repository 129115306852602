import {
  useDocumentData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore'
import {
  Comment,
  Commentable,
  DocRef,
  ReplyNotification,
  articleConverter,
  bookConverter,
  commentConverter,
  profileRef,
} from '../../../firebase'
import { useAuthState } from '../../../firebase/hooks'
import { ArticleTitleLink } from '../../article'
import { CommentItem } from '../../comment'
import { PageTitleLink } from '../../page'

type Props = {
  notification: ReplyNotification
}

export const CreateReplyNotificationDetail = ({ notification }: Props) => {
  const [reply] = useDocumentData(notification.target_ref)
  const [comment] = useDocumentDataOnce(
    reply?.ref.parent.parent
      ? (reply.ref.parent.parent.withConverter(
          commentConverter
        ) as DocRef<Comment>)
      : null
  )
  const [commentable] = useDocumentDataOnce<Commentable>(
    comment?.ref.parent.parent?.parent.id === 'articles'
      ? (comment.ref.parent.parent.withConverter(
          articleConverter
        ) as DocRef<Commentable>)
      : comment?.ref.parent.parent?.parent.id === 'books'
      ? (comment.ref.parent.parent.withConverter(
          bookConverter
        ) as DocRef<Commentable>)
      : null
  )
  const { user } = useAuthState()
  const [profile] = useDocumentDataOnce(user ? profileRef(user.uid) : null)
  return reply && comment && commentable ? (
    <>
      <div className="mb-2">
        {commentable.parent_id === 'articles' ? (
          <ArticleTitleLink article={commentable} />
        ) : (
          <PageTitleLink
            base={
              commentable.ref.parent.parent
                ? `/${commentable.ref.parent.parent.parent.id}/${commentable.ref.parent.parent.id}`
                : ''
            }
            page={commentable}
          />
        )}
      </div>
      {profile && (
        <CommentItem
          hiddenLabel
          setting={profile.comments_section_setting}
          commentSectionOwnerUid={profile.id}
          commentable={commentable}
          comment={comment}
        />
      )}
    </>
  ) : (
    <></>
  )
}
