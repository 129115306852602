import { useEffect, useRef, useState } from 'react'
import { Suggestions } from '../../plugins'

type Props = {
  suggestions: Suggestions
}

export const PCSuggestionList = ({ suggestions }: Props) => {
  const suggestionsRef = useRef<HTMLDivElement>(null)
  const [menuDirection, setMenuDirection] = useState<'up' | 'down'>()
  const [suggestionsHeight, setSuggestionsHeight] = useState(0)

  const { isOpen, selectedIndex, items, position, acceptSuggestion } =
    suggestions

  useEffect(() => {
    const suggestionsHeight =
      suggestionsRef.current !== null ? suggestionsRef.current.offsetHeight : 0
    setSuggestionsHeight(suggestionsHeight)

    if (!isOpen) setMenuDirection(undefined)
    else if (menuDirection === undefined)
      setMenuDirection(
        suggestionsHeight + position.cursorBottom < position.editorHeight
          ? 'down'
          : 'up'
      )
  }, [items, position, isOpen, menuDirection])

  useEffect(() => {
    if (suggestionsRef.current === null) return
    const suggestionsElement = suggestionsRef.current

    // 選択されているアイテムが見えるまでスクロール
    const selectedSuggestionItem = suggestionsElement.getElementsByClassName(
      `suggestion-item-${selectedIndex}`
    )[0] as HTMLElement
    if (selectedSuggestionItem === undefined) return
    // 選択中のアイテムが既に見えている時は何もしない
    if (
      suggestionsElement.scrollTop <= selectedSuggestionItem.offsetTop &&
      selectedSuggestionItem.offsetTop + selectedSuggestionItem.offsetHeight <=
        suggestionsElement.scrollTop + suggestionsElement.offsetHeight
    )
      return

    // 選択中のアイテムが既に見えていない時は必要最小限でスクロール
    suggestionsElement.scrollTop =
      suggestionsElement.scrollTop < selectedSuggestionItem.offsetTop
        ? selectedSuggestionItem.offsetTop -
          suggestionsElement.offsetHeight +
          selectedSuggestionItem.offsetHeight
        : selectedSuggestionItem.offsetTop
  }, [items, selectedIndex])

  return (
    <div
      ref={suggestionsRef}
      // カーソルの位置に補完を出す
      className={`position-absolute overflow-auto shadow-sm bg-white dropdown-menu show p-0 ${
        !isOpen ||
        items.length === 0 ||
        suggestionsHeight === 0 ||
        menuDirection === undefined
          ? 'invisible'
          : ''
      }`}
      style={{
        top:
          menuDirection === 'down'
            ? position.cursorBottom
            : position.cursorTop - suggestionsHeight,
        left: position.cursorLeft,
        maxHeight: 200,
        width: 230,
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={`cursor-pointer dropdown-item suggestion-item-${index} px-2 ${
            selectedIndex === index ? 'active' : ''
          }`}
          onClick={() => {
            if (acceptSuggestion) {
              acceptSuggestion(index)
            }
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}
