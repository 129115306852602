import { FormulaButtonData } from '../type'

const others: FormulaButtonData[] = [
  {
    formula: '\\S',
    displayFormula: '\\S',
    tooltip: 'セクション',
  },
  {
    formula: '\\aleph',
    displayFormula: '\\aleph',
    tooltip: 'アレフ',
  },
  {
    formula: '\\beth',
    displayFormula: '\\beth',
    tooltip: 'ベート',
  },
  {
    formula: '\\gimel',
    displayFormula: '\\gimel',
    tooltip: 'ギメル',
  },
  {
    formula: '\\daleth',
    displayFormula: '\\daleth',
    tooltip: 'ダレット',
  },
  {
    formula: '\\forall',
    displayFormula: '\\forall',
    tooltip: 'さかさまの A',
  },
  {
    formula: '\\exists',
    displayFormula: '\\exists',
    tooltip: 'さかさまの E',
  },
  {
    formula: '\\Finv',
    displayFormula: '\\Finv',
    tooltip: 'さかさまの F',
  },
  {
    formula: '\\hbar',
    displayFormula: '\\hbar',
    tooltip: 'バー付きの h',
  },
  {
    formula: '\\imath',
    displayFormula: '\\imath',
    tooltip: '点なしの i',
  },
  {
    formula: '\\jmath',
    displayFormula: '\\jmath',
    tooltip: '点なしの j',
  },
  {
    formula: '\\Bbbk',
    displayFormula: '\\Bbbk',
    tooltip: '中抜き文字の k',
  },
  {
    formula: '\\ell',
    displayFormula: '\\ell',
    tooltip: '筆記体の l',
  },
  {
    formula: '\\circledR',
    displayFormula: '\\circledR',
    tooltip: '丸付きの R',
  },
  {
    formula: '\\circledS',
    displayFormula: '\\circledS',
    tooltip: '丸付きの S',
  },
  {
    formula: '\\TeX',
    displayFormula: '\\TeX',
    tooltip: 'TeXのロゴ',
    fontSize: 20,
    mobileFontSize: 14,
  },
  {
    formula: '\\LaTeX',
    displayFormula: '\\LaTeX',
    tooltip: 'LaTeXのロゴ',
    fontSize: 17,
    mobileFontSize: 12,
  },
]

export const othersPill = {
  pill: 'others',
  label: 'その他',
  buttonData: others,
}
