import Link from 'next/link'
import { EditorView } from 'prosemirror-view'
import { memo } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'
import { useAuthState } from '../../../../../../firebase/hooks'
import { Designed } from '../../../../../../types'
import { HELP_NOTION_LINKS, logEvent } from '../../../../../../utils'
import { menuData } from './data'

type Props = {
  editor: EditorView
}

const PCMenuImpl = ({ editor, ...designProps }: Designed<Props>) => {
  const { user } = useAuthState()

  return (
    <>
      <ButtonGroup {...designProps}>
        {menuData.map(({ component: Component, ...props }, index) => (
          // TODO: any型を改善する
          <Component key={index} editor={editor} {...(props as any)} />
        ))}
        <Link href={HELP_NOTION_LINKS.EDITOR_USAGE}>
          <a
            className="btn btn-outline-dark btn-sm fw-bold align-items-center"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              logEvent('tap_editor_help', {
                uid: user?.uid,
              })
            }
          >
            <FaQuestionCircle />
          </a>
        </Link>
      </ButtonGroup>
    </>
  )
}

export const PCMenu = memo(PCMenuImpl)
