import { memo } from 'react'
import { Button } from 'react-bootstrap'
import { FaArrowDown, FaArrowUp, FaRegTrashAlt } from 'react-icons/fa'

type Props = {
  table: string[][]
  setTable: (table: string[][]) => void
  row: number
  width: number
  disableDelete: boolean
}

const RowManagerImpl = ({
  row,
  table,
  setTable,
  width,
  disableDelete,
}: Props) => {
  // row1 < row2 を満たすようにすること
  const changeRow = (row1: number, row2: number) =>
    setTable([
      ...table.slice(0, row1),
      table[row2],
      ...table.slice(row1 + 1, row2),
      table[row1],
      ...table.slice(row2 + 1),
    ])

  const deleteRow = (row: number) =>
    setTable([...table.slice(0, row), ...table.slice(row + 1)])

  return (
    <div
      style={{
        visibility: row === 0 ? 'hidden' : 'visible',
        width,
      }}
      className="d-flex flex-shrink-0 gap-2 justify-content-between"
    >
      <Button
        variant="danger"
        disabled={disableDelete}
        onClick={() => deleteRow(row)}
      >
        <FaRegTrashAlt />
      </Button>
      <Button
        variant="secondary"
        disabled={row === 1}
        onClick={() => changeRow(row - 1, row)}
      >
        <FaArrowUp />
      </Button>
      <Button
        variant="secondary"
        disabled={row === table.length - 1}
        onClick={() => changeRow(row, row + 1)}
      >
        <FaArrowDown />
      </Button>
    </div>
  )
}

export const RowManager = memo(RowManagerImpl)
