import { replaceUnicode } from '../../utils'

// 数式の前に$がついていたらエラーが出るパターン
const noDollarPatterns = [
  '\\begin{align}',
  '\\begin{align*}',
  '\\begin{alignat}',
  '\\begin{alignat*}',
  '\\begin{eqnarray}',
  '\\begin{eqnarray*}',
  '\\begin{equation}',
  '\\begin{equation*}',
  '\\begin{flalign}',
  '\\begin{flalign*}',
  '\\begin{gather}',
  '\\begin{gather*}',
  '\\begin{multline}',
  '\\begin{multline*}',
  '\\begin{numcases}',
  '\\begin{prooftree}',
  '\\begin{subnumcases}',
  '\\begin{xalignat}',
  '\\begin{xalignat*}',
  '\\begin{xxalignat}',
]

// 数式の前に$がついていないとエラーが出るパターン
const dollarPatterns = [
  '\\begin{aligned}',
  '\\begin{alignedat}',
  '\\begin{array}',
  '\\begin{bmatrix}',
  '\\begin{Bmatrix}',
  '\\begin{bmatrix*}',
  '\\begin{Bmatrix*}',
  '\\begin{bsmallmatrix}',
  '\\begin{Bsmallmatrix}',
  '\\begin{bsmallmatrix*}',
  '\\begin{Bsmallmatrix*}',
  '\\begin{cases}',
  '\\begin{cases*}',
  '\\begin{CD}',
  '\\begin{crampedsubarray}',
  '\\begin{dcases}',
  '\\begin{dcases*}',
  '\\begin{drcases}',
  '\\begin{drcases*}',
  '\\begin{gathered}',
  '\\begin{lgathered}',
  '\\begin{matrix}',
  '\\begin{matrix*}',
  '\\begin{multlined}',
  '\\begin{pmatrix}',
  '\\begin{pmatrix*}',
  '\\begin{psmallmatrix}',
  '\\begin{psmallmatrix*}',
  '\\begin{rcases}',
  '\\begin{rcases*}',
  '\\begin{rgathered}',
  '\\begin{smallmatrix}',
  '\\begin{smallmatrix*}',
  '\\begin{split}',
  '\\begin{subarray}',
  '\\begin{vmatrix}',
  '\\begin{Vmatrix}',
  '\\begin{vmatrix*}',
  '\\begin{Vmatrix*}',
  '\\begin{vsmallmatrix}',
  '\\begin{Vsmallmatrix}',
  '\\begin{vsmallmatrix*}',
  '\\begin{Vsmallmatrix*}',
]

export const formula = (text: string) => {
  const matchArray: RegExpMatchArray | null = text.match(/\\begin{[^}]+}/)
  if (matchArray != null) {
    noDollarPatterns.forEach((pattern) => {
      if (matchArray[0].includes(pattern)) {
        if (/^\$\$?[\s]*/.test(text)) {
          text = text.replace(/\$\$?[\s]*\\begin/, '\\begin')
        }
        if (/\$\$?[\s]*$/.test(text)) {
          text = text.replace(/\$\$?[\s]*$/, '')
        }
      }
    })
    dollarPatterns.forEach((pattern) => {
      if (matchArray[0].includes(pattern)) {
        if (!/^\$\$?[\s]*/.test(text)) {
          // 正規表現の置換のため$２個を$$$$にしている
          text = text.replace(/[\s]*\\begin/, '$$$$\\begin')
        }
        if (!/\$\$?[\s]*$/.test(text)) {
          // 正規表現の置換のため$２個を$$$$にしている
          text = text.replace(/$/, '$$$$')
        }
      }
    })
  }
  return `${replaceUnicode(text)}`.replace('\n\n', '\n')
}
