import {
  insertAxmBox,
  insertConjBox,
  insertCorBox,
  insertDefBox,
  insertExBox,
  insertExcBox,
  insertFmlBox,
  insertLemBox,
  insertNormalBox,
  insertPrfBox,
  insertPropBox,
  insertRemBox,
  insertThmBox,
} from '../../../../../commands'
import { OffcanvasItemData } from '../../OffcanvasItem'

export const formatOffcanvasData: OffcanvasItemData[] = [
  {
    command: insertNormalBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          囲み枠
        </div>
      </div>
    ),
    menuTitle: '囲み枠',
    menuDescription: 'シンプルで汎用的な囲み枠。',
  },
  {
    command: insertConjBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          予想
        </div>
      </div>
    ),
    menuTitle: '予想',
    menuDescription: '予想専用の囲み枠。',
  },
  {
    command: insertAxmBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          公理
        </div>
      </div>
    ),
    menuTitle: '公理',
    menuDescription: '公理専用の囲み枠。',
  },
  {
    command: insertDefBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          定義
        </div>
      </div>
    ),
    menuTitle: '定義',
    menuDescription: '定義専用の囲み枠。',
  },
  {
    command: insertPropBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          命題
        </div>
      </div>
    ),
    menuTitle: '命題',
    menuDescription: '命題専用の囲み枠。',
  },
  {
    command: insertFmlBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          公式
        </div>
      </div>
    ),
    menuTitle: '公式',
    menuDescription: '公式専用の囲み枠。',
  },
  {
    command: insertLemBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          補題
        </div>
      </div>
    ),
    menuTitle: '補題',
    menuDescription: '補題専用の囲み枠。',
  },
  {
    command: insertThmBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          定理
        </div>
      </div>
    ),
    menuTitle: '定理',
    menuDescription: '定理専用の囲み枠。',
  },
  {
    command: insertCorBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          系
        </div>
      </div>
    ),
    menuTitle: '系',
    menuDescription: '系専用の囲み枠。',
  },
  {
    command: insertPrfBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          証明
        </div>
      </div>
    ),
    menuTitle: '証明',
    menuDescription: '証明専用の囲み枠。',
  },
  {
    command: insertExBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          例
        </div>
      </div>
    ),
    menuTitle: '例',
    menuDescription: '例専用の囲み枠。',
  },
  {
    command: insertExcBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          問題
        </div>
      </div>
    ),
    menuTitle: '問題',
    menuDescription: '問題専用の囲み枠。',
  },
  {
    command: insertRemBox,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 40 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          注意
        </div>
      </div>
    ),
    menuTitle: '注意',
    menuDescription: '注意専用の囲み枠。',
  },
]
