import { signOut } from 'firebase/auth'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { NavDropdown } from 'react-bootstrap'
import { BiVolumeMute } from 'react-icons/bi'
import {
  FaCog,
  FaCoins,
  FaInfoCircle,
  FaListUl,
  FaQuestionCircle,
  FaRegEnvelope,
  FaSignInAlt,
  FaSignOutAlt,
  FaUserAlt,
  FaUserCircle,
  FaUserCog,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa'
import { MdPrivacyTip } from 'react-icons/md'
import { VscLaw } from 'react-icons/vsc'
import { IconWrapper } from 'web_core/assets/IconWrapper'
import { usePopupMessage } from 'web_core/assets/messenger'
import { UserImage } from 'web_core/assets/user'
import { useAfterSignedInPath, useAuthState } from 'web_core/firebase/hooks'
import { fb } from 'web_core/firebase/instance'
import {
  HELP_NOTION_LINKS,
  TEAM_ACCOUNT_APPLICATION_LINK,
  logEvent,
} from 'web_core/utils'
import { PlanInfo } from './PlanInfo'

export const PCHeaderUserMenu = () => {
  const { user, profile } = useAuthState()
  const { t } = useTranslation()
  const { setPopupMessage } = usePopupMessage()
  const { setAfterSignedInPath } = useAfterSignedInPath()
  return (
    <NavDropdown
      title={
        profile ? (
          <UserImage size={35} profile={profile} className="d-inline-block" />
        ) : (
          <FaUserCircle
            className="text-white"
            style={{ fontSize: 35 }} // UserImageのsizeと一致させた。height, width に1emが設定されるため。
          />
        )
      }
      className="d-none d-md-block"
      align="end"
    >
      {user ? (
        <>
          <PlanInfo profile={profile} className="px-3" />
          <NavDropdown.Divider />
          <Link href={`/users/${user.uid}`} passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.profile')}
              >
                <FaUserAlt />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href="/article_drafts" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.drafts')}
              >
                <FaListUl />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href="/default_macros" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.macros')}
              >
                <FaCog />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href="/sales" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.sales')}
              >
                <FaCoins />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <NavDropdown.Divider />
          <Link href="/mute_and_block" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t(
                  'mathlog.components.layout.header.dropdown.mute_and_block'
                )}
              >
                <BiVolumeMute />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href="/auth/setting" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.setting')}
              >
                <FaUserCog />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href={TEAM_ACCOUNT_APPLICATION_LINK} passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t(
                  'mathlog.components.layout.header.dropdown.apply_team'
                )}
              >
                <FaUsers />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <NavDropdown.Item
            onClick={async () => {
              await signOut(fb.auth)
              setPopupMessage(
                t('packages.firebase.popup_messages.sign_out'),
                'danger'
              )
            }}
          >
            <IconWrapper
              suffix={t('mathlog.components.layout.header.dropdown.sign_out')}
            >
              <FaSignOutAlt />
            </IconWrapper>
          </NavDropdown.Item>
          <NavDropdown.Divider />
        </>
      ) : (
        <>
          <Link href="/auth/sign_up" passHref>
            <NavDropdown.Item>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.sign_up')}
              >
                <FaUserPlus />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <Link href="/auth/sign_in" passHref>
            <NavDropdown.Item onClick={() => setAfterSignedInPath(null)}>
              <IconWrapper
                suffix={t('mathlog.components.layout.header.dropdown.sign_in')}
              >
                <FaSignInAlt />
              </IconWrapper>
            </NavDropdown.Item>
          </Link>
          <NavDropdown.Divider />
        </>
      )}
      <NavDropdown.Item
        href={HELP_NOTION_LINKS['ROOT']}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => logEvent('tap_help', { uid: user?.uid })}
      >
        <IconWrapper
          suffix={t('mathlog.components.layout.header.dropdown.help')}
        >
          <FaQuestionCircle />
        </IconWrapper>
      </NavDropdown.Item>
      <Link href="/community_guideline" passHref>
        <NavDropdown.Item>
          <IconWrapper
            suffix={t(
              'mathlog.components.layout.header.dropdown.community_guideline'
            )}
          >
            <FaUsers />
          </IconWrapper>
        </NavDropdown.Item>
      </Link>
      <Link href="/terms" passHref>
        <NavDropdown.Item>
          <IconWrapper
            suffix={t('mathlog.components.layout.header.dropdown.terms')}
          >
            <FaInfoCircle />
          </IconWrapper>
        </NavDropdown.Item>
      </Link>
      <Link href="/privacy" passHref>
        <NavDropdown.Item>
          <IconWrapper
            suffix={t('mathlog.components.layout.header.dropdown.privacy')}
          >
            <MdPrivacyTip />
          </IconWrapper>
        </NavDropdown.Item>
      </Link>
      <Link href="/tradelaw" passHref>
        <NavDropdown.Item>
          <IconWrapper
            suffix={t('mathlog.components.layout.header.dropdown.tradelaw')}
          >
            <VscLaw />
          </IconWrapper>
        </NavDropdown.Item>
      </Link>
      <Link href="/inquiry" passHref>
        <NavDropdown.Item>
          <IconWrapper
            suffix={t('mathlog.components.layout.header.dropdown.contact')}
          >
            <FaRegEnvelope />
          </IconWrapper>
        </NavDropdown.Item>
      </Link>
    </NavDropdown>
  )
}
