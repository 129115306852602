import { memo } from 'react'
import { Form } from 'react-bootstrap'

type Props = {
  table: string[][]
  setTable: (table: string[][]) => void
  row: number
  col: number
  width: number
}

const FieldImpl = ({ row, col, table, setTable, width }: Props) => (
  <div style={{ width }} className="flex-shrink-0 ">
    <Form.Control
      type="text"
      placeholder="値を入力"
      value={table[row][col]}
      onChange={(e) => {
        setTable([
          ...table.slice(0, row),
          [
            ...table[row].slice(0, col),
            e.currentTarget.value,
            ...table[row].slice(col + 1),
          ],
          ...table.slice(row + 1),
        ])
      }}
    />
  </div>
)

export const Field = memo(FieldImpl)
