import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaCommentAlt } from 'react-icons/fa'
import InfiniteScroll from 'react-infinite-scroller'
import { CommentForm, CommentsList } from '.'
import { IconWrapper } from '../../assets/IconWrapper'
import { Commentable, CommentsSectionSetting } from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { useComments } from '../../hooks/models/useComments'
import { Designed } from '../../types'
import { LoadingSpinner } from '../spinners'

type Props = {
  commentable: Commentable
  setting?: CommentsSectionSetting
  commentSectionOwnerUid: string
}

export const CommentSection = ({
  commentable,
  setting,
  commentSectionOwnerUid,
  ...wrapperProps
}: Designed<Props>) => {
  const { user: currentUser } = useAuthState()
  const {
    showComments,
    hiddenComments,
    currentUserComments,
    loadMore,
    fetching,
    isLast,
  } = useComments(commentable, setting)
  const [showCurrentUserCommentsMore, setShowCurrentUserCommentsMore] =
    useState(false)
  const [showHiddenComments, setShowHiddenComments] = useState(false)

  return (
    <section {...wrapperProps}>
      <div className="d-flex flex-column gap-7">
        <div>
          <h2 className="h5">
            <IconWrapper suffix="コメント" className="text-muted">
              <FaCommentAlt />
            </IconWrapper>
          </h2>
          {currentUser && (
            <CommentForm commentable={commentable} className="d-print-none" />
          )}
        </div>
        {currentUserComments && currentUserComments.length > 0 && (
          <div>
            <h3 className="h6">あなたのコメント</h3>
            <CommentsList
              setting={setting}
              commentSectionOwnerUid={commentSectionOwnerUid}
              comments={
                showCurrentUserCommentsMore
                  ? currentUserComments
                  : currentUserComments.slice(0, 3)
              }
              commentable={commentable}
              hiddenLabel
            />
            {currentUserComments.length > 3 && !showCurrentUserCommentsMore && (
              <Button
                className="mt-5"
                variant="link"
                onClick={() => setShowCurrentUserCommentsMore(true)}
              >
                もっと見る
              </Button>
            )}
          </div>
        )}
        <div>
          <h3 className="h6">他の人のコメント</h3>
          <InfiniteScroll
            loadMore={async () => {
              if (!fetching) await loadMore()
            }}
            hasMore={!isLast}
            loader={<LoadingSpinner />}
            useWindow={true}
          >
            <CommentsList
              setting={setting}
              comments={showComments}
              commentable={commentable}
              commentSectionOwnerUid={commentSectionOwnerUid}
            />
          </InfiniteScroll>
        </div>
        {hiddenComments.length > 0 && (
          <div>
            <h3 className="h6">投稿者が非表示にしたコメント</h3>
            <div className={showHiddenComments ? 'd-block' : 'd-none'}>
              <CommentsList
                setting={setting}
                commentSectionOwnerUid={commentSectionOwnerUid}
                comments={hiddenComments}
                commentable={commentable}
              />
            </div>
            {!showHiddenComments && (
              <Button
                variant="link"
                className="mt-5"
                onClick={() => setShowHiddenComments(true)}
              >
                表示する
              </Button>
            )}
          </div>
        )}
      </div>
    </section>
  )
}
