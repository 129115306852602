import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { Nav } from 'react-bootstrap'
import { IconWrapper } from 'web_core/assets/IconWrapper'
import { CategoriesMenu } from 'web_core/assets/category/Menu'
import { Category } from 'web_core/firebase'
import { NAV_LINKS } from './Default'

type Props = {
  width: number
  top: number
  navLinks: boolean
  categories?: Category[]
  categoriesMenu: boolean
  activeCategoryId: string
  activeNavLink: string
}

export const LeftSidebar = ({
  width,
  top,
  navLinks,
  categories,
  categoriesMenu,
  activeCategoryId,
  activeNavLink,
}: Props) => {
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className="flex-shrink-0 d-md-flex d-none" style={{ width }} />
      <div
        className="overflow-hidden d-md-flex d-none flex-column sidenav bottom-0 position-fixed shadow" // 無限スクロール時もメニューを画面上で固定させるために position-fixed を使用
        style={{ width, top }}
      >
        {/* ここはTabletのみが表示される領域なのでtabletのみで条件判定すれば良い */}
        {navLinks && (
          <Nav className="flex-shrink-0 d-flex flex-column gap-1">
            {NAV_LINKS.map(({ key, path, icon: Icon }) => (
              <Link key={key} href={path} passHref>
                <Nav.Link
                  active={activeNavLink === key}
                  className="px-4 py-3 fw-bold"
                >
                  <IconWrapper
                    suffix={t(`mathlog.components.layout.header.${key}`)}
                  >
                    <Icon />
                  </IconWrapper>
                </Nav.Link>
              </Link>
            ))}
          </Nav>
        )}
        {navLinks && categoriesMenu && <hr className="flex-shrink-0 my-1" />}
        {categoriesMenu && (
          <CategoriesMenu
            categories={categories}
            locale={i18n.language}
            activeCategoryId={activeCategoryId}
          />
        )}
      </div>
    </>
  )
}
