import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, ButtonGroup, ListGroup, Modal } from 'react-bootstrap'
import { FaGripVertical } from 'react-icons/fa'
import {
  addDoc,
  Chapter,
  deleteDoc,
  DocRef,
  Page,
  PagesParent,
  pagesRef,
  Timestamp,
  updateDoc,
} from '../../firebase'
import { useOrderChanger } from '../../hooks/useOrdersChanger'
import { Designed } from '../../types'

type ItemProps = {
  page: Page
  parentPath: string
  destChapters?: Chapter[]
}

const PageItem = ({ page, parentPath, destChapters }: ItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: page.id })

  const [destModalShow, setDestModalShow] = useState(false)

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  return (
    <>
      <ListGroup.Item
        className="py-3 d-flex justify-content-between align-items-center"
        ref={setNodeRef}
        style={style}
      >
        <div className="d-flex align-items-center">
          <Button
            className="text-muted me-2 flex-shrink-0"
            variant="link"
            {...listeners}
            {...attributes}
          >
            <FaGripVertical />
          </Button>
          <h3 className="h6 mb-0">{page.title || 'タイトル未設定'}</h3>
        </div>
        <ButtonGroup>
          {destChapters && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setDestModalShow(true)}
            >
              移動
            </Button>
          )}
          <Link href={`${parentPath}/${page.id}/edit`} passHref>
            <Button size="sm" variant="success">
              編集
            </Button>
          </Link>
          <Button
            size="sm"
            variant="danger"
            onClick={async () => {
              const confirmed = window.confirm('本当に削除しますか。')
              if (!confirmed) return
              await deleteDoc(page.ref)
            }}
          >
            削除
          </Button>
        </ButtonGroup>
      </ListGroup.Item>
      {destChapters && (
        <Modal onHide={() => setDestModalShow(false)} show={destModalShow}>
          <Modal.Header closeButton>移動先のチャプターを選択</Modal.Header>
          <Modal.Body>
            {destChapters.length > 0 ? (
              <ListGroup>
                {destChapters.map((destChapter) => (
                  <ListGroup.Item key={destChapter.id}>
                    <div className="d-flex gap-4 justify-content-between align-items-center">
                      <h3 className="mb-0 fs-6">{destChapter.title}</h3>
                      <Button
                        size="sm"
                        onClick={async () => {
                          await updateDoc(page.ref, {
                            chapter_id: destChapter.id,
                            order: 0,
                            updated_by_user_at: Timestamp.now(),
                          })
                          setDestModalShow(false)
                        }}
                      >
                        決定
                      </Button>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <>移動可能な別のチャプターがありません。</>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

type Props = {
  orderedPages: Page[]
  parentRef: DocRef<PagesParent>
  parentPath: string
  chapterId?: string
  destChapters?: Chapter[]
}

export const PagesManager = ({
  orderedPages,
  parentRef,
  parentPath,
  chapterId,
  destChapters,
  ...otherProps
}: Designed<Props>) => {
  const router = useRouter()
  const { shift, nextOrder } = useOrderChanger(orderedPages)
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  return (
    <div {...otherProps}>
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <h2 className="h5 mb-0">ページ一覧</h2>
        <Button
          variant="primary"
          onClick={async () => {
            const now = Timestamp.now()
            const pageRef = await addDoc(pagesRef(parentRef), {
              title: '',
              body: '',
              chapter_id: chapterId,
              order: nextOrder,
              created_by_user_at: now,
              updated_by_user_at: now,
              comments_count: 0,
            })
            await router.push(`${parentPath}/${pageRef.id}/edit`)
          }}
          size="sm"
        >
          ページを追加
        </Button>
      </div>
      <ListGroup>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={async (event) => {
            const { active, over } = event
            if (over === null || active.id === over.id) return
            const target = orderedPages.findIndex(
              (orderedPage) => orderedPage.id === `${active.id}`
            )
            const position = orderedPages.findIndex(
              (orderedPage) => orderedPage.id === `${over.id}`
            )
            await shift(target, position)
          }}
        >
          <SortableContext
            items={orderedPages}
            strategy={verticalListSortingStrategy}
          >
            {orderedPages.map((page) => (
              <PageItem
                key={page.id}
                page={page}
                parentPath={parentPath}
                destChapters={destChapters}
              />
            ))}
          </SortableContext>
        </DndContext>
      </ListGroup>
      {orderedPages.length === 0 && (
        <div className="my-2">
          ページが存在しません。1ページ目を作成しましょう。
        </div>
      )}
    </div>
  )
}
