import { memo } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs'
import { FaPen, FaRegEye } from 'react-icons/fa'
import { VscOpenPreview } from 'react-icons/vsc'
import { PromiseVoid } from '../../../../../types'
import type { WindowMode } from '../../types'

type Props = {
  mode: WindowMode
  onChange: (mode: WindowMode) => PromiseVoid
  isFullScreen: boolean
  onChangeScreen: (screen: 'full' | 'partial') => void
}

const WindowModeButtonGroupImpl = ({
  mode,
  onChange: handleChange,
  isFullScreen,
  onChangeScreen: handleChangeScreen,
}: Props) => {
  return (
    <ButtonGroup className="w-auto">
      <Button
        variant={mode === 'input' ? 'primary' : 'outline-primary'}
        onClick={() => handleChange('input')}
        size="sm"
      >
        <FaPen />
      </Button>
      <Button
        variant={mode === 'both' ? 'primary' : 'outline-primary'}
        onClick={() => handleChange('both')}
        size="sm"
        className="d-none d-md-inline-block"
      >
        <VscOpenPreview />
      </Button>
      <Button
        variant={mode === 'output' ? 'primary' : 'outline-primary'}
        onClick={() => handleChange('output')}
        size="sm"
      >
        <FaRegEye />
      </Button>
      <Button
        variant={'outline-primary'}
        onClick={() => handleChangeScreen(isFullScreen ? 'partial' : 'full')}
        size="sm"
      >
        {isFullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
      </Button>
    </ButtonGroup>
  )
}
export const WindowModeButtonGroup = memo(WindowModeButtonGroupImpl)
