import { memo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useMedia } from '../../../../../../hooks'
import { MathJax } from '../../../../mathjax'

export type FormulaButtonProps = {
  onClick?: () => void
  tooltip?: string
  formula: string
  displayFormula?: string
  size?: number
  fontSize?: number
  mobileFontSize?: number
  className?: string
  cache?: string
}

const FormulaButtonImpl = ({
  onClick: handleClick,
  formula,
  displayFormula = formula,
  size = 60,
  fontSize = 18,
  mobileFontSize = 16,
  className = '',
  cache,
}: FormulaButtonProps) => {
  const [show, setShow] = useState(false)
  const isMd = useMedia('md')

  return (
    <Button
      onClick={handleClick}
      variant="light"
      className={`border overflow-hidden p-0 ${className}`}
      style={{
        height: size,
        width: size,
        fontSize: isMd ? fontSize : mobileFontSize,
      }}
    >
      <MathJax
        cache={cache}
        className={show ? '' : 'd-none'}
        onTypeset={() => setShow(true)}
        inline
      >
        {`$\\displaystyle{${displayFormula}}$`}
      </MathJax>
    </Button>
  )
}

export const FormulaButton = memo(FormulaButtonImpl)
