import { EditorView } from 'prosemirror-view'
import { memo, useRef, useState } from 'react'
import { Button, Modal, Nav } from 'react-bootstrap'
import { MathJax } from '../../../../mathjax'
import { PCFormulaPaletteButton } from './Button'
import { pcFormulaPaletteData } from './data'

type Props = {
  editor: EditorView
  buttonLabel: React.ReactNode
  buttonVariant?: string
  buttonSize?: 'sm' | 'lg'
  buttonClassName?: string
}

const PCFormulaPaletteImpl = ({
  editor,
  buttonLabel,
  buttonVariant,
  buttonSize,
  buttonClassName,
}: Props) => {
  const [show, setShow] = useState(false)
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  const [currentPillIndex, setCurrentPillIndex] = useState(0)
  const formulaeCache = useRef<Record<string, string>>({})
  return (
    <>
      <Button
        onClick={() => setShow(true)}
        variant={buttonVariant}
        size={buttonSize}
        className={buttonClassName}
      >
        {buttonLabel}
      </Button>

      {/* 数式のキャッシュ */}
      <div className="d-none">
        {pcFormulaPaletteData.map(({ pillData }, tabIndex) =>
          pillData.map(({ buttonData }, pillIndex) =>
            buttonData.map(({ formula }, buttonIndex) => {
              const key = `${tabIndex}-${pillIndex}-${buttonIndex}`
              return (
                <MathJax
                  key={key}
                  onTypeset={(result) => {
                    formulaeCache.current[key] = result
                  }}
                >{`$\\displaystyle{${formula}}$`}</MathJax>
              )
            })
          )
        )}
      </div>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="w-100 h-100 p-4"
        dialogClassName="w-100 h-100 m-0 mw-100"
        contentClassName="h-100"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">数式パレット</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav fill variant="tabs" className="mb-6">
            {pcFormulaPaletteData.map(({ label, tab }, index) => (
              <Nav.Item key={tab}>
                <Nav.Link
                  className="py-2"
                  active={index === currentTabIndex}
                  onClick={() => {
                    setCurrentTabIndex(index)
                    setCurrentPillIndex(0)
                  }}
                >
                  {label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <div className="d-flex gap-6 align-items-start">
            <Nav
              variant="pills"
              className="flex-column flex-shrink-0"
              style={{ width: 200 }}
            >
              {pcFormulaPaletteData[currentTabIndex].pillData.map(
                ({ label, pill }, index) => (
                  <Nav.Item key={pill}>
                    <Nav.Link
                      active={index === currentPillIndex}
                      onClick={() => setCurrentPillIndex(index)}
                    >
                      {label}
                    </Nav.Link>
                  </Nav.Item>
                )
              )}
            </Nav>
            <div className="gap-2 d-flex flex-wrap">
              {pcFormulaPaletteData[currentTabIndex].pillData[
                currentPillIndex
              ].buttonData.map((props, index) => (
                <PCFormulaPaletteButton
                  editor={editor}
                  key={`${currentTabIndex}-${currentPillIndex}-${index}`}
                  onClick={() => setShow(false)}
                  size={70}
                  {...props}
                />
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export const PCFormulaPalette = memo(PCFormulaPaletteImpl)
