import { FormulaButtonData } from '../type'

const dotsAndLines: FormulaButtonData[] = [
  {
    formula: '\\cdot',
    tooltip: '点（中央）',
  },
  {
    formula: '\\cdots',
    tooltip: '複数の点（中央横向き）',
  },
  {
    formula: '\\ldots',
    tooltip: '複数の点（下側横向き）',
  },
  {
    formula: '\\vdots',
    tooltip: '複数の点（中央縦向き）',
  },
  {
    formula: '\\ddots',
    tooltip: '複数の点（斜め）',
  },
  {
    formula: '\\vert',
    tooltip: '縦線',
  },
  {
    formula: 'a \\mid b',
    tooltip: 'スペース付き縦線',
  },
  {
    formula: '\\Vert',
    tooltip: '2重縦線',
  },
  {
    formula: '/',
    tooltip: 'スラッシュ',
  },
  {
    formula: '\\backslash',
    tooltip: 'バックスラッシュ',
  },
  {
    formula: '\\diagdown',
    tooltip: '斜線（下）',
  },
  {
    formula: '\\diagup',
    tooltip: '斜線（上）',
  },
]

export const dotsAndLinesPill = {
  pill: 'dots_and_line',
  label: '点と線',
  buttonData: dotsAndLines,
}
