import { useRouter } from 'next/router'
import { useState } from 'react'
import { Button, Container, Dropdown, Form, Navbar } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { IoChevronBack } from 'react-icons/io5'
import { Page, PagesParent, deleteDoc } from '../../firebase'
import { useElementSize, useMobileKeyboardStatus } from '../../hooks'
import { usePageForm } from '../../hooks/forms/usePageForm'
import { Designed } from '../../types'
import {
  EditorTypeChangeDropdownItems,
  EditorTypeChangeNav,
  EditorTypeName,
} from '../editor_addons/editor_type_changer'
import { MacrosEditor } from '../macro'
import { MathdownEditor } from '../mathdown/editor'
import { useMathdownEditorWindowMode } from '../mathdown/editor/hooks'
import { usePopupMessage } from '../messenger'
import { ReferencesEditor } from '../reference'
import { SpinnerButton } from '../spinners'

type Props = {
  page: Page
  parent: PagesParent
  backPath: string
}

export const PageForm = ({
  page,
  parent,
  backPath,
  ...otherProps
}: Designed<Props>) => {
  const router = useRouter()
  const [isDeleting, setIsDeleting] = useState(false)

  const { windowMode, setWindowMode } = useMathdownEditorWindowMode()
  const [editorType, setEditorType] = useState<EditorTypeName>('mathdown')
  const {
    room,
    title,
    handleTitleChange,
    saveTitleChange,
    body,
    handleBodyChange,
    saveBodyChange,
    macros,
    references,
    errors,
    handleSave,
  } = usePageForm(parent, page)

  const { setPopupMessage } = usePopupMessage()

  const [isTitleFocused, setIsTitleFocused] = useState(false)

  const keyboardStatus = useMobileKeyboardStatus()

  const { elementRef: headerRef, height: headerHeight } = useElementSize()

  return (
    <Form {...otherProps} onSubmit={(e) => e.preventDefault()}>
      <div className="d-flex flex-column h-100">
        <Form.Control
          className="gap-1 mb-2 d-none d-md-block"
          onChange={handleTitleChange}
          value={title}
          onBlur={saveTitleChange}
          isInvalid={!!errors.title}
          placeholder="タイトルを入力してください"
        />
        {(isTitleFocused || keyboardStatus === 'close') && (
          <>
            <Navbar
              ref={headerRef}
              expand="md"
              variant="dark"
              className="flex-shrink-0 shadow-sm px-1 bg-primary-dark fixed-top d-flex flex-nowrap align-items-center justify-content-start d-md-none gap-2"
              style={{ height: 50 }} // ヘッダーの高さは中の要素の高さの影響を受けないように固定値にした
            >
              {(!isTitleFocused || keyboardStatus === 'close') && (
                <Button
                  variant="link"
                  className="text-white fs-4"
                  onClick={async () => {
                    await handleSave()
                    await router.push(backPath)
                  }}
                >
                  <IoChevronBack />
                </Button>
              )}
              <Form.Control
                onChange={handleTitleChange}
                value={title}
                onFocus={() => {
                  setIsTitleFocused(true)
                }}
                onBlur={async () => {
                  setIsTitleFocused(false)
                  await saveTitleChange()
                }}
                isInvalid={!!errors.title}
                placeholder="タイトルを入力"
                className="d-inline flex-fill text-light text-truncate"
                size="sm"
                plaintext
              />
              {(!isTitleFocused || keyboardStatus === 'close') && (
                <>
                  <Dropdown align={'end'}>
                    <Dropdown.Toggle
                      variant="link"
                      className="text-light fs-5 dropdown-toggle-after-none me-2"
                    >
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <EditorTypeChangeDropdownItems
                        editorType={editorType}
                        onChangeEditorType={setEditorType}
                        windowMode={windowMode}
                        onChangeWindowMode={setWindowMode}
                      />
                      <Dropdown.Divider />
                      <Dropdown.Item
                        className="text-danger"
                        onClick={async () => {
                          const windowConfirm =
                            window.confirm('本当に削除しますか？')
                          if (!windowConfirm) return
                          await deleteDoc(page.ref)
                          setPopupMessage('ページを削除しました。', 'danger')
                          await router.push(backPath)
                        }}
                      >
                        ページを削除
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </Navbar>
            <Container
              className="flex-shrink-0 d-print-none d-md-none"
              style={{ height: headerHeight }}
              fluid
            />
          </>
        )}
        <EditorTypeChangeNav
          activeEditorType={editorType}
          onChange={setEditorType}
          className="mb-2 d-none d-md-flex"
        />
        <div className="flex-grow-1 overflow-x-hidden overflow-y-auto px-md-0">
          <MathdownEditor
            room={room}
            className={
              editorType !== 'mathdown' ? 'd-none' : 'h-100 w-100 px-2 p-md-0'
            }
            mobileMenuPosition={{
              top: keyboardStatus === 'close' ? headerHeight : 0,
              right: 8,
              left: 8,
            }}
            design="colorful"
            defaultValue={body}
            onSave={saveBodyChange}
            onChange={handleBodyChange}
            macros={macros ?? []}
            references={references ?? []}
            windowMode={windowMode}
            onChangeWindowMode={setWindowMode}
          />
          <ReferencesEditor<PagesParent>
            parentRef={parent.ref}
            references={references ?? []}
            className={
              editorType !== 'references' ? 'd-none' : 'h-100 w-100 p-2 p-md-0'
            }
          />
          <MacrosEditor<PagesParent>
            parentRef={parent.ref}
            macros={macros ?? []}
            className={
              editorType !== 'macros' ? 'd-none' : 'h-100 w-100 p-2 p-md-0'
            }
            windowMode={windowMode}
          />
        </div>
        <div className="d-none d-md-flex gap-4 justify-content-end mt-2">
          <SpinnerButton
            size="sm"
            variant="danger"
            type="button"
            onClick={async () => {
              const windowConfirm = window.confirm('本当に削除しますか？')
              if (!windowConfirm) return
              setIsDeleting(true)
              await deleteDoc(page.ref)
              await router.push(backPath)
              setPopupMessage('ページを削除しました。', 'danger')
            }}
            loading={isDeleting}
          >
            ページを削除
          </SpinnerButton>
          <Button
            type="button"
            variant="secondary"
            size={'sm'}
            onClick={async () => {
              await router.push(backPath)
            }}
            disabled={isDeleting}
          >
            本の編集ページへ
          </Button>
        </div>
      </div>
    </Form>
  )
}
