export const heading = (level: number, text: string, label?: string) => {
  let anchorText: string

  switch (level) {
    case 1:
      anchorText = `\\section{${text}}\n`
      anchorText += label !== undefined ? `\\label{${label}}\n` : ''
      break
    case 2:
      anchorText = `\\subsection{${text}}\n`
      anchorText += label !== undefined ? `\\label{${label}}\n` : ''
      break
    case 3:
      anchorText = `\\subsubsection{${text}}\n`
      anchorText += label !== undefined ? `\\label{${label}}\n` : ''
      break
    default:
      anchorText = `\\textbf{${text}}\n`
      anchorText += label !== undefined ? `\\label{${label}}\n` : '\n'
      break
  }

  return level < 7 ? anchorText : text
}
