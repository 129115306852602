import { Stack } from 'react-bootstrap'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { ArticleTitleLink, ArticleTypeBadge } from '.'
import { PVCounter } from '../../assets/PVCounter'
import { CategoryBadge } from '../../assets/category/Badge'
import { GoodCounter } from '../../assets/evaluation/GoodCounter'
import { ReferenceBadge } from '../../assets/reference'
import { InlineTagList } from '../../assets/tag'
import { UserNameLink } from '../../assets/user'
import { ESArticle } from '../../elastic_search'
import { articleRef, profileRef } from '../../firebase'
import { useCurrentUserRoles } from '../../hooks'
import {
  calcAuthorEvaluation,
  calcAuthorPopularity,
  calcCommunicationScore,
  calcContentEvaluation,
} from '../../shared/indicators'
import { Designed } from '../../types'
import { BeginnerIcon } from '../content/BeginnerIcon'
import { BadCounter } from '../evaluation/BadCounter'
import { MessagesCounter } from '../message'
import { ShapedTimestamp } from '../timestamp/ShapedTimestamp'
import { UserImageWithLink } from '../user/ImageWithLink'

type Props = {
  article: ESArticle
  showProfile?: boolean
  showProfileImage?: boolean
  showProfileDisplayName?: boolean
}

export const ArticleItem = ({
  article,
  showProfile = true,
  showProfileImage = showProfile,
  showProfileDisplayName = showProfile,
  ...wrapperProps
}: Designed<Props>) => {
  // 管理者の場合だけarticleを読み込んでスコアを表示
  const { hasRole } = useCurrentUserRoles()
  const [articleForAdmin] = useDocumentDataOnce(
    hasRole('mathlog_admin') ? articleRef(article.id) : null
  )
  const [profileForAdmin] = useDocumentDataOnce(
    hasRole('mathlog_admin') ? profileRef(article.user.id) : null
  )

  return (
    <div {...wrapperProps}>
      <Stack direction="horizontal" className="gap-lg-4 gap-2">
        {showProfileImage && (
          <>
            <div className="flex-shrink-0 align-self-start d-none d-lg-block">
              <UserImageWithLink size={'45px'} profile={article.user} />
            </div>
            <div className="flex-shrink-0 align-self-start d-lg-none">
              <UserImageWithLink size={'30px'} profile={article.user} />
            </div>
          </>
        )}
        <div className="flex-grow-1 overflow-hidden">
          <Stack direction="horizontal" gap={1} className="small">
            <CategoryBadge
              category={article.category}
              contentRef={articleRef(article.id)}
            />
            <ArticleTypeBadge article={article} />
            {showProfileDisplayName && (
              <div className="text-truncate">
                <UserNameLink profile={article.user} className="text-black" />
              </div>
            )}
            <div className="flex-shrink-0 text-muted">
              <ShapedTimestamp timestamp={article.created_at} />
            </div>
          </Stack>
          <ArticleTitleLink
            article={article}
            className="my-1 text-break text-black text-truncate-3 lh-sm fw-bold"
          />
          <InlineTagList tags={article.tags} size="sm" />
          <Stack
            direction="horizontal"
            gap={2}
            className="align-items-center small"
          >
            <GoodCounter
              value={article.good_count}
              className="text-muted small"
            />
            <BadCounter
              value={article.bad_count}
              className="text-muted small"
            />
            <PVCounter value={article.pv} className="text-muted small" />
            <MessagesCounter
              value={article.comments_count}
              className="text-muted small"
            />
            {article.is_beginner && (
              <BeginnerIcon text={'初心者'} className="text-muted small" />
            )}
            <ReferenceBadge
              referable={article}
              className="small"
              color="muted"
            />
          </Stack>
        </div>
      </Stack>
      {hasRole('mathlog_admin') && articleForAdmin && profileForAdmin && (
        <div className="mt-1 text-muted smalls">
          CE: {calcContentEvaluation(articleForAdmin).toFixed(5)}, UE:{' '}
          {calcAuthorEvaluation(profileForAdmin).toFixed(5)}, UP:{' '}
          {calcAuthorPopularity(profileForAdmin).toFixed(5)}, UC:{' '}
          {calcCommunicationScore(profileForAdmin).toFixed(5)}
        </div>
      )}
    </div>
  )
}
