import { Command } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { memo } from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  command: Command
  editor: EditorView
  onClick?: () => void
  children: React.ReactNode
}

const OperationButtonImpl = ({
  editor,
  command,
  onClick: handleClick,
  children,
}: Props) => (
  <Button
    onClick={() => {
      if (handleClick) handleClick()

      // commandの内部でthisを書き換えられることはないのでESLintのエラーを無視する
      // eslint-disable-next-line @typescript-eslint/unbound-method
      command(editor.state, editor.dispatch, editor)
    }}
    size="sm"
    variant="outline-dark"
  >
    {children}
  </Button>
)

export const OperationButton = memo(OperationButtonImpl)
