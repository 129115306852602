import { Alert } from 'react-bootstrap'
import { Designed } from '../../types'

export const BeginnerAlert = (props: Designed) => {
  return (
    <Alert variant="warning" {...props}>
      この著者は初心者として投稿しています。間違いや考慮が足りていない点が含まれている可能性が高いです。見つけたらコメント欄で優しく指摘してあげましょう。
    </Alert>
  )
}
