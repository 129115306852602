import { Fragment, Slice } from 'prosemirror-model'
import {
  Command,
  EditorState,
  TextSelection,
  Transaction,
} from 'prosemirror-state'
import { DecorationSet, EditorView } from 'prosemirror-view'
import { Formula } from '../../compiler'
import { Extractor } from '../../compiler/lexer/extractor'
import { searchKey } from '../plugins/search'
import { schema } from '../schema'
import {
  getBeginOfParagraph,
  getBlockSelectedText,
  getEndOfParagraph,
  getSizeByText,
  mathdownToNode,
  nodeToMathdown,
} from '../utils'

export type BlockOperation = {
  prefix?: string
  suffix?: string
  lineStart?: string
  lineEnd?: string
  placeholder?: string
}

export const createBlockOperationCommand = ({
  prefix = '',
  suffix = '',
  lineStart = '',
  lineEnd = '',
  placeholder = '',
}: BlockOperation): Command => {
  return (
    state: EditorState,
    dispatch?: (tr: Transaction) => void,
    editor?: EditorView
  ) => {
    const tr = state.tr
    const { from, to } = state.selection

    const endOfParagraph = getEndOfParagraph(state.doc, to)
    const beginOfParagraph = getBeginOfParagraph(state.doc, from)
    if (beginOfParagraph === null || endOfParagraph === null)
      throw new Error('Unexpected.')

    const mathdown = getBlockSelectedText(state)
    const splittedText = mathdown.split('\n').slice(0, -1) // 最後に余分に改行が入るので取り除く
    let usedPlaceholder = false
    if (splittedText.length === 1 && splittedText[0] === '') {
      splittedText[0] = placeholder
      usedPlaceholder = true
    }
    const lineProcessedText = splittedText.map((mathdown, index) => {
      const start = lineStart.replace('[n]', `${index + 1}`)
      const end = lineEnd.replace('[n]', `${index + 1}`)
      return `${start}${mathdown}${end}`
    })
    const joinedLineProcessedText = lineProcessedText.join('\n')
    const newNode = mathdownToNode(
      `${prefix}${joinedLineProcessedText}${suffix}`
    )
    const fragment = Fragment.from(newNode)
    tr.replace(
      beginOfParagraph - 1,
      endOfParagraph + 1,
      new Slice(fragment, 0, 0)
    )

    const placeholderStart =
      beginOfParagraph +
      getSizeByText(prefix) +
      getSizeByText(lineStart.replace('[n]', '1'))

    const originalSelected =
      beginOfParagraph +
      getSizeByText(prefix) +
      getSizeByText(joinedLineProcessedText) -
      getSizeByText(lineEnd.replace('[n]', `${lineProcessedText.length}`)) -
      endOfParagraph +
      to

    tr.setSelection(
      TextSelection.create(
        tr.doc,
        usedPlaceholder ? placeholderStart : originalSelected,
        originalSelected
      )
    )
    if (dispatch) dispatch(tr)
    editor?.focus()
    return true
  }
}

export type InlineOperation = {
  prefix?: string
  suffix?: string
  placeholder?: string
}

export const createInlineOperationCommand = ({
  placeholder = '',
  prefix = '',
  suffix = '',
}: InlineOperation): Command => {
  return (
    state: EditorState,
    dispatch?: (tr: Transaction) => void,
    editor?: EditorView
  ) => {
    const { from, to } = state.selection
    const tr = state.tr

    tr.insertText(suffix, to)
    if (from === to) {
      tr.insertText(placeholder, from)
    }
    tr.insertText(prefix, from)

    tr.setSelection(
      TextSelection.create(
        tr.doc,
        from === to ? from + prefix.length : to + prefix.length,
        from === to
          ? to + prefix.length + placeholder.length
          : to + prefix.length
      )
    )
    if (dispatch) dispatch(tr)
    editor?.focus()
    return true
  }
}

export const getReplaceCommand = (
  searchText: string,
  replaceText: string
): Command => {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    if (dispatch === undefined) return false

    const { doc } = state
    let transaction = state.tr

    doc.descendants((node, pos) => {
      if (node.isText) {
        const mathdownContent = node.text
        let startIndex = 0
        let index

        while (
          mathdownContent !== undefined &&
          (index = mathdownContent.indexOf(searchText, startIndex)) > -1
        ) {
          transaction = transaction.replaceWith(
            pos + index,
            pos + index + searchText.length,
            state.schema.text(replaceText)
          )

          startIndex = index + searchText.length
        }
      }
    })

    if (transaction.docChanged) {
      dispatch(transaction)
      return true
    }

    return false
  }
}

export const getBlockSelectedReplacer = (mathdown: string): Command => {
  return (state: EditorState, dispatch?: (tr: Transaction) => void) => {
    const { from, to } = state.selection

    const endOfParagraph = getEndOfParagraph(state.doc, to)
    const beginOfParagraph = getBeginOfParagraph(state.doc, from)

    if (beginOfParagraph === null || endOfParagraph === null)
      throw new Error('Unexpected.')

    const tr = state.tr
    const newNode = mathdownToNode(mathdown)
    const fragment = Fragment.from(newNode)
    tr.replace(
      beginOfParagraph - 1,
      endOfParagraph + 1,
      new Slice(fragment, 0, 0)
    )
    if (dispatch) dispatch(tr)
    return true
  }
}

export const insertH1 = createBlockOperationCommand({
  lineStart: '\n# ',
  placeholder: '特大見出し',
})

export const insertH2 = createBlockOperationCommand({
  lineStart: '\n## ',
  placeholder: '大見出し',
})

export const insertH3 = createBlockOperationCommand({
  lineStart: '\n### ',
  placeholder: '中見出し',
})

export const insertH4 = createBlockOperationCommand({
  lineStart: '\n#### ',
  placeholder: '小見出し',
})

export const insertBlockquote = createBlockOperationCommand({
  placeholder: '引用したい文章',
  lineStart: '> ',
})

export const insertNormalBox = createBlockOperationCommand({
  placeholder: '内容',
  prefix: '\n&&&\n',
  suffix: '\n&&&\n\n',
})

export const insertConjBox = createBlockOperationCommand({
  placeholder: '予想',
  prefix: '\n&&&conj 予想名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertAxmBox = createBlockOperationCommand({
  placeholder: '公理',
  prefix: '\n&&&axm 公理名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertDefBox = createBlockOperationCommand({
  placeholder: '定義',
  prefix: '\n&&&def 定義語句（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertPropBox = createBlockOperationCommand({
  placeholder: '命題',
  prefix: '\n&&&prop 命題名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertFmlBox = createBlockOperationCommand({
  placeholder: '公式',
  prefix: '\n&&&fml 公式名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertLemBox = createBlockOperationCommand({
  placeholder: '補題',
  prefix: '\n&&&lem 補題名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertThmBox = createBlockOperationCommand({
  placeholder: '定理',
  prefix: '\n&&&thm 定理名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertCorBox = createBlockOperationCommand({
  placeholder: '系',
  prefix: '\n&&&cor 対象（省略すると直前の定理）\n',
  suffix: '\n&&&\n\n',
})

export const insertPrfBox = createBlockOperationCommand({
  placeholder: '証明',
  prefix: '\n&&&prf 証明手法（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertExBox = createBlockOperationCommand({
  placeholder: '例',
  prefix: '\n&&&ex 例の名前（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertExcBox = createBlockOperationCommand({
  placeholder: '問題',
  prefix: '\n&&&exc 問題名（任意）\n',
  suffix: '\n&&&\n\n',
})

export const insertRemBox = createBlockOperationCommand({
  placeholder: '注意',
  prefix: '\n&&&rem 注意の見出し\n',
  suffix: '\n&&&\n\n',
})

export const insertUL = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: '- ',
})

export const insertOL = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: '[n]. ',
})

export const insertOLWithParenthesis = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: '([n]) ',
})

export const insertOLWithSquareBrackets = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: '[[n]] ',
})

export const insertRomanOL = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: 'R[n]. ',
})

export const insertRomanOLWithParenthesis = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: '(R[n]) ',
})

export const insertRomanOLWithSquareBracket = createBlockOperationCommand({
  prefix: '\n',
  placeholder: '項目',
  lineStart: '[R[n]] ',
})

export const insertBlockCode = createBlockOperationCommand({
  placeholder: 'ソースコード',
  prefix: '\n```\n',
  suffix: '\n```\n\n',
})

export const insertHr = createBlockOperationCommand({
  prefix: '\n\n------------------\n\n',
})

export const insertTab = createBlockOperationCommand({
  lineStart: '\t',
})

export const removeLineStartTab = (
  state: EditorState,
  dispatch?: (tr: Transaction) => void
): boolean => {
  const { doc, selection } = state
  if (!dispatch) return true

  const from = selection.from
  const $from = doc.resolve(from)

  const lineStart = $from.start()
  const lineText = doc.textBetween(lineStart, from)

  const match = lineText.match(/^(?:\t| {1,4})/)

  if (match === null) return true

  // タブを削除するトランザクションを作成して実行
  const tr = state.tr.delete(lineStart, lineStart + match[0].length)
  dispatch(tr)

  return true
}

export const insertBold = createInlineOperationCommand({
  placeholder: '強調したい文字',
  prefix: '**',
  suffix: '**',
})

export const insertItalic = createInlineOperationCommand({
  placeholder: '斜体にしたい文字',
  prefix: '*',
  suffix: '*',
})

export const insertDel = createInlineOperationCommand({
  placeholder: '取り消したい文字',
  prefix: '~~',
  suffix: '~~',
})

export const insertLink = createInlineOperationCommand({
  placeholder: 'リンクにしたい文字',
  prefix: '[',
  suffix: '](https://mathlog.info)',
})

export const insertUploadingImage = createInlineOperationCommand({
  placeholder: '画像の名前',
  prefix: '![',
  suffix: '](uploading...)',
})

export const insertInlineCode = createInlineOperationCommand({
  placeholder: 'コード',
  prefix: '`',
  suffix: '`',
})

export const getFormulaState = (
  state: EditorState
): { inner: boolean; type: 'display' | 'inline' } => {
  const SELECTION_TO_TOKEN = '[SELECTION_TO]'

  const selection = state.selection
  const tr = state.tr
  tr.insert(selection.to, schema.text(SELECTION_TO_TOKEN))
  const mathdown = nodeToMathdown(tr.doc)
  const formulae: Formula[] = []
  const extracted = Extractor.extract(mathdown, formulae, []).replace(
    /\r\n|\r/g,
    '\n'
  )
  const selectionToIndex = extracted.indexOf(SELECTION_TO_TOKEN)

  if (selectionToIndex >= 0) {
    if (
      (selectionToIndex === 0 || extracted[selectionToIndex - 1] === '\n') &&
      (selectionToIndex + SELECTION_TO_TOKEN.length === extracted.length ||
        extracted[selectionToIndex + SELECTION_TO_TOKEN.length + 1] === '\n')
    ) {
      return { inner: false, type: 'display' }
    } else {
      return { inner: false, type: 'inline' }
    }
  } else {
    const formula = formulae.find((f) => f.text.includes(SELECTION_TO_TOKEN))
    if (formula === undefined) {
      return { inner: false, type: 'inline' }
    }
    if (formula.text[0] === '$') {
      return { inner: true, type: 'inline' }
    } else {
      return { inner: true, type: 'display' }
    }
  }
}

export const getInsertFormulaCommand = (formula: string): Command => {
  return (state, dispatch) => {
    // 選択領域周辺のテキストを取得
    const { from, to } = state.selection
    const beginOfParagraph = getBeginOfParagraph(state.doc, to)
    const endOfParagraph = getEndOfParagraph(state.doc, to)
    if (beginOfParagraph === null || endOfParagraph === null) return false
    const prefixText = state.doc.textBetween(beginOfParagraph, to)
    const suffixText = state.doc.textBetween(to, endOfParagraph)

    // 選択領域の状態（数式内か、インラインかディスプレイかを判定）
    const { type, inner } = getFormulaState(state)

    // 実際に追加する数式を作成
    const tr = state.tr
    const formulaPrefix = !inner ? (type === 'display' ? '$$\n' : '$') : ' '
    const formulaSuffix = !inner ? (type === 'display' ? '\n$$' : '$') : ' '
    const formulaWithAffix = `${formulaPrefix}${formula}${formulaSuffix}`

    // Nodeを作って挿入
    const newNode = mathdownToNode(
      `${prefixText}${formulaWithAffix}${suffixText}`
    )
    const fragment = Fragment.from(newNode)
    tr.replace(
      beginOfParagraph - 1,
      endOfParagraph + 1,
      new Slice(fragment, 0, 0)
    )

    // 選択領域を変更する
    tr.setSelection(
      TextSelection.create(tr.doc, from, to + formulaWithAffix.length)
    )

    // 適用
    if (dispatch) dispatch(tr)
    return true
  }
}

export const getSearchCommand = (query: string, cursor = 0): Command => {
  return (state, dispatch) => {
    if (dispatch)
      dispatch(state.tr.setMeta(searchKey, { query, cursor, active: true }))
    return true
  }
}

export const searchSelectedText: Command = (state, dispatch, view) => {
  const { from, to } = state.selection
  const text = state.doc.textBetween(from, to)
  const command = getSearchCommand(text, from)
  return command(state, dispatch, view)
}

export const proceedNextSearchedText: Command = (state, dispatch) => {
  const searchReplace = searchKey.getState(state)
  if (!searchReplace) return false
  const { query, decorations, cursor, active } = searchReplace
  if (!active) return false
  const decorationsAfterCursor = decorations.find(cursor)
  if (decorationsAfterCursor.length === 0) return false
  if (dispatch)
    dispatch(
      state.tr.setMeta(searchKey, {
        query,
        cursor:
          decorationsAfterCursor.length >= 2
            ? decorationsAfterCursor[0].to + 1
            : 0,
        active: true,
      })
    )
  return true
}

export const proceedPrevSearchedText: Command = (state, dispatch) => {
  const searchReplace = searchKey.getState(state)
  if (!searchReplace) return false
  const { query, decorations, cursor, active } = searchReplace
  if (!active) return false
  const allDecorations = decorations.find()
  if (allDecorations.length === 0) return false
  const decorationsAfterCursor = decorations.find(cursor)
  const prevDecoration =
    decorationsAfterCursor.length === allDecorations.length
      ? allDecorations.slice(-1)[0]
      : allDecorations[
          allDecorations.length - decorationsAfterCursor.length - 1
        ]
  if (dispatch)
    dispatch(
      state.tr.setMeta(searchKey, {
        query,
        cursor: prevDecoration.from,
        active: true,
      })
    )
  return true
}

export const getReplaceSearchedTextCommand = (
  replace: string,
  all = false
): Command => {
  return (state, dispatch) => {
    const searchReplace = searchKey.getState(state)
    if (!searchReplace) return false
    const { query, decorations, cursor, active } = searchReplace
    if (query === '' || query === null || !active) return false
    const tr = state.tr
    if (all) {
      let offset = 0
      decorations.find().forEach(({ from, to }) => {
        if (replace !== '') {
          tr.replaceWith(from - offset, to - offset, state.schema.text(replace))
        } else {
          tr.delete(from - offset, to - offset)
        }
        offset += to - from - replace.length
      })
      tr.setMeta(searchKey, { query, cursor: 0, active: true })
    } else {
      const afterCursorDecorations = decorations.find(cursor)
      if (afterCursorDecorations.length > 0) {
        const { from, to } = afterCursorDecorations[0]
        if (replace !== '') {
          tr.replaceWith(from, to, state.schema.text(replace))
        } else {
          tr.delete(from, to)
        }
      }
      tr.setMeta(searchKey, {
        query,
        cursor:
          afterCursorDecorations.length >= 2
            ? afterCursorDecorations[0].from + replace.length
            : 0,
        active: true,
      })
    }
    if (dispatch) dispatch(tr)
    return true
  }
}

export const deactivateSearch: Command = (state, dispatch) => {
  if (dispatch)
    dispatch(
      state.tr.setMeta(searchKey, {
        query: null,
        cursor: 0,
        currentIndex: 0,
        active: false,
        decorations: DecorationSet.empty,
      })
    )
  return true
}
