import { FormulaButtonData } from '../type'

const logic: FormulaButtonData[] = [
  {
    formula: '\\rightarrow',
    tooltip: '含意1',
  },
  {
    formula: '\\Rightarrow',
    tooltip: '含意2',
  },
  {
    formula: '\\Longrightarrow',
    tooltip: '含意3',
  },
  {
    formula: '\\leftarrow',
    tooltip: '含意（逆向き）1',
  },
  {
    formula: '\\Leftarrow',
    tooltip: '含意（逆向き）2',
  },
  {
    formula: '\\Longleftarrow',
    tooltip: '含意（逆向き）3',
  },
  {
    formula: '\\leftrightarrow',
    tooltip: '同値1',
  },
  {
    formula: '\\Leftrightarrow',
    tooltip: '同値2',
  },
  {
    formula: '\\Longleftrightarrow',
    tooltip: '同値3',
  },
  {
    formula: '\\therefore',
    tooltip: 'よって',
  },
  {
    formula: '\\because',
    tooltip: 'なぜならば',
  },
  {
    formula: '\\forall',
    tooltip: '全称記号',
  },
  {
    formula: '\\exists',
    tooltip: '存在記号',
  },
  {
    formula: '\\nexists',
    tooltip: '非存在記号',
  },
  {
    formula: '\\land',
    tooltip: '論理積',
  },
  {
    formula: '\\lor',
    tooltip: '論理和',
  },
  {
    formula: '\\lnot',
    tooltip: '否定',
  },
  {
    formula: '\\overline{P}',
    tooltip: '否定2',
  },
  {
    formula: '\\oplus',
    tooltip: '排他的論理和',
  },
  {
    formula: '\\veebar',
    tooltip: '排他的論理和2',
  },
  {
    formula: '\\top',
    tooltip: 'トートロジー',
  },
  {
    formula: '\\bot',
    tooltip: '矛盾',
  },
  {
    formula: '\\vdash',
    tooltip: '証明可能',
  },
  {
    formula: '\\models',
    tooltip: '論理的帰結',
  },
]

export const logicPill = {
  pill: 'logic',
  label: '論理',
  buttonData: logic,
}
