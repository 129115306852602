import { FormulaButtonData } from '../type'

const multipleLines: FormulaButtonData[] = [
  {
    formula:
      '\\begin{eqnarray}\n\t\\left\\{\n\t\t\\begin{array}{l}\n\t\t\t\\cdots \\\\\n\t\t\t\\cdots\n\t\t\\end{array}\n\t\\right.\n\\end{eqnarray}',
    tooltip: '複数行',
    mobileFontSize: 12,
  },
]

export const multipleLinesPill = {
  pill: 'multiple_lines',
  label: '複数行',
  buttonData: multipleLines,
}
