import { Plugin } from 'prosemirror-state'

export const focusPlugin = (handleFocus?: (focused: boolean) => void) => {
  return new Plugin({
    props: {
      handleDOMEvents: {
        focus: () => {
          if (handleFocus) handleFocus(true)
          return false
        },
        blur: () => {
          if (handleFocus) handleFocus(false)
          return false
        },
      },
    },
  })
}
