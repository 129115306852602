import { useEffect, useState } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { FaChevronLeft, FaSearch } from 'react-icons/fa'
import { useAuthState } from '../firebase/hooks'
import { useMedia, useQueryData } from '../hooks'
import { Designed, PromiseVoid } from '../types'
import { isBlank, logEvent } from '../utils'

type Props = {
  defaultKeyword?: string
  onSearch: (keyword: string) => PromiseVoid
  onExpand?: () => PromiseVoid
  onShrink?: () => PromiseVoid
  variant?: 'dark' | 'light'
}

export const SearchField = ({
  defaultKeyword = '',
  onSearch: handleSearch,
  onExpand: handleExpand,
  onShrink: handleShrink,
  className,
  style,
  variant = 'dark',
}: Designed<Props>) => {
  const isMd = useMedia('md', false)
  const [keyword, setKeyword] = useState(defaultKeyword)
  const queryKeyword = useQueryData('keyword')
  const [showField, setShowField] = useState(false)
  const { user } = useAuthState()

  useEffect(() => {
    if (keyword === '' && !isBlank(queryKeyword) && queryKeyword !== '') {
      setKeyword(queryKeyword)
    }
  }, [queryKeyword])

  return (
    <div
      style={{ maxWidth: isMd ? 500 : undefined, ...style }}
      className={className}
    >
      {isMd || showField ? (
        <Form
          onSubmit={async (e) => {
            e.preventDefault()
            setShowField(false)
            if (handleShrink) await handleShrink()
            await handleSearch(keyword)
            logEvent('search', {
              search_term: keyword,
              uid: user?.uid,
            })
          }}
        >
          <InputGroup className="w-100">
            {!isMd && (
              <Button
                type="button"
                variant={variant}
                className={
                  variant === 'dark'
                    ? 'bg-dark text-white border-muted'
                    : 'bg-white text-black border-muted'
                }
                onClick={async () => {
                  setShowField(false)
                  if (handleShrink) await handleShrink()
                }}
              >
                <FaChevronLeft />
              </Button>
            )}
            <FormControl
              size="sm"
              className={
                variant === 'dark'
                  ? 'bg-dark text-white border-muted'
                  : 'bg-white text-black border-muted'
              }
              style={{ boxShadow: 'none' }}
              value={keyword}
              placeholder="キーワードを入力"
              onChange={(e) => setKeyword(e.currentTarget.value)}
              autoFocus
            />
            <Button
              disabled={keyword === ''}
              type="submit"
              variant={variant}
              className={
                variant === 'dark'
                  ? 'bg-dark text-white border-muted'
                  : 'bg-white text-black border-muted'
              }
            >
              <FaSearch />
            </Button>
          </InputGroup>
        </Form>
      ) : (
        <div
          className="d-flex justify-content-end cursor-pointer"
          onClick={async () => {
            if (handleExpand) await handleExpand()
            setShowField(true)
          }}
        >
          <FaSearch
            className={
              variant === 'dark' ? 'text-white fs-4' : 'text-black fs-4'
            }
          />
        </div>
      )}
    </div>
  )
}
