import {
  insertOL,
  insertOLWithParenthesis,
  insertOLWithSquareBrackets,
  insertRomanOL,
  insertRomanOLWithParenthesis,
  insertRomanOLWithSquareBracket,
  insertUL,
} from '../../../../../commands'
import { OffcanvasItemData } from '../../OffcanvasItem'

export const listOffcanvasItemData: OffcanvasItemData[] = [
  {
    command: insertUL,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          <div>・ー</div>
          <div>・ー</div>
        </div>
      </div>
    ),
    menuTitle: '数字なし',
    menuDescription: 'シンプルな箇条書きのリスト。',
  },
  {
    command: insertOL,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-1">
          <div>1 ー</div>
          <div>2 ー</div>
        </div>
      </div>
    ),
    menuTitle: '通常数字あり',
    menuDescription: '通常の数字のリスト。',
  },
  {
    command: insertOLWithParenthesis,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-sm text-end">
          <div>(1)ー</div>
          <div>(2)ー</div>
        </div>
      </div>
    ),
    menuTitle: '丸括弧・通常数字あり',
    menuDescription: '丸括弧で括られた通常の数字のリスト。',
  },
  {
    command: insertOLWithSquareBrackets,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-sm text-end">
          <div>[1]ー</div>
          <div>[2]ー</div>
        </div>
      </div>
    ),
    menuTitle: '角括弧・通常数字あり',
    menuDescription: '角括弧で括られた通常の数字のリスト。',
  },
  {
    command: insertRomanOL,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-sm text-end">
          <div>ⅰー</div>
          <div>ⅱー</div>
        </div>
      </div>
    ),
    menuTitle: 'ローマン数字あり',
    menuDescription: 'ローマン数字のリスト。',
  },
  {
    command: insertRomanOLWithParenthesis,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-sm text-end">
          <div>(ⅰ)ー</div>
          <div>(ⅱ)ー</div>
        </div>
      </div>
    ),
    menuTitle: '丸括弧・ローマン数字あり',
    menuDescription: '丸括弧で括られたローマン数字のリスト。',
  },
  {
    command: insertRomanOLWithSquareBracket,
    menuIcon: (
      <div className="ratio ratio-1x1" style={{ width: 30 }}>
        <div className="d-flex flex-column justify-content-center align-items-center fs-7 fw-bold lh-sm text-end">
          <div>[ⅰ]ー</div>
          <div>[ⅱ]ー</div>
        </div>
      </div>
    ),
    menuTitle: '角括弧・ローマン数字あり',
    menuDescription: '角括弧で括られたローマン数字のリスト。',
  },
]
