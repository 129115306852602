import { getDoc } from 'firebase/firestore'
import { useCallback, useState } from 'react'
import { Profile, profileRef } from '../../firebase'
import { chunkArray } from '../../utils'

const COLLABORATOR_LIMIT = 20
export const useCollaborators = (collaboratorUids: string[]) => {
  const chunkCollaborators = chunkArray(collaboratorUids, COLLABORATOR_LIMIT)

  const [collaborators, setCollaborators] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)
  const [currentChunk, setCurrentChunk] = useState(0)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    if (
      chunkCollaborators.length === 0 ||
      chunkCollaborators[currentChunk].length === 0
    )
      return

    setFetching(true)
    const loadedCollaborators = await Promise.all(
      chunkCollaborators[currentChunk].map(async (uid) => {
        const profileSnapshot = await getDoc(profileRef(uid))
        return profileSnapshot.data()
      })
    )

    const filteredLoadedCollaborators = loadedCollaborators.filter(
      (c) => c !== undefined
    ) as Profile[]

    setCollaborators([...collaborators, ...filteredLoadedCollaborators])
    if (currentChunk === chunkCollaborators.length - 1) {
      setIsLast(true)
      return
    }

    setCurrentChunk(currentChunk + 1)
    setFetching(false)
  }, [isLast, fetching, collaborators])

  return { loadMore, collaborators, isLast, fetching }
}
