import { MessageForm } from '../../assets/message'
import { Comment, DocRef, Reply } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'

type Props = {
  onCancel: () => PromiseVoid
  onSave?: (replyRef: DocRef<Reply>) => PromiseVoid
  comment: Comment
  reply?: Reply
}

export const ReplyForm = ({
  reply,
  comment,
  ...otherProps
}: Designed<Props>) => (
  <MessageForm
    type="reply"
    parentRef={comment.ref}
    message={reply}
    {...otherProps}
  />
)
