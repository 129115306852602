import { Nav } from 'react-bootstrap'
import { IconType } from 'react-icons'
import { FaBook, FaCog, FaPencilAlt } from 'react-icons/fa'
import { EditorTypeName } from '.'
import { Designed, PromiseVoid } from '../../../types'
import { IconWrapper } from '../../IconWrapper'

type Props = {
  activeEditorType: EditorTypeName
  onChange: (activeEditorType: EditorTypeName) => PromiseVoid
}

export type EditorType = {
  name: EditorTypeName
  displayname: string
  icon: IconType
}

export const EditorTypes: EditorType[] = [
  {
    name: 'mathdown',
    displayname: '本文',
    icon: FaPencilAlt,
  },
  {
    name: 'references',
    displayname: '参考文献',
    icon: FaBook,
  },
  {
    name: 'macros',
    displayname: 'マクロ',
    icon: FaCog,
  },
]

export const EditorTypeChangeNav = ({
  activeEditorType,
  onChange: handleChange,
  ...wrapperProps
}: Designed<Props>) => (
  <Nav variant="pills" {...wrapperProps}>
    {EditorTypes.map(({ name, displayname, icon: Icon }) => (
      <Nav.Item key={name}>
        <Nav.Link
          active={activeEditorType === name}
          onClick={() => handleChange(name)}
        >
          <IconWrapper suffix={displayname}>
            <Icon />
          </IconWrapper>
        </Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
)
