import {} from 'firebase/firestore'
import { useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useForm } from 'react-hook-form'
import { BsMegaphoneFill } from 'react-icons/bs'
import {
  DocRef,
  REPORT_TYPES,
  ReportType,
  Reportable,
  addDoc,
  getReportTypeDescription,
  reportsRef,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { reportRefByUser } from '../../models/report'
import { Designed } from '../../types'
import { IconWrapper } from '../IconWrapper'
import { usePopupMessage } from '../messenger'

type Props = {
  targetUid: string
  label?: string
  size?: 'sm' | 'lg'
  reportableRefWithoutConverter: DocRef<Reportable>
}

export const ReportButton = ({
  targetUid,
  reportableRefWithoutConverter,
  label,
  ...otherProps
}: Designed<Props>) => {
  const { user } = useAuthState()
  const { setPopupMessage } = usePopupMessage()
  const [reports, loadingReport] = useCollectionData(
    user ? reportRefByUser(user.uid, reportableRefWithoutConverter) : null
  )

  const [show, setShow] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{
    body: string
    type: ReportType
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  if (!user || loadingReport || reports === undefined) return <></>
  return (
    <>
      <Button
        variant="link"
        onClick={reports.length === 0 ? () => setShow(true) : undefined}
        {...otherProps}
      >
        {reports.length === 0 ? (
          <IconWrapper suffix={label}>
            <BsMegaphoneFill />
          </IconWrapper>
        ) : (
          <>通報済み</>
        )}
      </Button>
      <Modal show={show} onHide={() => setShow(false)} scrollable>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">コンテンツの通報</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning" className="py-2">
            数学的な間違いや誤植に関しては通報ではなく、コメントで著者にわかるようにお伝えしていただければ幸いです。
          </Alert>
          <Form.Group className="mb-4">
            <Form.Label>種別</Form.Label>
            <div className="d-flex flex-column gap-2">
              {REPORT_TYPES.map((type) => (
                <Form.Check
                  key={type}
                  type="radio"
                  {...register('type', {
                    required: true,
                  })}
                  value={type}
                  label={getReportTypeDescription(type)}
                  id={type}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>詳細</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              {...register('body', { required: true })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={!isValid}
            onClick={handleSubmit(async (values) => {
              await addDoc(reportsRef, {
                target_uid: targetUid,
                target_ref: reportableRefWithoutConverter,
                created_by: user.uid,
                body: values.body !== '' ? values.body : undefined,
                type: values.type,
              })
              setShow(false)
              setPopupMessage('通報しました。')
            })}
          >
            送信
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
