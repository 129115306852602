import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { FormEventHandler, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { FaCircleCheck } from 'react-icons/fa6'
import { useAccount } from '../../context/account'
import { fb } from '../../firebase'
import { Interval } from '../../shared/stripe'
import { PromiseVoid } from '../../types'
import { isBlank, logEvent } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { Price } from '../Price'
import { usePopupMessage } from '../messenger'
import { LoadingSpinner, SpinnerButton } from '../spinners'

type Props = {
  defaultInterval?: Interval
  onSubmit?: () => PromiseVoid
}

export const PremiumPlanPaymentForm = ({
  defaultInterval,
  onSubmit: providedHandleSubmit,
}: Props) => {
  const stripe = useStripe()
  const elements = useElements()
  const [interval, setInterval] = useState<Interval>(
    defaultInterval ?? 'yearly'
  )
  const [errorMessage, setErrorMessage] = useState<string>()
  const { account } = useAccount()
  const [submitting, setSubmitting] = useState(false)
  const { setPopupMessage } = usePopupMessage()

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    setSubmitting(true)
    event.preventDefault()
    const cardElement = elements?.getElement(CardNumberElement)
    if (stripe === null || isBlank(cardElement)) return
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })
    if (error !== undefined) {
      setErrorMessage(error.message)
      setSubmitting(false)
      return
    }
    if (paymentMethod === undefined)
      throw new Error('payment method is missing.')
    try {
      await fb.call('subscribePremiumPlan')({
        payment_method_id: paymentMethod.id,
        interval,
      })
    } catch {
      setSubmitting(false)
      setErrorMessage(
        'カード情報が不正であるか、お支払い限度額を超過しています。'
      )
      return
    }
    setPopupMessage('プレミアムプランへのお申し込みが完了しました。')
    if (providedHandleSubmit) await providedHandleSubmit()
    setSubmitting(false)
  }

  if (!account) return <LoadingSpinner />

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex flex-column gap-6">
        <div className="d-flex flex-column gap-4">
          <FormGroup>
            <label className="mb-1 fw-bold small">プラン選択</label>
            <div className="d-flex align-items-stretch bg-light-gray rounded">
              <div
                onClick={() => setInterval('yearly')}
                className={
                  interval === 'yearly'
                    ? 'text-center col py-2 border border-2 border-primary rounded bg-white'
                    : 'text-center col py-2 text-muted cursor-pointer'
                }
              >
                <h3 className="fs-7 fw-bold m-0">年払いプラン</h3>
                <Price price={980} size={4} />
                <div className="small fs-7">(年額 ¥11,760)</div>
              </div>
              <div
                onClick={() => setInterval('monthly')}
                className={
                  interval === 'monthly'
                    ? 'text-center col py-2 border border-2 border-primary rounded bg-white'
                    : 'text-center col py-2 text-muted cursor-pointer'
                }
              >
                <h3 className="fs-7 fw-bold m-0">月払いプラン</h3>
                <Price price={1480} size={4} />
                <div className="small fs-7">(年額 ¥17,760)</div>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="mb-1 fw-bold small">カード情報</label>
            <CardNumberElement className="form-control py-3 mb-2" />
            <div className="d-block d-sm-flex gap-2">
              <CardExpiryElement className="col form-control py-3 mb-2 mb-sm-0" />
              <CardCvcElement className="col form-control py-3" />
            </div>
            {errorMessage !== undefined && (
              <Form.Text className="text-danger">{errorMessage}</Form.Text>
            )}
            <div className="d-flex flex-column gap-1 mt-4 small">
              {account.has_tried_premium_plan ? (
                <ul
                  className="mb-0 ps-0"
                  style={{
                    listStyle: 'none',
                  }}
                >
                  <li className="position-relative ps-5">
                    <span
                      className="position-absolute start-0"
                      style={{ color: '#2c90d3' }}
                    >
                      <FaCheckCircle className="align-text-top" />
                    </span>
                    {interval === 'monthly'
                      ? '即時で一ヶ月分決済されます。'
                      : '即時で一年分決済されます。'}
                  </li>
                  <li className="position-relative ps-5 py-1">
                    <span
                      className="position-absolute start-0"
                      style={{ color: '#2c90d3' }}
                    >
                      <FaCheckCircle className="align-text-top" />
                    </span>
                    {interval === 'monthly'
                      ? '次回のお支払いは一ヶ月後です。'
                      : '次回のお支払いは一年後です。'}
                  </li>
                  <li className="position-relative ps-5">
                    <span
                      className="position-absolute start-0"
                      style={{ color: '#2c90d3' }}
                    >
                      <FaCheckCircle className="align-text-top" />
                    </span>
                    いつでも解約できます。
                  </li>
                </ul>
              ) : (
                <>
                  <IconWrapper suffix="初回のお支払いは1週間後です。">
                    <FaCircleCheck className="text-primary flex-shrink-0" />
                  </IconWrapper>
                  <IconWrapper suffix="1週間以内に解約した場合は料金が発生しません。">
                    <FaCircleCheck className="text-primary flex-shrink-0" />
                  </IconWrapper>
                </>
              )}
            </div>
          </FormGroup>
        </div>
        <div>
          <SpinnerButton
            loading={submitting}
            className="w-100"
            type="submit"
            onClick={() => {
              account.has_tried_premium_plan === true
                ? // プランの購入ボタンが押された際のイベント収集
                  logEvent('tap_popup_subscribe')
                : // 無料体験の開始ボタンが押された際のイベント収集
                  logEvent('tap_popup_free_trial')
            }}
          >
            {account.has_tried_premium_plan === true
              ? 'プランを開始'
              : '1週間無料体験を開始'}
          </SpinnerButton>
        </div>
      </div>
    </form>
  )
}
