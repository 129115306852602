export const environments: string[] = [
  'align',
  'alignat',
  'aligned',
  'alignedat',
  'array',
  'bmatrix',
  'Bmatrix',
  'bsmallmatrix',
  'Bsmallmatrix',
  'cases',
  'CD',
  'crampedsubarray',
  'dcases',
  'drcases',
  'empheq',
  'eqnarray',
  'equation',
  'flalign',
  'gather',
  'gathered',
  'lgathered',
  'matrix',
  'multline',
  'multlined',
  'numcases',
  'pmatrix',
  'prooftree',
  'psmallmatrix',
  'rcases',
  'rgathered',
  'smallmatrix',
  'split',
  'spreadlines',
  'subarray',
  'subnumcases',
  'vmatrix',
  'Vmatrix',
  'vsmallmatrix',
  'Vsmallmatrix',
  'xalignat',
  'xxalignat',
]
