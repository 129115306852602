import { LuHeading1, LuHeading2, LuHeading3, LuHeading4 } from 'react-icons/lu'
import { insertH1, insertH2, insertH3, insertH4 } from '../../../../../commands'
import { OffcanvasItemData } from '../../OffcanvasItem'

export const headingOffcanvasItemData: OffcanvasItemData[] = [
  {
    command: insertH1,
    menuIcon: <LuHeading1 />,
    menuTitle: '特大見出し',
    menuDescription: '最も大きい見出し。目次に挿入される。',
  },
  {
    command: insertH2,
    menuIcon: <LuHeading2 />,
    menuTitle: '大見出し',
    menuDescription: '大きい見出し。目次に挿入される。',
  },
  {
    command: insertH3,
    menuIcon: <LuHeading3 />,
    menuTitle: '中見出し',
    menuDescription: '中くらいの見出し。',
  },
  {
    command: insertH4,
    menuIcon: <LuHeading4 />,
    menuTitle: '子見出し',
    menuDescription: '小さめの見出し。',
  },
]
