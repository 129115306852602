import { EditorView } from 'prosemirror-view'
import { memo, useState } from 'react'
import {
  Offcanvas as BootstrapOffcanvas,
  Button,
  ListGroup,
} from 'react-bootstrap'
import { OffcanvasItem, OffcanvasItemData } from './OffcanvasItem'

type Props = {
  menuData: OffcanvasItemData[]
  editor: EditorView
  menuStyle: React.CSSProperties
  children: React.ReactNode
  menuClassName: string
}

const OffcanvasImpl = ({
  children,
  menuData,
  editor,
  menuStyle,
  menuClassName,
}: Props) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <>
      <Button
        variant={show ? 'dark' : 'outline-dark'}
        size="sm"
        onClick={handleShow}
      >
        {children}
      </Button>
      <BootstrapOffcanvas
        show={show}
        onHide={handleClose}
        style={{ height: '50vh' }}
        placement="bottom"
      >
        <BootstrapOffcanvas.Body>
          <ListGroup
            variant="flush"
            className={menuClassName}
            style={menuStyle}
          >
            {menuData.map(({ ...props }, index) => (
              <OffcanvasItem
                key={index}
                editor={editor}
                onClick={() => {
                  setShow(false)
                }}
                {...props}
              />
            ))}
          </ListGroup>
        </BootstrapOffcanvas.Body>
      </BootstrapOffcanvas>
    </>
  )
}

export const Offcanvas = memo(OffcanvasImpl)
