import YouTube from 'react-youtube'

type Props = {
  youtube_code: string
}

export const YouTubePlayer = ({ youtube_code }: Props) => {
  return (
    <YouTube
      videoId={youtube_code}
      className="w-100 ratio ratio-16x9 my-6"
      iframeClassName="w-100 h-100"
    />
  )
}
