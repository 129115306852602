import * as renderer from '../../renderer/tex'
import { TexImage, Token } from '../../types'

/**
 * Parse Inline Tokens
 */
export const parseInlineToTex = (
  tokens: Token[],
  labels: Map<string, string> = new Map(),
  images: Map<string, TexImage> = new Map()
): string => {
  let out = ''
  for (const token of tokens) {
    switch (token.type) {
      case 'escape': {
        out += renderer.text(token.text)
        break
      }
      case 'tag': {
        out += renderer.tag(token.text, images)
        break
      }
      case 'link': {
        out += renderer.link(token.href)
        break
      }
      case 'image': {
        out += renderer.image(
          token.href,
          token.title,
          parseInlineToTex(token.tokens, labels, images),
          images
        )
        break
      }
      case 'strong': {
        out += renderer.strong(parseInlineToTex(token.tokens, labels, images))
        break
      }
      case 'em': {
        out += renderer.em(parseInlineToTex(token.tokens, labels, images))
        break
      }
      case 'codespan': {
        out += renderer.codespan(token.text)
        break
      }
      case 'br': {
        out += renderer.br()
        break
      }
      case 'del': {
        out += renderer.del(parseInlineToTex(token.tokens, labels, images))
        break
      }
      case 'formula': {
        out += renderer.formula(token.text)
        break
      }
      case 'shortened_link': {
        out += renderer.shortenedLink(token.title, labels)
        break
      }
      case 'html': {
        out += renderer.inlineHtml(
          parseInlineToTex(token.tokens, labels, images)
        )
        continue
      }
      case 'text': {
        out += renderer.text(token.text)
        break
      }
      default: {
        const errMsg = 'Token with "' + token.type + '" type was not found.'
        console.error(errMsg)
        return ''
      }
    }
  }
  return out
}
