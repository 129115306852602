import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'
import {
  getDoc,
  getDocs,
  Profile,
  profileRef,
  TagContributor,
} from '../../firebase'
import { sortedTagContributorsRef } from '../../models/follow'

const CONTRIBUTORS_LIMIT = 20

export const useTagContributors = (tagId: string) => {
  const lastContributor = useRef<QueryDocumentSnapshot<TagContributor> | null>(
    null
  )
  const [contributors, setContributors] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedContributorsSnapshot = await getDocs(
      sortedTagContributorsRef(
        tagId,
        lastContributor.current,
        CONTRIBUTORS_LIMIT
      )
    )
    const loadedContributorDocs = loadedContributorsSnapshot.docs
    const loadedContributors = await Promise.all(
      loadedContributorDocs.map(async (doc) => {
        const tagId = doc.id
        const profileSnapshot = await getDoc(profileRef(tagId))
        const user = profileSnapshot.data()
        return user
      })
    )
    const filteredLoadedContributors = loadedContributors.filter(
      (user) => user !== undefined
    ) as Profile[] // undefinedを除去したのでasを使って型を強制変更
    setContributors([...contributors, ...filteredLoadedContributors])
    if (!loadedContributorsSnapshot.empty) {
      lastContributor.current = loadedContributorDocs.slice(-1)[0]
    }
    if (loadedContributorDocs.length < CONTRIBUTORS_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [contributors, fetching, isLast])

  return { loadMore, contributors, isLast, fetching }
}
