export const table = (
  header: string,
  body: string,
  align: ('center' | 'left' | 'right' | null)[]
) =>
  `\\begin{table}[htbp]
    \\centering
    \\begin{tabular}{|${align
      .map((a) => (a ? `${a.substring(0, 1)}|` : 'c|'))
      .join('')}}
        \\hline
        ${header} \\hline
        ${body}
        \\hline
    \\end{tabular}
\\end{table}`.replace(/%/g, '\\%')
