import { FormulaButtonData } from '../type'

const algebra: FormulaButtonData[] = [
  {
    formula: '\\boldsymbol{ a }',
    tooltip: '太字',
  },
  {
    formula: '\\overrightarrow{ a }',
    tooltip: 'ベクトル',
  },
  {
    displayFormula: '\\sum',
    formula: '\\sum_{i=0}^{n}',
    tooltip: '総和',
    mobileFontSize: 12,
  },
  {
    displayFormula: '\\prod',
    formula: '\\prod_{i=0}^{n}',
    tooltip: '総乗',
    mobileFontSize: 12,
  },
  {
    formula: '\\begin{pmatrix}\n\ta & b \\\\\n\tc & d\n\\end{pmatrix}',
    tooltip: '行列（丸かっこ）',
    fontSize: 12,
    mobileFontSize: 10,
  },
  {
    formula: '\\begin{bmatrix}\n\ta & b \\\\\n\tc & d\n\\end{bmatrix}',
    tooltip: '行列（角かっこ）',
    fontSize: 12,
    mobileFontSize: 10,
  },
  {
    formula: '\\begin{vmatrix}\n\ta & b \\\\\n\tc & d\n\\end{vmatrix}',
    tooltip: '行列（縦線）',
    fontSize: 12,
    mobileFontSize: 10,
  },
  {
    formula: 'A^{\\mathrm{T}}',
    tooltip: '転置行列1',
  },
  {
    formula: '{}^t \\! A',
    tooltip: '転置行列2',
  },
  { formula: '\\dim', tooltip: '次元' },
  {
    formula: '\\mathrm{rank}',
    tooltip: '行列の階数',
  },
  { formula: '\\mathrm{Tr}', tooltip: '対角和' },
  { formula: '\\mathrm{det}', tooltip: '行列式' },
]

export const algebraPill = {
  pill: 'algebra',
  label: '代数学',
  buttonData: algebra,
}
