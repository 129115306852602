import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { DISPLAY_APP_NAME, IS_PROD } from '../utils'

const TWITTER_USER_NAME = '@MathlogOfficial'
const FACEBOOK_APP_ID = '1342202149890724'

export type HeadProps = {
  type?: 'website' | 'article'
  title: string
  description?: string
  keyword?: string
  keywords?: string[]
  image?: string
  path?: string
  index?: boolean
  cardType?: 'summary' | 'summary_large_image'
}

export const Head = ({
  type = 'website',
  title,
  description = '',
  keyword,
  image,
  keywords = [],
  path,
  index = true,
  cardType = 'summary',
}: HeadProps) => {
  if (!IS_PROD) index = false

  const router = useRouter()
  const host = process.env.NEXT_PUBLIC_HOST ?? 'http://localhost:3000'

  const strPath = path ?? router.asPath
  const url = host + strPath

  if (keyword !== undefined) keywords = [keyword, ...keywords]

  const ogpTitle = title
  title += ' | ' + DISPLAY_APP_NAME

  if (image === undefined) {
    image = host + '/logo.png'
  } else if (image.startsWith('/')) {
    image = host + image
  }

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="keywords" content={keywords.join(', ')} />

      {!index && <meta name="robots" content="noindex,nofollow" />}
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
      />
      <link rel="canonical" href={url} />

      <link rel="shortcut icon" href={host + '/favicon.ico'} />
      <link rel="apple-touch-icon" href={host + '/logo.png'} />

      {/* OGP */}
      <meta property="og:title" content={ogpTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={DISPLAY_APP_NAME} />

      {/* Twitter */}
      <meta name="twitter:card" content={cardType} />
      <meta name="twitter:site" content={TWITTER_USER_NAME} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={ogpTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={ogpTitle} />

      {/* Facebook */}
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />

      {/* IOS safari 桁数が多い数字が電話番号化されることの防止 */}
      <meta name="format-detection" content="telephone=no" />

      {/* 広告追加用 */}
      <script
        async
        src={
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4318304326866018'
        }
        crossOrigin="anonymous"
      />
    </NextHead>
  )
}
