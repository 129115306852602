import Link from 'next/link'
import { FaTag } from 'react-icons/fa'
import { Tag } from '../../firebase'
import { ESTag } from '../../shared/elastic_search/scheme/tag'
import { Designed } from '../../types'

type Props = {
  tags: Tag[] | ESTag[]
  size?: 'sm'
}

export const InlineTagList = ({
  tags,
  size,
  ...wrapperProps
}: Designed<Props>) =>
  tags.length !== 0 ? (
    <div {...wrapperProps}>
      <div
        className={`d-flex align-items-center ${
          size === 'sm' ? 'fs-7' : 'fs-6'
        }`}
      >
        <FaTag className="flex-shrink-0 text-muted me-1" />
        <div
          className="d-flex align-items-center overflow-auto scrollbar-none text-muted"
          style={{ whiteSpace: 'nowrap' }}
        >
          {tags.map(({ id, name }, index) => (
            <span key={id}>
              <Link href={`/tags/${id}`} passHref>
                <a className="text-muted">{name}</a>
              </Link>
              {index < tags.length - 1 && <span className="me-1">,</span>}
            </span>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
