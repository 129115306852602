import { baseKeymap } from 'prosemirror-commands'
import { Plugin, PluginKey, TextSelection } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { _bullet } from '../../compiler/rules/block/_bullet'
import { _attribute } from '../../compiler/rules/common/_attribute'
import { RegexEditor } from '../../compiler/rules/utils/regex_editor'
import { getBeginOfParagraph } from '../utils'

const autoCloseMap: Record<string, string> = {
  // open: close
  '(': ')',
  '[': ']',
  '{': '}',
  '"': '"',
}

type State = {
  lastInput: string
}

const key = new PluginKey<State>('autocomplete')

const lineStartReg = new RegexEditor(/^(?:\t| {2})*(?:bull |> ?)/)
  .replace(/bull/g, _bullet)
  .getRegex()

export const inputEnter = (view: EditorView) => {
  const { from } = view.state.tr.selection
  const lineStart = getBeginOfParagraph(view.state.doc, from)
  if (lineStart === null) return false
  const lineText = view.state.tr.doc.textBetween(lineStart, from)

  // 行頭が箇条書きや引用、タブで始まっているか
  const match = lineText.match(lineStartReg)
  const prefix = match
    ? match[0].replace(/\d+/g, (match) => {
        return String(Number(match) + 1)
      })
    : ''

  // デフォルトのEnterの挙動を実行する
  const defaultEnterHandled = baseKeymap['Enter'](
    view.state,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    view.dispatch
  )
  if (!defaultEnterHandled) return false

  // 次の行の先頭に引き継ぐ文字列がない場合は処理を終了
  if (prefix.length === 0) return true

  // 次の行に引き継ぐ文字列がある場合は追加
  const tr = view.state.tr.insertText(prefix)
  view.dispatch(tr)
}

export const inputBackspace = (view: EditorView) => {
  const { from, to } = view.state.selection

  const beforeBeforeBeforeChar = view.state.doc.textBetween(
    from - (from === to ? 3 : 2),
    from - (from === to ? 2 : 1)
  )
  const beforeBeforeChar = view.state.doc.textBetween(
    from - (from === to ? 2 : 1),
    from - (from === to ? 1 : 0)
  )
  const beforeChar = view.state.doc.textBetween(
    from - (from === to ? 1 : 0),
    from
  )
  const afterChar =
    view.state.doc.nodeSize - 2 > to + 1
      ? view.state.doc.textBetween(to, to + 1)
      : ''
  const afterAfterChar =
    view.state.doc.nodeSize - 2 > to + 2
      ? view.state.doc.textBetween(to + 1, to + 2)
      : ''

  const lineStart = getBeginOfParagraph(view.state.doc, from)
  if (lineStart === null) return false
  const lineText = view.state.tr.doc.textBetween(lineStart, from)

  const match = lineText.match(lineStartReg)

  // 開き括弧が削除されたら閉じ括弧も削除されるように
  if (autoCloseMap[beforeChar] === afterChar) {
    const tr = view.state.tr.delete(from, to + 1)
    view.dispatch(tr)
    return true
  }

  // $の中身が削除された時に$も削除する
  if (beforeBeforeChar === '$' && afterChar === '$') {
    if (beforeBeforeBeforeChar === '$' && afterAfterChar === '$') {
      const tr = view.state.tr.delete(from - (from === to ? 2 : 1), to + 2)
      view.dispatch(tr)
    } else {
      const tr = view.state.tr.delete(from - (from === to ? 1 : 0), to + 1)
      view.dispatch(tr)
    }
    return true
  }

  // 箇条書きや引用の開始後に1文字削除した場合は開始に使用したコマンド（1. や> など）も削除するように
  if (match !== null && match[0].length === lineText.length) {
    const tr = view.state.tr.delete(from - match[0].length + 1, from)
    view.dispatch(tr)
  }
}

// プラグインを作成して、追加のテキストを挿入
export const autocompletePlugin = new Plugin({
  key,
  state: {
    init() {
      return {
        lastInput: '',
      }
    },
    apply(tr, prev) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const meta = tr.getMeta(key)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (meta) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        return { lastInput: meta.lastInput }
      }
      return prev
    },
  },
  props: {
    // コマンドで実装してしまうと日本語（「や（など）入力時にも動作してしまうので、実際にTextが変わった時に動作させる
    handleTextInput(view, from, to, text) {
      if (view.composing) return false

      // 入力結果を次の入力時に使用できるように保存する
      const lastInput = key.getState(view.state)?.lastInput ?? ''
      view.dispatch(view.state.tr.setMeta(key, { lastInput: text }))

      // $が入力された場合の補完
      if (text === '$') {
        const tr = view.state.tr.insertText('$ $', from, to)
        view.dispatch(tr)
        view.dispatch(
          view.state.tr.setSelection(
            TextSelection.create(view.state.doc, from + 1, from + 2)
          )
        )
        return true
      }

      // 開き括弧を入力したとき
      if (Object.keys(autoCloseMap).includes(text)) {
        const closingBracket = autoCloseMap[text]
        const tr = view.state.tr.insertText(text + closingBracket, from, to)
        view.dispatch(tr)
        view.dispatch(
          view.state.tr.setSelection(
            TextSelection.create(view.state.doc, from + 1)
          )
        )
        return true
      }

      // 開き括弧の後にユーザが閉じ括弧を入力した場合
      if (from === to && Object.values(autoCloseMap).includes(text)) {
        const openBracket = Object.keys(autoCloseMap).find(
          (key) => autoCloseMap[key] === text
        )
        const beforeText = view.state.doc.textBetween(from - 1, from)
        if (lastInput === openBracket && beforeText === openBracket) {
          view.dispatch(
            view.state.tr.setSelection(
              TextSelection.create(view.state.doc, from + 1)
            )
          )
          return true
        }
      }

      if (text === '>') {
        const lineStart = view.state.tr.doc.resolve(from).start(1)
        const lineText = view.state.tr.doc.textBetween(lineStart, from)

        // HTMLに関する補完
        const openTagReg = new RegexEditor(
          /<([a-zA-Z][\w-]*)(?:attribute)*?\s*$/
        )
          .replace('attribute', _attribute)
          .getRegex()
        const match = openTagReg.exec(lineText)
        if (match !== null) {
          const tagName = match[1]
          const tr = view.state.tr.insertText(`></${tagName}>`, from, to)
          view.dispatch(tr)
          view.dispatch(
            view.state.tr.setSelection(
              TextSelection.create(view.state.doc, from + 1)
            )
          )
          return true
        }
      }

      return false
    },
    handleKeyDown(view, event) {
      if (view.composing) return false

      switch (event.key) {
        case 'Backspace': {
          inputBackspace(view)
          return false
        }
        case 'Enter': {
          inputEnter(view)
          return true
        }
      }
      return false
    },
  },
})
