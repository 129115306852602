import { FormulaButtonData } from '../type'
import { makeFormulaArrayWithAlphabet } from './utils'

const frak: FormulaButtonData[] = makeFormulaArrayWithAlphabet(
  'mathfrak',
  false
)

export const frakPill = {
  pill: 'frak',
  label: 'フラクトゥール',
  buttonData: frak,
}
