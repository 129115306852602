import { memo } from 'react'
import { Alert } from 'react-bootstrap'
import { Macro, Reference } from '../../../../firebase'
import { useMedia } from '../../../../hooks'
import { Designed, PromiseVoid } from '../../../../types'
import { Design } from '../../compiler'
import { MathdownEditorStateProvider } from '../context'
import { WindowMode } from '../types'
import { MobileMathdownEditor } from './mobile'
import { PCMathdownEditor } from './pc'

export type EditorProps = {
  onChange: (currentValue: string) => void
  onSave?: () => PromiseVoid
  design?: Design
  defaultValue?: string
  placeholder?: string
  isInvalid?: boolean
  isValid?: boolean
  macros?: Macro[]
  references?: Reference[]
  windowMode: WindowMode
  spSupported?: boolean
  mobileMenuPosition?: { top?: number; left?: number; right?: number }
  onChangeWindowMode: (windowMode: WindowMode) => PromiseVoid
  room?: string
}

const EditorImpl = ({
  spSupported = true,
  ...props
}: Designed<EditorProps>) => {
  const isMd = useMedia('md', true)
  return (
    <MathdownEditorStateProvider>
      {isMd ? (
        <PCMathdownEditor {...props} />
      ) : spSupported ? (
        <MobileMathdownEditor {...props} />
      ) : (
        <Alert variant="danger">
          モバイルデバイスからの編集はサポートしていません。PCをご利用ください。
        </Alert>
      )}
    </MathdownEditorStateProvider>
  )
}

export const MathdownEditor = memo(EditorImpl)
