import { Accordion, ButtonGroup } from 'react-bootstrap'
import { DeleteMacroButton, EditMacroButton } from '.'
import { Macro } from '../../firebase'
import { Designed } from '../../types'
import { MathJax } from '../mathdown/mathjax'

type Props = {
  macros: Macro[]
  editingId?: string
  activeKeys: string[]
  setActiveKeys: (keys: string[]) => void
  onClickEditButton: (macro: Macro) => void
}

const makeSampleCommand = (macro: Macro) => {
  let str = '\\' + macro.command_name
  for (let i = 0; i < macro.arguments_count; i++) str += `{x${i + 1}}`
  return str
}

export const MacroList = ({
  macros,
  editingId,
  activeKeys,
  setActiveKeys,
  onClickEditButton: handleClickEditButton,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <Accordion activeKey={activeKeys} alwaysOpen {...wrapperProps}>
      {macros.map((macro) => (
        <Accordion.Item key={macro.id} eventKey={macro.id}>
          <Accordion.Header
            onClick={() => {
              const activeKeyIndex = activeKeys.indexOf(macro.id)
              if (activeKeyIndex >= 0) {
                setActiveKeys([
                  ...activeKeys.slice(0, activeKeyIndex),
                  ...activeKeys.slice(activeKeyIndex + 1),
                ])
              } else {
                setActiveKeys([...activeKeys, macro.id])
              }
            }}
          >
            {'\\' + macro.command_name}
            {editingId === macro.id && (
              <span className="text-success">（編集中）</span>
            )}
          </Accordion.Header>
          <Accordion.Body>
            <ul className="mb-4 ps-6">
              <li>引数：{macro.arguments_count}個</li>
              <li>数式：{macro.formula}</li>
              <li>
                使用例
                <ul className="ps-6">
                  <li>入力：{makeSampleCommand(macro)}</li>
                  <li>
                    出力：
                    <MathJax macros={macros} inline>{`$${makeSampleCommand(
                      macro
                    )}$`}</MathJax>
                  </li>
                </ul>
              </li>
            </ul>
            <ButtonGroup>
              <EditMacroButton
                onClickEditButton={() => handleClickEditButton(macro)}
              />
              <DeleteMacroButton macro={macro} />
            </ButtonGroup>
          </Accordion.Body>
        </Accordion.Item>
      ))}
      {macros.length === 0 && (
        <span className="text-muted">マクロは追加されていません。</span>
      )}
    </Accordion>
  )
}
