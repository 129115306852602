import { Button } from 'react-bootstrap'
import { FaTrashAlt } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { usePopupMessage } from '../../assets/messenger'
import { deleteDoc, Macro } from '../../firebase'

type Props = {
  macro: Macro
}

export const DeleteMacroButton = ({ macro }: Props) => {
  const { setPopupMessage } = usePopupMessage()
  return (
    <Button
      type="button"
      size="sm"
      variant="danger"
      onClick={async () => {
        const msg = '本当に削除しますか？'
        if (!window.confirm(msg)) return
        await deleteDoc(macro.ref)
        setPopupMessage('マクロを削除しました。', 'danger')
      }}
    >
      <IconWrapper suffix="削除">
        <FaTrashAlt />
      </IconWrapper>
    </Button>
  )
}
