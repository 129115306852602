import { Profile } from '../firebase'
import { getSigmoidPainless, sigmoid } from './sigmoid'

export const CommunicationScoreIndicator = {
  weights: {
    messages_good_count_sum: 1.0,
    messages_bad_count_sum: -1.0,
    messages_reports_score_sum: -3.0,
    // 追加する場合はcalcAuthorEvaluation関数も更新すること
  },
  sigmoid: {
    a: 1 / 8,
  },
}

export const getCommunicationScorePainless = () => {
  const weightKeys = Object.keys(
    CommunicationScoreIndicator['weights']
  ) as (keyof typeof CommunicationScoreIndicator['weights'])[]
  const x = weightKeys
    .map(
      (key) =>
        `${CommunicationScoreIndicator['weights'][key]} * doc['user.${key}'].value`
    )
    .join(' + ')
  return getSigmoidPainless(x, CommunicationScoreIndicator['sigmoid'])
}

export const calcCommunicationScore = (author: Profile) => {
  const weights = CommunicationScoreIndicator.weights
  const { good_count, bad_count, reports_score } = author.messages_aggregation
  return sigmoid(
    good_count * weights['messages_good_count_sum'] +
      bad_count * weights['messages_bad_count_sum'] +
      reports_score * weights['messages_reports_score_sum'],
    CommunicationScoreIndicator.sigmoid.a
  )
}
