import { useMemo } from 'react'
import { Notification } from '../../firebase'
import { Designed } from '../../types'
import { NotificationItem } from './Item'

type Props = {
  notifications: Notification[]
  readAction?: boolean
  onlyUnread?: boolean
  onlySummary?: boolean
}

export const NotificationList = ({
  notifications,
  readAction = false,
  onlyUnread = false,
  onlySummary = false,
  ...wrapperProps
}: Designed<Props>) => {
  const displayNotifications = useMemo(() => {
    return onlyUnread
      ? notifications.filter((e) => e.status === 'unread')
      : notifications
  }, [onlyUnread, notifications])

  return (
    <div {...wrapperProps}>
      {displayNotifications.map((notification) => (
        <div key={notification.id}>
          <NotificationItem
            notification={notification}
            readAction={readAction}
            onlySummary={onlySummary}
          />
          <hr />
        </div>
      ))}
      {displayNotifications.length === 0 && <>通知はありません。</>}
    </div>
  )
}
