export const makeFormulaArrayWithAlphabet = (
  command: string,
  onlyCaptal = true
) => {
  const upperAlphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowerAlphabets = upperAlphabets.toLowerCase()
  const alphabets = onlyCaptal
    ? upperAlphabets
    : upperAlphabets + lowerAlphabets
  return alphabets.split('').map((alphabet) => {
    return {
      formula: `\\${command}{${alphabet}}`,
    }
  })
}
