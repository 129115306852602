import { useEffect, useState } from 'react'
import { useMedia } from '../../../../hooks'
import { WindowMode } from '../types'

export const useMathdownEditorWindowMode = () => {
  const [windowMode, setWindowMode] = useState<WindowMode>('both')
  const isMd = useMedia('md', true)
  useEffect(() => {
    if (!isMd && windowMode === 'both') setWindowMode('input')
  }, [isMd])
  return { windowMode, setWindowMode }
}
