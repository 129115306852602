import { history } from 'prosemirror-history'
import { keymap } from 'prosemirror-keymap'
import { Selection } from 'prosemirror-state'
import { useMemo } from 'react'
import { Macro } from '../../../../firebase'
import { PromiseVoid } from '../../../../types'
import { autocompletePlugin } from './autocomplete'
import { focusPlugin } from './focus'
import { getMathdownKeymap } from './keymap'
import { getPlaceholderPlugin } from './placeholder'
import { SearchState, searchPlugin } from './search'
import { selectionPlugin } from './selection'
import { useSuggestionsPlugins } from './suggestions'
export type { Suggestions } from './suggestions'

type Props = {
  editorContainerElement: HTMLDivElement | undefined
  macros?: Macro[]
  handleSelection?: (selection: Selection) => void
  handleFocus?: (hasFocused: boolean) => void
  handleSave?: () => PromiseVoid
  handlePreview?: () => PromiseVoid
  handleSearch?: (state: SearchState) => PromiseVoid
}

export const useMathdownPlugins = ({
  editorContainerElement,
  macros,
  handleSelection,
  handleFocus,
  handlePreview,
  handleSearch,
}: Props) => {
  const { plugins: suggestionsPlugins, suggestions } = useSuggestionsPlugins(
    editorContainerElement,
    macros
  )
  const plugins = useMemo(() => {
    return [
      history(),
      ...suggestionsPlugins,
      autocompletePlugin,
      keymap(getMathdownKeymap({ handlePreview })),
      focusPlugin(handleFocus),
      selectionPlugin(handleSelection),
      searchPlugin(handleSearch),
      getPlaceholderPlugin('本文を入力...'),
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    suggestions,
    plugins,
  }
}
