import {
  QueryDocumentSnapshot,
  increment,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'
import {
  Batch,
  Comment,
  CommentDraft,
  Commentable,
  DocRef,
  MessageType,
  Reply,
  ReplyDraft,
  Timestamp,
  bookRef,
  commentDraftsRef,
  commentRefWithoutConverter,
  commentsRef,
  doc,
  getDocs,
  repliesRef,
  replyDraftsRef,
  replyRefWithoutConverter,
} from '../firebase'
import { addDefaultMacos, addMacros, replaceMacros } from './macros'
import { addReferences, replaceReferences } from './references'

export const sortedRepliesRef = (
  commentRef: DocRef<Comment>,
  after: QueryDocumentSnapshot<Reply> | null
) =>
  after
    ? query(repliesRef(commentRef), orderBy('created_at'), startAfter(after))
    : query(repliesRef(commentRef), orderBy('created_at'))

export const sortedRepliesRefWithLimit = (
  commentRef: DocRef<Comment>,
  after: QueryDocumentSnapshot<Reply> | null,
  per: number
) =>
  after
    ? query(
        repliesRef(commentRef),
        orderBy('created_at', 'asc'),
        startAfter(after),
        limit(per)
      )
    : query(repliesRef(commentRef), orderBy('created_at', 'asc'), limit(per))

export const newRepliesRef = (comment: Comment, now: Date) =>
  query(repliesRef(comment.ref), where('created_at', '>=', now))

export const currentUserRepliesRef = (comment: Comment, uid: string) =>
  query(repliesRef(comment.ref), where('created_by', '==', uid))

export const sortedCommentsRef = (
  commentableRef: DocRef<Commentable>,
  after: QueryDocumentSnapshot<Comment> | null
) =>
  after
    ? query(
        commentsRef(commentableRef),
        orderBy('created_at', 'desc'),
        startAfter(after)
      )
    : query(commentsRef(commentableRef), orderBy('created_at', 'desc'))

export const sortedCommentsRefWithLimit = (
  commentableRef: DocRef<Commentable>,
  after: QueryDocumentSnapshot<Comment> | null,
  per: number
) =>
  after
    ? query(
        commentsRef(commentableRef),
        orderBy('created_at', 'desc'),
        startAfter(after),
        limit(per)
      )
    : query(
        commentsRef(commentableRef),
        orderBy('created_at', 'desc'),
        limit(per)
      )

export const newCommentsRef = (commentable: Commentable, now: Date) =>
  query(
    commentsRef(commentable.ref),
    where('created_at', '>=', now),
    orderBy('created_at', 'desc')
  )

export const currentUserCommentsRef = (commentable: Commentable, uid: string) =>
  query(
    commentsRef(commentable.ref),
    where('created_by', '==', uid),
    orderBy('created_at', 'desc')
  )

export const incrementCommentsCount = (
  parentRef: DocRef<Commentable>,
  batch: Batch
) => {
  const path = parentRef.path
  const typeOfDocument = path.split('/')[0]
  let documentId
  let documentRef
  switch (typeOfDocument) {
    case 'books':
      documentId = path.split('/')[1]
      documentRef = bookRef(documentId)
      // 本のインクリメント
      batch.update(documentRef, { comments_count: increment(1) })
      // ページのインクリメント
      batch.update(parentRef, { comments_count: increment(1) })
      return
    case 'articles':
      // 記事のインクリメント
      batch.update(parentRef, { comments_count: increment(1) })
      return
  }
}

export const decrementCommentsCount = (
  parentRef: DocRef<Commentable>,
  batch: Batch
) => {
  const path = parentRef.path
  const typeOfDocument = path.split('/')[0]
  let documentId
  let documentRef
  switch (typeOfDocument) {
    case 'books':
      documentId = path.split('/')[1]
      documentRef = bookRef(documentId)
      // 本のインクリメント
      batch.update(documentRef, { comments_count: increment(-1) })
      // ページのインクリメント
      batch.update(parentRef, { comments_count: increment(-1) })
      return
    case 'articles':
      // 記事のインクリメント
      batch.update(parentRef, { comments_count: increment(-1) })
      return
  }
}

export type MessageParams = {
  body: string
  type: MessageType
}

export const createComment = async (
  uid: string,
  commentableRef: DocRef<Commentable>,
  params: MessageParams
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  const commentRef = batch.add(commentsRef(commentableRef), {
    created_by: uid,
    good_count: 0,
    bad_count: 0,
    replies_count: 0,
    reports_score: 0,
    created_by_user_at: now,
    updated_by_user_at: now,
    ...params,
  })
  incrementCommentsCount(commentableRef, batch)
  await addDefaultMacos(uid, commentRef, batch)
  await batch.commit()
  return commentRef
}

export const publishComment = async (draft: CommentDraft) => {
  const now = Timestamp.now()
  if (draft.publication_ref) {
    const batch = new Batch()
    const publicationRef = draft.publication_ref
    await replaceMacros(draft.ref, publicationRef, batch)
    await replaceReferences(draft.ref, publicationRef, batch)
    batch.update(publicationRef, {
      type: draft.type,
      body: draft.body,
      updated_by_user_at: now,
    })
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  } else {
    const batch = new Batch()
    const uid = draft.ref.parent.parent?.id
    if (uid === undefined) {
      throw new Error('Cloud not specify user.')
    }
    const publicationRef = doc(commentsRef(draft.commentable_ref))
    await addMacros(draft.ref, publicationRef, batch)
    await addReferences(draft.ref, publicationRef, batch)
    batch.create(publicationRef, {
      created_by: uid,
      type: draft.type,
      body: draft.body,
      good_count: 0,
      bad_count: 0,
      reports_score: 0,
      replies_count: 0,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    incrementCommentsCount(draft.commentable_ref, batch)
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  }
}

export const createCommentDraft = async (
  uid: string,
  commentableRef: DocRef<Commentable>,
  params: MessageParams,
  publicationRef?: DocRef<Comment>
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  const commentDraftRef = batch.add(commentDraftsRef(uid), {
    ...params,
    commentable_ref: commentableRef,
    publication_ref: publicationRef,
    created_by_user_at: now,
    updated_by_user_at: now,
  })
  await addDefaultMacos(uid, commentDraftRef, batch)
  await batch.commit()
  return commentDraftRef
}

export const createReply = async (
  uid: string,
  commentRef: DocRef<Comment>,
  params: MessageParams
) => {
  const batch = new Batch()
  const now = Timestamp.now()
  const replyRef = batch.add(repliesRef(commentRef), {
    created_by: uid,
    good_count: 0,
    reports_score: 0,
    created_by_user_at: now,
    updated_by_user_at: now,
    bad_count: 0,
    ...params,
  })
  batch.update(commentRef, { replies_count: increment(1) })
  await addDefaultMacos(uid, replyRef, batch)
  await batch.commit()
  return replyRef
}

export const createReplyDraft = async (
  uid: string,
  commentRef: DocRef<Comment>,
  params: MessageParams,
  publicationRef?: DocRef<Reply>
) => {
  const now = Timestamp.now()
  const batch = new Batch()
  const replyDraftRef = batch.add(replyDraftsRef(uid), {
    ...params,
    comment_ref: commentRef,
    publication_ref: publicationRef,
    created_by_user_at: now,
    updated_by_user_at: now,
  })
  await addDefaultMacos(uid, replyDraftRef, batch)
  await batch.commit()
  return replyDraftRef
}

export const publishReply = async (draft: ReplyDraft) => {
  const now = Timestamp.now()
  if (draft.publication_ref) {
    const batch = new Batch()
    const publicationRef = draft.publication_ref
    await replaceMacros(draft.ref, publicationRef, batch)
    await replaceReferences(draft.ref, publicationRef, batch)
    batch.update(publicationRef, {
      type: draft.type,
      body: draft.body,
      updated_by_user_at: now,
    })
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  } else {
    const batch = new Batch()
    const uid = draft.ref.parent.parent?.id
    if (uid === undefined) {
      throw new Error('Cloud not specify user.')
    }
    const publicationRef = doc(repliesRef(draft.comment_ref))
    await addMacros(draft.ref, publicationRef, batch)
    await addReferences(draft.ref, publicationRef, batch)
    batch.create(publicationRef, {
      created_by: uid,
      type: draft.type,
      body: draft.body,
      good_count: 0,
      bad_count: 0,
      reports_score: 0,
      created_by_user_at: now,
      updated_by_user_at: now,
    })
    batch.update(draft.comment_ref, {
      replies_count: increment(1),
    })
    await batch.delete(draft.ref)
    await batch.commit()
    return publicationRef
  }
}

export const deleteReply = async (reply: Reply, parentRef: DocRef<Comment>) => {
  const batch = new Batch()
  await batch.delete(reply.ref)
  batch.update(parentRef, { replies_count: increment(-1) })
  // 関連する下書きを削除
  const draftsSnapshot = await getDocs(
    query(
      replyDraftsRef(reply.created_by),
      where(
        'publication_ref',
        '==',
        replyRefWithoutConverter(parentRef, reply.id)
      )
    )
  )
  for (const snapshot of draftsSnapshot.docs) await batch.delete(snapshot.ref)
  await batch.commit()
}

export const deleteComment = async (
  comment: Comment,
  parentRef: DocRef<Commentable>
) => {
  const batch = new Batch()
  await batch.delete(comment.ref)
  decrementCommentsCount(parentRef, batch)
  // 関連する下書きを削除
  const draftsSnapshot = await getDocs(
    query(
      commentDraftsRef(comment.created_by),
      where(
        'publication_ref',
        '==',
        commentRefWithoutConverter(parentRef, comment.id)
      )
    )
  )
  for (const snapshot of draftsSnapshot.docs) await batch.delete(snapshot.ref)
  await batch.commit()
}
