import { Command } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { memo } from 'react'
import { Dropdown } from 'react-bootstrap'

type Props = {
  command: Command
  editor: EditorView
  onClick?: () => void
  children: React.ReactNode
}

const OperationItemImpl = ({
  editor,
  command,
  onClick: handleClick,
  children,
}: Props) => (
  <Dropdown.Item
    onClick={() => {
      if (handleClick) handleClick()

      // commandの内部でthisを書き換えられることはないのでESLintのエラーを無視する
      // eslint-disable-next-line @typescript-eslint/unbound-method
      command(editor.state, editor.dispatch, editor)
    }}
  >
    {children}
  </Dropdown.Item>
)

export const OperationItem = memo(OperationItemImpl)
