import { MessageForm } from '../../assets/message'
import { Comment, Commentable, DocRef } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'

type Props = {
  onCancel?: () => PromiseVoid
  onSave?: (commentRef: DocRef<Comment>) => PromiseVoid
  commentable: Commentable
  comment?: Comment
}

export const CommentForm = ({
  comment,
  commentable,
  ...otherProps
}: Designed<Props>) => (
  <MessageForm
    type="comment"
    parentRef={commentable.ref}
    message={comment}
    {...otherProps}
  />
)
