import { Selection } from 'prosemirror-state'
import { createContext, useContext, useState } from 'react'
import { SearchState } from '../plugins/search'

export const MathdownEditorStateContext = createContext<{
  selection: Selection | undefined
  setSelection: (selection: Selection) => void
  focus: boolean
  setFocus: (focus: boolean) => void
  searchState?: SearchState
  setSearchState?: (selection: SearchState) => void
}>({
  selection: undefined,
  focus: false,
  setSelection() {
    throw new Error('not provided')
  },
  setFocus() {
    throw new Error('not provided')
  },
})

export const MathdownEditorStateProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [selection, setSelection] = useState<Selection | undefined>(undefined)
  const [focus, setFocus] = useState(false)
  const [searchState, setSearchState] = useState<SearchState>()
  return (
    <MathdownEditorStateContext.Provider
      value={{
        selection,
        setSelection,
        focus,
        setFocus,
        searchState,
        setSearchState,
      }}
    >
      {children}
    </MathdownEditorStateContext.Provider>
  )
}

export const useMathdownEditorState = () =>
  useContext(MathdownEditorStateContext)
