import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { IconWrapper } from '../../assets/IconWrapper'
import { Page } from '../../firebase'
import { Designed, PromiseVoid } from '../../types'

type Props = {
  pages: Page[]
  currentPageIndex: number
  onClickPrev: () => PromiseVoid
  onClickNext: () => PromiseVoid
}

export const Pagination = ({
  pages,
  currentPageIndex,
  onClickPrev: handleClickPrev,
  onClickNext: handleClickNext,
  ...containerProps
}: Designed<Props>) => (
  <div {...containerProps}>
    <div
      onClick={currentPageIndex !== 0 ? handleClickPrev : undefined}
      className={currentPageIndex === 0 ? 'text-muted' : 'cursor-pointer'}
    >
      <IconWrapper suffix="前のページへ">
        <FaCaretLeft />
      </IconWrapper>
    </div>
    <div>{`${currentPageIndex + 1} / ${pages.length}`}</div>
    <div
      onClick={
        currentPageIndex !== pages.length - 1 ? handleClickNext : undefined
      }
      className={
        currentPageIndex === pages.length - 1 ? 'text-muted' : 'cursor-pointer'
      }
    >
      <IconWrapper prefix="次のページへ">
        <FaCaretRight />
      </IconWrapper>
    </div>
  </div>
)
