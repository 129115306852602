import * as renderer from '../../renderer/tex'
import { TexImage, Token, Tokens } from '../../types'
import { parseInlineToTex } from './inline'

const parseCellToTex = (
  cellTokens: Token[],
  isLastIndex: boolean,
  labels: Map<string, string>,
  images: Map<string, TexImage>
) => {
  return renderer.tablecell(
    parseInlineToTex(cellTokens, labels, images),
    isLastIndex
  )
}

const parseHeaderToTex = (
  header: Tokens.TableCell[],
  labels: Map<string, string>,
  images: Map<string, TexImage>
) => {
  let cell = ''
  for (let cellIndex = 0; cellIndex < header.length; cellIndex++) {
    cell += parseCellToTex(
      header[cellIndex].tokens,
      cellIndex === header.length - 1,
      labels,
      images
    )
  }
  return renderer.tablerow(cell)
}

const parseBodyToTex = (
  rows: Tokens.TableCell[][],
  labels: Map<string, string>,
  images: Map<string, TexImage>
) => {
  let body = ''
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex]
    let cell = ''
    for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
      cell += parseCellToTex(
        row[cellIndex].tokens,
        cellIndex === row.length - 1,
        labels,
        images
      )
    }
    body += renderer.tablerow(cell)
  }
  return body
}

export const parseTableToTex = (
  token: Tokens.Table,
  labels: Map<string, string>,
  images: Map<string, TexImage>
) => {
  const header = parseHeaderToTex(token.header, labels, images)
  const body = parseBodyToTex(token.rows, labels, images)
  return renderer.table(header, body, token.align)
}
