import { FormulaButtonData } from '../type'

const comparisonSymbols: FormulaButtonData[] = [
  { formula: '\\gt', tooltip: '大なり' },
  { formula: '\\geq', tooltip: '大なりイコール1' },
  { formula: '\\geqq', tooltip: '大なりイコール2' },
  { formula: '\\lt', tooltip: '小なり' },
  { formula: '\\leq', tooltip: '小なりイコール1' },
  { formula: '\\leqq', tooltip: '小なりイコール2' },
  { formula: '=', tooltip: '等しい' },
  { formula: '\\neq', tooltip: '等しくない' },
  { formula: '\\equiv', tooltip: '同値' },
  { formula: '\\fallingdotseq', tooltip: 'ほぼ等しい' },
  { formula: '\\sim', tooltip: 'ほぼ等しい2' },
  { formula: '\\simeq', tooltip: 'ほぼ等しい3' },
  { formula: '\\approx', tooltip: 'ほぼ等しい4' },
  { formula: '\\gg', tooltip: '十分大きい' },
  { formula: '\\ll', tooltip: '十分小さい' },
  {
    displayFormula: '\\max',
    formula: '\\max_{x \\in a}',
    tooltip: '最大',
    mobileFontSize: 13,
  },
  {
    displayFormula: '\\min',
    formula: '\\min_{x \\in a}',
    tooltip: '最小',
    mobileFontSize: 13,
  },
  {
    displayFormula: '\\sup',
    formula: '\\sup_{x \\in a}',
    tooltip: '上限',
    mobileFontSize: 13,
  },
  {
    displayFormula: '\\inf',
    formula: '\\inf_{x \\in a}',
    tooltip: '下限',
    mobileFontSize: 13,
  },
  {
    formula: '\\aleph',
    tooltip: 'アレフ数',
  },
]

export const comparisonSymbolsPill = {
  pill: 'comparison_symbols',
  label: '比較記号',
  buttonData: comparisonSymbols,
}
