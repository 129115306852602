import { BookImage, BookTitleLink } from '.'
import { LinkWrapper } from '../../assets/LinkWrapper'
import { Book } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  width: string | number
  book: Book
  gap?: number
}

export const BookImageWithTitle = ({
  width,
  book,
  gap = 2,
  ...wrapperProps
}: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      <div className={`d-flex align-items-center gap-${gap}`}>
        <LinkWrapper href={`/books/${book.id}`} passHref>
          <BookImage book={book} width={width} className="flex-shrink-0" />
        </LinkWrapper>
        <div className="flex-grow-1 overflow-hidden">
          <BookTitleLink book={book} className="text-break text-truncate-3" />
        </div>
      </div>
    </div>
  )
}
