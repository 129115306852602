import Image from 'next/image'
import Link from 'next/link'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { AdsCard } from 'web_core/assets/google_adsense/AdsCard'
import { UserList } from 'web_core/assets/user'
import { useAccount } from 'web_core/context/account'
import { Profile } from 'web_core/firebase'
import { ADSENSE_SLOTS, logEvent } from 'web_core/utils'

type Props = {
  popularProfiles?: Profile[]
}

export const RightSidebar = ({ popularProfiles }: Props) => {
  const { account } = useAccount()
  return (
    <div
      className="flex-shrink-0 d-none d-print-none py-3 d-xl-flex flex-column gap-6 pe-4"
      style={{ width: 320 }}
    >
      {(account === null || account?.plan === 'Free') && (
        <>
          <Link href="/pricing" passHref>
            <a
              onClick={() => {
                // バナーがクリックされた際のイベント収集
                logEvent('tap_default_sidebar_banner')
              }}
            >
              <Image
                src="/images/premium_banner_640x440.png"
                alt="数学の情報共有をもっと円滑に! Mathlog Premiumプラン! 1週間無料体験"
                width={640}
                height={440}
              />
            </a>
          </Link>
          <AdsCard slot={ADSENSE_SLOTS.DEFAULT_RIGHT_SIDEBAR_SLOT} />
        </>
      )}
      {popularProfiles && (
        <div className="p-4 border rounded">
          <h2 className="h5">人気のユーザ</h2>
          <UserList
            imageSize="30px"
            profiles={popularProfiles}
            isFollowButton={false}
          />
        </div>
      )}
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="MathlogOfficial"
        options={{ height: 1000 }}
      />
    </div>
  )
}
