import { query, where } from 'firebase/firestore'
import { DocRef, Reportable, reportsRef } from '../firebase'

export const reportsRefForUser = (targetUserId: string) =>
  query(reportsRef, where('target_uid', '==', targetUserId))

export const reportsRefByUser = (createdBy: string) =>
  query(reportsRef, where('created_by', '==', createdBy))

export const reportRefByUser = (
  createdBy: string,
  // Converterが適用されていると動かない
  targetRef: DocRef<Reportable>
) =>
  query(
    reportsRef,
    where('created_by', '==', createdBy),
    where('target_ref', '==', targetRef)
  )
