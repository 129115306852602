import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

const placeholderKey = new PluginKey('placeholder')

export const getPlaceholderPlugin = (text: string) => {
  return new Plugin({
    key: placeholderKey,
    props: {
      decorations(state) {
        const doc = state.doc
        if (
          doc.childCount === 1 &&
          doc.firstChild?.isTextblock === true &&
          doc.firstChild.content.size === 0
        ) {
          const placeHolder = document.createElement('span')
          placeHolder.className = 'placeholder-text'
          placeHolder.textContent = text
          return DecorationSet.create(doc, [Decoration.widget(1, placeHolder)])
        }
      },
    },
  })
}
