import { Badge } from '../../firebase'
import { Designed } from '../../types'
import { BadgeItem } from './Item'

type Props = {
  badges: Badge[]
  size: string
}

export const BadgeList = ({ badges, size, ...otherProps }: Designed<Props>) => (
  <div {...otherProps}>
    <div className="d-flex gap-2">
      {badges.map((badge) => (
        <BadgeItem key={badge.id} badge={badge} size={size} />
      ))}
      {badges.length === 0 && <>バッジはありません。</>}
    </div>
  </div>
)
