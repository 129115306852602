import { FormulaButtonData } from '../type'
import { makeFormulaArrayWithAlphabet } from './utils'

const scr: FormulaButtonData[] = makeFormulaArrayWithAlphabet('mathscr')

export const scrPill = {
  pill: 'scr',
  label: 'スクリプト',
  buttonData: scr,
}
