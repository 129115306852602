import Link from 'next/link'
import { Button } from 'react-bootstrap'
import { FaCrown } from 'react-icons/fa'
import { UserImage } from 'web_core/assets/user'
import { useAccount } from 'web_core/context/account'
import { useAuthState } from 'web_core/firebase/hooks'
import { useStripePortal } from 'web_core/hooks'
import { Designed } from 'web_core/types'
import { logEvent } from 'web_core/utils'

export const PlanInfo = (props: Designed) => {
  const { account } = useAccount()
  const { profile } = useAuthState()
  const { openPortal, opening } = useStripePortal()
  if (!account || !profile) return <></>
  return (
    <div {...props}>
      <UserImage profile={profile} size={40} className="mb-2" />
      <div className="small mb-1">{profile.display_name}</div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="small">
          {account.plan === 'Premium' && (
            <FaCrown style={{ color: 'rgb(219, 181, 45)' }} className="me-1" />
          )}
          <Link href={'/pricing'} passHref>
            <a>{account.plan === 'Premium' ? 'プレミアム' : '無料'}プラン</a>
          </Link>
        </div>
        {account.plan === 'Premium' ? (
          <Button
            size="sm"
            onClick={openPortal}
            variant="link"
            disabled={opening}
          >
            管理
          </Button>
        ) : (
          <div className="small">
            <Link
              href={'/pricing'}
              onClick={() => {
                // ヘッダーメニューからアップグレードしようとした際のイベント収集
                logEvent('tap_header_plan_upgrade')
              }}
              passHref
            >
              <a>アップグレード</a>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
