import { Stack } from 'react-bootstrap'
import { BlockTagItem, TagFollowButton, TagRelationshipsCounter } from '.'
import { Tag } from '../../firebase'
import { Designed } from '../../types'

type Props = {
  tag: Tag
}

export const TagInfo = ({ tag, ...wrapperProps }: Designed<Props>) => {
  return (
    <div {...wrapperProps}>
      <Stack gap={4} className="border rounded p-4">
        <BlockTagItem tag={tag} showFollowButton={false} className="w-100 " />
        <Stack direction="horizontal" gap={3} className="mw-100">
          <TagRelationshipsCounter
            tag={tag}
            type="contributors"
            className="text-muted text-decoration-none"
          />
          <TagRelationshipsCounter
            tag={tag}
            type="followers"
            className="text-muted text-decoration-none"
          />
        </Stack>
        <TagFollowButton tag={tag} type="text" size="sm" className="w-100" />
      </Stack>
    </div>
  )
}
