import { RefObject, memo, useState } from 'react'
import { Badge, FormCheck } from 'react-bootstrap'
import { FaPlay } from 'react-icons/fa'
import { Design, Mathdown } from '../../../../../assets/mathdown/compiler'
import { Macro, Reference } from '../../../../../firebase'
import { PromiseVoid } from '../../../../../types'
import { WindowMode } from '../../types'

type Props = {
  _ref?: RefObject<HTMLDivElement | null>
  value: string
  onPreviewButtonClick: () => PromiseVoid
  onAutoPreviewChange: (autoPreview: boolean) => PromiseVoid
  design?: Design
  windowMode: WindowMode
  macros?: Macro[]
  references?: Reference[]
}

const PreviewerImpl = ({
  _ref,
  value,
  windowMode,
  onPreviewButtonClick: handlePreviewButtonClick,
  onAutoPreviewChange: handleAutoPreviewChange,
  design,
  macros,
  references,
}: Props) => {
  const [autoPreview, setAutoPreview] = useState(true)
  return (
    <div className="h-100 d-flex flex-column">
      {windowMode === 'both' && (
        <div className="d-flex align-items-center gap-4 border-bottom pb-2 mb-2">
          <FormCheck
            type="switch"
            className="align-self-center mb-0"
            label="自動プレビュー"
            checked={autoPreview}
            onChange={async (e) => {
              setAutoPreview(e.currentTarget.checked)
              await handleAutoPreviewChange(e.currentTarget.checked)
            }}
          />
          {!autoPreview && (
            <div className="d-flex align-items-center gap-2 flex-shrink-0">
              <FaPlay
                className="text-success flex-shrink-0"
                onClick={handlePreviewButtonClick}
              />
              <span className="flex-shrink-0">
                プレビュー
                <Badge className="bg-secondary ms-2">Ctrl+E</Badge>
              </span>
            </div>
          )}
          <hr className="my-2" />
        </div>
      )}
      <Mathdown
        _ref={_ref}
        mathdown={value}
        className="overflow-auto position-relative flex-grow-1 pe-2"
        design={design}
        macros={macros}
        references={references}
        dynamic
      />
    </div>
  )
}

export const Previewer = memo(PreviewerImpl)
