import { createContext, useContext, useState } from 'react'
import { Content } from '../shared/firebase'
import { PromiseVoid } from '../types'

type ProviderValue = {
  goodCount: number
  badCount: number
  handleChangeGoodCount?: (
    added: number,
    isDecrementBad: boolean
  ) => PromiseVoid
  handleChangeBadCount?: (
    added: number,
    isDecrementGood: boolean
  ) => PromiseVoid
}

export const EvaluationContext = createContext<ProviderValue>({
  goodCount: 0,
  badCount: 0,
})

type ProviderProps = {
  children: React.ReactNode
  content: Content
}

export const EvaluationProvider = ({ children, content }: ProviderProps) => {
  const [goodCount, setGoodCount] = useState<number>(content.good_count)
  const [badCount, setBadCount] = useState<number>(content.bad_count)

  return (
    <EvaluationContext.Provider
      value={{
        goodCount: goodCount,
        badCount: badCount,
        handleChangeGoodCount: (added, isDecrementBad) => {
          setGoodCount((current) => current + added)
          if (isDecrementBad) setBadCount((current) => current - 1)
        },
        handleChangeBadCount: (added, isDecrementGood) => {
          setBadCount((current) => current + added)
          if (isDecrementGood) setGoodCount((current) => current - 1)
        },
      }}
    >
      {children}
    </EvaluationContext.Provider>
  )
}

export const useEvaluation = () => useContext(EvaluationContext)
