import { QueryDocumentSnapshot } from 'firebase/firestore'
import { useCallback, useRef, useState } from 'react'
import {
  getDoc,
  getDocs,
  Profile,
  profileRef,
  TagFollower,
} from '../../firebase'
import { sortedTagFollowersRef } from '../../models/follow'

const FOLLOWERS_LIMIT = 20

export const useTagFollowers = (tagId: string) => {
  const lastFollower = useRef<QueryDocumentSnapshot<TagFollower> | null>(null)
  const [followers, setFollowers] = useState<Profile[]>([])
  const [fetching, setFetching] = useState(false)
  const [isLast, setIsLast] = useState(false)

  const loadMore = useCallback(async () => {
    if (fetching) return
    if (isLast) return
    setFetching(true)
    const loadedFollowersSnapshot = await getDocs(
      sortedTagFollowersRef(tagId, lastFollower.current, FOLLOWERS_LIMIT)
    )
    const loadedFollowerDocs = loadedFollowersSnapshot.docs
    const loadedFollowers = await Promise.all(
      loadedFollowerDocs.map(async (doc) => {
        const tagId = doc.id
        const profileSnapshot = await getDoc(profileRef(tagId))
        const user = profileSnapshot.data()
        return user
      })
    )
    const filteredLoadedFollowers = loadedFollowers.filter(
      (user) => user !== undefined
    ) as Profile[] // undefinedを除去したのでasを使って型を強制変更
    setFollowers([...followers, ...filteredLoadedFollowers])
    if (!loadedFollowersSnapshot.empty) {
      lastFollower.current = loadedFollowerDocs.slice(-1)[0]
    }
    if (loadedFollowerDocs.length < FOLLOWERS_LIMIT) {
      setIsLast(true)
    }
    setFetching(false)
  }, [followers, fetching, isLast])

  return { loadMore, followers, isLast, fetching }
}
