import { Content } from '../firebase'
import { getSigmoidPainless, sigmoid } from './sigmoid'

export const ContentEvaluationIndicator = {
  weights: {
    good_count: 1.0,
    bad_count: -1.0,
    reports_score: -1.5,
    // 追加する場合はcalcAuthorEvaluation関数も更新すること
  },
  sigmoid: {
    a: 1 / 8,
  },
}

export const getContentEvaluationPainless = () => {
  const weightKeys = Object.keys(
    ContentEvaluationIndicator['weights']
  ) as (keyof typeof ContentEvaluationIndicator['weights'])[]
  const x = weightKeys
    .map(
      (key) =>
        `${ContentEvaluationIndicator['weights'][key]} * doc['${key}'].value`
    )
    .join(' + ')
  return getSigmoidPainless(x, ContentEvaluationIndicator['sigmoid'])
}

export const calcContentEvaluation = (content: Content) => {
  const weights = ContentEvaluationIndicator.weights
  const { good_count, bad_count, reports_score } = content
  return sigmoid(
    good_count * weights['good_count'] +
      bad_count * weights['bad_count'] +
      reports_score * weights['reports_score'],
    ContentEvaluationIndicator.sigmoid.a
  )
}
