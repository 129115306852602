import { Node, Schema } from 'prosemirror-model'
import { EditorState, Plugin } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { memo, useEffect, useRef, useState } from 'react'
import {
  prosemirrorToYDoc,
  yCursorPlugin,
  ySyncPlugin,
  yUndoPlugin,
} from 'y-prosemirror'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
import { useAuthState, useAuthToken } from '../../../../../firebase/hooks'
import { Designed, PromiseVoid } from '../../../../../types'
import { IS_PROD, unique } from '../../../../../utils'

const CURSOR_COLORS = [
  '#0000FF', // 濃い青
  '#FF0000', // 赤
  '#008000', // 緑
  '#800080', // 濃い紫
  '#FFA500', // オレンジ
  '#FF1493', // 濃いピンク
  '#008B8B', // 濃いシアン
  '#32CD32', // ライムグリーン
  '#8B008B', // 濃いマゼンタ
  '#D2691E', // チョコレート
  '#EAA221', // マリーゴールド
  '#000080', // ネイビーブルー
  '#556B2F', // オリーブグリーン
  '#008080', // ティール
  '#C41E3A', // カーディナル
]

type Props = {
  editorContainerRefSetter: (elm: HTMLDivElement) => void
  defaultValue?: string
  onChange?: (value: string) => PromiseVoid
  toNode: (value: string) => Node
  fromNode: (node: Node) => string
  onCreateEditorView?: (view: EditorView) => PromiseVoid
  room?: string
  /** pluginsの動的な変更は未対応。webSocketProviderが存在する場合はpluginsも対応している必要がある。 */
  plugins?: Plugin[]
  schema: Schema
  onSave?: () => PromiseVoid
}

const NotSharedEditorImpl = ({
  editorContainerRefSetter: setEditorContainerRef,
  defaultValue,
  onChange: handleChange,
  onCreateEditorView: handleCreateEditorView,
  onSave: handleSave,
  schema,
  toNode,
  fromNode,
  plugins,
  className = '',
  style,
  room,
}: Designed<Props>) => {
  if (room !== undefined) throw new Error('Unexpected')

  const editorContainerRef = useRef<HTMLDivElement>(null)
  const [editorView, setEditorView] = useState<EditorView>()
  const saveTimer = useRef<null | NodeJS.Timeout>(null)

  useEffect(() => {
    if (editorContainerRef.current === null) return
    const doc = defaultValue !== undefined ? toNode(defaultValue) : undefined
    const state = EditorState.create({
      doc,
      schema,
      plugins,
    })
    editorContainerRef.current.innerHTML = ''
    const view = new EditorView(editorContainerRef.current, {
      state,
      async dispatchTransaction(transaction) {
        if (view === undefined) throw new Error('Unexpected error.')
        const newState = view.state.apply(transaction)
        view.updateState(newState)
        if (!transaction.docChanged) return
        const value = fromNode(view.state.doc)
        if (handleChange) await handleChange(value)
        if (saveTimer.current !== null) clearTimeout(saveTimer.current)
        saveTimer.current = setTimeout(async () => {
          if (handleSave) await handleSave()
        }, 1000)
      },
    })
    if (handleCreateEditorView) void handleCreateEditorView(view)
    setEditorView(view)
    setEditorContainerRef(editorContainerRef.current)
    return () => {
      if (saveTimer.current) clearTimeout(saveTimer.current)
      editorView?.destroy()
      if (handleSave) void handleSave()
    }
  }, [])

  return (
    <div
      className={`prosemirror-editor-container not-shared ${className}`}
      style={style}
      ref={editorContainerRef}
    />
  )
}

const NotSharedEditor = memo(NotSharedEditorImpl)

const SharedEditorImpl = ({
  editorContainerRefSetter: setEditorContainerRef,
  defaultValue,
  onChange: handleChange,
  onCreateEditorView: handleCreateEditorView,
  schema,
  toNode,
  fromNode,
  plugins,
  onSave: handleSave,
  className = '',
  style,
  room,
}: Designed<Props>) => {
  const { token } = useAuthToken()
  const { profile } = useAuthState()

  if (room === undefined) throw new Error('Unexpected')

  const editorContainerRef = useRef<HTMLDivElement>(null)
  const [editorState, setEditorState] = useState<EditorState | null>(null)
  const [editorView, setEditorView] = useState<EditorView>()
  const [synced, setSynced] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const isSaver = useRef<boolean>(false)
  const saveTimer = useRef<null | NodeJS.Timeout>(null)

  useEffect(() => {
    if (initialized) return
    if (editorContainerRef.current === null) return
    if (token === undefined) return
    if (profile === undefined || profile === null) return
    const yjsDoc = new Y.Doc()
    const provider = new WebsocketProvider(
      IS_PROD ? `wss://yjs.mathlog.info` : 'ws://localhost:8080',
      room,
      yjsDoc,
      {
        params: { token },
      }
    )
    // カーソルにユーザ名と色を設定（色は15色からランダムに選択）
    provider.awareness.setLocalStateField('user', {
      name: profile.display_name,
      color: CURSOR_COLORS[Math.floor(Math.random() * CURSOR_COLORS.length)],
      uid: profile.id,
    })
    const yXmlFragment = yjsDoc.getXmlFragment('prosemirror')

    // 初期値の設定 最初の同期が完了した時に実行
    provider.on('synced', () => {
      if (synced) return
      if (defaultValue === undefined) return
      // 共同編集に参加しているクライアントのセッションID
      const awarenessStates = provider.awareness.getStates()
      const clientIds = Array.from(awarenessStates.keys())
      // 共同編集に参加しているユーザのMathlogのユーザID
      const uids = unique(
        clientIds.map((clientId) => {
          const clientState = awarenessStates.get(clientId) as {
            user: { uid: string }
          }
          return clientState.user.uid
        })
      )
      // 最初の接続者の1つのクライアントのみ実行
      if (
        (uids.length === 1 && clientIds[0] === yjsDoc.clientID) ||
        yXmlFragment.toString() === ''
      ) {
        yXmlFragment.delete(0, yXmlFragment.length)
        const defaultDoc = toNode(defaultValue)
        const yDefaultDoc = prosemirrorToYDoc(defaultDoc)
        Y.applyUpdate(yjsDoc, Y.encodeStateAsUpdate(yDefaultDoc))
      }
      setSynced(true)
    })

    // 接続者数が変更されるたびに一番最初のクライアントをsaveする人にする
    // 重複してさまざまなクライアントでsaveすることを防止
    provider.awareness.on('change', () => {
      const clientIds = Array.from(provider.awareness.getStates().keys())
      isSaver.current = clientIds.length > 0 && clientIds[0] === yjsDoc.clientID
    })

    setEditorState(
      EditorState.create({
        schema,
        plugins: [
          ySyncPlugin(yXmlFragment),
          yCursorPlugin(provider.awareness),
          yUndoPlugin(),
          ...(plugins ?? []),
        ],
      })
    )
    setInitialized(true)

    return () => {
      provider.disconnect()
    }
  }, [token, profile])

  useEffect(() => {
    if (editorContainerRef.current === null) return
    if (editorState === null) return
    const view = new EditorView(editorContainerRef.current, {
      state: editorState,
      async dispatchTransaction(transaction) {
        if (view === undefined) throw new Error('Unexpected error.')
        const newState = view.state.apply(transaction)
        view.updateState(newState)
        if (!transaction.docChanged) return
        const value = fromNode(view.state.doc)
        if (handleChange) await handleChange(value)
        if (saveTimer.current !== null) clearTimeout(saveTimer.current)
        if (!isSaver.current) return
        saveTimer.current = setTimeout(async () => {
          if (handleSave) await handleSave()
        }, 1000)
      },
    })
    if (handleCreateEditorView) void handleCreateEditorView(view)
    setEditorView(view)
    setEditorContainerRef(editorContainerRef.current)
    return () => {
      isSaver.current = false
      if (saveTimer.current) clearTimeout(saveTimer.current)
      if (handleSave && isSaver.current) void handleSave()
      editorView?.destroy()
    }
  }, [synced])

  return (
    <div
      className={`prosemirror-editor-container shared ${className}`}
      style={style}
      ref={editorContainerRef}
    />
  )
}

const SharedEditor = memo(SharedEditorImpl)

const ProseMirrorEditorImpl = (props: Designed<Props>) => {
  // ソケット通信のサーバーが提供されている場合はリアルタイム編集同期が行われるようにする
  if (props.room !== undefined) return <SharedEditor {...props} />
  else return <NotSharedEditor {...props} />
}

export const ProseMirrorEditor = memo(ProseMirrorEditorImpl)
