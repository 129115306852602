export const list = (
  text: string,
  ordered: boolean,
  start: number | '',
  roman: boolean,
  parentheses: boolean,
  brackets: boolean,
  level: number
) => {
  if (ordered) {
    const setCount =
      start !== 1 && start !== '' ? `\\setcouner{enumi}{${start}}` : ''
    let classEnumerate = ''
    let indent = ''
    const classNames = []
    if (roman) classNames.push('\\roman*')
    else classNames.push('\\arabic*')
    if (parentheses) {
      classNames.unshift('(')
      classNames.push(')')
    }
    if (brackets) {
      classNames.unshift('{[}')
      classNames.push('{]}')
    }
    if (classNames.length === 1) {
      classEnumerate = '[label=' + classNames.join('') + '.]'
    } else {
      classEnumerate = '[label=' + classNames.join('') + ']'
    }
    for (let i = 0; i < level; i++) {
      indent += '    '
    }
    return `\n${indent}\\begin{enumerate}${classEnumerate}${
      setCount !== '' ? `  \n  ${indent}${setCount}\n` : ''
    }
${text}${indent}\\end{enumerate}\n\n`
  } else {
    let indent = ''
    for (let i = 0; i < level; i++) {
      indent += '    '
    }
    return `\n${indent}\\begin{itemize}
${text}${indent}\\end{itemize}\n\n`
  }
}
