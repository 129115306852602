import { Elements } from '@stripe/react-stripe-js'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa'
import { FaCircleCheck, FaCrown } from 'react-icons/fa6'
import { useAccount } from '../../context/account'
import { useStripeClient } from '../../hooks'
import { Interval } from '../../shared/stripe'
import { PromiseVoid } from '../../types'
import { logEvent } from '../../utils'
import { IconWrapper } from '../IconWrapper'
import { PremiumPlanPaymentForm } from './PremiumPlanPaymentForm'

type Props = {
  show: boolean
  introduction?: boolean
  onHide: () => PromiseVoid
  defaultInterval?: Interval
}

export const PremiumPlanPaymentPopup = ({
  defaultInterval,
  introduction = true,
  show,
  onHide: handleHide,
}: Props) => {
  const { account } = useAccount()
  const client = useStripeClient()
  const [showIntroduction, setShowIntroduction] = useState(introduction)

  // プレミアムプランのポップアップを開いた際のイベント収集
  useEffect(() => {
    if (!show) return
    logEvent('open_premium_popup')
  }, [show])

  if (client === undefined || !account) return <></>
  return (
    <Modal show={show} onHide={handleHide} backdrop="static">
      {showIntroduction ? (
        <>
          <Modal.Body className="p-0 text-center">
            <div className="bg-primary pt-5">
              <div className="text-end pe-sm-5 pe-3">
                <FaTimes className="cursor-pointer" onClick={handleHide} />
              </div>
              <p className="fs-7 mb-0 px-5">
                <FaCrown
                  style={{ color: 'rgb(219, 181, 45)' }}
                  className="d-none d-sm-inline"
                />
                <span className="mx-1">
                  この機能を利用するには
                  <br className="d-sm-none" />
                  プレミアムプランへの申し込みが必要です
                </span>
                <FaCrown
                  style={{ color: 'rgb(219, 181, 45)' }}
                  className="d-none d-sm-inline"
                />
              </p>
              <h2 className="my-2">Mathlogプレミアム</h2>
              <Image
                src="/images/premium_plan_popup_icon.png"
                alt="Mathlogプレミアムプラン"
                width={218}
                height={122.61}
              />
            </div>
            <section className="mt-5 mb-6">
              <h3 className="fs-6 fw-bold">プレミアムプランの機能一覧</h3>
              <div className="d-flex flex-column small gap-2 my-4">
                <div className="d-flex gap-1 gap-sm-7 mx-auto">
                  <IconWrapper suffix="共同編集" style={{ width: 140 }}>
                    <FaCircleCheck className="text-primary" />
                  </IconWrapper>
                  <IconWrapper
                    suffix="LaTeXエクスポート"
                    style={{ width: 140 }}
                  >
                    <FaCircleCheck className="text-primary" />
                  </IconWrapper>
                </div>
                <div className="d-flex gap-1 gap-sm-7 mx-auto">
                  <IconWrapper suffix="公開範囲の設定" style={{ width: 140 }}>
                    <FaCircleCheck className="text-primary" />
                  </IconWrapper>
                  <IconWrapper suffix="広告の非表示" style={{ width: 140 }}>
                    <FaCircleCheck className="text-primary" />
                  </IconWrapper>
                </div>
              </div>
              <div>
                <Link
                  href="/pricing"
                  onClick={() => {
                    // ポップアップから「プランの詳細」へのボタンを押した際のイベント収集
                    logEvent('tap_popup_plan_detail')
                  }}
                >
                  <a className="text-muted small" target="_blank">
                    プレミアムプランの詳細はこちら
                  </a>
                </Link>
              </div>
            </section>
            <div className="my-4">
              <Button
                onClick={() => {
                  // ポップアップから支払いフォームに移動した際のイベント収集
                  logEvent('move_to_payment')
                  setShowIntroduction(false)
                }}
                style={{ minWidth: '75%' }}
              >
                {account.has_tried_premium_plan
                  ? 'プラン開始の手続きへ'
                  : '1週間無料体験の申し込みへ'}
              </Button>
            </div>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title className="fs-6">
              Mathlogプレミアムに申し込む
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Elements stripe={client}>
              <PremiumPlanPaymentForm
                defaultInterval={defaultInterval}
                onSubmit={handleHide}
              />
            </Elements>
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}
