import { useEffect, useState } from 'react'
import { Badge, Button, Form, InputGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FaQuestionCircle } from 'react-icons/fa'
import { usePopupMessage } from '../../assets/messenger'
import { SpinnerButton } from '../../assets/spinners'
import {
  CreateParamsWithoutFieldValue,
  DocRef,
  Macro,
  MacrosParentRefAssignable,
  Package,
  ValidDocument,
  addDoc,
  macrosRef,
  updateDoc,
} from '../../firebase'
import { useAuthState } from '../../firebase/hooks'
import { Designed, PromiseVoid } from '../../types'
import { HELP_NOTION_LINKS, logEvent, range, remove } from '../../utils'

type Props<T extends ValidDocument> = {
  macro?: Macro
  onSave: (macro: DocRef<Macro>) => PromiseVoid
  onCancel: () => void
  parentRef: MacrosParentRefAssignable<T>
  packages?: Package[]
}

export const MacroForm = <T extends ValidDocument>({
  macro,
  parentRef,
  onSave: handleSave,
  onCancel: handleCancel,
  packages,
  ...containerProps
}: Designed<Props<T>>) => {
  const { user } = useAuthState()
  const { setPopupMessage } = usePopupMessage()
  const {
    register,
    getValues,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<CreateParamsWithoutFieldValue<Macro>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const saveMacro = async () => {
    const fields = getValues()
    const savedMacroRef = macro
      ? await updateDoc(macro.ref, {
          command_name: fields.command_name,
          arguments_count: fields.arguments_count,
          formula: fields.formula,
          package_id: fields.package_id !== '' ? fields.package_id : undefined,
        })
      : await addDoc(macrosRef(parentRef), {
          command_name: fields.command_name,
          arguments_count: fields.arguments_count,
          formula: fields.formula,
          package_id: fields.package_id !== '' ? fields.package_id : undefined,
        })
    setPopupMessage(macro ? 'マクロを更新しました。' : 'マクロを追加しました。')
    await handleSave(savedMacroRef)
  }

  const resetWithDefault = () =>
    reset({ arguments_count: 0, command_name: '', formula: '' })

  useEffect(() => {
    macro
      ? reset(remove(macro, ['id', 'parent_id', 'ref']))
      : resetWithDefault()
  }, [macro])

  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Form {...containerProps}>
      <Badge className="mb-2" bg={macro ? 'success' : 'primary'}>
        {macro ? '編集モード' : '追加モード'}
      </Badge>
      <div className="mb-6">
        <Form.Label>コマンド名</Form.Label>
        <InputGroup>
          <InputGroup.Text>\</InputGroup.Text>
          <Form.Control
            {...register('command_name', {
              required: true,
              pattern: {
                value: /^[a-zA-Z]*$/,
                message: '英文字のみ入力できます。',
              },
            })}
            isInvalid={!!errors.command_name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.command_name?.message}
          </Form.Control.Feedback>
        </InputGroup>
      </div>
      <div className="mb-6">
        <Form.Label>引数の個数</Form.Label>
        <Form.Select
          style={{ flex: 'none' }}
          {...register('arguments_count', {
            required: true,
          })}
        >
          {range(10).map((index) => (
            <option key={index} value={index}>
              {index}
            </option>
          ))}
        </Form.Select>
      </div>
      <div className="mb-6">
        <Form.Label>数式</Form.Label>
        <Form.Control
          {...register('formula', {
            required: true,
          })}
          isInvalid={!!errors.formula}
        />
        <Form.Control.Feedback type="invalid">
          {errors.formula?.message}
        </Form.Control.Feedback>
      </div>
      {packages && packages.length > 0 && (
        <div className="mb-6">
          <Form.Label>パッケージ</Form.Label>
          <Form.Select
            style={{ flex: 'none' }}
            {...register('package_id')}
            value={macro?.package_id}
          >
            <option value="" defaultChecked>
              {'指定なし'}
            </option>
            {packages.map((p) => {
              return (
                <option key={p.id} value={p.id} selected={p.id === 'default'}>
                  {p.name || '名称未設定'}
                </option>
              )
            })}
          </Form.Select>
        </div>
      )}
      <div className="d-flex gap-4">
        <SpinnerButton
          loading={isSubmitting}
          loadingText="保存中..."
          disabled={!isValid}
          onClick={async () => {
            setIsSubmitting(true)
            await saveMacro()
            resetWithDefault()
            setIsSubmitting(false)
          }}
        >
          {macro ? '更新' : '追加'}
        </SpinnerButton>
        <Button
          onClick={() => {
            resetWithDefault()
            handleCancel()
          }}
          variant="secondary"
          disabled={!isDirty && !macro}
        >
          キャンセル
        </Button>
      </div>
      <hr />
      <div className="d-flex align-items-center small text-muted mb-6 mb-md-0">
        <FaQuestionCircle className="me-1" />
        <span>マクロの追加方法や使い方は</span>
        <a
          href={HELP_NOTION_LINKS.MACROS_SETTING}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logEvent('tap_macro_help', {
              uid: user?.uid,
            })
          }
        >
          こちら
        </a>
      </div>
    </Form>
  )
}
