import { FormulaButtonData } from '../type'

const spaces: FormulaButtonData[] = [
  {
    formula: 'a \\ b',
    displayFormula: 'a \\ b',
    tooltip: '空白',
  },
  {
    formula: 'a \\quad b',
    displayFormula: 'a \\quad b',
    tooltip: '広い空白',
  },
  {
    formula: 'a \\qquad b',
    displayFormula: 'a \\qquad b',
    tooltip: '広い空白2',
    fontSize: 14,
    mobileFontSize: 11,
  },
  {
    formula: 'a \\hspace{ 10pt } b',
    displayFormula: 'a \\hspace{ 10pt } b',
    tooltip: '空白（サイズ指定）',
  },
  {
    formula: 'a \\! b',
    displayFormula: 'a \\! b',
    tooltip: '空白をなくす',
  },
  {
    formula: '\\begin{eqnarray}\n\taaa \\\\ bbb\n\\end{eqnarray}',
    displayFormula: '\\begin{eqnarray}\n\taaa \\\\ bbb\n\\end{eqnarray}',
    tooltip: '改行',
    mobileFontSize: 14,
  },
  {
    formula: '\\begin{eqnarray}\n\taaa \\\\[5pt] bbb\n\\end{eqnarray}',
    displayFormula: '\\begin{eqnarray}\n\taaa \\\\[5pt] bbb\n\\end{eqnarray}',
    tooltip: '改行（サイズ指定）',
    mobileFontSize: 14,
  },
]

export const spacesPill = {
  pill: 'spaces',
  label: '空白',
  buttonData: spaces,
}
