import { insertBold, insertDel, insertItalic } from '../../../../../commands'
import { OperationItem } from '../../OperationItem'

export const decorationDropdownData = [
  {
    component: OperationItem,
    children: <strong>強調</strong>,
    command: insertBold,
  },
  {
    component: OperationItem,
    children: <em>斜体</em>,
    command: insertItalic,
  },
  {
    component: OperationItem,
    children: <del>取り消し線</del>,
    command: insertDel,
  },
]
