import * as renderer from '../../renderer/tex'
import { TexImage, Tokens } from '../../types'
import { parseBlockToTex } from './block'

const parseListItemToTex = (
  item: Tokens.ListItem,
  loose: boolean,
  level: number,
  labels: Map<string, string>,
  images: Map<string, TexImage>
) => {
  let body = ''
  if (item.task) {
    const checkbox = renderer.checkbox(item.checked)
    if (loose) {
      if (item.tokens.length > 0 && item.tokens[0].type === 'paragraph') {
        item.tokens[0].text = checkbox + ' ' + item.tokens[0].text
        if (
          item.tokens[0].tokens !== undefined &&
          item.tokens[0].tokens.length > 0 &&
          item.tokens[0].tokens[0].type === 'text'
        ) {
          item.tokens[0].tokens[0].text =
            checkbox + ' ' + item.tokens[0].tokens[0].text
        }
      } else {
        item.tokens.unshift({
          type: 'text',
          text: checkbox,
          raw: checkbox,
        })
      }
    } else {
      body += checkbox
    }
  }
  level++
  body += parseBlockToTex(item.tokens, labels, images, undefined, loose)
  return renderer.listitem(body, level)
}

export const parseList = (
  token: Tokens.List,
  level: number,
  labels: Map<string, string>,
  images: Map<string, TexImage>
) => {
  let body = ''
  for (const item of token.items) {
    body += parseListItemToTex(item, token.loose, level, labels, images)
  }
  return renderer.list(
    body,
    token.ordered,
    token.start,
    token.roman,
    token.parentheses,
    token.brackets,
    level
  )
}
