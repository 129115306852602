import { EditorView } from 'prosemirror-view'
import { memo } from 'react'
import { getInsertFormulaCommand } from '../../../commands'
import { FormulaButton, FormulaButtonProps } from '../common/FormulaButton'

type Props = FormulaButtonProps & {
  editor: EditorView
}

// PCの方はボタンが押されたら数式を入れる処理を実行する必要がある
const PCFormulaPaletteButtonImpl = ({
  editor,
  onClick: handleClick,
  ...props
}: Props) => {
  return (
    <FormulaButton
      {...props}
      onClick={() => {
        const command = getInsertFormulaCommand(props.formula)
        // eslint-disable-next-line @typescript-eslint/unbound-method
        command(editor.state, editor.dispatch)
        if (handleClick) handleClick()
      }}
    />
  )
}

export const PCFormulaPaletteButton = memo(PCFormulaPaletteButtonImpl)
